import { Stack, Typography } from '@mui/material';

interface Props {
  title?: string;
  children: React.ReactNode;
}

export const IconList = ({ title, children }: Props) => {
  return (
    <Stack gap="1.6rem">
      {title !== undefined && <Typography variant="h5">{title}</Typography>}
      <Stack gap="2rem">{children}</Stack>
    </Stack>
  );
};

export { ListItem } from './ListItem';
