import { SHA224 } from 'crypto-es/lib/sha224';
import Session from 'api/storage/session';
import { STORAGE_SESSION_TIMEOUT } from 'config/constants';

export default class UserActivityTime {
  public static clearAll(): void {
    Object.keys(localStorage)
      .filter(x => x.startsWith(STORAGE_SESSION_TIMEOUT))
      .forEach(x => localStorage.removeItem(x));
  }

  public static clearUserTime() {
    const id = Session.decodeToken()?.['custom:userId'];
    if (!id) return false;

    const hash = SHA224(id);

    localStorage.removeItem(`${STORAGE_SESSION_TIMEOUT}/${hash}`);
    return true;
  }

  public static getUserTime(): Date | null {
    const id = Session.decodeToken()?.['custom:userId'];
    if (!id) return null;

    const hash = SHA224(id);

    const time = localStorage.getItem(`${STORAGE_SESSION_TIMEOUT}/${hash}`);

    if (!time) return null;

    try {
      const parsedTime = new Date(time);
      return parsedTime;
    } catch (e) {
      return null;
    }
  }

  public static saveUserTime(time: Date, userId?: string) {
    const id = userId ?? Session.decodeToken()?.['custom:userId'];
    if (!id) return false;

    const hash = SHA224(id);

    localStorage.setItem(
      `${STORAGE_SESSION_TIMEOUT}/${hash}`,
      time.toISOString(),
    );
    return true;
  }

  public static clearQualificationTime() {
    localStorage.removeItem(`${STORAGE_SESSION_TIMEOUT}`);
  }

  public static getQualificationTime(): Date | null {
    const time = localStorage.getItem(`${STORAGE_SESSION_TIMEOUT}`);

    if (!time) return null;

    try {
      const parsedTime = new Date(time);
      return parsedTime;
    } catch (e) {
      return null;
    }
  }

  public static saveQualificationTime(time: Date) {
    localStorage.setItem(`${STORAGE_SESSION_TIMEOUT}`, time.toISOString());
  }
}
