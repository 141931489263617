/**
 *
 * ErrorMessage
 *
 */
import * as React from 'react';

import { ErrorIcon } from 'assets/icons';
import { Fade, Typography } from '@mui/material';

import { Message as StyledMessage } from './styled';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  message?: string | JSX.Element;
  visible?: boolean;
  [key: string]: unknown;
  'data-cy'?: string;
}

export function ErrorMessage({ message, visible = true, ...props }: IProps) {
  return (
    <Fade in={!!message && visible} unmountOnExit aria-live="polite">
      <StyledMessage {...props}>
        <ErrorIcon />
        <Typography
          variant="body2"
          color="warning"
          data-cy={props['data-cy'] ?? 'error-message'}
          sx={{ minHeight: '2.4rem' }}
        >
          {message}
        </Typography>
      </StyledMessage>
    </Fade>
  );
}
