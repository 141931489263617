import { memo, useCallback, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import Config from 'config';
import {
  AgreementSelectionType,
  Agreement,
  AgreementDocument,
} from 'api/models/agreement';
import { PDFViewer } from 'components/PDFViewer';
import { messages } from '../../messages';
import { AgreementsForm } from '../../types';
import { Consent } from './Consent';

interface Props {
  onBack(agreements: AgreementsForm): void;
  stepForm: AgreementsForm;
  next(agreements: AgreementsForm): void;
  agreements: Agreement[];
  registryName: string;
}

export const AgreementsStep = memo(
  ({ onBack, next, stepForm, agreements, registryName }: Props) => {
    const { t } = useTranslation();
    const [selectedDocument, setSelectedDocument] =
      useState<AgreementDocument | null>(null);

    const clearDocument = useCallback(() => {
      setSelectedDocument(null);
    }, [setSelectedDocument]);

    const parseAgreements = (): AgreementsForm['agreements'] =>
      agreements.map(({ id, title, description, document }) => ({
        agreementId: id,
        agreementDocumentId: document?.versionId,
        agreed: false,
        snapshot: {
          agreement: { title, description },
        },
      }));

    const hasAgreementsChanged =
      agreements.length !== stepForm.agreements.length ||
      agreements.some(
        ({ id }) =>
          !stepForm.agreements.find(({ agreementId }) => id === agreementId),
      );

    const validAgreements = hasAgreementsChanged
      ? parseAgreements()
      : stepForm.agreements;

    return (
      <>
        <PDFViewer
          path={
            selectedDocument?.path
              ? Config.get().s3PublicBucketUrl + selectedDocument.path
              : undefined
          }
          title={selectedDocument?.title}
          open={selectedDocument !== null}
          onClose={clearDocument}
          gaSource="qualification_baseline"
        />
        <Formik
          initialValues={validAgreements}
          onSubmit={form => next({ agreements: form })}
          validateOnChange={false}
          validate={form => {
            const errors = {} as Record<string, string>;

            form.forEach(({ agreed, agreementId }, i) => {
              if (
                !agreed &&
                agreements[i].selectionType ===
                  AgreementSelectionType.REQUIRED_CHANGE_NOT_ALLOWED
              )
                errors[agreementId] = t(messages.thisConsentIsRequired());
            });

            return errors;
          }}
        >
          {({
            setFieldValue,
            setFieldTouched,
            setFieldError,
            values,
            errors,
            touched,
          }) => (
            <Form>
              <Stack
                spacing="1.6rem"
                direction="column"
                justifyContent="center"
              >
                {' '}
                <Typography
                  variant="body4"
                  textAlign="center"
                  color="button.primary"
                >
                  {registryName}
                </Typography>
                <Typography variant="h1" textAlign="center">
                  {t(messages.memberAgreements())}
                </Typography>
                <Typography variant="body1">
                  {t(messages.someConsentsAreRequired())}
                </Typography>
                {values.map((agreement, i) => (
                  <Consent
                    agreement={agreements[i]}
                    key={agreement.agreementId}
                    onOpenDocument={setSelectedDocument}
                    checked={agreement.agreed}
                    onCheck={(_, checked) => {
                      setFieldValue(`${i}.agreed`, checked);
                      setFieldTouched(`${i}.agreed`, true, false);

                      if (checked) {
                        setFieldError(agreement.agreementId, undefined);
                      }
                    }}
                    error={
                      touched[i]?.agreed &&
                      // wrongly typed
                      (errors as unknown as Record<string, string>)[
                        agreement.agreementId
                      ]
                    }
                  />
                ))}
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  data-cy="caregiver-contact-details-step-continue-button"
                  style={{ marginTop: '3.2rem' }}
                >
                  {t(messages.continue())}
                </Button>
                <Button
                  onClick={() => onBack({ agreements: values })}
                  color="primary"
                  variant="text"
                  data-cy="caregiver-contact-details-step-back-button"
                  style={{ marginTop: '3.2rem' }}
                >
                  {t(messages.back())}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </>
    );
  },
);
