import styled from 'styled-components';

export const SurveysContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: center;
  grid-gap: 1.6rem;
  padding-top: 1.6rem;
  > div {
    width: 100%;
    height: 100%;
  }
`;

export const StyledList = styled.ul`
  padding-left: 1.6rem;
  list-style: none;

  li {
    display: flex;
    gap: 0.8rem;
    align-items: center;

    > svg {
      color: ${({ theme }) => theme.palette.button.primary};
    }
  }
`;

export const FlexTabWrapper = styled.div`
  display: flex;
  gap: 0.6rem;
  align-items: start;

  > svg {
    min-width: 1.8rem;
    height: 1.8rem;
    margin-top: 0.1rem;
  }
`;
