import { memo, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Skeleton, Stack, Typography } from '@mui/material';
import Api from 'api/api';
import { queryKeys } from 'api/queryKeys';
import { EhrState } from 'api/models/HealthcareProviders';
import { errorToast, successToast } from 'helpers/toast';
import { clearLocationState } from 'helpers/clearLocationState';
import { useSetQueryData } from 'helpers/queries';
import { useConfig } from 'hooks/useConfig';
import { ConnectNewAccount } from './ConnectNewAccount';
import { Provider } from './Provider';
import { Params, HealthcareProvidersParams } from '../../types';
import { EhrContainer } from './styled';
import { messages } from './messages';

export const HealthcareProviders = memo(() => {
  const { ehrRedirectUri } = useConfig();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const location = useLocation();
  const setQueryData = useSetQueryData();
  const { healthcareProviders } = (location.state || {}) as Params;
  const { data } = useQuery({
    queryKey: queryKeys.healthcareProviders(),
    queryFn: Api.getHealthcareProviders,
  });

  const updateCache = (providerId: string) => {
    setQueryData(['healthcareProviders'], providers => {
      if (!providers) return providers;

      const copy = [...providers.healthcareProviders];
      const providerIndex = copy.findIndex(({ id }) => id === providerId);
      if (providerIndex === -1) return providers;

      const provider = copy[providerIndex];

      copy[providerIndex] = {
        ...provider,
        status: EhrState.paired,
      };

      return { healthcareProviders: copy };
    });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: Api.connectHealthcareProvider,
    onError: () => {
      errorToast(t(messages.unknownError()));
    },
    onSuccess: (_, { providerId }) => {
      queryClient.invalidateQueries(queryKeys.healthcareProviders());
      updateCache(providerId);
      successToast(
        t(messages.yourHealthcareProviderAccountConnectedSuccessfully()),
      );
    },
  });

  useEffect(() => {
    if (!healthcareProviders) return;
    try {
      const { code, providerId, error } =
        healthcareProviders as HealthcareProvidersParams;
      clearLocationState();

      if (error) errorToast(t(messages.unknownError()));

      const missingParams = !code || !providerId;
      if (missingParams) {
        return;
      }
      mutate({
        code,
        providerId,
        redirectUri: ehrRedirectUri,
      });
    } catch (e) {
      errorToast(t(messages.unknownError()));
    }
  }, [healthcareProviders]);

  const connectedProviders = data?.healthcareProviders.filter(
    provider => provider.status !== 'none',
  );

  const getLoader = () => (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      gap="2.4rem"
      justifyContent="space-between"
      alignItems={{ xs: 'start', md: 'center' }}
    >
      <Skeleton width="19.4rem" height="2.4rem" />
      <Skeleton width="21.7rem" height="2.4rem" />
    </Stack>
  );

  const getContent = () => {
    if (!data || !connectedProviders || isLoading) return getLoader();
    if (connectedProviders.length < 1)
      return (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          gap="2.4rem"
          justifyContent="space-between"
          alignItems={{ xs: 'start', md: 'center' }}
        >
          <Typography>{t(messages.noAccountConnectedYet())}</Typography>
          <ConnectNewAccount healthcareProviders={data.healthcareProviders} />
        </Stack>
      );

    return (
      <Stack gap="2.4rem">
        {connectedProviders.map(provider => (
          <Provider key={provider.id} {...provider} />
        ))}
      </Stack>
    );
  };

  return (
    <Stack>
      <Typography variant="h3">{t(messages.healthRecords())}</Typography>
      <Typography variant="body1">
        {t(messages.seamlesslyLinkYourPatientPortalAccount())}
      </Typography>
      <EhrContainer>{getContent()}</EhrContainer>
    </Stack>
  );
});
