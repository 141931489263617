import styled from 'styled-components';
import { ButtonBase } from '@mui/material';

export const EhrContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  background: ${({ theme }) => theme.palette.background.elevated.primary};
  border: 0.1rem solid ${({ theme }) => theme.palette.separator.primary};
  padding: 3.2rem;
  border-radius: 1.6rem;
  margin-top: 1.6rem;
`;

export const ProviderRadioIcon = styled.img`
  max-width: 4.8rem;
  max-height: 4.8rem;
  width: auto;
  height: auto;
`;

export const ProviderIcon = styled.img`
  max-width: 4.8rem;
  max-height: 4.8rem;
  width: auto;
  height: auto;
  border-radius: 1rem;
  background: ${({ theme }) => theme.palette.white};
  border: 0.1rem solid ${({ theme }) => theme.palette.separator.primary};
  padding: 0.4rem;
`;

export const ProviderRadioWrapper = styled(ButtonBase)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 1.6rem;
  width: 100%;
  height: 6.4rem;
  padding: 2rem 2.4rem;
  border: 0.15rem solid ${({ theme }) => theme.palette.separator.primary};
  border-radius: 1.6rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 2rem 1.6rem;
    height: auto;
  }
`;

export const ProviderWrapper = styled.div`
  display: flex;
  gap: 2.4rem;
  padding: 1.6rem;
  border-radius: 1.6rem;
  border: 0.1rem solid ${({ theme }) => theme.palette.separator.primary};
  background: ${({ theme }) => theme.palette.background.elevated.pressed};

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: end;
    justify-content: start;

    > div {
      width: 100%;
    }
  }
`;
