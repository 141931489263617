import { memo } from 'react';
import { FormContainer } from './components/FormContainer';
import { LoginForm } from './components/LoginForm';
import { Wrapper } from './styled';

interface Props {
  route: 'login' | 'register';
}

export const LoginPage = memo((props: Props) => {
  return (
    <Wrapper>
      <FormContainer>
        <LoginForm {...props} />
      </FormContainer>
    </Wrapper>
  );
});
