/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  login: () => _t(translations.pages.login.login, 'Log in'),
  emailOrPhoneNumber: () =>
    _t(translations.pages.login.emailOrPhoneNumber, 'Email or phone number'),
  next: () => _t(translations.pages.login.next, 'Next'),
  continue: () => _t(translations.pages.login.continue, 'Continue'),
  email: () => _t(translations.pages.login.email, 'email'),
  phoneNumber: () => _t(translations.pages.login.phoneNumber, 'phone number'),
  imNewUser: () => _t(translations.pages.login.imNewUser, 'I’m a new user - '),
  signUp: () => _t(translations.pages.login.signUp, 'Sign up'),
  typePassword: () =>
    _t(translations.pages.login.typePassword, 'Type password'),
  password: () => _t(translations.pages.login.password, 'Password'),
  forgotPassword: () =>
    _t(translations.pages.login.forgotPassword, 'Forgot password'),
  back: () => _t(translations.pages.login.back, 'Back'),
  incorrectPassword: () =>
    _t(translations.pages.login.incorrectPassword, 'Incorrect password.'),
  incorrectCode: () =>
    _t(
      translations.pages.login.incorrectCode,
      'Invalid code. Please make sure you enter the correct code.',
    ),
  codeExpired: () =>
    _t(translations.pages.login.codeExpired, 'Code expired. Log in again.'),
  noAccount: () =>
    _t(
      translations.pages.login.noAccount,
      'No account found with that {{type}}.',
    ),
  gotIt: () => _t(translations.pages.login.gotIt, 'Got it'),
  loginPage: () => _t(translations.pages.login.loginPage, 'Login page'),
  passwordReset: () =>
    _t(translations.pages.login.passwordReset, 'Password reset'),
  resetPassword: () =>
    _t(translations.pages.login.resetPassword, 'Reset password'),
  passwordResetDescription: () =>
    _t(
      translations.pages.login.passwordResetDescription,
      'Enter your email address and follow the instruction to reset the password.',
    ),
  checkYouEmail: () =>
    _t(translations.pages.login.checkYouEmail, 'Check your mail'),
  weSendEmail: () =>
    _t(
      translations.pages.login.weSendEmail,
      'We have sent you a message with a code to:',
    ),
  changeEmail: () =>
    _t(translations.pages.login.changeEmail, 'Change email address'),
  checkYourInbox: () =>
    _t(
      translations.pages.login.checkYourInbox,
      'Please check your inbox and enter a code from an email.',
    ),
  enterTheCode: () =>
    _t(translations.pages.login.enterTheCode, 'Enter the code'),
  resendEmail: () => _t(translations.pages.login.resendEmail, 'Resend email'),
  validateCode: () =>
    _t(translations.pages.login.validateCode, 'Validate the code'),
  weSendEmailEnterBelow: () =>
    _t(
      translations.pages.login.weSendEmailEnterBelow,
      'We have sent you a message with a code to {{email}}. Please enter it below to reset your password.',
    ),
  toResetEnterCode: () =>
    _t(
      translations.pages.login.toResetEnterCode,
      'To reset your password, please enter the code sent to: <0>{{username}}</0>',
    ),
  didntGetTheCode: () =>
    _t(translations.pages.login.didntGetTheCode, 'I didn’t get the code - '),
  enterNewPassword: () =>
    _t(translations.pages.login.enterNewPassword, 'Enter new password'),
  newPassword: () => _t(translations.pages.login.newPassword, 'New password'),
  chooseNewPassword: () =>
    _t(
      translations.pages.login.chooseNewPassword,
      'Choose a new password for: <0>{{username}}</0>',
    ),
  charactersMinimum: () =>
    _t(translations.pages.login.charactersMinimum, '8 characters minimum'),
  oneUppercase: () =>
    _t(translations.pages.login.oneUppercase, 'One uppercase character'),
  oneNumber: () => _t(translations.pages.login.oneNumber, 'One number'),
  invalidCode: () =>
    _t(
      translations.pages.login.invalidCode,
      'Invalid code. Please make sure you enter the correct code.',
    ),
  yourPasswordReset: () =>
    _t(
      translations.pages.login.yourPasswordReset,
      'Your password has been successfully updated',
    ),
  nowYouCanLogIn: () =>
    _t(
      translations.pages.login.nowYouCanLogIn,
      'Now you can log in using your new password.',
    ),
  verifyYourAccount: () =>
    _t(translations.pages.login.verifyYourAccount, 'Activate the account'),
  verifyYourAccountDescription: () =>
    _t(
      translations.pages.login.verifyYourAccountDescription,
      'The activation link was sent to the email <0>{{userEmail}}.<0>',
    ),
  yourAccountHasBeenActivated: () =>
    _t(
      translations.pages.login.yourAccountHasBeenActivated,
      'Congratulations! Your account has been successfully activated. You can now log in.',
    ),
  accountActivationError: () =>
    _t(
      translations.pages.login.accountActivationError,
      'Oops, something went wrong and we couldn’t activate your account. Please try again.',
    ),
  submit: () => _t(translations.pages.login.submit, 'Submit'),
  resendCodeDescription: () =>
    _t(
      translations.pages.login.resendCodeDescription,
      'It may take a moment to receive the code. Still don’t have it?',
    ),
  resendCode: () => _t(translations.pages.login.resendCode, 'Resend the code'),
  errorOccurredTryAgain: () =>
    _t(
      translations.pages.login.errorOccurredTryAgain,
      'Error occurred, try again later',
    ),
  accountAlreadyExists: () =>
    _t(
      translations.pages.login.accountAlreadyExists,
      'An account with this email address already exists. Please log in.',
    ),
  createAnAccount: () =>
    _t(translations.pages.login.createAnAccount, 'Create an account'),
  setUpPasswordDescription: () =>
    _t(
      translations.pages.login.setUpPasswordDescription,
      'Set up a password for the email provided in the application form to create an account.',
    ),
  createAccount: () =>
    _t(translations.pages.login.createAccount, 'Create account'),
  alreadyHaveAccount: () =>
    _t(translations.pages.login.alreadyHaveAccount, 'Already have account?'),
  didntGetLink: () =>
    _t(
      translations.pages.login.didntGetLink,
      "Didn't get any mail? - <0>Resend link</0>",
    ),
  tooManyAttempts: () =>
    _t(
      translations.pages.login.tooManyAttempts,
      'Too many login attempts, try again later',
    ),

  // validation messages

  emptyCode: () =>
    _t(translations.pages.login.emptyCode, 'Please fill in validation code'),
  usernameEmptyFieldMsg: () =>
    _t(
      translations.pages.login.usernameEmptyFieldMsg,
      'Fill in your email address.',
    ),
  invalidEmailPhone: () =>
    _t(
      translations.pages.login.invalidEmailPhone,
      'Please enter valid email or phone number.',
    ),
  invalidEmail: () =>
    _t(translations.pages.login.invalidEmail, 'Please enter valid email.'),
  passwordEmptyFieldMsg: () =>
    _t(
      translations.pages.login.passwordEmptyFieldMsg,
      'Please fill in your password.',
    ),
  iAgreeTo: () => _t(translations.pages.login.iAgreeTo, 'I agree to '),
  termsAndConditionsValidationMsg: () =>
    _t(
      translations.pages.login.termsAndConditionsValidationMsg,
      'You have to agree to Terms and Conditions',
    ),
  notAMemberYet: () =>
    _t(translations.pages.login.notAMemberYet, 'Not a member yet?'),
  joinTheRegistry: () =>
    _t(translations.pages.login.joinTheRegistry, 'Join the registry'),
  emailStep: () => _t(translations.pages.login.emailStep, 'Username step'),
  passwordStep: () =>
    _t(translations.pages.login.passwordStep, 'Password step'),
  validationStep: () =>
    _t(translations.pages.login.validationStep, 'Validation step'),
  verifyAccount: () =>
    _t(translations.pages.login.verifyAccount, 'Verify account'),
  resetPasswordEmailStep: () =>
    _t(
      translations.pages.login.resetPasswordEmailStep,
      'Reset password email step',
    ),
  resetPasswordCodeStep: () =>
    _t(
      translations.pages.login.resetPasswordCodeStep,
      'Reset password code step',
    ),
  resetPasswordSuccessScreen: () =>
    _t(
      translations.pages.login.resetPasswordSuccessScreen,
      'Reset password success screen',
    ),
  setUpPasswordStep: () =>
    _t(translations.pages.login.setUpPasswordStep, 'Set up password step'),
};
