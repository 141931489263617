export type Params = {
  redirectUri?: string;
  forwardedState?: unknown;
};

export enum FormSteps {
  USERNAME_STEP,
  PASSWORD_STEP,
  VALIDATION_STEP,
  VERIFY_ACCOUNT,
  RESET_PASSWORD_EMAIL_STEP,
  RESET_PASSWORD_CODE_STEP,
  RESET_PASSWORD_SUCCESS_SCREEN,
  SET_UP_PASSWORD_STEP,
}
