import * as yup from 'yup';
import i18next from 'i18next';
import { regexPatterns } from 'helpers/regexPatterns';
import { messages } from '../../messages';

export const getValidationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .required(i18next.t(messages.emptyEmail()))
      .email(i18next.t(messages.incorrectEmail())),
    phone: yup
      .string()
      .optional()
      .matches(
        regexPatterns.phoneNumber,
        i18next.t(messages.incorrectPhoneNumberFormat()),
      )
      .min(6, i18next.t(messages.incorrectPhoneNumberFormat())),
  });
