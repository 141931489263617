import { memo } from 'react';
import { StyledStep, StepArrow } from './styled';

interface Props extends React.HTMLAttributes<HTMLLIElement> {
  children: React.ReactNode;
  direction?: 'left' | 'right';
  component?: 'li' | 'div';
}

export const Step = memo(
  ({ children, direction, component = 'li', ...props }: Props) => {
    return (
      <StyledStep
        as={component}
        role={component === 'li' ? 'listitem' : 'group'}
        $direction={direction}
        {...props}
      >
        {children}
        <StepArrow $direction={direction} />
      </StyledStep>
    );
  },
);
