import styled from 'styled-components';

export const ConsentWrapper = styled.div<{ invalid?: boolean }>`
  padding: 2.4rem;
  transition: border-color 300ms;
  border: 0.15rem solid ${({ theme }) => theme.palette.separator.primary};
  border-color: ${({ invalid, theme }) =>
    invalid ? theme.palette.error.main : theme.palette.separator.primary};
  border-radius: 1.6rem;
  grid-gap: 1.6rem;
  display: flex;
  align-items: start;

  > span:first-child,
  > span:first-child > span {
    padding: 1.2rem;
    margin: -1.1rem -1.2rem;
  }
`;

export const RegistryConsentWrapper = styled.div<{ invalid?: boolean }>`
  grid-gap: 1.6rem;
  display: flex;
  align-items: start;
  padding-left: 2.4rem;

  > span:first-child,
  > span:first-child > span {
    padding: 1.2rem;
    margin: -1.1rem -1.2rem;
  }
`;
