import styled from 'styled-components';
import { Refresh } from 'iconsax-react';
import { ButtonBase, Typography } from '@mui/material';
import { useTypography } from 'helpers/mixins';
import { XIcon, WarningIcon } from 'assets/icons';

export const Container = styled.div<{ show: boolean }>`
  width: 100%;
  padding: 1.6rem;

  opacity: ${({ show }) => (show ? 1 : 0)};
  left: ${({ show }) => (show ? 0 : '-50%')};
  transition: opacity 300ms,
    left 0ms steps(2, jump-end) ${({ show }) => (show ? 0 : 300)}ms;
`;

export const Card = styled.div`
  position: relative;
  overflow: hidden;
  padding: 2rem;
  border-radius: 1.2rem;
  border: 0.1rem solid
    ${({ theme }) =>
      theme.palette.type === 'dark'
        ? theme.palette.separator.primary
        : theme.palette.additionalColors.bodyText};
  background: ${({ theme }) => theme.palette.additionalColors.background};

  h5 {
    color: ${({ theme }) => theme.palette.additionalColors.primaryText};
    margin-bottom: 0.8rem;
  }

  p {
    color: ${({ theme }) => theme.palette.additionalColors.secondaryText};
    margin-bottom: 0.8rem;
  }

  button,
  a {
    margin-top: 1.2rem;
    color: ${({ theme }) => theme.palette.additionalColors.primaryText};
    display: flex;
    align-items: center;
  }
`;

export const StyledReconnectIcon = styled(Refresh)`
  width: 1.8rem;
  height: 1.8rem;
  transition: color 300ms;
  align-self: baseline;
  margin-top: 0.12rem;

  path {
    stroke: currentColor !important;
  }
`;

export const WarningIndicator = styled(WarningIcon)`
  color: ${({ theme }) => theme.palette.additionalColors.tertiaryText};
  min-width: 1.8rem;
  height: 1.8rem;

  *[fill='#002E5F' i] {
    fill: ${({ theme }) => theme.palette.additionalColors.tertiaryText};
  }
  *[fill='#FF7B74' i] {
    fill: ${({ theme }) => theme.palette.additionalColors.accentError};
  }
`;

export const CloseIconWrapper = styled(ButtonBase)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transition: color 300ms;

  && {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    color: ${({ theme }) => theme.palette.additionalColors.secondaryText};
  }

  &:hover {
    color: ${({ theme }) => theme.palette.additionalColors.primaryText};
  }
`;

export const StyledCloseIcon = styled(XIcon)`
  width: 2rem;
  height: 2rem;

  path {
    stroke: currentColor !important;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: -0.5rem;
  right: -1.2rem;

  width: 12rem;
  height: 9.3rem;
  opacity: 0.1;
`;

export const EhrLogo = styled.img`
  max-width: 4.8rem;
  max-height: 4.8rem;
  width: auto;
  height: auto;
`;

export const EhrTitle = styled(Typography)<{ $displaySmallerCard: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ $displaySmallerCard }) =>
    $displaySmallerCard ? 1 : 2};
  -webkit-box-orient: vertical;
`;

export const ReconnectButton = styled.a`
  ${({ theme }) => useTypography(theme.typography.h4)}
  color: ${({ theme }) => theme.palette.additionalColors.primaryText};
  display: flex;
  align-items: center;
  justify-content: start;

  && {
    &:hover,
    &:active {
      color: ${({ theme }) => theme.palette.additionalColors.secondaryText};
    }
  }

  svg {
    margin-right: 0.8rem;
  }
`;
