import { memo } from 'react';
import { Stack, StackProps } from '@mui/material';
import { Loader } from 'components/Loader';
import { useAutofocus } from 'hooks/useAutofocus';

interface Props extends StackProps {
  autofocus?: boolean;
}

export const LoadingPage = memo(({ autofocus = true, ...props }: Props) => {
  const autofocusProps = useAutofocus();

  return (
    <Stack
      tabIndex={-1}
      justifyContent="center"
      alignItems="center"
      aria-live="polite"
      aria-busy="true"
      {...props}
      sx={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        backgroundColor: 'background.default',
        color: 'button.primary',
        ...props.sx,
      }}
      {...(autofocus && autofocusProps)}
    >
      <Loader />
    </Stack>
  );
});
