import { useState } from 'react';
import { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { StyledPhoneInput } from './styled';

// default width on most inputs
// should be changed quickly after first render
const initialWidth = 488;

export const BasePhoneInput = (props: PhoneInputProps) => {
  const [inputWidth, setInputWidth] = useState(initialWidth);
  useWindowDimensions();

  return (
    <StyledPhoneInput
      {...props}
      inputProps={{
        ...props.inputProps,
        ref: (x?: HTMLInputElement) => x && setInputWidth(x.offsetWidth),
      }}
      $width={inputWidth}
    ></StyledPhoneInput>
  );
};
