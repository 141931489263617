import { memo, useCallback, useId, useRef } from 'react';
import { tns, TinySliderInstance } from 'tiny-slider/src/tiny-slider';
import { ArrowRight } from 'iconsax-react';
import { Wrapper, CardsWrapper, StyledButton, ButtonContainer } from './styled';
import { baseConfig } from './constants';

interface Props {
  children: React.ReactNode;
}

export const SlideShow = memo(({ children }: Props) => {
  const sliderInstance = useRef<TinySliderInstance | null>(null);
  const prevButton = useId();
  const nextButton = useId();

  const initializeSlider = useCallback((element: HTMLUListElement | null) => {
    if (sliderInstance.current) sliderInstance.current.destroy();
    if (!element) return;

    sliderInstance.current = tns({
      ...baseConfig,
      container: element,
      prevButton: `[id="${prevButton}"]`,
      nextButton: `[id="${nextButton}"]`,
    });
  }, []);

  return (
    <Wrapper>
      <ButtonContainer>
        <StyledButton id={prevButton} $arrowMirrored>
          <ArrowRight />
        </StyledButton>
        <StyledButton id={nextButton}>
          <ArrowRight />
        </StyledButton>
      </ButtonContainer>
      <CardsWrapper ref={initializeSlider}>{children}</CardsWrapper>
    </Wrapper>
  );
});

export { Card } from './Card';
