import { useTheme } from 'styles/theme/ThemeProvider';

export const useProperlyThemedComponent = <T>({
  light,
  dark,
}: {
  light: T;
  dark: T;
}): T => {
  const theme = useTheme();

  if (theme.palette.type === 'dark') {
    return dark;
  }

  return light;
};
