import { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useLocation, generatePath, Link } from 'react-router-dom';
import { kebabCase } from 'lodash-es';
import Api from 'api/api';
import Session from 'api/storage/session';
import { queryKeys } from 'api/queryKeys';
import { Notepad2 as RegistryIcon } from 'iconsax-react';
import { Typography, Stack, ListItem, ListItemButton } from '@mui/material';
import { Tooltip } from 'components/Tooltip';
import { messages } from './messages';
import {
  SidebarLink,
  PageIconWrapper,
  ListItemRegistry,
  RegistriesWrapper,
} from './styled';
import { NumberOfSurveysIndicator } from './NumberOfSurveysIndicator';

interface Props {
  onClick(): void;
}

export const RegistriesLinks = memo(({ onClick }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { current: userEmail } = useRef(Session.decodeToken()?.email);
  const { data } = useQuery({
    queryKey: queryKeys.registries({ userEmail: userEmail! }),
    queryFn: Api.getRegistries,
    staleTime: 5 * 60 * 1000,
  });
  const registries = data?.registries;
  const inRegistry = location.pathname.startsWith(`/registries`);

  const renderRegistries = () => {
    if (!registries) return;

    return registries.map(({ id, name, showWarning, surveys }) => {
      const isSelected = location.pathname.startsWith(`/registries/${id}`);

      return (
        <ListItem key={id} disablePadding>
          <ListItemButton
            selected={isSelected}
            disableRipple
            role="link"
            component={Link}
            to={generatePath('/registries/:registryId', {
              registryId: id,
            })}
            state={{ id, name }}
            data-cy={`registry-${kebabCase(name)}-link`}
            onClick={onClick}
            {...(isSelected && { 'aria-current': 'page' })}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Tooltip
                title={name}
                showTooltipOnlyOnOverflow
                placement="right"
                arrow
                PopperProps={{
                  style: {
                    paddingLeft: '3.2rem',
                  },
                }}
              >
                <ListItemRegistry disableTypography>{name}</ListItemRegistry>
              </Tooltip>
              <NumberOfSurveysIndicator
                showWarning={showWarning}
                registryId={id}
                surveys={surveys}
              />
            </Stack>
          </ListItemButton>
        </ListItem>
      );
    });
  };

  const renderNoRegistries = () => {
    return (
      <SidebarLink
        $active={inRegistry}
        to={generatePath('/registries/')}
        data-cy={`registries-link`}
        onClick={onClick}
      >
        <PageIconWrapper active={inRegistry}>
          <RegistryIcon variant="Bold" aria-hidden="true" />
        </PageIconWrapper>
        <Typography
          variant="h5"
          color={
            inRegistry
              ? 'additionalColors.primaryText'
              : 'additionalColors.secondaryText'
          }
        >
          {t(messages.yourRegistries())}
        </Typography>
      </SidebarLink>
    );
  };

  const renderSingleRegistry = () => {
    if (!registries) return <></>;
    const [{ id, name, showWarning, surveys }] = registries;

    return (
      <SidebarLink
        $active={inRegistry}
        to={generatePath('/registries/:registryId', {
          registryId: id,
        })}
        data-cy={`registry-${kebabCase(name)}-link`}
        onClick={onClick}
      >
        <PageIconWrapper active={inRegistry}>
          <RegistryIcon variant="Bold" />
        </PageIconWrapper>
        <Tooltip
          title={name}
          showTooltipOnlyOnOverflow
          placement="right"
          arrow
          PopperProps={{
            style: {
              paddingLeft: '4rem',
            },
          }}
        >
          <Typography
            variant="h5"
            color={
              inRegistry
                ? 'additionalColors.primaryText'
                : 'additionalColors.secondaryText'
            }
            style={{
              whiteSpace: 'nowrap',
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </Typography>
        </Tooltip>
        <NumberOfSurveysIndicator
          showWarning={showWarning}
          registryId={id}
          surveys={surveys}
        />
      </SidebarLink>
    );
  };

  if (!registries?.length) return renderNoRegistries();
  if (registries?.length === 1) return renderSingleRegistry();

  return (
    <SidebarLink as="div" $active={inRegistry}>
      <Stack maxWidth="100%">
        <Stack direction="row" alignItems="center" gap="1.2rem">
          <PageIconWrapper active={inRegistry}>
            <RegistryIcon variant="Bold" />
          </PageIconWrapper>
          <Typography
            variant="h5"
            color={
              inRegistry
                ? 'additionalColors.primaryText'
                : 'additionalColors.secondaryText'
            }
          >
            {t(messages.yourRegistries())}
          </Typography>
        </Stack>
        <RegistriesWrapper>{renderRegistries()}</RegistriesWrapper>
      </Stack>
    </SidebarLink>
  );
});
