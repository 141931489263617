import { memo } from 'react';
import mixpanel from 'mixpanel-browser';
import { Stack, Typography, Button } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useLogoutTimer, LogoutTimerTypes } from 'hooks/useLogoutTimer';
import { useBooleanState } from 'hooks/useBooleanState';
import { useCountdown } from 'hooks/useCountdown';
import { useConfig } from 'hooks/useConfig';
import { SessionTimeoutIllustration } from 'assets/illustrations';
import { Modal } from 'components/Modal';
import { ModalContent } from 'components/Modal/ModalContent';
import { EventNames, TimeoutEventActions } from 'types/analytics';
import { messages } from './messages';

interface Props {
  onLogout?(): void;
  onExtendSession?(): void;
  type: LogoutTimerTypes;
}

export const AppLogout = memo(({ onLogout, onExtendSession, type }: Props) => {
  const { t } = useTranslation();
  const {
    value: showModal,
    setTrue: openModal,
    setFalse: closeModal,
  } = useBooleanState(false);
  const {
    autoLogout: { secondsToConfirm },
  } = useConfig();
  const { secondsLeft, resetTimer } = useCountdown({
    seconds: secondsToConfirm,
    onFinish: () => {
      if (onLogout && showModal) {
        mixpanel.track(EventNames.TIMEOUT_STATE_CHANGE, {
          action: TimeoutEventActions.EXTEND_SESSION_MODAL_TIMEOUT,
          timer_type: type,
        });

        onLogout();
      }
    },
  });
  const { addListeners } = useLogoutTimer({
    onTimeout: () => {
      resetTimer();
      openModal();
    },
    onImmediateTimeout: onLogout,
    type,
  });

  const handleExtend = () => {
    mixpanel.track(EventNames.TIMEOUT_STATE_CHANGE, {
      action: TimeoutEventActions.EXTEND_SESSION_MODAL_EXTENDED,
      timer_type: type,
    });

    addListeners();
    closeModal();
    if (onExtendSession) onExtendSession();
  };

  const handleLogout = () => {
    mixpanel.track(EventNames.TIMEOUT_STATE_CHANGE, {
      action: TimeoutEventActions.EXTEND_SESSION_MODAL_CLOSED,
      timer_type: type,
    });

    closeModal();
    if (onLogout) onLogout();
  };

  return (
    <Modal open={showModal} aria-live="assertive">
      <ModalContent>
        <Stack gap="1.6rem" alignItems="center" textAlign="center">
          <SessionTimeoutIllustration
            style={{ maxWidth: '41rem', marginBottom: '2.4rem' }}
          />
          <Typography variant="h1">{t(messages.stillWithUs())}</Typography>
          <span>
            <Trans
              as={Typography}
              variant="body1"
              i18nKey={messages.beenInactiveForWhile()}
              t={t}
              components={[
                <Typography
                  variant="h5"
                  color="button.primary"
                  component="span"
                />,
              ]}
              values={{ timeout: secondsLeft }}
            />
          </span>
        </Stack>
        <Stack
          direction={{
            md: 'row',
            xs: 'column-reverse',
          }}
          gap="2.4rem"
          width="100%"
          marginTop="3.2rem"
        >
          <Button
            onClick={handleLogout}
            variant="alternative"
            style={{ width: '100%' }}
            data-cy="sign-out-now-button"
          >
            {t(messages.signOutNow())}
          </Button>
          <Button
            onClick={handleExtend}
            style={{ width: '100%' }}
            data-cy="extend-session-button"
          >
            {t(messages.extendSession())}
          </Button>
        </Stack>
      </ModalContent>
    </Modal>
  );
});
