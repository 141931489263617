import { memo } from 'react';
import { useParams, useNavigate } from 'react-router';
import Api from 'api/api';
import { errorToast } from 'helpers/toast';
import { usePrefetchQuery, useQuery } from 'helpers/queries';
import { LoadingPage } from 'pages/Loading';
import { GetPublicRegistryResponse } from 'api/interfaces/response';
import { GlobalFormState, Params, QualificationSteps } from './types';
import { QualificationFormManager } from './QualificationFormManager';
import { initialGlobalState } from './constants';
import { PageWrapper } from './styled';
import { SidePanel } from './components/SidePanel';

const getInitialFormState = (
  registry: GetPublicRegistryResponse,
): GlobalFormState => {
  if (registry.roles.length === 1) {
    return {
      ...initialGlobalState,
      [QualificationSteps.ROLE_SELECTION]: {
        role: registry.roles[0],
      },
    };
  }

  return initialGlobalState;
};

export const QualificationPage = memo(() => {
  const { registryCode } = useParams<Params>();
  const navigate = useNavigate();
  const prefetchQuery = usePrefetchQuery();

  const { data, isLoading } = useQuery({
    queryKey: ['publicRegistry', { registryCode: registryCode! }],
    queryFn: () => Api.getPublicRegistry(registryCode!),
    staleTime: 30 * 60 * 1000,
    onError: e => {
      if (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e as any)?.response?.data?.message ===
        'Registry does not exist or user has no access to it'
      )
        return navigate('/not-found');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const message = (e as any)?.response?.data?.message ?? (e as any).message;
      errorToast(message);
    },
    onSuccess: registry => {
      if (registry.qualification?.survey)
        prefetchQuery(
          [
            'survey',
            {
              registryId: registry.id,
              surveyId: registry.qualification.survey.id,
              qualificationSurvey: true,
            },
          ],
          () => {
            return Api.getRegistryQualificationSurvey({
              registryId: registry.id,
              surveyId: registry.qualification.survey.id,
            });
          },
        );
    },
  });

  if (!data || isLoading) return <LoadingPage />;

  return (
    <PageWrapper>
      <SidePanel />
      <QualificationFormManager
        registry={data}
        initialFormState={getInitialFormState(data)}
      />
    </PageWrapper>
  );
});
