import { Components } from '@mui/material/styles/components';
import { ArrowDown2 as ArrowIcon } from 'iconsax-react';
import { typography } from 'styles/theme/themes/fonts';
import { Theme } from 'styles/theme/themes';

export const MuiAccordionSummary: Components<Theme>['MuiAccordionSummary'] = {
  defaultProps: {
    expandIcon: <ArrowIcon />,
  },
  styleOverrides: {
    root: ({ theme, color }) => ({
      ...typography(theme.palette, theme.breakpoints).h3,

      '.MuiAccordionSummary-expandIconWrapper': {
        '> svg': {
          width: '1.8rem',
          height: '1.8rem',
          color: theme.palette.text.primary,
        },
      },
    }),
  },
};
