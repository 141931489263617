import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as Email2IllustrationLight } from './email2.svg';
import { ReactComponent as Email2IllustrationDark } from './email2_dark.svg';

export const Email2Illustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return <Email2IllustrationDark aria-hidden="true" {...props} />;
    return <Email2IllustrationLight aria-hidden="true" {...props} />;
  },
);
