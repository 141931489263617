const formatEuPhoneNumber = (phoneNumber: string) => {
  const cleaned = phoneNumber.replace(/\D/g, '');
  const match = cleaned.match(/^([+]?\d?\d?|)?(\d{3})(\d{3})(\d{3})$/);

  if (match) {
    return [
      phoneNumber[0] === '+' ? '+' : '',
      match[1],
      match[1]?.length ? ' ' : '',
      match[2],
      '-',
      match[3],
      '-',
      match[4],
    ].join('');
  }
  return phoneNumber;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = phoneNumber.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return formatEuPhoneNumber(phoneNumber);
};
