import styled from 'styled-components';

export const AlreadyHaveAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2.7rem;
  margin: auto;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 3.2rem 0 0 0;
    flex-direction: row;
  }

  div {
    &:first-child {
      color: ${({ theme }) => theme.palette.text.primary};

      ${({ theme }) => theme.breakpoints.up('sm')} {
        &:after {
          content: '-';
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
`;
