import styled from 'styled-components';
import { SmsNotification } from 'iconsax-react';

export const IllustrationWrapper = styled.div`
  width: 50%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    margin-right: 0;
  }

  img {
    height: 100%;
    width: 100%;

    ${({ theme }) => theme.breakpoints.down('lg')} {
      width: calc(100% + 10rem);
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      width: calc(100% + 8rem);
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 100%;
      margin-right: 0;
    }
  }
`;

export const MailIcon = styled(SmsNotification).attrs(p => ({
  variant: 'Bold',
  ...p,
}))`
  background: ${({ theme }) => theme.palette.text.primary};
  border-radius: 1.6rem;
  width: 4.8rem;
  min-width: 4.8rem;
  height: 4.8rem;
  padding: 1.2rem;
  box-sizing: border-box;

  path:nth-child(1),
  path:nth-child(2) {
    fill: ${({ theme }) => theme.palette.button.primary};
  }
  path:nth-child(3) {
    fill: ${({ theme }) => theme.palette.button.text};
  }

  + a {
    color: ${({ theme }) => theme.palette.text.primary};

    :hover {
      color: ${({ theme }) => theme.palette.button.primary};
    }
  }
`;
