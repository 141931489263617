import { memo } from 'react';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { EventNames, SurveyCompletedEventActions } from 'types/analytics';
import { SurveyCompletedIllustration } from 'assets/illustrations';
import { SurveyLocationState } from 'pages/Survey/types';
import { StartButton } from 'components/StartButton';
import { useNumberOfTopSurveys } from 'api/storage/numberOfTopPrioritySurveys/hook';
import { Illustration } from './styled';
import { messages } from '../messages';
import { AvailableTabs, RegistryParams } from '../types';

interface Props {
  onClose(): void;
  registryName?: string;
  registryId?: string;
  nextSurveyId?: string;
  surveysLength: number;
}

export const RegularSurveyModalContent = memo(
  ({
    onClose,
    nextSurveyId,
    registryName,
    registryId,
    surveysLength,
  }: Props) => {
    const { t } = useTranslation();
    const { id, tab } = useParams<RegistryParams>();
    const { remainingTopSurveys } = useNumberOfTopSurveys(id!, surveysLength);

    const isSurveyAvailable =
      !nextSurveyId ||
      (remainingTopSurveys === 0 && tab !== AvailableTabs.ALL_SURVEYS);

    const getModalButtons = () => {
      if (isSurveyAvailable) {
        return null;
      }

      return (
        <Stack
          direction={{
            md: 'row',
            xs: 'column-reverse',
          }}
          alignItems="stretch"
          gap="2.4rem"
          width="100%"
          marginTop="4rem"
        >
          <Button
            onClick={() => {
              mixpanel.track(EventNames.SURVEY_COMPLETED_MODAL, {
                action: SurveyCompletedEventActions.NOT_NOW,
                registry_name: registryName,
                registry_id: registryId,
              });

              onClose();
            }}
            variant="alternative"
            style={{ width: '100%' }}
            data-cy="not-now"
          >
            {t(messages.notNowFinish())}
          </Button>
          <StartButton
            component={Link}
            to={generatePath('/registries/:registryId/survey/:surveyId', {
              registryId: id!,
              surveyId: nextSurveyId,
            })}
            state={
              { returnUrl: window.location.pathname } as SurveyLocationState
            }
            style={{ width: '100%' }}
            data-cy="start-next-survey"
            onClick={() => {
              mixpanel.track(EventNames.SURVEY_COMPLETED_MODAL, {
                action: SurveyCompletedEventActions.START_NEXT_SURVEY,
                registry_name: registryName,
                registry_id: registryId,
              });
            }}
          >
            {t(messages.startNextSurvey())}
          </StartButton>
        </Stack>
      );
    };

    return (
      <>
        <Illustration as={SurveyCompletedIllustration} />
        <Typography variant="h1" marginY="1.6rem" textAlign="center">
          {t(messages.surveyCompleted())}
        </Typography>
        <Typography variant="body1" textAlign="center">
          {t(messages.allAnswersHaveBeenSent())}
          {isSurveyAvailable || t(messages.doYouWantToStartNextSurvey())}
        </Typography>
        {getModalButtons()}
      </>
    );
  },
);
