export enum EhrState {
  paired = 'paired',
  unpaired = 'unpaired',
}

export enum EhrSystemType {
  epic = 'epic',
}

export enum EhrSystemAuthType {
  oauth2 = 'oauth2',
}

export interface AuthConfiguration {
  authUrl: string;
  webAuthUrl: string;
}

export type EhrStateResponse = EhrState | 'none';

export interface EhrSystemResponse {
  id: string;
  code: string;
  auth: {
    type: EhrSystemAuthType;
    configuration: AuthConfiguration;
  };
}

export interface HealthcareProviderResponse {
  id: string;
  name: string;
  iconPath: string;
  location?: string | null;
  status: EhrStateResponse;
  ehr: EhrSystemResponse;
}
