import styled, { css } from 'styled-components';
import { useTypography } from 'helpers/mixins';
import { ReactComponent as UnstyledStepArrow } from './stepArrow.svg';

export const StepsWrapper = styled.div`
  display: flex;
  justify-content: center;

  isolation: isolate;
`;

export const ListWrapper = styled.ol`
  max-width: 107.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    gap: 3.2rem;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 2.4rem;
  }

  > div {
    width: 100%;
    display: grid;
    flex-direction: row;
    justify-items: flex-start;
    position: relative;

    ${({ theme }) => theme.breakpoints.down('lg')} {
      width: fit-content;
    }

    :before {
      content: '';
      position: absolute;
      z-index: -1;
      height: calc(100% + 4.8rem);
      width: 0;
      top: 0;
      text-align: center;
      margin-right: -0.075rem;
      border-right: 0.15rem solid
        ${({ theme }) => theme.palette.additionalColors.bodyText};

      ${({ theme }) => theme.breakpoints.up('xs')} {
        left: 50%;
        right: 50%;
      }
      ${({ theme }) => theme.breakpoints.up('sm')} {
        left: 2rem;
      }
      ${({ theme }) => theme.breakpoints.up('lg')} {
        left: 50%;
        right: 50%;
      }
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
      grid-template-columns: auto 1fr;
      grid-gap: 2.4rem;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
      grid-template-columns: 1fr auto 1fr;
      grid-gap: 5.8rem;

      > li {
        grid-column: 1;
        grid-row: 1;
      }
      > span {
        grid-column: 2;
        grid-row: 1;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    > div:nth-child(even) {
      justify-items: flex-end;
      > li {
        grid-column: 3;
      }
    }
  }

  > div:last-child {
    padding-bottom: 5.6rem;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      padding-bottom: 7.2rem;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
      padding-bottom: 9.6rem;
    }

    ${({ theme }) => theme.breakpoints.down('xxl')} {
      padding-bottom: 12rem;
    }

    :before {
      border: none;
      width: 0.15rem;
      border-right-style: none;
      background-image: linear-gradient(
        transparent 50%,
        ${({ theme }) => theme.palette.additionalColors.bodyText} 0%
      );
      background-position: right;
      background-size: 0.15rem 1.2rem;
      background-repeat: repeat-y;
    }
  }
`;

export const StepNumberIndicator = styled.span`
  ${({ theme }) => useTypography(theme.typography.body3)}

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  border-radius: 1.6rem;

  background: ${({ theme }) => theme.palette.button.primary};
  color: ${({ theme }) => theme.palette.button.text};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const StyledStep = styled.li<{
  $direction?: 'left' | 'right';
}>`
  max-width: 44rem;
  border-radius: 2.4rem;
  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  position: relative;
  background: ${({ theme }) =>
    theme.palette.additionalColors.selectedBackground};

  > h1,
  h2,
  h3,
  p {
    color: ${({ theme }) => theme.palette.additionalColors.primaryText};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-left: 2rem;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    ${({ $direction }) => {
      if (!$direction) return null;

      return $direction === 'left'
        ? css`
            margin-left: 0;
            margin-right: 2rem;
          `
        : css`
            margin-left: 2rem;
          `;
    }};
  }
`;

export const StepArrow = styled(UnstyledStepArrow)<{
  $direction?: 'left' | 'right';
}>`
  width: 4.3rem;
  height: 3.8rem;
  position: absolute;
  top: 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    left: -1.9rem;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    ${({ $direction }) => {
      if (!$direction)
        return css`
          display: none;
        `;
      return $direction === 'left'
        ? css`
            left: unset;
            right: -1.9rem;
          `
        : css`
            left: -1.9rem;
          `;
    }};
  }

  *[fill='#002E5F' i] {
    fill: ${({ theme }) => theme.palette.additionalColors.selectedBackground};
  }
`;
