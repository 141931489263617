import styled from 'styled-components';
import {
  Accordion,
  AccordionSummary,
  Divider,
  Skeleton,
  Stack,
} from '@mui/material';
import { scrollbar } from 'helpers/mixins';

export const ScrollableAccordion = styled(Accordion)`
  flex-direction: column;
  overflow: auto;
  max-height: 100%;
  min-height: 4.8rem;

  ${({ theme }) =>
    scrollbar({
      thumbBackground: theme.palette.separator.primary,
      thumbHovered: theme.palette.separator.hover,
    })}

  display: flex;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: unset;
  }

  > .MuiCollapse-entered {
    overflow: auto;

    ${({ theme }) =>
      scrollbar({
        thumbBackground: theme.palette.separator.primary,
        thumbHovered: theme.palette.separator.hover,
      })}
  }
`;

export const SettingsDivider = styled(Divider)`
  color: ${({ theme }) => theme.palette.separator.primary};
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  border-radius: 1.6rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
`;

export const Wrapper = styled.div`
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 3.2rem;
  width: 100%;
  background: ${({ theme }) => theme.palette.background.elevated.primary};
  border: 0.1rem solid ${({ theme }) => theme.palette.separator.primary};
  border-radius: 1.6rem;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
`;

export const ConsentsWrapper = styled.div`
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding-right: 3.2rem;
  padding-left: 0.8rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  background: ${({ theme }) => theme.palette.background.elevated.primary};
  border: 0.1rem solid ${({ theme }) => theme.palette.separator.primary};
  border-radius: 1.6rem;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 0;
    padding-right: 1.6rem;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
`;

export const ConsentsDividerWrapper = styled.div`
  margin-left: 2.4rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 1.6rem;
  }
`;

export const EmailStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  gap: 2.4rem;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    flex-direction: column;
    gap: 0.8rem;
    align-items: flex-start;
  }
`;

export const PasswordStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  gap: 2.4rem;
`;

export const CustomAccordionSummary = styled(AccordionSummary)`
  padding-left: 2.4rem;
  font-size: 1.6rem;
`;

export const SwitchSkeleton = styled(Skeleton)`
  height: 2.7rem;
  width: 4.2rem;
  border-radius: 1.3rem;
`;
