import { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { produce } from 'immer';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Modal } from 'components/Modal';
import Api from 'api/api';
import Session from 'api/storage/session';
import { useUserRole } from 'api/storage/userRoles/hook';
import { errorToast, successToast } from 'helpers/toast';
import { useInvalidateQuery, useSetQueryData } from 'helpers/queries';
import { messages } from './messages';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  registryId: string;
  registryName: string;
  personId: string;
}

export const DeleteCaregiverRoleModal = memo(
  ({ showModal, closeModal, registryId, registryName, personId }: Props) => {
    const { t } = useTranslation();
    const setQueryData = useSetQueryData();
    const invalidateQuery = useInvalidateQuery();
    const { clearRole } = useUserRole(registryId);
    const { current: userEmail } = useRef(Session.decodeToken()?.email);

    const mutation = useMutation({
      mutationFn: () => Api.deleteRegistryPerson({ registryId, personId }),
      onSuccess: () => {
        closeModal();
        successToast(t(messages.participantsCaregiverRemoved()));
        setQueryData(
          [
            'registryPersons',
            {
              registryId,
              roles: ['caregiver'],
            },
          ],
          () => ({ persons: [] }),
        );
        invalidateQuery([
          'registryPersons',
          {
            registryId,
            roles: ['caregiver'],
          },
        ]);

        setQueryData(
          ['userRegistryNotifications', { userEmail: userEmail! }],
          data => {
            if (!data) return data;

            return produce(data, draft => {
              const registry = draft.registryNotifications.find(
                ({ registryId: rId }) => rId === registryId,
              );
              if (!registry) return;

              registry.surveysNotifications.persons = [];
            });
          },
        );
        invalidateQuery([
          'userRegistryNotifications',
          { userEmail: userEmail! },
        ]);

        clearRole();
      },
      onError: () => {
        closeModal();
        errorToast(t(messages.unknownError()));
      },
    });

    return (
      <Modal open={showModal} disableMobileFullScreen>
        <Stack paddingX={{ sm: '4rem', xs: '2.4rem' }} gap="3.2rem">
          <Stack gap="2.4rem">
            <Typography variant="h1">
              {t(messages.deleteCaregiverRole())}
            </Typography>
            <Typography variant="body1">
              {t(messages.thisWillRemoveCaregiverRole(), {
                registryName,
              })}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" gap="2.4rem">
            <Button
              variant="alternative"
              style={{ width: '100%' }}
              data-cy="cancel-delete-caregiver-button"
              onClick={closeModal}
            >
              {t(messages.cancel())}
            </Button>
            <LoadingButton
              style={{ width: '100%' }}
              data-cy="delete-caregiver-button"
              onClick={() => mutation.mutate()}
              loading={mutation.isLoading}
            >
              {t(messages.delete())}
            </LoadingButton>
          </Stack>
        </Stack>
      </Modal>
    );
  },
);
