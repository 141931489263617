import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  titleTemplate: () => _t(translations.app.titleTemplate, '%s - RNI Health'),
  defaultTitle: () =>
    _t(translations.app.defaultTitle, 'RNI Health - contributors app'),
  description: () =>
    _t(
      translations.app.description,
      'Primary purpose of the Health App is to connect with the users who are participants of a research study in understanding and finding the underlying causes of certain illnesses.',
    ),
};
