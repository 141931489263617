import React, { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Auth } from '@aws-amplify/auth';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';

import { ErrorMessage } from 'components/ErrorMessage/index';
import { useValidationSchema } from 'hooks/useValidationSchema';
import { errorToast } from 'helpers/toast';

import { messages } from '../../messages';
import { StyledInput } from './styled';
import { getValidationSchema } from './validators';

interface FormTypes {
  code: [string, string, string, string, string, string];
}

interface Props {
  cognitoUser: CognitoUserInterface;
  onSuccess(cognitoUser: CognitoUserInterface): void;
  username: string;
  password: string;
  onExpire(): void;
}

export const MFAStep = memo(
  ({ onSuccess, cognitoUser, username, password, onExpire }: Props) => {
    const { t } = useTranslation();
    const validationSchema = useValidationSchema(getValidationSchema);
    const inputRefs = useRef<HTMLInputElement[]>(new Array(6).fill(null));
    const { mutate, isLoading, error, reset } = useMutation<
      CognitoUserInterface,
      Record<string, unknown>,
      FormTypes
    >({
      mutationFn: async ({ code }) =>
        Auth.confirmSignIn(cognitoUser, code.join('')),
    });
    const [isResendActionBlocked, setIsResendActionBlocked] = useState(false);

    const submitForm = (form: FormTypes) => {
      mutate(form, {
        onSuccess: user => {
          onSuccess(user);
        },
        onError: e => {
          if (e?.code === 'NotAuthorizedException') {
            onExpire();
            errorToast(t(messages.codeExpired()), {
              position: 'top-right',
            });
          }
        },
      });
    };

    const mapError = () => {
      if (error?.code === 'CodeMismatchException')
        return t(messages.incorrectCode());
    };

    const getPhoneNumber = () => {
      const { CODE_DELIVERY_DESTINATION: phoneNumber } =
        cognitoUser.challengeParam;

      if (phoneNumber)
        return ` ••• - ••• - ${phoneNumber.substring(phoneNumber.length - 4)}`;
    };

    const resendCode = () => {
      if (!isResendActionBlocked) {
        setIsResendActionBlocked(true);
        Auth.signIn(username, password);

        // Resent actions is blocked for 1 minute
        setTimeout(() => setIsResendActionBlocked(false), 60 * 1000);
      }
    };
    return (
      <Formik
        initialValues={
          {
            code: Array(6).fill(''),
          } as FormTypes
        }
        onSubmit={submitForm}
        validationSchema={validationSchema}
      >
        {({ errors, touched, setFieldValue, validateForm, values }) => {
          const onInputChange = (
            index: number,
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => {
            reset();
            const { value } = e.target;
            setFieldValue(`code.${index}`, value.charAt(0), false);

            if (index <= 4 && Number(value) >= 0) {
              inputRefs.current[index + 1]?.focus();
            }
            setTimeout(() => {
              validateForm();
            }, 100);
          };

          const onKeyDown = (
            index: number,
            e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => {
            const { key } = e;
            const {
              target: { value },
            } = e as unknown as { target: { value: string } };

            switch (key) {
              case 'Backspace':
                if (value.length > 0) setFieldValue(`code.${index}`, '', false);
                else if (index > 0) {
                  setFieldValue(`code.${index - 1}`, '', false);
                  inputRefs.current[index - 1]?.focus();
                }
                e.preventDefault();
                break;
              case 'ArrowLeft':
                if (index > 0) inputRefs.current[index - 1]?.focus();
                e.preventDefault();
                break;
              case 'ArrowRight':
                if (index < 5) inputRefs.current[index + 1]?.focus();
                e.preventDefault();
                break;
              case 'ArrowUp':
                reset();
                setFieldValue(`code.${index}`, Number(value) + 1, false);
                e.preventDefault();
                break;
              case 'ArrowDown':
                reset();
                setFieldValue(`code.${index}`, (Number(value) + 9) % 10, false);
                e.preventDefault();
                break;
              default:
                break;
            }

            setTimeout(() => {
              validateForm();
            }, 100);
          };

          const onKeyPress = (e: React.KeyboardEvent) => {
            const { key } = e;
            if (key === 'Enter') return;
            if (!key.match(/^[0-9]+$/)) e.preventDefault();
          };

          const onPaste = (index: number, e: React.ClipboardEvent) => {
            reset();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const pasted = (e.clipboardData || window.clipboardData).getData(
              'text',
            );

            let pastedCounter = 0;
            pasted.split('').forEach(number => {
              if (index + pastedCounter > 5) return;
              if (number.match(/^[0-9]+$/)) {
                setFieldValue(`code.${index + pastedCounter}`, number, false);
                pastedCounter += 1;
              }
            });

            const toFocus =
              index + pastedCounter > 5 ? 5 : index + pastedCounter;
            inputRefs.current[toFocus]?.focus();
            e.preventDefault();

            setTimeout(() => {
              validateForm();
            }, 100);
          };

          return (
            <Form data-cy="mfa-code-step">
              <Stack
                spacing="2.4rem"
                direction="column"
                justifyContent="center"
              >
                <Typography variant="h1">
                  {t(messages.verifyYourAccount())}
                </Typography>
                <Typography variant="body1" style={{ marginTop: '1.6rem' }}>
                  {t(messages.verifyYourAccountDescription())}
                  {getPhoneNumber()}
                </Typography>
                <Stack
                  direction="row"
                  gap="0.8rem"
                  data-cy="code-inputs-container"
                >
                  {values.code.map((code, index) => (
                    <StyledInput
                      key={index}
                      value={code}
                      name={`code.${index}`}
                      onChange={e => onInputChange(index, e)}
                      onPaste={e => onPaste(index, e)}
                      InputProps={{
                        style: { padding: 0 },
                      }}
                      inputProps={{
                        onKeyDown: e => onKeyDown(index, e),
                        onKeyPress,
                        ref: (element: HTMLInputElement) => {
                          inputRefs.current[index] = element;
                        },
                        'data-cy': `code-${index}-input`,
                        style: { textAlign: 'center' },
                      }}
                    />
                  ))}
                </Stack>
                <ErrorMessage
                  message={mapError()}
                  visible={!(errors.code && touched.code)}
                  style={{ marginTop: '0.8rem' }}
                />
                <LoadingButton
                  type="submit"
                  loading={isLoading}
                  color="primary"
                  variant="contained"
                  data-cy="mfa-step-submit-button"
                >
                  {t(messages.submit())}
                </LoadingButton>
                <Button
                  variant="text"
                  onClick={resendCode}
                  data-cy="resend-code-button"
                >
                  {t(messages.resendCode())}
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    );
  },
);
