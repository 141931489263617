import styled from 'styled-components';
import { Button } from '@mui/material';
import abstractBackground from './assets/abstractBackground.svg';
import abstractBackgroundDark from './assets/abstractBackground_dark.svg';

export const PanelContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.palette.additionalColors.gradient};

  padding: 9.6rem 7.2rem 7.2rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 7.2rem 4.8rem 5.6rem;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 5.6rem 2.4rem 3.2rem;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    :before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: url(${({ theme }) =>
        theme.palette.type === 'dark'
          ? abstractBackgroundDark
          : abstractBackground});
      background-repeat: repeat-y;
      background-position: center -22rem;
      background-size: 228rem 124.7rem;
    }
  }
`;

export const ShowLessMoreButton = styled(Button)`
  align-self: center;
  margin-top: 6.6rem;
  transition: none;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    align-self: flex-start;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 5.8rem;
  }

  color: ${({ theme }) =>
    theme.palette.type === 'dark'
      ? theme.palette.button.primary
      : theme.palette.button.text};

  &&:hover,
  &&:active {
    background: transparent;
    color: ${({ theme }) =>
      theme.palette.type === 'dark'
        ? theme.palette.button.primary
        : theme.palette.button.text};
  }

  &&:focus-visible {
    border-radius: 1.6rem;
    border: ${({ theme }) => theme.palette.additionalColors.accentSuccess}B2
      0.4rem solid !important;
    padding: 0.4rem;
    margin -0.8rem; 
    margin-top: 5rem;
  }
`;
