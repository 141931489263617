import styled, { css } from 'styled-components';
import { Form } from 'formik';
import { useTypography } from 'helpers/mixins';

export const DataRowWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;

  > div {
    &:nth-child(1) {
      ${({ theme }) => useTypography(theme.typography.subtitle1)}
      margin-right: 1.6rem;
      text-transform: uppercase;
      color: ${({ theme }) => theme.palette.text.secondary};
    }

    &:nth-child(2) {
      ${({ theme }) => useTypography(theme.typography.body1)}
      padding-bottom: 0.2rem;
      word-break: break-word;
    }
  }
`;

export const SeparatingLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.palette.separator.primary};
`;

export const SectionWrapper = styled(Form)<{
  $active: boolean;
  $disabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 1.2rem;
  border: 0.1rem solid ${({ theme }) => theme.palette.separator.primary};
  padding: 1.6rem 1.6rem 2.4rem 1.6rem;
  transition: background 300ms, color 300ms;
  background: ${({ theme, $active }) =>
    $active ? theme.palette.background.base.primary : 'transparent'};

  > div {
    width: 100%;
  }

  ${({ theme, $disabled }) =>
    $disabled
      ? css`
          color: ${theme.palette.text.disabled};
        `
      : css`
          :hover {
            background: ${theme.palette.background.base.primary};
          }
        `}
`;
