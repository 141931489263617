import * as yup from 'yup';
import i18next from 'i18next';
import { messages } from '../../messages';

export const getValidationSchema = () =>
  yup.object().shape({
    username: yup
      .string()
      .required(i18next.t(messages.usernameEmptyFieldMsg()))
      .email(i18next.t(messages.invalidEmail())),

    // commented out as only email is supported for now
    //
    // username: yup.lazy((value: string) => {
    //   const basicValidation = yup
    //     .string()
    //     .required(i18next.t(messages.usernameEmptyFieldMsg()));

    //   if (value?.indexOf('@') !== -1)
    //     return basicValidation.email(i18next.t(messages.invalidEmailPhone()));

    //   return basicValidation.matches(
    //     regexPatterns.phoneNumber,
    //     i18next.t(messages.invalidEmailPhone()),
    //   );
    // }),
  });
