import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router';
import {
  Button,
  Fade,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { format } from 'date-fns';
import { GetRegistryResponse } from 'api/interfaces/response';
import Session from 'api/storage/session';
import Api from 'api/api';
import { queryKeys } from 'api/queryKeys';
import { LoadingPage } from 'pages/Loading';
import { useInvalidateQuery, usePrefetchQuery } from 'helpers/queries';
import { useAutofocus } from 'hooks/useAutofocus';
import { QualificationBaselineValidationIllustration } from 'assets/illustrations';
import { ParticipationConfirmationModal } from './ParticipationConfirmationModal';
import { AbsoluteOverlayContainer, OverlayContent } from './styled';
import { messages } from '../messages';
import { useSetQueryData } from '../../../helpers/queries';

interface Props {
  show: boolean;
  registry?: GetRegistryResponse;
}

export const ParticipationConfirmation = memo(({ show, registry }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: registryId } = useParams<{ id: string }>();
  const {
    current: { email: userEmail },
  } = useRef(Session.decodeToken() ?? { email: '' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const invalidateQuery = useInvalidateQuery();
  const prefetchQuery = usePrefetchQuery();
  const setQueryData = useSetQueryData();
  const closeModal = useCallback(
    () => setShowConfirmationModal(false),
    [setShowConfirmationModal],
  );
  const autofocus = useAutofocus(show);

  const registries = useQuery({
    queryKey: queryKeys.registries({ userEmail }),
    queryFn: Api.getRegistries,
  });

  const redirectToNextRegistry = () => {
    const nextRegistryId =
      registries.data?.registries.find(({ id }) => id !== registryId)?.id ?? '';

    navigate(`/registries/${nextRegistryId}`);
  };

  const updateCacheOnReject = () => {
    setQueryData(
      ['registries', { userEmail }],
      // eslint-disable-next-line @typescript-eslint/no-shadow
      registries => {
        if (!registries?.registries) return registries;

        const filtered = registries.registries.filter(
          ({ id }) => id !== registryId,
        );

        return { registries: filtered };
      },
    );
  };

  const {
    mutate,
    isLoading,
    status: queryStatus,
    reset,
  } = useMutation({
    mutationFn: (status: 'confirmed' | 'rejected') =>
      Api.setQualificationBaselineStatus({
        registryId: registryId!,
        registryParticipationId: registry!.participation.id,
        status,
      }),
    onSettled: (_data, _error, status) => {
      invalidateQuery(['registries', { userEmail }]);
      invalidateQuery(['userAgreements', { userEmail }]);
      invalidateQuery(['userRegistryNotifications', { userEmail }]);

      if (status === 'confirmed')
        prefetchQuery(
          ['userAgreements', { userEmail: userEmail! }],
          Api.getAgreements,
        );
    },
    onSuccess: (_data, status) => {
      closeModal();

      if (status === 'rejected') {
        updateCacheOnReject();
        redirectToNextRegistry();
      }
    },
  });

  useEffect(reset, [registryId]);

  const getFormattedDate = () => {
    if (!registry) return 'unknown date';

    return format(
      new Date(registry.participation.date),
      "MMMM do, y 'at' h:mm aaa",
    );
  };

  const content = (
    <OverlayContent data-cy="participation-confirmation-overlay" {...autofocus}>
      <Stack gap="2.4rem" direction="column" alignItems="center">
        <QualificationBaselineValidationIllustration
          style={{ maxWidth: '49.6rem', width: '100%' }}
        />
        <Typography variant="h1" data-cy="were-you-enrolled-to-at">
          {t(messages.wereYouEnrolledTo(), {
            registry: registry?.name,
            date: getFormattedDate(),
          })}
        </Typography>
        <Typography variant="body1">
          <Trans
            i18nKey={t(messages.weDetectedThatSomeone())}
            values={{ registry: registry?.name, email: userEmail }}
            components={[
              <strong
                data-cy="participant-email"
                style={{ wordBreak: 'break-word' }}
              />,
            ]}
          />
        </Typography>
      </Stack>
      <Stack
        gap={isMobile ? '1.6rem' : '2.4rem'}
        direction={isMobile ? 'column-reverse' : 'row'}
        justifyContent="stretch"
        width="100%"
      >
        <Button
          variant="alternative"
          onClick={() => setShowConfirmationModal(true)}
          disabled={isLoading}
          data-cy="was-not-enrolled"
        >
          {t(messages.noWasntEnrolled())}
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={() => mutate('confirmed')}
          data-cy="was-enrolled"
        >
          {t(messages.yesWasEnrolled())}
        </LoadingButton>
      </Stack>
    </OverlayContent>
  );

  return (
    <Fade in={show} appear={false}>
      <AbsoluteOverlayContainer>
        <ParticipationConfirmationModal
          open={showConfirmationModal}
          onClose={closeModal}
          registryName={registry?.name ?? ''}
          isLoading={isLoading}
          onConfirm={mutate}
        />
        {queryStatus === 'success' ? <LoadingPage /> : content}
      </AbsoluteOverlayContainer>
    </Fade>
  );
});
