import styled, { css } from 'styled-components';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import { scrollbar, useTypography } from 'helpers/mixins';

const defaultProps: PhoneInputProps = {
  preferredCountries: ['us'],
  country: 'us',
};

export const StyledPhoneInput = styled(ReactPhoneInput).attrs(props => ({
  ...defaultProps,
  ...props,
}))<{ $width?: number }>`
  && {
    input {
      background: ${({ theme }) => theme.palette.button.background};
      height: 5.2rem;
      width: 100%;
      border: 0.15rem solid ${({ theme }) => theme.palette.separator.primary};
      border-radius: 1.6rem;
      padding: 0.5rem 1.6rem;
      padding-left: 8.6rem; // to fit flag selection
      transition: border-color 300ms;

      color: ${({ theme }) => theme.palette.text.primary};
      ${({ theme }) => useTypography(theme.typography.h5)}

      &.open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .flag-dropdown {
      width: 7.6rem;
      background: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .selected-flag {
      width: 7.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent !important;
      height: 2.4rem;
      border-right: ${({ theme }) => theme.palette.separator.primary} 0.1rem
        solid;

      :hover {
        background: transparent;
      }

      .flag {
        margin-left: -18px;
        image-rendering: crisp-edges;
      }

      .arrow {
        width: 8px;
        height: 5px;
        margin-top: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid ${({ theme }) => theme.palette.text.secondary};
      }

      .arrow.up {
        margin-top: -0.1rem;
        border-top: 0;
        border-bottom: 4px solid ${({ theme }) => theme.palette.text.secondary};
      }
    }

    .country-list {
      top: 5.2rem;
      left: 0;
      margin: 0;
      box-shadow: none;
      border-radius: 0 0 1.6rem 1.6rem;
      border: 0.15rem solid ${({ theme }) => theme.palette.separator.primary};
      border-top: none;
      background: ${({ theme }) => theme.palette.button.background};

      ${({ theme }) => useTypography(theme.typography.body1)}
      color: ${({ theme }) => theme.palette.text.primary};

      ${({ $width }) =>
        $width &&
        css`
          width: ${$width}px;
        `}

      ${({ theme }) =>
        scrollbar({
          thumbBackground: theme.palette.separator.primary,
          thumbHovered: theme.palette.separator.hover,
        })}

      > li {
        background: transparent !important;
        padding: 1.3rem 1.6rem 1.3rem 4.8rem;
        transition: background 300ms;

        .flag {
          left: 1.6rem;
        }

        :hover {
          background: ${({ theme }) =>
            theme.palette.background.elevated.pressed} !important;
        }
      }

      .divider {
        border-color: ${({ theme }) => theme.palette.separator.primary};
        margin: 0 2rem;
        padding: 0;
      }

      .flag {
        top: unset !important;
      }
    }
  }
`;
