import styled, { css } from 'styled-components';
import { Stack } from '@mui/material';
import { ArrowIcon, XIcon } from '../../assets/icons';

const iconButtonCss = css`
  cursor: pointer;
  width: 3.2rem;
  min-width: 3.2rem;
  height: 3.2rem;
  padding: 0.8rem;
  border-radius: 1.6rem;
  transition: background 0.3s;

  path {
    stroke: ${({ theme }) => theme.palette.button.primary};
  }

  &:hover {
    background: ${({ theme }) => theme.palette.background.elevated.menuHover};
  }
`;

export const BackIcon = styled(ArrowIcon)`
  ${iconButtonCss};

  margin-top: 0;
  transform: rotate(180deg);
`;

export const CloseIcon = styled(XIcon)`
  ${iconButtonCss};

  && {
    margin-top: 0;
  }
`;

export const IconButtonsContainer = styled.div`
  position: sticky;
  top: 0rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.background.default};
  padding: 3.2rem 0 1.8rem 0;
  z-index: 3;
`;

export const FormModalStepContainer = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.palette.background.default};
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  padding: 0 3.2rem 3.2rem 3.2rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FormStackWrapper = styled(Stack)`
  flex-direction: column;
  justify-content: center;

  > svg {
    margin: auto;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
