/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  pageNotFound: () =>
    _t(translations.pages.notFound.pageNotFound, 'Page not found'),
  areYouLost: () => _t(translations.pages.notFound.areYouLost, 'Are you lost?'),
  pageDoesntExist: () =>
    _t(
      translations.pages.notFound.pageDoesntExist,
      "We are sorry, but the page you are looking for doesn't exist.",
    ),
  goToLoginPage: () =>
    _t(translations.pages.notFound.goToLoginPage, 'Go to log in page'),
  goToRegistryList: () =>
    _t(
      translations.pages.notFound.goToRegistryList,
      'Go to list of all registries',
    ),
};
