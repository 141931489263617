import { forwardRef, memo } from 'react';
import { wrapInTooltip } from 'components/Tooltip/wrapInTooltip';
import { ButtonBaseProps } from '@mui/material';
import { StyledButton } from './styled';

interface Props extends ButtonBaseProps {
  color?: 'text' | 'primary' | 'warning';
  size?: 'large' | 'medium';
  hoverMessage?: string;
  disabled?: boolean;
  disabledMessage?: string;
  children: React.ReactNode;
}

export const IconButton = memo(
  forwardRef<HTMLButtonElement, Props>(
    (
      {
        color = 'text',
        size = 'medium',
        hoverMessage,
        disabled = false,
        disabledMessage,
        children,
        ...props
      },
      ref,
    ) =>
      wrapInTooltip(
        <StyledButton
          $size={size}
          $color={color}
          disabled={disabled}
          ref={ref}
          {...props}
        >
          {children}
        </StyledButton>,
        hoverMessage,
        disabledMessage,
        disabled,
      ),
  ),
);
