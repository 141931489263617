import { Stack, Typography } from '@mui/material';
import { messages } from 'pages/Registry/messages';
import { SuccessIllustration } from 'assets/illustrations';
import { useTranslation } from 'react-i18next';
import {
  StyledButton,
  StyledDiv,
  StyledStack,
  SuccessIllustrationStack,
} from '../styled';

interface Props {
  loadNextBatch: () => void;
}

export const NoTopSurveysComponent = ({ loadNextBatch }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack height="100%" justifyContent="space-between">
      <SuccessIllustrationStack>
        <SuccessIllustration style={{ maxWidth: '100%' }} />
        <Stack gap="0.8rem" maxWidth="37.5rem">
          <Typography variant="h1" textAlign="center">
            {t(messages.wellDone())}
          </Typography>
          <Typography variant="body1" textAlign="center">
            {t(messages.youHaveSuccessfullyCompleted())}
          </Typography>
        </Stack>
      </SuccessIllustrationStack>
      <StyledDiv>
        <StyledStack>
          <Typography variant="body1" textAlign="left" width="100%">
            {t(messages.readyToTackleMore())}
          </Typography>
          <StyledButton onClick={loadNextBatch}>
            {t(messages.loadNextBatch())}
          </StyledButton>
        </StyledStack>
      </StyledDiv>
    </Stack>
  );
};
