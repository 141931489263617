import { memo } from 'react';
import { Form, Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { useValidationSchema } from 'hooks/useValidationSchema';
import { Link } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { FormikTextField } from 'components/FormikTextField';
import { ErrorMessage } from 'components/ErrorMessage';
import { AccountDetailsForm, QualificationFormRole } from '../../types';
import { getValidationSchema } from './validators';
import { messages } from '../../messages';

interface Props {
  onBack: (values: AccountDetailsForm) => void;
  onSubmit: (values: AccountDetailsForm) => void;
  stepForm: AccountDetailsForm;
  role: QualificationFormRole['code'];
  perspective: QualificationFormRole['perspective'];
  invalidEmail: string | null;
  registryName: string;
}

export const AccountDetailsStep = memo(
  ({
    onBack,
    onSubmit,
    stepForm,
    role,
    perspective,
    invalidEmail,
    registryName,
  }: Props) => {
    const { t } = useTranslation();
    const validationSchema = useValidationSchema(getValidationSchema);

    const getEmailAddressDescription = () => {
      if (perspective === 'first') {
        return t(messages.theEmailAddressGivenBelowMember());
      }

      if (perspective === 'third' && role === 'doctor') {
        return t(messages.theEmailAddressGivenBelowDoctor());
      }

      return t(messages.theEmailAddressGivenBelowCaregiver());
    };

    return (
      <Formik
        initialValues={stepForm}
        onSubmit={values => onSubmit(values)}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ values, errors }) => (
          <Form data-cy="account-details-step">
            <Stack spacing="1.6rem" direction="column" justifyContent="center">
              <Typography
                variant="body4"
                textAlign="center"
                color="button.primary"
              >
                {registryName}
              </Typography>
              <Typography variant="h1" textAlign="center">
                {t(messages.accountDetails())}
              </Typography>
              <Typography variant="body1">
                {getEmailAddressDescription()}
              </Typography>
              <FormikTextField
                name="email"
                type="email"
                placeholder={t(messages.email())}
                validationSchema={validationSchema}
                oneWayValidationOnChange={true}
                data-cy="email-input"
                label={t(messages.email())}
                InputProps={{
                  className:
                    values.email === invalidEmail
                      ? 'InvalidInputValue'
                      : undefined,
                }}
              />
              <ErrorMessage
                visible={values.email === invalidEmail}
                message={
                  <Trans
                    i18nKey={messages.anAccountWithThisEmailAlready()}
                    t={t}
                    values={{ registryName }}
                    components={[<Link to="/login" />]}
                  ></Trans>
                }
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                data-cy="account-details-step-continue-button"
                style={{ marginTop: '3rem' }}
                disabled={
                  values.email === invalidEmail ||
                  Object.values(errors).length > 0
                }
              >
                {t(messages.continue())}
              </Button>
              <Button
                onClick={() => onBack(values)}
                color="primary"
                variant="text"
                data-cy="account-details-step-back-button"
                style={{ marginTop: '3rem' }}
              >
                {t(messages.back())}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    );
  },
);
