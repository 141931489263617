import { useEffect } from 'react';

export const MouseDetector = () => {
  useEffect(() => {
    const onMouseDown = () => {
      document.body.classList.add('using-mouse');
    };
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        document.body.classList.remove('using-mouse');
      }
    };

    document.body.addEventListener('mousedown', onMouseDown);
    document.body.addEventListener('keydown', onKeyDown);

    return () => {
      document.body.removeEventListener('mousedown', onMouseDown);
      document.body.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return null;
};
