import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.background.elevated.primary};
  color: ${({ theme }) => theme.palette.text.primary};

  display: flex;
  flex-direction: column;
`;

export const RolesOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  background: ${({ theme }) => theme.palette.background.elevated.primary};
  display: flex;
  flex-direction: column;
`;

export const RolesOverlayContent = styled.div`
  gap: 3.2rem;
  height: 100%;
  width: 100%;
  transition: padding 300ms;
  padding: 4.8rem;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 4.8rem 2.4rem;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 4.8rem 1.2rem;
  }

  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
`;

export const SurveyInstructionWrapper = styled.div`
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: ${({ theme }) => theme.palette.background.elevated.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 7rem;

  > div {
    box-sizing: content-box;
    padding: 0.8rem;
    max-width: 64.8rem;
    ${({ theme }) => theme.breakpoints.down('lg')} {
      max-width: 49.8em;
      padding: 2.4rem;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      max-width: 100%;
    }

    > svg {
      width: 64.8rem;
      height: auto;
      max-width: 100%;
      margin-bottom: 4rem;
    }

    > button {
      margin-top: 0.8rem;
    }
  }
`;

export const UpperBarContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 3;
  transition: padding 300ms;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background: ${({ theme }) => theme.palette.background.elevated.primary};
  align-items: center;
  grid-column-gap: 0.8rem;

  > *:first-child {
    justify-self: start;
  }

  > *:nth-child(2) {
    justify-self: center;
  }

  > *:last-child {
    justify-self: end;
  }

  svg {
    margin: 0;
  }

  padding: 1.6rem 6rem 0.8rem 7.2rem;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 1.6rem 3.6rem 0.8rem 4.8rem;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1rem 1.2rem 1rem 2.4rem;
    grid-template-columns: auto 1fr;

    > *:nth-child(1) {
      grid-row: 2;
      justify-self: start;
    }

    > *:last-child {
      grid-row: 1/3;
    }
  }
`;
