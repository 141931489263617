import { useMediaQuery, useTheme } from '@mui/material';
import { MobileNavbar } from './MobileNavbar';
import { DesktopNavbar } from './DesktopNavbar';

export const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  if (isMobile) return <MobileNavbar />;
  return <DesktopNavbar />;
};
