import { memo, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { useMutation, useQuery } from '@tanstack/react-query';
import Api from 'api/api';
import { queryKeys } from 'api/queryKeys';
import { GetRegistrySurveyResponse } from 'api/interfaces/response';
import { errorToast } from 'helpers/toast';
import { EventNames, SurveyModuleEventActions } from 'types/analytics';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { SurveyModule } from 'components/SurveyModule';
import { SurveyResult } from 'components/SurveyModule/types';
import { UpperBar } from 'pages/Survey/UpperBar';
import { QualificationFormRole } from '../../types';
import { Wrapper } from './styled';

interface Props {
  qualificationSurveyId: string;
  role: Omit<QualificationFormRole, 'agreements'>;
  participationId: string;
  next(): void;
  registryId: string;
  back(): void;
}

export const QualificationSurveyStep = memo(
  ({
    qualificationSurveyId,
    role,
    participationId,
    registryId,
    next,
    back,
  }: Props) => {
    const [isSurveyModuleLoaded, setIsSurveyModuleLoaded] = useState(false);

    const { data, isLoading } = useQuery<
      GetRegistrySurveyResponse,
      { message: string }
    >({
      queryKey: queryKeys.survey({
        registryId: registryId!,
        surveyId: qualificationSurveyId,
        qualificationSurvey: true,
      }),
      queryFn: () => {
        return Api.getRegistryQualificationSurvey({
          registryId: registryId!,
          surveyId: qualificationSurveyId ?? '',
        });
      },
      enabled: qualificationSurveyId !== undefined,
      staleTime: 60 * 60 * 1000, // 1h
      onError: () => {
        errorToast('Unknown error occured, try again later');
        back();
      },
    });

    const { mutate, ...mutation } = useMutation({
      mutationFn: Api.submitPublicQualificationSurvey,
      onSuccess: () => next(),
      onError: () => {
        errorToast('Unknown error occured, try again later');
      },
    });

    const postResults = ({
      studyId,
      assignmentId,
      ...results
    }: SurveyResult) => {
      mutate({
        participationId,
        surveyId: qualificationSurveyId!,
        registryId: registryId!,
        roleId: role.id,
        ...results,
      });
    };

    return (
      <Wrapper>
        <LoadingOverlay
          in={isLoading || mutation.isLoading || !isSurveyModuleLoaded}
          appear={isSurveyModuleLoaded} // disable transition on first mount
        />
        <UpperBar
          role={role}
          qualificationSurvey
          onClose={() => {
            mixpanel.track(EventNames.BASELINE_SURVEY_MODULE, {
              action: SurveyModuleEventActions.SURVEY_CLOSED,
              survey_id: qualificationSurveyId,
              registry_id: registryId,
              qualification_survey: true,
              selected_role_id: role.id,
              selected_role_name: role.name,
              flutter_module_loaded: isSurveyModuleLoaded,
              survey_loaded: isLoading,
              number_of_questions: data?.questions.length,
              is_submitting: mutation.isLoading,
            });

            back();
          }}
        />
        <SurveyModule
          onSuccess={postResults}
          survey={data}
          onInitialized={() => setIsSurveyModuleLoaded(true)}
        />
      </Wrapper>
    );
  },
);
