/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  dashboard: () => _t(translations.pages.registry.dashboard, 'Dashboard'),
  registry: () =>
    _t(translations.pages.registry.registry, 'Registry {{name}} '),
  completeInAnyOrderYouChoose: () =>
    _t(
      translations.pages.registry.completeInAnyOrderYouChoose,
      'Complete the set of surveys we prepared for you, in any order you choose.',
    ),
  aboutRegistry: () =>
    _t(translations.pages.registry.aboutRegistry, 'About registry'),
  about: () => _t(translations.pages.registry.about, 'About'),
  showAll: () => _t(translations.pages.registry.showAll, 'Show all surveys'),
  showLess: () => _t(translations.pages.registry.showLess, 'Show less surveys'),
  topPriority: () =>
    _t(translations.pages.registry.topPriority, 'Top priority'),
  top: () => _t(translations.pages.registry.top, 'Top'),
  selectRole: () => _t(translations.pages.registry.selectRole, 'Select role'),
  continue: () => _t(translations.pages.registry.continue, 'Continue'),
  allSurveys: () => _t(translations.pages.registry.allSurveys, 'All surveys '),
  changeAssignment: () =>
    _t(translations.pages.registry.changeAssignment, 'Change assignment'),
  youAreCurrentlyAssigned: () =>
    _t(
      translations.pages.registry.youAreCurrentlyAssigned,
      'As a member of the {{registry}} you are assigned to:',
    ),
  weDisplaySurveysAccordingToGroups: () =>
    _t(
      translations.pages.registry.weDisplaySurveysAccordingToGroups,
      'We display surveys according to your assignment. If something has changed, let us know by clicking on the “Change assignment” button and fill out the qualification survey again.',
    ),
  youAreNotAssignedToAnyOfTheAreas: () =>
    _t(
      translations.pages.registry.youAreNotAssignedToAnyOfTheAreas,
      'You are not added to any of the assignments of the {{registry}}. ',
    ),
  wereYouEnrolledTo: () =>
    _t(
      translations.pages.registry.wereYouEnrolledTo,
      'Were you enrolled in {{registry}} on {{date}}?',
    ),
  weDetectedThatSomeone: () =>
    _t(
      translations.pages.registry.weDetectedThatSomeone,
      'We detected that someone with <0>{{email}}</0> email address was enrolled to {{registry}}.',
    ),
  noWasntEnrolled: () =>
    _t(translations.pages.registry.noWasntEnrolled, 'No, I wasn’t enrolled'),
  yesWasEnrolled: () =>
    _t(translations.pages.registry.yesWasEnrolled, 'Yes, I was enrolled'),
  areYouSureYourWerentEnrolledTo: () =>
    _t(
      translations.pages.registry.areYouSureYourWerentEnrolledTo,
      'Are you sure you weren’t enrolled to {{registry}}?',
    ),
  areYouSureYourWerentEnrolled: () =>
    _t(
      translations.pages.registry.areYouSureYourWerentEnrolled,
      'Are you sure you weren’t enrolled?',
    ),
  onceConfirmedYouCantChange: () =>
    _t(
      translations.pages.registry.onceConfirmedYouCantChange,
      "Once you confirm it, you won't be able to change it.",
    ),
  goBack: () => _t(translations.pages.registry.goBack, 'Go back'),
  iSureIwasntEnrolled: () =>
    _t(
      translations.pages.registry.iSureIwasntEnrolled,
      'I’m sure, I wasn’t enrolled',
    ),
  youAreAlmostThere: () =>
    _t(translations.pages.registry.youAreAlmostThere, 'You are almost there!'),
  itLooksLikeYouDidntFillQualificationSurvey: () =>
    _t(
      translations.pages.registry.itLooksLikeYouDidntFillQualificationSurvey,
      "It looks like you haven't completed the {{registry}} <0>qualification survey</0> yet. Qualification survey helps our researchers to assign you proper surveys.",
    ),
  fillOutQualificationSurvey: () =>
    _t(
      translations.pages.registry.fillOutQualificationSurvey,
      'Start qualification survey',
    ),
  allGoodForNow: () =>
    _t(translations.pages.registry.allGoodForNow, 'All good for now'),
  noSurveysAssigned: () =>
    _t(translations.pages.registry.noSurveysAssigned, 'No surveys assigned'),
  weDidntAssignYouToAnyWithing: () =>
    _t(
      translations.pages.registry.weDidntAssignYouToAnyWithing,
      'We didn’t assign you to any of the areas of the {{registry}}.\nWe will email you when the next survey will be available.',
    ),
  weWillEmailYouWhenSurveysBecomeAvailable: () =>
    _t(
      translations.pages.registry.weWillEmailYouWhenSurveysBecomeAvailable,
      'We will email you when surveys become available for you.',
    ),
  weWillEmailYou: () =>
    _t(
      translations.pages.registry.weWillEmailYou,
      'We will email you when the next survey will be available.',
    ),
  youAreNotPartOfAnyRegistry: () =>
    _t(
      translations.pages.registry.youAreNotPartOfAnyRegistry,
      'You are not a part of any registry yet',
    ),
  doYouWantToBecomePartOfCommunity: () =>
    _t(
      translations.pages.registry.doYouWantToBecomePartOfCommunity,
      'Do you want to become a part of our community? Browse the list of registries and join one. Together with researchers at RNI you can change the future.',
    ),
  browseRegistries: () =>
    _t(translations.pages.registry.browseRegistries, 'Browse the registries'),
  needHelp: () =>
    _t(translations.pages.registry.needHelp, 'Need help? - <0>{{email}}</0>'),
  congratulations: () =>
    _t(translations.pages.registry.congratulations, 'Congratulations!'),
  qualificationSurveyCompleted: () =>
    _t(
      translations.pages.registry.qualificationSurveyCompleted,
      'Qualification survey completed!',
    ),
  youEnrolledWillInformYou: () =>
    _t(
      translations.pages.registry.youEnrolledWillInformYou,
      'You enrolled to the {{registry}}. We will inform you when we have new surveys for you.',
    ),
  accordingToYourLatestWeWill: () =>
    _t(
      translations.pages.registry.accordingToYourLatestWeWill,
      'According to your latest answers in the qualification survey, we have decided not to add you to any of the {{registry}}’s assignments.',
    ),
  youEnrolledAndWereAssigned: () =>
    _t(
      translations.pages.registry.youEnrolledAndWereAssigned,
      'As a member of the {{registry}} you were assigned to:',
    ),
  completeSurveys: () =>
    _t(
      translations.pages.registry.completeSurveys,
      'Complete the set of surveys we prepared for you, in any order you choose.',
    ),
  youEnrolledCompleteSurveys: () =>
    _t(
      translations.pages.registry.youEnrolledCompleteSurveys,
      'You enrolled to {{registry}}. Complete the set of surveys we prepared for you, in any order you choose.',
    ),
  registryAreasChanged: () =>
    _t(
      translations.pages.registry.registryAreasChanged,
      'Registry assignment changed',
    ),
  weHaveChangedYourAreas: () =>
    _t(
      translations.pages.registry.weHaveChangedYourAreas,
      'We have changed your assignment according to your latest answers in the qualification survey. From now on, as a member of the {{registry}}, you are assigned to:',
    ),
  registryAreasUnchanged: () =>
    _t(
      translations.pages.registry.registryAreasUnchanged,
      'Registry assignment unchanged',
    ),
  didNotChangeDiagnosis: () =>
    _t(
      translations.pages.registry.didNotChangeDiagnosis,
      'According to your latest answers in qualification survey we have decided not to change your registry assignment. As a member of the {{registry}}, you are still assigned to:',
    ),
  surveyCompleted: () =>
    _t(translations.pages.registry.surveyCompleted, 'Survey completed!'),
  allAnswersHaveBeenSent: () =>
    _t(
      translations.pages.registry.allAnswersHaveBeenSent,
      'Great job! All your answers have been sent to our researchers. ',
    ),
  doYouWantToStartNextSurvey: () =>
    _t(
      translations.pages.registry.doYouWantToStartNextSurvey,
      'Remember that the more data you contribute, the faster science can move toward better conclusions. Do you want to start the next survey?',
    ),
  notNowFinish: () => _t(translations.pages.registry.notNowFinish, 'Not now'),
  startNextSurvey: () =>
    _t(translations.pages.registry.startNextSurvey, 'Start next survey'),
  description: () => _t(translations.pages.registry.description, 'Description'),
  caregiverDetails: () =>
    _t(translations.pages.registry.caregiverDetails, 'Caregiver details'),
  noCaregiverParticipates: () =>
    _t(
      translations.pages.registry.noCaregiverParticipates,
      'No Caregiver participates in this Registry with the Participant',
    ),
  addCaregiver: () =>
    _t(translations.pages.registry.addCaregiver, 'Add Caregiver'),
  relationshipToMember: () =>
    _t(
      translations.pages.registry.relationshipToMember,
      'Relationship to the member',
    ),
  firstName: () => _t(translations.pages.registry.firstName, 'First name'),
  lastName: () => _t(translations.pages.registry.lastName, 'Last name'),
  phoneNumber: () =>
    _t(translations.pages.registry.phoneNumber, 'Phone number (optional)'),
  emailAddress: () =>
    _t(translations.pages.registry.emailAddress, 'E-mail address'),
  learnMoreAboutRegistry: () =>
    _t(
      translations.pages.registry.learnMoreAboutRegistry,
      'Learn more about registry on ',
    ),
  here: () => _t(translations.pages.registry.here, 'here'),
  goals: () => _t(translations.pages.registry.goals, 'Goals'),
  wellDone: () => _t(translations.pages.registry.wellDone, 'Well done!'),
  youHaveSuccessfullyCompleted: () =>
    _t(
      translations.pages.registry.youHaveSuccessfullyCompleted,
      'You have successfully completed all the highest priority surveys.',
    ),
  loadNextBatch: () =>
    _t(translations.pages.registry.loadNextBatch, 'Load next batch'),
  readyToTackleMore: () =>
    _t(
      translations.pages.registry.readyToTackleMore,
      'Ready to tackle more surveys? Load the next batch now!',
    ),
  moreInfo: () => _t(translations.pages.registry.moreInfo, 'More info'),
  change: () => _t(translations.pages.registry.change, 'Change'),
  registryAssignments: () =>
    _t(translations.pages.registry.registryAssignments, 'Registry assignments'),
  selectYourRole: () =>
    _t(translations.pages.registry.selectYourRole, 'Select your role'),
  selectYourRoleDescription: () =>
    _t(
      translations.pages.registry.selectYourRoleDescription,
      'Please select your role in the “{{ registryName }}” to continue.\nYou can also change it later.',
    ),
  yourAssignments: () =>
    _t(translations.pages.registry.yourAssignments, 'Your assignments: '),
  yourRole: () => _t(translations.pages.registry.yourRole, 'Your\u00a0role:'),
  showMoreAssignments: () =>
    _t(translations.pages.registry.showMoreAssignments, 'Show more'),
  showLessAssignments: () =>
    _t(translations.pages.registry.showLessAssignments, 'Show less'),
  editCaregiver: () =>
    _t(translations.pages.registry.editCaregiver, 'Edit caregiver'),
  deleteCaregiver: () =>
    _t(translations.pages.registry.deleteCaregiver, 'Delete caregiver'),
};
