import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  charactersConstraint: () =>
    _t(
      translations.components.PasswordValidator.charactersConstraint,
      '8 characters minimum',
    ),
  uppercaseConstraint: () =>
    _t(
      translations.components.PasswordValidator.uppercaseConstraint,
      'One uppercase character',
    ),
  lowercaseConstraint: () =>
    _t(
      translations.components.PasswordValidator.lowercaseConstraint,
      'One lowercase character',
    ),
  numberConstraint: () =>
    _t(
      translations.components.PasswordValidator.numberConstraint,
      'One number',
    ),
  emptyPassword: () =>
    _t(
      translations.components.PasswordValidator.emptyPassword,
      'Password is required',
    ),
  ariaLabel: () =>
    _t(
      translations.components.PasswordValidator.ariaLabel,
      'Password must constain: 8 characters minimum, one uppercase character, one lowercase character, one number',
    ),
};
