import { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { Email2Illustration } from 'assets/illustrations';
import { messages } from '../../messages';

interface Props {
  email: string;
  next(): void;
}

export const NextStepsStep = memo(({ email, next }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack
      gap="1.6rem"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
      paddingTop={{
        xs: '4rem',
        sm: '2.4rem',
      }}
    >
      <Stack
        gap="1.6rem"
        marginY="auto"
        justifyContent="center"
        alignItems="center"
      >
        <Email2Illustration width="100%" height="auto" />
        <Typography variant="h1" width="100%" textAlign="left">
          {t(messages.weAreExcitedToHaveYou())}
        </Typography>
        <Typography
          width="100%"
          variant="body1"
          color="text.primary"
          textAlign="left"
        >
          <Trans
            i18nKey={t(messages.checkMailForNextSteps())}
            values={{ userEmail: email }}
            components={[
              <strong
                data-cy="participant-email"
                style={{ wordBreak: 'break-word' }}
              />,
            ]}
          />
        </Typography>
        <Typography variant="body1">
          {t(messages.youWillFindInstructionsHowToCreateAccount())}
        </Typography>
      </Stack>
      <Button
        onClick={next}
        variant="text"
        data-cy="finish-and-go-to-registry-page-button"
        style={{ marginTop: '3rem' }}
      >
        {t(messages.finishAndGotToRegistryPage())}
      </Button>
    </Stack>
  );
});
