import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Stack } from '@mui/material';
import { Formik } from 'formik';
import { FormikDateField } from 'components/FormikDateField';
import { FormikTextField } from 'components/FormikTextField';
import { FormikPhoneInput } from 'components/PhoneInput';
import { useValidationSchema } from 'hooks/useValidationSchema';
import { formatPhoneNumber } from 'helpers/phoneNumbers';
import { getValidationSchema } from '../../../MemberDetailsStep/validators';
import { MemberDetailsForm } from '../../../../types';
import { messages } from '../../../../messages';
import { DataRowWrapper } from '../../styled';
import { SettingSection } from '../../SettingsSection';

interface Props {
  active: boolean;
  disabled: boolean;
  onCancel: () => void;
  onSubmit: (newValues: MemberDetailsForm) => void;
  onEdit: () => void;
  memberDetails: MemberDetailsForm;
}

export const MemberDetailsSection = ({
  active,
  disabled,
  onCancel,
  onSubmit,
  onEdit,
  memberDetails,
}: Props) => {
  const { t } = useTranslation();
  const validationSchema = useValidationSchema(getValidationSchema);

  const details = (
    <Stack direction="column" spacing="0.6rem">
      <DataRowWrapper>
        <div>{t(messages.firstName())}</div>
        <div>{memberDetails.firstName}</div>
      </DataRowWrapper>
      <DataRowWrapper>
        <div>{t(messages.lastName())}</div>
        <div>{memberDetails.lastName}</div>
      </DataRowWrapper>
      <DataRowWrapper>
        <div>{t(messages.dateOfBirth())}</div>
        <div>
          {memberDetails.dateOfBirth
            ? format(memberDetails.dateOfBirth, 'MM/dd/yyyy')
            : '-'}
        </div>
      </DataRowWrapper>
      <DataRowWrapper>
        <div>{t(messages.phoneNumber())}</div>
        <div>
          {memberDetails.phone ? formatPhoneNumber(memberDetails.phone) : '-'}
        </div>
      </DataRowWrapper>
    </Stack>
  );

  const form = (
    <Stack direction="column" spacing="1.6rem">
      <FormikTextField
        name="firstName"
        label={t(messages.firstName())}
        validationSchema={validationSchema}
        oneWayValidationOnChange={true}
        placeholder={t(messages.firstName())}
        data-cy="first-name-input"
      />
      <FormikTextField
        name="lastName"
        label={t(messages.lastName())}
        validationSchema={validationSchema}
        oneWayValidationOnChange={true}
        placeholder={t(messages.lastName())}
        data-cy="last-name-input"
      />
      <FormikDateField
        name="dateOfBirth"
        validationSchema={validationSchema}
        oneWayValidationOnChange={true}
        data-cy="date-of-birth-input"
        disableFuture
        label={t(messages.dateOfBirth())}
      />
      <FormikPhoneInput
        name="phone"
        placeholder={t(messages.phoneNumber())}
        validationSchema={validationSchema}
        oneWayValidationOnChange={true}
        data-cy="phone-number-input"
        label={`${t(messages.phoneNumber())} (${t(messages.optional())})`}
      />
    </Stack>
  );

  return (
    <Formik
      initialValues={memberDetails}
      onSubmit={values => onSubmit(values)}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      {({ resetForm }) => (
        <SettingSection
          disabled={disabled}
          active={active}
          onCancel={() => {
            onCancel();
            resetForm();
          }}
          onEdit={onEdit}
          sectionName={t(messages.memberDetails())}
        >
          {active ? form : details}
        </SettingSection>
      )}
    </Formik>
  );
};
