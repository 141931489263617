import * as yup from 'yup';
import i18next from 'i18next';

import { UpdateRegistryAgreements } from 'api/interfaces/request/updateRegistryAgreements';
import { GetAgreementsResponse } from 'api/interfaces/response/getAgreementsResponse';
import { AgreementSelectionType } from 'api/models/agreement';
import { getPasswordValidationSchema } from 'components/PasswordValidator';
import { messages } from './messages';

export const getChangePasswordValidationSchema = () =>
  yup.object().shape({
    newPassword: getPasswordValidationSchema(),
    currentPassword: getPasswordValidationSchema(),
  });

export const validateConsents = (
  form: UpdateRegistryAgreements['registryAgreements'],
  registryAgreements: GetAgreementsResponse['registryAgreements'],
) => {
  const errors = {} as Record<string, Record<string, string>>;

  form.forEach(({ registryId, agreements }) => {
    const baseRegistry = registryAgreements.find(
      ({ registryId: id }) => id === registryId,
    );
    if (!baseRegistry) return;

    agreements.forEach(({ agreed, id: agreementId }) => {
      const baseAgreement = baseRegistry.agreements.find(
        ({ id }) => id === agreementId,
      );
      if (!baseAgreement) return;

      if (
        baseAgreement.selectionType ===
          AgreementSelectionType.REQUIRED_CHANGE_NOT_ALLOWED &&
        !agreed
      ) {
        if (!errors[registryId]) errors[registryId] = {};

        errors[registryId][agreementId] = i18next.t(
          messages.thisConsentIsRequired(),
        );
      }
    });
  });

  return errors;
};
