import { memo, useRef, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import Session from 'api/storage/session';
import Api from 'api/api';
import { queryKeys } from 'api/queryKeys';
import { errorToast } from 'helpers/toast';
import { useSetQueryData } from 'helpers/queries';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { LoadingPage } from 'pages/Loading';
import { validateConsents } from 'pages/Settings/validators';
import Config from 'config';
import { PDFViewer } from 'components/PDFViewer';
import { AgreementDocument } from 'api/models/agreement';
import { UpdateRegistryAgreements } from 'api/interfaces/request';
import { GetAgreementsResponse } from 'api/interfaces/response';
import { UserAgreement } from 'api/interfaces/response/getAgreementsResponse';
import { messages } from '../../messages';
import { RegistryConsentsList } from './RegistryConsentsList';

export const ConsentsSection = memo(() => {
  const { t } = useTranslation();
  const { current: userEmail } = useRef(Session.decodeToken()?.email);
  const [selectedDocument, setSelectedDocument] =
    useState<AgreementDocument | null>(null);
  const queryClient = useQueryClient();
  const setQueryData = useSetQueryData();
  const clearDocument = useCallback(() => {
    setSelectedDocument(null);
  }, [setSelectedDocument]);

  const { data } = useQuery({
    queryKey: queryKeys.userAgreements({ userEmail: userEmail! }),
    queryFn: Api.getAgreements,
    staleTime: 5 * 60 * 1000,
    onError: () => {
      errorToast(t(messages.unknownError()));
    },
  });

  const mappedAgreements = useMemo(
    () =>
      data?.registryAgreements.map(({ registryId, agreements }) => ({
        registryId,
        agreements: agreements.map(
          ({ id, agreed, title, description, document }) => ({
            id,
            agreed: agreed ?? false,
            agreementDocumentId: document?.versionId,
            snapshot: {
              agreement: {
                title,
                description,
              },
            },
          }),
        ),
      })),
    [data?.registryAgreements],
  );

  const ReverseMapAgreements = (
    newForm: UpdateRegistryAgreements,
    currentData: GetAgreementsResponse | undefined,
  ): GetAgreementsResponse | undefined => {
    if (!currentData) return currentData;

    return {
      registryAgreements: currentData.registryAgreements.map(
        ({ registryId, registryName, agreements }) => ({
          registryId,
          registryName,
          agreements: agreements.map(
            ({ id, title, selectionType, description, document }) =>
              ({
                id,
                title,
                description,
                selectionType,
                agreed:
                  newForm.registryAgreements
                    ?.find(
                      ({ registryId: mappedId }) => mappedId === registryId,
                    )
                    ?.agreements.find(
                      ({ id: agreementId }) => agreementId === id,
                    )?.agreed ?? false,
                document,
              } as UserAgreement),
          ),
        }),
      ),
    };
  };

  const allAgreements = useMemo(() => {
    if (!data?.registryAgreements) {
      return [];
    }

    const agreementsList = data.registryAgreements.flatMap(({ agreements }) =>
      agreements.map(({ id, agreed, title, description, document }) => ({
        id,
        agreed: agreed ?? false,
        agreementDocumentId: document?.versionId,
        snapshot: {
          agreement: {
            title,
            description,
          },
        },
      })),
    );

    return agreementsList;
  }, [data?.registryAgreements]);

  const { mutate } = useMutation({
    mutationFn: Api.updateUserAgreements,
    onMutate: async newForm => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.userAgreements({ userEmail: userEmail! }),
      });
      setQueryData(
        ['userAgreements', { userEmail: userEmail! }],
        (currentData = data) => ReverseMapAgreements(newForm, currentData),
      );
      return { previousForm: data };
    },
    onError: (_err, _newForm, context) => {
      setQueryData(
        ['userAgreements', { userEmail: userEmail! }],
        context?.previousForm,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.userAgreements({ userEmail: userEmail! }),
      });
    },
  });

  if (allAgreements.length === 0) return null;
  return (
    <>
      <PDFViewer
        path={
          selectedDocument?.path
            ? Config.get().s3PublicBucketUrl + selectedDocument.path
            : undefined
        }
        title={selectedDocument?.title}
        open={selectedDocument !== null}
        onClose={clearDocument}
        gaSource="edit_consent_modal"
      />
      <Stack gap="1.6rem">
        <Typography variant="h3">{t(messages.consents())}</Typography>
        <Formik
          initialValues={mappedAgreements ?? []}
          enableReinitialize
          onSubmit={form => mutate({ registryAgreements: form })}
          validateOnChange={false}
          validate={form =>
            validateConsents(form, data?.registryAgreements ?? [])
          }
        >
          {() => (
            <Stack
              component={Form}
              gap="3.8rem"
              direction="column"
              overflow="hidden"
              maxHeight="100%"
              height="100%"
            >
              <Stack
                spacing="2.4rem"
                overflow="auto"
                display={{
                  xs: 'unset',
                  sm: 'flex',
                }}
              >
                {!data ? (
                  <LoadingPage
                    style={{ minHeight: '16rem', background: 'transparent' }}
                  />
                ) : (
                  <RegistryConsentsList
                    data={data}
                    setSelectedDocument={setSelectedDocument}
                  />
                )}
              </Stack>
            </Stack>
          )}
        </Formik>
      </Stack>
    </>
  );
});
