import { memo, useRef, useState } from 'react';
import { useAutofocus } from 'hooks/useAutofocus';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import mixpanel from 'mixpanel-browser';
import {
  Sms as EmailIcon,
  Add as PlusIcon,
  Minus as MinusIcon,
} from 'iconsax-react';
import {
  Stack,
  Typography,
  Button,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '@mui/material';
import { SUPPORT_EMAIL } from 'config/constants';
import { Tooltip } from 'components/Tooltip';
import Session from 'api/storage/session';
import { EventNames, HelpPageEventActions } from 'types/analytics';
import { messages } from './messages';
import { getFAQ } from './constants';
import { EmailEllipsis } from './styled';

export const HelpPage = memo(() => {
  const { t } = useTranslation();
  const { current: userEmail } = useRef(Session.decodeToken()?.email);
  const [expanded, setExpanded] = useState<number | null>(null);
  const faqContent = getFAQ();
  const autofocus = useAutofocus();

  const handleChange =
    (panelIndex: number) =>
    (_e: React.SyntheticEvent, nowExpanded: boolean) => {
      if (nowExpanded) {
        setExpanded(panelIndex);

        mixpanel.track(EventNames.HELP_PAGE, {
          action: HelpPageEventActions.OPEN_PANEL,
          question: faqContent[panelIndex]?.question,
          panel_index: panelIndex,
        });
      } else setExpanded(null);
    };

  const handleEmailPress = () => {
    mixpanel.track(EventNames.HELP_PAGE, {
      action: HelpPageEventActions.EMAIL_CLICKED,
    });
  };

  return (
    <Stack
      gap={{
        xs: '2.4rem',
        md: '3.2rem',
      }}
    >
      <Helmet>
        <title>{t(messages.helpCenter())}</title>
      </Helmet>
      <Stack
        gap={{
          xs: '2.2rem',
          md: '1.6rem',
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          justifyContent={{
            xs: 'flex-start',
            md: 'space-between',
          }}
          alignItems={{
            xs: 'flex-start',
            md: 'center',
          }}
          gap="2.2rem"
        >
          <Typography variant="h1" {...autofocus}>
            {t(messages.helpCenter())}
          </Typography>
          <Button
            variant="text"
            component="a"
            href={`mailto:${SUPPORT_EMAIL}?subject=Support request for user ${userEmail}&body=Problem description...`}
            style={{
              userSelect: 'unset',
              verticalAlign: 'initial',
              maxWidth: '100%',
            }}
            data-cy="email-support-button"
            onClick={handleEmailPress}
          >
            <EmailIcon
              style={{ marginRight: '0.8rem', minWidth: '1.8rem' }}
              size="1.8rem"
            />
            <Tooltip title={SUPPORT_EMAIL} showTooltipOnlyOnOverflow>
              <EmailEllipsis>{SUPPORT_EMAIL}</EmailEllipsis>
            </Tooltip>
          </Button>
        </Stack>
        <Typography variant="body1" maxWidth="73.2rem">
          {t(messages.helpCenterDescription())}
        </Typography>
      </Stack>

      <Stack
        gap={{
          xs: '1.6rem',
          md: '2.4rem',
        }}
      >
        {faqContent.map(({ question, answer }, i) => (
          <Accordion
            key={i}
            expanded={expanded === i}
            onChange={handleChange(i)}
            variant="neumorphic"
          >
            <AccordionSummary
              expandIcon={expanded === i ? <MinusIcon /> : <PlusIcon />}
            >
              {question}
            </AccordionSummary>
            <AccordionDetails>{answer}</AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Stack>
  );
});
