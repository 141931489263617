import styled from 'styled-components';
import abstractBackground from './assets/abstractBackground.svg';
import abstractBackgroundDark from './assets/abstractBackground_dark.svg';

export const PanelContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.additionalColors.background};

  padding: 9.6rem 7.2rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 7.2rem 4.8rem 5.6rem;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 5.6rem 2.4rem 3.2rem;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    background-image: url(${({ theme }) =>
      theme.palette.type === 'dark'
        ? abstractBackgroundDark
        : abstractBackground});
    background-repeat: repeat-y;
    background-position: center -32rem;
    background-size: 200.7652rem 151.8547rem;
  }
`;
