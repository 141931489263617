export enum AgreementSelectionType {
  REQUIRED_CHANGE_NOT_ALLOWED = 'Required_ChangeNotAllowed',
  OPTIONAL_CHANGE_ALLOWED = 'Optional_ChangeAllowed',
  OPTIONAL_ONLY_CHANGE_AGREE_ALLOWED = 'Optional_OnlyChangeAgreeAllowed',
}

export interface AgreementDocument {
  id: string;
  versionId: string;
  title: string;
  description?: string;
  path: string;
}

export interface Agreement {
  id: string;
  title: string;
  description: null;
  selectionType: AgreementSelectionType;
  document?: AgreementDocument;
}
