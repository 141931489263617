import * as yup from 'yup';
import i18next from 'i18next';

import { getPasswordValidationSchema } from 'components/PasswordValidator';

import { messages } from '../../messages';

export const getValidationSchema = () =>
  yup.object().shape({
    password: getPasswordValidationSchema(),
    agreedToTermsAndConditions: yup
      .boolean()
      .oneOf([true], i18next.t(messages.termsAndConditionsValidationMsg())),
  });
