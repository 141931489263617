import { Components } from '@mui/material/styles/components';
import { Theme } from 'styles/theme/themes';

export const MuiTextField: Components<Theme>['MuiTextField'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      boxSizing: 'border-box',
      borderWidth: '0',
      ...theme.typography.h5,

      ':focus-within': {
        borderRadius: '2rem',
        border: `${theme.palette.additionalColors.accentSuccess}B2 .4rem solid`,
      },

      '.MuiInputBase-root': {
        background: theme.palette.button.background,
        height: '5.2rem',
        border: `0.15rem solid ${theme.palette.separator.primary}`,
        borderRadius: '1.6rem',
        padding: '0.5rem 1.6rem',
        transition: 'border-color 300ms',

        color: theme.palette.text.primary,
        ...theme.typography.h5,

        '.Mui-disabled': {
          background: theme.palette.text.secondary,
          border: 'none',
        },
      },

      '.InvalidInputValue': {
        borderColor: theme.palette.message.text.error,
      },

      '::after, ::before': {
        display: 'none',
      },

      input: {
        height: '100%',
        padding: '0',

        '::placeholder': {
          ...theme.typography.body1,
          opacity: 1,
          color: theme.palette.text.secondary,
        },

        // The code below delays transition (change of background color) by almost 14 hours
        // This was neccessary since setting -webkit-box-shadow transparent didn't work
        ':-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active':
          {
            transition: 'all 0s 50000s',
          },
      },

      fieldset: {
        border: 'none',
      },

      '.MuiInputAdornment-root': {
        svg: {
          fill: theme.palette.text.secondary,
        },
      },

      '.MuiFilledInput-input': {
        padding: '0',
      },
    }),
  },
};
