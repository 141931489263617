import { useEffect, useRef } from 'react';
import mixpanel from 'mixpanel-browser';
import { FlutterMessageEvent } from 'components/SurveyModule/types';
import { useConfig } from 'hooks/useConfig';
import UserActivityTime from 'api/storage/userActivityTime';
import { STORAGE_FOCUSED, STORAGE_LOGOUT } from 'config/constants';
import { EventNames, TimeoutEventActions } from 'types/analytics';
import Session from 'api/storage/session';

export enum LogoutTimerTypes {
  QUALIFICATION = 'qualification',
  AUTHENTICATED_USER = 'authenticated-user',
}

interface Props {
  onTimeout(): void;
  onImmediateTimeout?(): void;
  type: LogoutTimerTypes;
}

export const useLogoutTimer = ({
  onTimeout,
  onImmediateTimeout,
  type,
}: Props) => {
  const {
    autoLogout: { events, flutterEvents, timeout, secondsToConfirm },
  } = useConfig();
  const timer = useRef<NodeJS.Timeout | null>(null);

  const handleTimeout = () => {
    mixpanel.track(EventNames.TIMEOUT_STATE_CHANGE, {
      action: TimeoutEventActions.OPEN_EXTEND_SESSION_MODAL,
      timer_type: type,
    });

    events.forEach(event => {
      window.removeEventListener(event, resetTimer);
    });

    stopTimer();
    onTimeout();
  };

  const startTimer = () => {
    timer.current = setTimeout(handleTimeout, timeout);
  };

  const stopTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  };

  const resetTimer = () => {
    stopTimer();
    startTimer();
  };

  const onFocusOut = () => {
    const focusOutTime = new Date();

    switch (type) {
      case LogoutTimerTypes.QUALIFICATION:
        UserActivityTime.saveQualificationTime(focusOutTime);
        break;
      case LogoutTimerTypes.AUTHENTICATED_USER:
        UserActivityTime.saveUserTime(focusOutTime);
        break;
    }
  };

  const handleFlutterEvent = (event: FlutterMessageEvent<{ type: string }>) => {
    if (event.data?.source !== 'rni_web_surveys') return;
    if (event.data.type !== 'user_input_event') return;

    if ((flutterEvents as string[]).includes(event.data.data.type)) {
      resetTimer();
      onFocusOut(); // focusing iframe makes page lose focus
    }
  };

  const addListeners = () => {
    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });

    if (flutterEvents.length)
      window.addEventListener('message', handleFlutterEvent, false);

    resetTimer();
  };

  const removeListeners = () => {
    events.forEach(event => {
      window.removeEventListener(event, resetTimer);
    });

    if (flutterEvents.length)
      window.removeEventListener('message', handleFlutterEvent, false);
  };

  useEffect(() => {
    addListeners();

    return removeListeners;
  }, [onTimeout]);

  const getLastActivityTime = () => {
    switch (type) {
      case LogoutTimerTypes.QUALIFICATION:
        return UserActivityTime.getQualificationTime();
      case LogoutTimerTypes.AUTHENTICATED_USER:
        return UserActivityTime.getUserTime();
    }
  };

  const clearActivityTime = () => {
    switch (type) {
      case LogoutTimerTypes.QUALIFICATION:
        return UserActivityTime.clearQualificationTime();
      case LogoutTimerTypes.AUTHENTICATED_USER:
        return UserActivityTime.clearUserTime();
    }
  };

  const sendFocusedEvent = () => {
    localStorage.setItem(STORAGE_FOCUSED, 'true');
    localStorage.removeItem(STORAGE_FOCUSED);
  };

  const handleStorageEvent = (e: StorageEvent) => {
    if (e.key === STORAGE_FOCUSED && e.newValue === 'true') stopTimer();
    if (
      type === LogoutTimerTypes.AUTHENTICATED_USER &&
      e.key === STORAGE_LOGOUT
    )
      (onImmediateTimeout ?? onTimeout)();
  };

  const onFocusIn = () => {
    sendFocusedEvent();

    const lastActivityTime = getLastActivityTime()?.getTime();
    if (!lastActivityTime) {
      mixpanel.track(EventNames.TIMEOUT_STATE_CHANGE, {
        action: TimeoutEventActions.NO_LAST_ACTIVITY_TIME,
        timer_type: type,
      });

      clearActivityTime();
      return onTimeout();
    }

    const now = new Date().getTime();

    if (now - lastActivityTime > timeout + secondsToConfirm * 1000) {
      mixpanel.track(EventNames.TIMEOUT_STATE_CHANGE, {
        action: TimeoutEventActions.RETURN_TO_EXPIRED,
        timer_type: type,
        timeout: now - lastActivityTime,
      });

      clearActivityTime();
      return (onImmediateTimeout ?? onTimeout)();
    }

    if (now - lastActivityTime > timeout) {
      mixpanel.track(EventNames.TIMEOUT_STATE_CHANGE, {
        action: TimeoutEventActions.RETURN_TO_EXTEND_SESSION_MODAL,
        timer_type: type,
        timeout: now - lastActivityTime,
      });

      clearActivityTime();
      return onTimeout();
    }

    mixpanel.track(EventNames.TIMEOUT_STATE_CHANGE, {
      action: TimeoutEventActions.RETURN_BEFORE_TIMEOUT,
      timer_type: type,
      timeout: now - lastActivityTime,
    });
    removeListeners();
    addListeners();
  };

  useEffect(() => {
    window.addEventListener('blur', onFocusOut);
    window.addEventListener('focus', onFocusIn);
    window.addEventListener('storage', handleStorageEvent, false);

    return () => {
      window.removeEventListener('blur', onFocusOut);
      window.removeEventListener('focus', onFocusIn);
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [onTimeout, onImmediateTimeout, type]);

  return { addListeners };
};

export const sendLogoutEvent = () => {
  const userId = Session.decodeToken()?.['custom:userId'];
  if (!userId) return;

  localStorage.setItem(STORAGE_LOGOUT, userId);
  localStorage.removeItem(STORAGE_LOGOUT);
};
