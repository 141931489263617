import { useNumberOfTopSurveys } from 'api/storage/numberOfTopPrioritySurveys/hook';
import { RegistrySurveysIndicator, RegistryWarningIndicator } from './styled';
import { useUserRole } from '../../api/storage/userRoles/hook';

interface Props {
  showWarning: boolean;
  registryId: string;
  surveys: { id: string; priority: number; role?: string | undefined }[];
}

export const NumberOfSurveysIndicator = ({
  showWarning,
  registryId,
  surveys,
}: Props) => {
  const { roleId } = useUserRole(registryId);

  const filteredSurveys = surveys.filter(
    survey => survey.role === roleId || !survey.role,
  );

  const { remainingTopSurveys } = useNumberOfTopSurveys(
    registryId,
    filteredSurveys.length,
  );

  if (showWarning)
    return (
      <RegistryWarningIndicator
        data-cy="registry-warning"
        title="action needed"
      />
    );
  if (remainingTopSurveys === 0) return null;

  return (
    <RegistrySurveysIndicator
      color="success"
      data-cy={`available-surveys-${remainingTopSurveys}`}
      aria-label={`Available surveys: ${remainingTopSurveys}`}
    >
      {remainingTopSurveys}
    </RegistrySurveysIndicator>
  );
};
