import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Stack } from '@mui/material';
import { messages } from 'pages/Login/messages';
import { ForgotPasswordIllustration } from 'assets/illustrations';
import {
  CloseIcon,
  FormModalStepContainer,
  FormStackWrapper,
  IconButtonsContainer,
} from '../../styled';
import { ResponsivenessHelperDown } from '../../../../helpers/responsiveness';

interface Props {
  onReturn(): void;
}

export const PasswordResetSuccessScreen = memo(({ onReturn }: Props) => {
  const { t } = useTranslation();

  const content = (
    <>
      <FormStackWrapper
        spacing="3.2rem"
        data-cy="password-reset-success-step"
        alignItems="center"
      >
        <ForgotPasswordIllustration
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
        <Stack
          gap={{
            xs: '0.8rem',
            sm: '1.6rem',
          }}
          paddingBottom={{
            xs: '0',
            sm: '3.2rem',
          }}
        >
          <Typography
            variant="h1"
            textAlign={{
              xs: 'center',
              lg: 'left',
            }}
          >
            {t(messages.yourPasswordReset())}
          </Typography>
          <Typography
            variant="body1"
            width="100%"
            textAlign={{
              xs: 'center',
              lg: 'left',
            }}
          >
            {t(messages.nowYouCanLogIn())}
          </Typography>
        </Stack>
      </FormStackWrapper>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        onClick={onReturn}
        data-cy="login-button"
      >
        {t(messages.login())}
      </Button>
    </>
  );

  return (
    <ResponsivenessHelperDown
      breakpoint={'sm'}
      beforeBreakpoint={
        <FormModalStepContainer>
          <IconButtonsContainer style={{ justifyContent: 'flex-end' }}>
            <CloseIcon onClick={onReturn} />
          </IconButtonsContainer>
          {content}
        </FormModalStepContainer>
      }
      afterBreakpoint={
        <Stack justifyContent="center" height="100%">
          {content}
        </Stack>
      }
    />
  );
});
