import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as EmptyFolderIllustrationLight } from './emptyFolder.svg';
import { ReactComponent as EmptyFolderIllustrationDark } from './emptyFolder_dark.svg';

export const EmptyFolderIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return <EmptyFolderIllustrationDark aria-hidden="true" {...props} />;
    return <EmptyFolderIllustrationLight aria-hidden="true" {...props} />;
  },
);
