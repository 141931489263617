import { useParams } from 'react-router';
import { Link, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, useTheme } from '@mui/material';
import { ArrowLeft } from 'iconsax-react';
import Config from 'config';
import { Params } from 'pages/Qualification/types';
import { FIRST_STEP } from 'pages/Qualification/constants';
import { StartButton } from 'components/StartButton';
import scientistIllustrationLight from './assets/scientist.svg';
import scientistIllustrationDark from './assets/scientist_dark.svg';
import { messages } from '../../messages';
import { IllustrationWrapper, GoBackLink } from './styled';

interface Props {
  name: string;
  description: string;
  registryIllustration?: string;
  registryIllustrationDark?: string;
}

export const IntroSection = ({
  name,
  description,
  registryIllustration,
  registryIllustrationDark,
}: Props) => {
  const { registryCode } = useParams<Params>();
  const { t } = useTranslation();
  const theme = useTheme();

  const getIllustration = () => {
    if (theme.palette.type === 'dark')
      return registryIllustrationDark
        ? `${Config.get().s3PublicBucketUrl}${registryIllustrationDark}`
        : scientistIllustrationDark;
    return registryIllustration
      ? `${Config.get().s3PublicBucketUrl}${registryIllustration}`
      : scientistIllustrationLight;
  };

  return (
    <Stack
      padding={{
        lg: '2.4rem 7.2rem 0',
        md: '4.35rem 4.8rem 0',
        xs: '3.2rem 2.4rem 0',
      }}
      direction={{
        lg: 'row',
        xs: 'column',
      }}
      alignItems="center"
      maxWidth="129.6rem"
      margin="auto"
      boxSizing="content-box"
    >
      <Stack
        gap={{ lg: '2.4rem', xs: '1.6rem' }}
        paddingY={{ md: '2.4rem' }}
        width={{
          lg: '50%',
          xs: '100%',
        }}
      >
        <GoBackLink to="/registry">
          <ArrowLeft color={theme.palette.button.primary} />
          <Typography
            variant="body1"
            color="additionalColors.bodyText"
            component="span"
          >
            {t(messages.goBackToList())}
          </Typography>
        </GoBackLink>
        <Typography
          variant="h1"
          sx={{ fontSize: '3.6rem', lineHeight: '130%' }}
        >
          {name}
        </Typography>
        <Typography variant="body1">{description}</Typography>
        <StartButton
          component={Link}
          to={generatePath('/registry/:registryCode/:step', {
            registryCode: registryCode!,
            step: FIRST_STEP,
          })}
          sx={{ marginTop: '0.8rem', maxWidth: '30.6rem' }}
          hideIconOnMobile
        >
          {t(messages.joinTheRegistry())}
        </StartButton>
      </Stack>
      <IllustrationWrapper>
        <img src={getIllustration()} alt="" />
      </IllustrationWrapper>
    </Stack>
  );
};
