import { Stack, Typography } from '@mui/material';
import { ArrowRight } from 'iconsax-react';
import { ListItemIcon } from './styled';

interface Props {
  children: React.ReactNode;
}

export const ListItem = ({ children }: Props) => {
  return (
    <Stack gap="1.6rem" direction="row" alignItems="center">
      <ListItemIcon>
        <ArrowRight />
      </ListItemIcon>
      <Typography variant="body1">{children}</Typography>
    </Stack>
  );
};
