import { memo, useCallback, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import mixpanel from 'mixpanel-browser';
import { EventNames, SurveyCompletedEventActions } from 'types/analytics';
import { Modal } from 'components/Modal';
import { CloseIcon } from 'components/Modal/styled';
import { Stack } from '@mui/material';
import { FirstQualificationSurveyModalContent } from './FirstQualificationSurveyModalContent';
import { RegularSurveyModalContent } from './RegularSurveyModalContent';
import { AnotherQualificationSurveyModalContent } from './AnotherQualificationSurveyModalContent';
import { RegistryLocationState } from '../types';

interface Props {
  registryName: string;
  nextSurveyId?: string;
  surveysLength: number;
  registryId?: string;
}

enum ModalTypes {
  SURVEY,
  QUALIFICATION_SURVEY,
  FIRST_QUALIFICATION_SURVEY,
}

export const CongratulationsModal = memo(
  ({ registryName, nextSurveyId, surveysLength, registryId }: Props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [modalType, setModalType] = useState<null | ModalTypes>(null);
    const closeModal = useCallback(() => setModalType(null), [setModalType]);
    const {
      current: { cohorts, surveyCompleted },
    } = useRef<RegistryLocationState>(location.state ?? {});

    useEffect(() => {
      if (modalType) return;

      switch (true) {
        case cohorts?.firstCompletion:
          setModalType(ModalTypes.FIRST_QUALIFICATION_SURVEY);
          break;
        case cohorts !== undefined:
          setModalType(ModalTypes.QUALIFICATION_SURVEY);
          break;
        case surveyCompleted:
          setModalType(ModalTypes.SURVEY);
          break;
        default:
      }

      // clear state after consuming
      navigate(location.pathname, {});
    }, [cohorts, surveyCompleted]);

    const getModalContent = () => {
      switch (modalType) {
        case ModalTypes.SURVEY:
          return (
            <RegularSurveyModalContent
              onClose={closeModal}
              nextSurveyId={nextSurveyId}
              registryName={registryName}
              surveysLength={surveysLength}
              registryId={registryId}
            />
          );
        case ModalTypes.QUALIFICATION_SURVEY:
          return (
            <AnotherQualificationSurveyModalContent
              registryName={registryName}
              {...cohorts!}
            />
          );
        case ModalTypes.FIRST_QUALIFICATION_SURVEY:
          return (
            <FirstQualificationSurveyModalContent
              registryName={registryName}
              {...cohorts!}
            />
          );

        default:
      }
    };

    return (
      <Modal
        open={modalType !== null}
        onClose={() => {
          if (modalType === ModalTypes.SURVEY)
            mixpanel.track(EventNames.SURVEY_COMPLETED_MODAL, {
              action: SurveyCompletedEventActions.MODAL_CLOSED,
              type: 'clickaway',
              next_survey_available: nextSurveyId !== undefined,
              registry_name: registryName,
              registry_id: registryId,
            });

          closeModal();
        }}
        disableMobileFullScreen
        closeOnClickAway
      >
        <Stack
          direction="row"
          justifyContent="end"
          marginX={{
            lg: '4.8rem',
            sm: '3.2rem',
            xs: '2.4rem',
          }}
          alignItems="center"
        >
          <CloseIcon
            onClick={() => {
              if (modalType === ModalTypes.SURVEY)
                mixpanel.track(EventNames.SURVEY_COMPLETED_MODAL, {
                  action: SurveyCompletedEventActions.MODAL_CLOSED,
                  type: 'close icon',
                  next_survey_available: nextSurveyId !== undefined,
                  registry_name: registryName,
                  registry_id: registryId,
                });

              closeModal();
            }}
            data-cy="modal-close-icon"
          />
        </Stack>
        <Stack
          alignItems="center"
          textAlign="center"
          marginX={{
            lg: '4.8rem',
            sm: '3.2rem',
            xs: '2.4rem',
          }}
        >
          {getModalContent()}
        </Stack>
      </Modal>
    );
  },
);
