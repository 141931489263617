export const termsAndConditionsEn = `
### WVU RNI Health App Terms of Use; Disclaimers.

Use of the WVU-RNI Health Application (“Application”) constitutes acceptance of these terms of use (“Terms”), as revised from time to time, and is a binding agreement between the user (“User”) and the West Virginia University Board of Governors on behalf of West Virginia University (“WVU”) governing use of the Application. BY DOWNLOADING OR INSTALLING THIS APPLICATION, THE USER ACKNOWLEDGES, AND AGREES TO BE BOUND BY, THESE TERMS.

**Eligibility**: The Application may be used only by individuals aged sixteen (16) years or older. If the User is sixteen (16) years or older, but under the age of eighteen (18), the User should review these Terms with User’s parent or guardian. The User represents and warrants that (i) the User is not located in a country that is subject to a U.S. Government embargo or that has been designated by the U.S. Government as a “terrorist support” country, and (ii) the User is not included on any U.S. Government list of prohibited or restricted parties.

**Disclaimers**: The information received from the Application is not meant or intended to serve as a substitute for a healthcare professional's medical advice. ALTHOUGH SOME CONTENT MAY BE PROVIDED BY INDIVIDUALS IN THE MEDICAL PROFESSION, YOU ACKNOWLEDGE THAT PROVISION OF SUCH CONTENT DOES NOT CREATE A MEDICAL PROFESSIONAL-PATIENT RELATIONSHIP AND DOES NOT CONSTITUTE AN OPINION, MEDICAL ADVICE, PROFESSIONAL DIAGNOSIS, SERVICE OR
TREATMENT OF ANY CONDITION. Access to general information is provided for educational purposes only, through this Application and links to other sites. For patients and individuals, this information is not a substitute for medical or professional care, therapy, healthcare advice, or counseling and you should not use the information in place of a visit, call, consultation or the advice of your physician or other healthcare provider. For medical issues or concerns, including decisions about medications and other treatments, Application users should always consult their physician or, in serious cases, seek immediate assistance from emergency personnel.

WVU, ITS CONTRACTORS, AND AFFILIATES DO NOT WARRANT OR GUARANTEE THE ACCURACY, RELIABILITY OR COMPLETENESS OF THE APPLICATION CONTENT OR REPRESENT THAT THE APPLICATION OR THE APPLICATION CONTENT IS ERROR-FREE OR CAPABLE OF OPERATING ON AN UNINTERRUPTED BASIS. THE APPLICATION IS PROVIDED “AS IS" AND "AS AVAILABLE" AND WITHOUT WARRANTY OF ANY KIND. TO THE FULLEST EXTENT ALLOWABLE UNDER APPLICABLE LAW, ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, ARE HEREBY DISCLAIMED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.

**Limitation of Liability**: USE OF THE APPLICATION IS AT THE USER’S OWN RISK. TO THE FULLEST EXTENT ALLOWABLE UNDER APPLICABLE LAW, WVU AND ITS AFFILIATED ENTITIES SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO:

1.  USE OR MISUSE OF THE APPLICATION OR THE APPLICATION CONTENT;

2. INABILITY TO ACCESS OR USE THE APPLICATION OR THE APPLICATION CONTENT;

3. ANY LOSS OR CORRUPTION OF DATA OR INFORMATION SUBMITTED VIA THE APPLICATION; OR

4. ANY COMMUNICATIONS OR SERVICES PROVIDED BY, OR REQUESTED FROM, WVU VIA THE APPLICATION.

**Content**: All Application content, including, without limitation, all text, graphics, audio, video, music, icons, images, hyperlinks, displays, HTML code and scripts (collectively, the "Application Content"), and the collection, arrangement and assembly of the Application Content, is the property of WVU and is protected by copyright law and other U.S. and international laws and treaties. The User is granted no right, title or interest in or to the Application Content other than the non-exclusive, non-transferable, limited, revocable license to access and use the Application and the Application Content for the User’s own, non-commercial purposes. The User may not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works from, distribute, perform, display, incorporate into a website or in any way exploit the Application Content, or any portion thereof, without the express permission of authorized representatives of WVU. All rights not expressly granted are reserved by WVU and its affiliated entities. The aforementioned limited license may be revoked at any time for any reason or no reason. Trademarks: All rights regarding any trademarks, trade names, service marks, logos and/or trade dress (collectively, “Marks”) contained in, or used in connection with, the Application are expressly reserved by WVU and its affiliates. The Marks, and all associated logos or images, are registered and/or common law trademarks, and are protected by U.S. and international laws and treaties. No license with respect to the use of the Marks is granted to the User under these Terms or by virtue of User’s use of the Application. The Marks may not be copied, imitated or used, in whole or in part, without the prior written consent of authorized representatives of WVU or the applicable rights holder.

**User Information**: Some functionality of the Application may require the transmission of information provided by the User (“User Information”). By using such functionality, the User consents to the transmission of User Information to WVU and/or its agents and authorizes  WVU and/or its agents to record, process, and store such User Information as necessary for the Application functionality. Acceptable Use: The User’s permitted use of the Application and the Application Content is limited to the contemplated functionality of the Application. In no event may the Application be used in a manner that
1. is unlawful, fraudulent or deceptive,

2. harasses, abuses, stalks, threatens, defames, or otherwise infringes or violates the rights of any third party (including, without limitation, the rights of publicity or other proprietary rights),
3. uses technology or other means not authorized by WVU to access Application Content,
4. attempts to introduce viruses or any other computer code, files or programs that interrupt, destroy or limit the functionality of any computer software, hardware or telecommunications equipment,
5. attempts to gain unauthorized access to WVU’s systems or user accounts, including the systems or user accounts of WVU’s affiliated entities,
6. encourages conduct that would constitute a criminal offense or that gives rise to civil liability,
7. attempts to damage, disable or overburden WVU’s servers or networks, or

8. fails to comply with applicable third-party terms.

The User must comply with all applicable third-party terms and conditions (e.g., wireless carrier terms of service) at all times while using the Application. WVU reserves the right, in its sole discretion, to terminate the license granted to the User hereunder and/or bring legal action against the User if the User engages in, or WVU reasonably believes the User has engaged in or is engaging in, use that violates these Terms. WVU’s failure or delay in taking such actions does not constitute a waiver of its rights to enforce these Terms.

**Indemnity**: Upon request by WVU, the User agrees to indemnify, defend and hold harmless WVU and its board, employees, contractors, agents, officers, directors, sponsors, distributors, contractors, and licensors from and against all claims, suits, demands, damages, liabilities, loss, cost, and expense (including reasonable attorneys’ fees) that arise from the User’s use or misuse of the Application or violation of these Terms. WVU reserves the right, to assume the exclusive defense and control of any matter otherwise subject to indemnification by the User, in which event the User will cooperate with WVU in asserting any available defenses.

**Changes to Application**: At any time and without notice to the User, WVU may change or modify the Application, or any features or functions thereof, or may suspend or discontinue the Application, or stop supporting the Application, or any aspect thereof. Any such termination, change, suspension or discontinuance shall be without liability to the User.

**Changes to Terms; Updates**: WVU reserves the right to change these Terms at any time, and in its sole discretion, by changing these Terms within the Application. The User’s continued use of the Application after any such changes to these Terms are made available through the Application will constitute the User’s acceptance of those changes. These Terms shall govern any updates to, or supplements or replacements for the Application unless separate terms are provided in conjunction with such updates, supplements or replacements - in which case such separate terms will apply.

**Governing Law**: These Terms are governed by the laws of the State of West Virginia and applicable federal laws of the United States of America, without giving effect to conflict of laws principles. The User irrevocably consents to the exclusive jurisdiction of the federal and state courts situated in West Virginia for purposes of any legal action arising out of or related to these Terms or use of the Application. Contact: Any questions, complaints, or claims regarding the Application may be directed to:
West Virginia University Rockefeller Neuroscience Institute
33 Medical Center Drive
Morgantown, West Virginia 26505


**Apple Users**: All Users who install the Application designed for use on an iPhone, iPad or other device provided by Apple, Inc. (“Apple”) acknowledges that the Terms are concluded between the User and NCH only, and not with Apple, and that Apple is not responsible for the Application or the content thereof.
`;
