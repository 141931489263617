import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Modal } from 'components/Modal';
import { ModalContent } from 'components/Modal/ModalContent';
import { ModalButtonsContainer } from 'components/Modal/styled';
import {
  GlobalFormState,
  QualificationFormRole,
  QualificationSteps,
} from '../../types';
import { messages } from '../../messages';
import { AccountSection } from './sections/AccountSection/index';
import { MemberDetailsSection } from './sections/MemberDetailsSection/index';
import { CaregiverDetailsSection } from './sections/CaregiverDetailsSection/index';

interface Props {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
  role: QualificationFormRole['code'];
  isLoading: boolean;
  registryName: string;
  formState: GlobalFormState;
  updateForm: Dispatch<SetStateAction<GlobalFormState>>;
}

export const ConfirmMemberApplicationModal = ({
  isOpen,
  onSubmit,
  onClose,
  role,
  registryName,
  formState,
  updateForm,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState<
    'account' | 'member' | 'caregiver' | null
  >(null);

  useEffect(() => {
    setActiveSection(null);
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t(messages.confirmMembersApplication())}
      closeOnClickAway
    >
      <ModalContent>
        <Typography variant="body1" style={{ marginTop: '2.5rem' }}>
          {t(messages.weWillUseTheDataProvided(), { registryName })}
        </Typography>
        <Stack
          direction="column"
          spacing="1.6rem"
          style={{ marginTop: '3.2rem' }}
        >
          <AccountSection
            active={activeSection === 'account'}
            onEdit={() => setActiveSection('account')}
            onCancel={() => setActiveSection(null)}
            disabled={activeSection !== 'account' && activeSection !== null}
            accountDetails={formState[QualificationSteps.ACCOUNT_DETAILS]}
            onSubmit={values => {
              updateForm(prev => ({
                ...prev,
                [QualificationSteps.ACCOUNT_DETAILS]: values,
              }));
              setActiveSection(null);
            }}
          />
          <MemberDetailsSection
            active={activeSection === 'member'}
            onEdit={() => setActiveSection('member')}
            onCancel={() => setActiveSection(null)}
            disabled={activeSection !== 'member' && activeSection !== null}
            memberDetails={formState[QualificationSteps.MEMBER_DETAILS]}
            onSubmit={values => {
              updateForm(prev => ({
                ...prev,
                [QualificationSteps.MEMBER_DETAILS]: values,
              }));
              setActiveSection(null);
            }}
          />
          {role === 'caregiver' && (
            <CaregiverDetailsSection
              active={activeSection === 'caregiver'}
              onEdit={() => setActiveSection('caregiver')}
              onCancel={() => setActiveSection(null)}
              disabled={activeSection !== 'caregiver' && activeSection !== null}
              caregiverDetails={formState[QualificationSteps.CAREGIVER_DETAILS]}
              caregiverContactDetails={
                formState[QualificationSteps.CAREGIVER_CONTACT_DETAILS]
              }
              onSubmit={values => {
                updateForm(prev => ({
                  ...prev,
                  [QualificationSteps.CAREGIVER_DETAILS]:
                    values.caregiverDetails,
                  [QualificationSteps.CAREGIVER_CONTACT_DETAILS]:
                    values.caregiverContactDetails,
                }));
                setActiveSection(null);
              }}
            />
          )}
        </Stack>
      </ModalContent>
      <ModalButtonsContainer>
        <LoadingButton
          color="primary"
          variant="contained"
          data-cy="confirm-members-application-step-continue-button"
          style={{ marginTop: '3rem' }}
          loading={isLoading}
          onClick={onSubmit}
          disabled={activeSection !== null}
        >
          {t(messages.confirmAndSubmit())}
        </LoadingButton>
        <Button
          onClick={onClose}
          color="primary"
          variant="text"
          data-cy="confirm-members-application-step-back-button"
          style={{ marginTop: '3rem' }}
        >
          {t(messages.back())}
        </Button>
      </ModalButtonsContainer>
    </Modal>
  );
};
