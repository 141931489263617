import { kebabCase } from 'lodash-es';
import { toast, ToastOptions } from 'material-react-toastify';
import { Stack } from '@mui/material';
import { ErrorIcon, InfoIcon } from 'assets/icons';

export const errorToast = (message: string, options?: ToastOptions) => {
  toast.error(
    <Stack
      data-cy={kebabCase(`toast-error-${message}`)}
      direction="row"
      alignItems="center"
      marginRight="1.6rem"
    >
      <ErrorIcon
        className="Toastify__toast-icon--error"
        style={{ width: '1.6rem', minWidth: '1.6rem' }}
      />
      {message}
    </Stack>,
    options,
  );
};

export const successToast = (message: string, options?: ToastOptions) => {
  toast.success(
    <Stack
      data-cy={kebabCase(`toast-success-${message}`)}
      direction="row"
      alignItems="center"
      marginRight="1.6rem"
    >
      <InfoIcon
        className="Toastify__toast-icon--success"
        style={{ width: '1.6rem', minWidth: '1.6rem' }}
      />
      <span>{message}</span>
    </Stack>,
    options,
  );
};

export const infoToast = (message: string, options?: ToastOptions) => {
  toast.info(
    <Stack
      data-cy={kebabCase(`toast-success-${message}`)}
      direction="row"
      alignItems="center"
      marginRight="1.6rem"
    >
      <InfoIcon
        className="Toastify__toast-icon--info"
        style={{ width: '1.6rem', minWidth: '1.6rem' }}
      />
      {message}
    </Stack>,
    options,
  );
};
