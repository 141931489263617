import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as SessionTimeoutIllustrationLight } from './sessionTimeout.svg';
import { ReactComponent as SessionTimeoutIllustrationDark } from './sessionTimeout_dark.svg';

export const SessionTimeoutIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return <SessionTimeoutIllustrationDark aria-hidden="true" {...props} />;
    return <SessionTimeoutIllustrationLight aria-hidden="true" {...props} />;
  },
);
