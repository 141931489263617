import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Profile, TaskSquare, BookSquare, SearchNormal } from 'iconsax-react';
import { useMediaQuery, useTheme } from '@mui/material';
import { messages } from '../../messages';
import { Bar, Separator } from './styled';
import { SingleStatistic } from './SingleStatistic';

const MINIMUM_MEMBERS = 50;

interface Props {
  members: number;
  completedSurveys: number;
  researchers?: number;
  beneficiaries?: number;
}

export const StatisticsBar = memo(
  ({ members, completedSurveys, researchers, beneficiaries }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    if (members < MINIMUM_MEMBERS) return null;
    return (
      <Bar
        numberOfElements={
          1 +
          Number(!isMobile) +
          Number(researchers !== undefined) +
          Number(beneficiaries !== undefined)
        }
      >
        <SingleStatistic
          label={t(messages.members())}
          number={members}
          Icon={Profile}
        />
        <Separator />
        {!isMobile && (
          <SingleStatistic
            label={t(messages.completedSurveys())}
            number={completedSurveys}
            Icon={TaskSquare}
          />
        )}
        {researchers !== undefined && (
          <>
            <Separator />
            <SingleStatistic
              label={t(messages.researchers())}
              number={researchers}
              Icon={BookSquare}
            />
          </>
        )}
        {beneficiaries !== undefined && (
          <>
            <Separator />
            <SingleStatistic
              label={t(messages.peopleWeCanHelp())}
              number={`${beneficiaries.toLocaleString('fr-FR')} +`}
              Icon={SearchNormal}
            />
          </>
        )}
      </Bar>
    );
  },
);
