declare module '@mui/material/styles' {
  interface TypeBackground {
    base: {
      primary: string;
    };
    color: {
      primary: string;
      secondary: string;
      activityStack: string;
    };
  }

  type PaletteType = 'light' | 'dark';

  interface Palette {
    type: PaletteType;
    background: TypeBackground;
    separator: {
      primary: string;
      hover: string;
    };
    white: string;
    button: TypeButton;
    message: {
      text: {
        success: string;
        neutral: string;
        warning: string;
        error: string;
        informative: string;
      };
      background: {
        success: string;
        neutral: string;
        warning: string;
        error: string;
        informative: string;
      };
      error: string;
      success: string;
    };
    elements: TypeElements;
    additionalColors: TypeAdditionalColors;
    paper: Palette['primary'];
    label: TypeLabel;
  }

  interface PaletteOptions {
    separator?: {
      primary?: string;
      hover?: string;
    };
    light?: { hover?: string };
    white?: string;
    button?: Partial<TypeButton>;
    message?: {
      text?: {
        success?: string;
        neutral?: string;
        warning?: string;
        error?: string;
        informative?: string;
      };
      background?: {
        success?: string;
        neutral?: string;
        warning?: string;
        error?: string;
        informative?: string;
      };
      error?: string;
      success?: string;
    };
    elements: TypeElements;
    additionalColors?: TypeAdditionalColors;
    paper?: PaletteOptions['primary'];
    label: TypeLabel;
  }

  interface ThreeColorPalette {
    primary: string;
    secondary: string;
    tertiary: string;
  }

  interface ThreeColorPaletteWithAccent extends ThreeColorPalette {
    accent: string;
  }

  interface TypeBackground {
    default: string;
    paper: string;
    primaryMobile: string;
    screen: {
      solid: string;
      transparent: string;
    };
    tile: {
      primary: string;
      secondary: string;
      secondaryShade: string;
    };
    elevated: {
      primary: string;
      secondary: string;
      tetriary: string;
      menuHover: string;
      pressed: string;
    };
    elevatedShadow: string;
  }

  interface TypeElements extends ThreeColorPalette {
    mainBackground: string;
    secondaryBackground: string;
    inputBackground: string;
    hoverState: string;
    disabledBackground: string;
    inactiveElement: string;
    separator: string;
    elevatedShadow: string;
  }

  interface TypeButton {
    primary: string;
    disabledBackground: string;
    disabledText: string;
    text: string;
    background: string;
  }

  interface TypeTags {
    background: {
      success: string;
      neutral: string;
      error: string;
    };
    text: {
      success: string;
      neutral: string;
      error: string;
    };
  }

  interface TypeAdditionalColors {
    background: string;
    gradient: string;
    selectedBackground: string;
    primaryText: string;
    secondaryText: string;
    tertiaryText: string;
    bodyText: string;
    accentSuccess: string;
    accentError: string;
    surveyCard: string;
    tileShadow: string;
    sideMenuShadow: string;
    accentLabel: string;
    banner: string;
    inverseCardBorder: string;
  }

  interface TypeLabel {
    background: string;
    text: string;
  }
}

type PaletteType = 'light' | 'dark';

export const lightPalette = {
  type: 'light' as PaletteType,
  primary: {
    main: '#002E5F',
  },
  white: 'rgba(255, 255, 255, 1)',
  paper: {
    main: 'rgba(255, 255, 255, 1)',
  },
  text: {
    primary: 'rgba(0, 46, 95, 1)',
    secondary: '#6A7490',
  },
  separator: {
    primary: '#D9DEEB',
    hover: '#C3CBDF',
  },
  background: {
    default: '#FBFBFB',
    base: {
      primary: '#FBFBFB',
    },
    primaryMobile: '#FBFBFB',
    elevated: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
      tetriary: '#FFFFFF',
      menuHover: '#F1F3F9',
      pressed: '#E9EBF0',
    },
    color: {
      primary: '#D6EAFF',
      secondary: '#ACD4FF',
      activityStack: '#D6EAFF',
    },
    elevatedShadow: 'rgba(149, 157, 165, 0.2)',
  },
  button: {
    primary: 'rgba(0, 106, 220, 1)',
    disabledBackground: 'rgba(233, 235, 241, 1)',
    disabledText: '#8C94AA',
    text: 'rgba(255, 255, 255, 1)',
    background: 'rgba(255, 255, 255, 1)',
  },
  message: {
    text: {
      success: '#008321',
      neutral: '#002E5F',
      warning: '#CC7800',
      error: '#EA1A0F',
      informative: '#006ADC',
    },
    background: {
      success: '#EBF9EE',
      neutral: '#E6F0FB',
      warning: '#FFF6E8',
      error: '#FFEBEA',
      informative: '#E5F0FC',
    },
  },
  elements: {
    primary: '#006ADC',
    secondary: '#FFFFFF',
    tertiary: '#FAFBFF',
    mainBackground: '#FBFBFB',
    secondaryBackground: '#FFFFFF',
    inputBackground: '#FFF',
    hoverState: '#F1F3F9',
    disabledBackground: '#F1F3F9',
    inactiveElement: '#E8EAEE',
    separator: '#D9DEEB',
    elevatedShadow: 'rgba(149, 157, 165, 0.2)',
  },
  additionalColors: {
    background: '#002347',
    gradient:
      'radial-gradient(271.12% 89.52% at 81.73% 99.08%, #002E5F 0%, #002347 62.76%)',
    selectedBackground: '#002E5F',
    primaryText: '#FFFFFF',
    secondaryText: '#CACED8',
    tertiaryText: '#002E5F',
    bodyText: '#3F5173',
    accentSuccess: '#40C676',
    accentError: '#FF7B74',
    surveyCard:
      'linear-gradient(180deg, #F5F5F5 -0.05%, rgba(255, 255, 255, 0) 100%)',
    tileShadow: '0px -1rem 2rem #FFFFFF, 0px .4rem 3rem rgba(49, 48, 111, 0.1)',
    sideMenuShadow: '1rem 1rem 4rem rgba(0, 35, 71, 0.14)',
    accentLabel: '#F77A06',
    banner: '#DAE5F1',
    inverseCardBorder: '#3F5173',
  },
  label: {
    background: '#e5f0fc',
    text: '#006adc',
  },
};

export const darkPalette = {
  type: 'dark' as PaletteType,
  primary: {
    main: '#002E5F',
  },
  white: 'rgba(255, 255, 255, 1)',
  paper: {
    main: 'rgba(255, 255, 255, 1)',
  },
  text: {
    primary: '#EAEAEA',
    secondary: '#868686',
  },
  separator: {
    primary: '#464649',
    hover: '#555559',
  },
  background: {
    default: '#131314',
    base: {
      primary: '#131314',
    },
    primaryMobile: '#000000',
    elevated: {
      primary: '#1C1C1E',
      secondary: '#272728',
      tetriary: '#353537',
      menuHover: '#3C3C3F',
      pressed: '#2F2E2E',
    },
    color: {
      primary: '#272728',
      secondary: '#353537',
      activityStack: '#0E0E0E',
    },
    elevatedShadow: 'rgba(0, 0, 0, 0.8)',
  },
  button: {
    primary: '#F2B014',
    disabledBackground: '#333336',
    disabledText: '#868686',
    text: '#2C2C2E',
    background: '#34343B',
  },
  message: {
    text: {
      success: '#40C676',
      neutral: '#FFFFFF',
      warning: '#ED8B00',
      error: '#FF7B74',
      informative: '#007BFF',
    },
    background: {
      success: '#243F30',
      neutral: '#4A4A4C',
      warning: '#473319',
      error: '#4A3030',
      informative: '#162F4B',
    },
  },
  elements: {
    primary: '#F2B014',
    secondary: '#1C1C1E',
    tertiary: '#262627',
    mainBackground: '#131314',
    secondaryBackground: '#353537',
    inputBackground: '#2A2A2E',
    hoverState: '#3C3C3F',
    disabledBackground: '#1C1C1E',
    inactiveElement: '#313133',
    separator: '#464649',
    elevatedShadow: 'rgba(0, 0, 0, 0.8)',
  },
  additionalColors: {
    background: '#1C1C1E',
    gradient:
      'radial-gradient(271.12% 89.52% at 81.73% 99.08%, #2D2D2F 0%, #1C1C1E 62.76%)',
    selectedBackground: '#2D2D2F',
    primaryText: '#FFFFFF',
    secondaryText: '#BDBDBD',
    tertiaryText: '#2C2C2E',
    bodyText: '#C1C1C1',
    accentSuccess: '#40C676',
    accentError: '#FF7B74',
    surveyCard:
      'linear-gradient(180deg, #2F2E2E -0.04%, rgba(255, 255, 255, 0) 100%)',
    tileShadow: '0px .4rem 3rem rgba(49, 48, 111, 0.1)',
    sideMenuShadow: '1rem 1rem 4rem rgba(45, 45, 47, 0.14)',
    accentLabel: '#FF7E6A',
    banner: '#333333',
    inverseCardBorder: '#464649',
  },
  label: {
    background: '#463218',
    text: '#ED8B00',
  },
};
