import styled from 'styled-components';
import abstractBackground from './assets/abstractBackground.svg';
import abstractBackgroundDark from './assets/abstractBackground_dark.svg';

export const PanelContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.additionalColors.background};

  padding: 12rem 7.2rem 0;

  ${({ theme }) => theme.breakpoints.down('xxl')} {
    padding: 9.6rem 7.2rem 0;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 7.2rem 4.8rem 0;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 5.6rem 2.4rem 0;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    background-image: url(${({ theme }) =>
      theme.palette.type === 'dark'
        ? abstractBackgroundDark
        : abstractBackground});
    background-repeat: repeat-y;
    background-position: center -22rem;
    background-size: 2189rem 173rem;
  }
`;
