import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  questions: () =>
    _t(translations.components.ActivityCard.questions, '{{count}} questions'),
  minutes: () => _t(translations.components.ActivityCard.minutes, 'minutes'),
  startSurvey: () =>
    _t(translations.components.ActivityCard.startSurvey, 'Start survey'),
  next: () => _t(translations.components.ActivityCard.next, 'Next'),
  estimatedTime: () =>
    _t(translations.components.ActivityCard.estimatedTime, 'Estimated time:'),
  min: () => _t(translations.components.ActivityCard.min, 'min'),
  multipleGroups: () =>
    _t(translations.components.ActivityCard.multipleGroups, 'Multiple groups'),
  forRoleOnly: () =>
    _t(translations.components.ActivityCard.forRoleOnly, 'For {{role}} only'),
};
