import { DependencyList, useEffect } from 'react';

export const useScrollTop = (
  behavior = 'instant' as ScrollOptions['behavior'],
  dependencyArray = [] as DependencyList,
) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior });
  }, dependencyArray);

  return null;
};
