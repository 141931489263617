import { ReportCallback } from 'web-vitals';
import mixpanel from 'mixpanel-browser';

const sendToAnalytics: ReportCallback = ({
  name,
  delta,
  value,
  rating,
  id,
}) => {
  mixpanel.track(name, {
    value: delta,
    metric_id: id,
    metric_value: value,
    metric_delta: delta,
    metric_rating: rating,
  });
};

const initWebVitals = (onPerfEntry?: ReportCallback) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ onCLS, onFID, onLCP }) => {
      onCLS(onPerfEntry);
      onFID(onPerfEntry);
      onLCP(onPerfEntry);
    });
  }
};

const reportWebVitals = () => {
  initWebVitals(sendToAnalytics);
};

export default reportWebVitals;
