import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  yourRegistries: () =>
    _t(translations.components.SideBar.yourRegistries, 'Your registries'),
  settings: () => _t(translations.components.SideBar.settings, 'Settings'),
  help: () => _t(translations.components.SideBar.help, 'Help'),
  logOut: () => _t(translations.components.SideBar.logOut, 'Log out'),
};
