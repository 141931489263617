import styled from 'styled-components';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useTypography } from 'helpers/mixins';

export const StyledReactMarkdown = styled(ReactMarkdown)`
  ${({ theme }) => useTypography(theme.typography.body1)}

  p {
    margin: 0.8rem 0;
  }

  ul {
    padding-left: 0.8rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    li {
      display: flex;
      gap: 0.8rem;
      align-items: flex-start;

      > p {
        margin: 0;
      }

      > svg {
        color: ${({ theme }) => theme.palette.button.primary};
        min-width: 0.8rem;
        margin-top: 0.8rem;
      }
    }
  }
`;
