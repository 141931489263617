/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  whoWillBeFillingOut: () =>
    _t(
      translations.components.RoleSelection.whoWillBeFillingOut,
      'Who will be filling out this survey?',
    ),
  youWillBeTakenToFirstQuestion: () =>
    _t(
      translations.components.RoleSelection.youWillBeTakenToFirstQuestion,
      'You will be taken to the first question in the survey in a moment. Let us know who will be filling it out.',
    ),
  fillingOutAs: () =>
    _t(
      translations.components.RoleSelection.fillingOutAs,
      'I’m filling out as a {{role}}',
    ),
  fillingOutFor: () =>
    _t(
      translations.components.RoleSelection.fillingOutFor,
      'I’m a {{role}}, filling out for a member',
    ),
  assisting: () =>
    _t(
      translations.components.RoleSelection.assisting,
      'I’m a {{role}}, assisting a member',
    ),
  beginTheSurvey: () =>
    _t(
      translations.components.RoleSelection.beginTheSurvey,
      'Begin the survey',
    ),
  joiningByMyself: () =>
    _t(
      translations.components.RoleSelection.joiningByMyself,
      ' (joining by myself)',
    ),
  assistingParticipant: () =>
    _t(
      translations.components.RoleSelection.assistingParticipant,
      ' (assisting a participant)',
    ),
  enrollingParticipant: () =>
    _t(
      translations.components.RoleSelection.enrollingParticipant,
      ' (enrolling a participant)',
    ),
};
