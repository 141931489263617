import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/Modal';
import { ModalContent } from 'components/Modal/ModalContent';
import { MarkdownRenderer } from 'components/MarkdownRenderer';
import { termsAndConditionsEn } from 'assets/documents/termsAndConditionsEn';
import { messages } from './messages';

import { ContentWrapper, Label } from './styled';

export const TermsAndConditions = memo(() => {
  const { t, i18n } = useTranslation();
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const getProperTranslation = () => {
    switch (i18n.language) {
      case 'en':
        return termsAndConditionsEn;
      default:
        return termsAndConditionsEn;
    }
  };

  return (
    <>
      <Label
        onClick={() => setShowTermsAndConditions(true)}
        data-cy="terms-and-conditions-link"
      >
        {t(messages.termsAndConditions())}
      </Label>
      <Modal
        open={showTermsAndConditions}
        onClose={() => setShowTermsAndConditions(false)}
        title={t(messages.termsAndConditions())}
        closeOnClickAway
      >
        <ModalContent>
          <ContentWrapper>
            <MarkdownRenderer>{getProperTranslation()}</MarkdownRenderer>
          </ContentWrapper>
        </ModalContent>
      </Modal>
    </>
  );
});
