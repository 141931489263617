import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';
import { RegistryGoal } from 'api/models/Registry/AdditionalParams';
import { SlideShow, Card } from 'components/SlideShow';
import { messages } from '../../messages';
import { PanelContainer } from './styled';

interface Props {
  longDescription?: string;
  goals?: (RegistryGoal | string)[];
}

export const AboutRegistry = memo(({ longDescription, goals }: Props) => {
  const { t } = useTranslation();

  if (!longDescription) return null;

  const renderGoal = (goal: RegistryGoal | string) => {
    if (typeof goal === 'string') return goal;
    return (
      <>
        <Typography
          variant="h3"
          color="additionalColors.primaryText"
          marginBottom="1.2rem"
        >
          {goal.title}
        </Typography>
        <Typography variant="body1" color="additionalColors.primaryText">
          {goal.description}
        </Typography>
      </>
    );
  };

  return (
    <PanelContainer>
      <Stack
        width="100%"
        maxWidth="129.6rem"
        alignItems="center"
        gap={{ lg: '7.6rem', md: '5.2rem', xs: '4rem' }}
      >
        <Stack
          maxWidth="85.6rem"
          alignItems={{ lg: 'center', xs: 'flex-start' }}
          gap="1.6rem"
        >
          <Typography variant="h1" color="additionalColors.primaryText">
            {t(messages.aboutRegistry())}
          </Typography>
          <Typography
            variant="body1"
            color="additionalColors.primaryText"
            whiteSpace="pre-wrap"
          >
            {longDescription}
          </Typography>
        </Stack>
        <Stack
          width="100%"
          maxWidth="129.6rem"
          alignItems={{ lg: 'center', xs: 'flex-start' }}
          gap="2.4rem"
        >
          <Typography variant="h2" color="additionalColors.primaryText">
            {t(messages.whyItsImportant())}
          </Typography>
          {goals !== undefined && (
            <SlideShow>
              {goals.map((goal, i) => (
                <Card number={i + 1} key={i}>
                  {renderGoal(goal)}
                </Card>
              ))}
            </SlideShow>
          )}
        </Stack>
      </Stack>
    </PanelContainer>
  );
});
