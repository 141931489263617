import { Stack } from '@mui/material';
import { WVULogoDark } from 'assets/logo';
import { PrivacyPolicy } from 'components/PrivacyPolicy';
import { TermsAndConditions } from 'components/TermsAndConditions';
import { StyledFooter } from './styled';

export const Footer = () => {
  return (
    <StyledFooter>
      <WVULogoDark aria-label="WVU Rockefeller Neuroscience Institute logo" />
      <Stack gap="3.2rem" direction="row">
        <TermsAndConditions />
        <PrivacyPolicy />
      </Stack>
    </StyledFooter>
  );
};
