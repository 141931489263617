import type {} from '@mui/lab/themeAugmentation';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';
import { MuiTextField } from './MuiTextField';
import { MuiButton } from './MuiButton';
import { MuiDivider } from './MuiDivider';
import { MuiAccordion } from './MuiAccordion';
import { MuiAccordionSummary } from './MuiAccordionSummary';
import { MuiLoadingButton } from './MuiLoadingButton';
import { MuiRadio } from './MuiRadio';
import { MuiTabs } from './MuiTabs';
import { MuiTab } from './MuiTab';
import { MuiTabPanel } from './MuiTabPanel';
import { MuiSwitch } from './MuiSwitch';

export const components: Components<Omit<Theme, 'components'>> = {
  MuiTextField,
  MuiButton,
  MuiDivider,
  MuiAccordion,
  MuiAccordionSummary,
  MuiLoadingButton,
  MuiRadio,
  MuiTabs,
  MuiTab,
  MuiTabPanel,
  MuiSwitch,
};
