import { Auth } from '@aws-amplify/auth';

export const UpdatePassword = async ({
  newPassword,
  currentPassword,
}: {
  newPassword: string;
  currentPassword: string;
}) => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) return;

  return Auth.changePassword(user, currentPassword, newPassword);
};
