import { createContext, useState } from 'react';

type User = null;

interface Props {
  children: React.ReactNode;
}

const UserContext = createContext<User>(null);

const UserDispatchContext = createContext<
  React.Dispatch<React.SetStateAction<User | null>>
>((() => {}) as React.Dispatch<React.SetStateAction<User | null>>);

const UserProvider = ({ children }: Props) => {
  const [userDetails, setUserDetails] = useState<User | null>(null);

  return (
    <UserContext.Provider value={userDetails}>
      <UserDispatchContext.Provider value={setUserDetails}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext, UserDispatchContext };
