import i18next from 'i18next';
import { QualificationSteps } from './types';
import { messages } from './messages';

export const getStepName = (step: QualificationSteps) => {
  switch (step) {
    case QualificationSteps.ROLE_SELECTION:
      return i18next.t(messages.roleSelection());
    case QualificationSteps.MEMBER_DETAILS:
      return i18next.t(messages.memberDetails());
    case QualificationSteps.CAREGIVER_DETAILS:
      return i18next.t(messages.caregiverDetails());
    case QualificationSteps.CAREGIVER_CONTACT_DETAILS:
      return i18next.t(messages.caregiverContactDetails());
    case QualificationSteps.ACCOUNT_DETAILS:
      return i18next.t(messages.accountDetails());
    case QualificationSteps.AGREEMENTS:
      return i18next.t(messages.agreements());
    case QualificationSteps.CONFIRM_APPLICATION:
      return i18next.t(messages.confirmApplication());
    case QualificationSteps.NEXT_STEP:
      return i18next.t(messages.nextSteps());
    case QualificationSteps.SURVEY_START:
      return i18next.t(messages.surveyStart());
    case QualificationSteps.QUALIFICATION_SURVEY:
    default:
      return null;
  }
};

export const getSectionName = (step: QualificationSteps) => {
  switch (step) {
    case QualificationSteps.ROLE_SELECTION:
    case QualificationSteps.MEMBER_DETAILS:
    case QualificationSteps.CAREGIVER_DETAILS:
    case QualificationSteps.CAREGIVER_CONTACT_DETAILS:
    case QualificationSteps.ACCOUNT_DETAILS:
    case QualificationSteps.AGREEMENTS:
    case QualificationSteps.CONFIRM_APPLICATION:
    case QualificationSteps.NEXT_STEP:
      return i18next.t(messages.qualificationBaseline());
    case QualificationSteps.SURVEY_START:
    case QualificationSteps.QUALIFICATION_SURVEY:
      return i18next.t(messages.qualificationSurvey());
    default:
      return null;
  }
};
