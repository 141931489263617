import { Components } from '@mui/material/styles/components';
import { Theme } from 'styles/theme/themes';
import { RadioCheckedIcon, RadioUncheckedIcon } from 'assets/icons';

export const MuiRadio: Components<Theme>['MuiRadio'] = {
  defaultProps: {
    icon: <RadioUncheckedIcon />,
    checkedIcon: <RadioCheckedIcon />,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.button.primary,

      svg: {
        width: '2.4rem',
        height: '2.4rem',

        // outside rim
        rect: {
          stroke: theme.palette.separator.primary,
        },

        // inside
        circle: {
          fill: theme.palette.button.primary,
        },
      },

      '.MuiTouchRipple-root': {
        color: theme.palette.button.primary,
      },

      '&:hover': {
        backgroundColor: 'transparent',
      },
    }),
  },
};
