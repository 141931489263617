import styled from 'styled-components';

export const AbsoluteOverlayContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.background.base.primary};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 2.8rem 2.4rem;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 2.8rem 4.8rem;
  }
`;

export const OverlayContainer = styled.div`
  width: 100%;
  height: 43rem;
  background: ${({ theme }) => theme.palette.background.base.primary};

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 2.8rem 4.8rem;
  }
`;

export const OverlayContent = styled.div`
  max-width: 64.8rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    height: 100%;
    justify-content: space-between;

    > div:nth-child(1) {
      margin: auto;
    }

    > div:nth-child(2) {
      margin-top: auto;
    }
  }

  h1,
  p {
    text-align: center;
  }

  > svg,
  > div > svg {
    margin-bottom: 4rem;
    width: 90%;
    height: 100%;
  }

  button {
    width: 100%;
    margin-top: 0.8rem;
  }
`;

export const Illustration = styled.svg`
  width: 49.6rem;
  max-width: 100%;
  margin-bottom: 3.2rem;
`;

export const CohortList = styled.ul`
  list-style-position: inside;
  padding: 0;
`;
