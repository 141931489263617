import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const IllustrationWrapper = styled.div`
  width: 50%;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
  }

  img {
    height: 100%;
    aspect-ratio: 883 / 718;

    --intro-page-illustration-margin-left: -12rem;
    --intro-page-illustration-margin-right: -28.5rem;
    --intro-page-illustration-width: calc(
      100% - var(--intro-page-illustration-margin-right) -
        var(--intro-page-illustration-margin-left)
    );
    margin-left: var(--intro-page-illustration-margin-left);
    margin-right: var(--intro-page-illustration-margin-right);
    margin-bottom: -13.5rem;
    width: var(--intro-page-illustration-width);

    ${({ theme }) => theme.breakpoints.down('lg')} {
      --intro-page-illustration-margin-right: -16.8rem;
      height: calc(100% + 13rem);
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      --intro-page-illustration-margin-left: -8rem;
      --intro-page-illustration-margin-right: -18.4rem;
      margin-top: -4rem;
      margin-bottom: -4rem;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      margin-top: -2rem;
    }
  }
`;

export const GoBackLink = styled(Link)`
  display: flex;
  gap: 0.8rem;
  flex-direction: row;
  margin-bottom: 0.8rem;

  svg {
    width: 2.4rem;
  }

  span {
    transition: color 300ms;
  }

  :hover span {
    color: ${({ theme }) => theme.palette.button.primary};
  }
`;
