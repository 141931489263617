import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import mixpanel from 'mixpanel-browser';
import { ArrowRight } from 'iconsax-react';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Api from 'api/api';
import { queryKeys } from 'api/queryKeys';
import { EventNames } from 'types/analytics';
import { Params } from 'pages/Qualification/types';
import { messages } from '../../messages';
import { Wrapper, CloseIcon, HealthyRegistryLink, Placeholder } from './styled';

export const PublicRegistriesNotification = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const { registryCode } = useParams<Params>();

  const { data } = useQuery({
    queryKey: queryKeys.publicRegistries(),
    queryFn: Api.getPublicRegistries,
    staleTime: 30 * 60 * 1000,
    enabled: visible,
  });

  const publicRegistry = data?.registries.find(
    ({ isForHealthyPopulation }) => isForHealthyPopulation,
  );
  const isPublicRegistry = registryCode === publicRegistry?.code;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!visible || !publicRegistry || isPublicRegistry) return null;
  return (
    <>
      <Placeholder />
      {!isMobile ? (
        <Wrapper>
          <Stack
            columnGap="2rem"
            direction={{
              xs: 'row',
            }}
            flexWrap="wrap"
          >
            <Typography
              variant="body2"
              color="additionalColors.primaryText"
              flexShrink={0}
              maxWidth="100%"
            >
              {t(messages.healthyVolunteersAreJustAsImportant())}
            </Typography>
            <HealthyRegistryLink
              to={`/registry/${publicRegistry.code}`}
              onClick={() => {
                mixpanel.track(
                  EventNames.PUBLIC_REGISTRY_NOTIFICATION_CLICKED,
                  {
                    registry_code: registryCode,
                  },
                );
              }}
            >
              {t(messages.goToRegistryForHealthy())}
              <ArrowRight />
            </HealthyRegistryLink>
          </Stack>
          <CloseIcon
            role="button"
            aria-label="Close notification"
            onClick={() => setVisible(false)}
          />
        </Wrapper>
      ) : (
        <Wrapper>
          <HealthyRegistryLink to={`/registry/${publicRegistry.code}`}>
            {t(messages.goToRegistryForHealthy())}
          </HealthyRegistryLink>
          <CloseIcon
            role="button"
            aria-label="Close notification"
            onClick={() => setVisible(false)}
          />
        </Wrapper>
      )}
    </>
  );
};
