import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as BrowseRegistriesIllustrationLight } from './browseRegistries.svg';
import { ReactComponent as BrowseRegistriesIllustrationDark } from './browseRegistries_dark.svg';

export const BrowseRegistriesIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return <BrowseRegistriesIllustrationDark aria-hidden="true" {...props} />;
    return <BrowseRegistriesIllustrationLight aria-hidden="true" {...props} />;
  },
);
