import React, { useRef } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { getSystemTheme } from 'styles/theme/utils';

export const useTheme = () => useRef(getSystemTheme()).current;

export const ThemeProvider = ({
  children,
}: {
  children: React.ReactChild;
}): JSX.Element => {
  const theme = useTheme();

  return (
    <MuiThemeProvider theme={theme}>
      {React.Children.only(children)}
    </MuiThemeProvider>
  );
};
