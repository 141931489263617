import styled from 'styled-components';

export const IllustrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 43.9rem;
  padding-top: 0;
  padding-bottom: 0;
  gap: 3.2rem;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    height: auto;
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
`;
