import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { secondsToMilliseconds } from 'date-fns';
import Api from 'api/api';
import Session from 'api/storage/session';
import UserActivityTime from 'api/storage/userActivityTime';
import { usePrefetchQuery } from 'helpers/queries';
import { LoadingPage } from 'pages/Loading';
import { Params as LoginParams } from 'pages/Login/types';
import { messages } from './messages';

export const HealthcareProvidersCallback = () => {
  const { t } = useTranslation();
  const { current: userEmail } = useRef(Session.decodeToken()?.email);
  const navigate = useNavigate();
  const prefetchQuery = usePrefetchQuery();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const providerId = searchParams.get('state');
  const missingParams = !code || !providerId;

  const [isLoggedIn] = useState(() => {
    const decodedToken = Session.decodeToken();
    if (!decodedToken) return false;

    const expTime = secondsToMilliseconds(decodedToken.exp);
    const currentTime = Date.now();
    const isActive = expTime - currentTime > secondsToMilliseconds(30);

    if (isActive) {
      // update user activity time so that the user is not logged out immediately
      UserActivityTime.saveUserTime(new Date(), decodedToken['custom:userId']);
    }

    return isActive;
  });

  useEffect(() => {
    if (isLoggedIn) {
      prefetchQuery(
        ['userAgreements', { userEmail: userEmail! }],
        Api.getAgreements,
      );
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (missingParams && isLoggedIn)
      return navigate('/settings', {
        state: {
          healthcareProviders: {
            error: t(messages.unknownError()),
          },
        },
      });
    if (missingParams && !isLoggedIn)
      return navigate('/login', {
        state: {
          redirectUri: '/settings',
          forwardedState: {
            healthcareProviders: {
              error: t(messages.unknownError()),
            },
          },
        } as LoginParams,
      });

    if (!isLoggedIn)
      return navigate('/login', {
        state: {
          redirectUri: '/settings',
          forwardedState: {
            healthcareProviders: {
              code,
              providerId,
            },
          },
        } as LoginParams,
      });

    navigate('/settings', {
      state: {
        healthcareProviders: {
          code,
          providerId,
        },
      },
    });
  }, [code, providerId, navigate]);

  return <LoadingPage />;
};
