import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1rem);
  grid-template-rows: 1rem;
  grid-gap: 0.4rem;

  @keyframes LoaderDot {
    0%,
    6.25% {
      opacity: 0.3;
      transform: scale(0.8);
    }

    43.75% {
      opacity: 1;
      transform: scale(1);
    }

    56.25% {
      opacity: 1;
      transform: scale(1);
    }

    93.75%,
    100% {
      opacity: 0.3;
      transform: scale(0.8);
    }
  }

  > span {
    background: ${({ theme }) => theme.palette.button.primary};
    border-radius: 100%;
    animation: LoaderDot 800ms linear infinite;
    animation-direction: alternate;

    opacity: 0.3;
    transform: scale(0.8);
    transform-origin: center;
  }

  > span:nth-child(1) {
    animation-delay: 0ms;
  }

  > span:nth-child(2) {
    animation-delay: calc(800ms / 4);
  }

  > span:nth-child(3) {
    animation-delay: calc(800ms / 2);
  }
`;
