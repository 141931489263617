import styled from 'styled-components';
import { Stack } from '@mui/material';
import { useTypography } from 'helpers/mixins';

export const Container = styled.div`
  width: 100%;
  border-radius: 1.6rem;
  border: 0.1rem solid ${({ theme }) => theme.palette.separator.primary};
  > a {
    background: ${({ theme }) => theme.palette.background.elevated.primary};
    border-radius: 1.6rem;
    display: flex;
    grid-gap: 1.6rem;
    flex-direction: row;
    justify-content: space-between;

    padding: 1.6rem;
    height: 100%;
    width: 100%;

    a {
      svg {
        height: 1.4rem;
        width: 1.4rem;
        margin-right: 0.8rem;
        opacity: 0;
        transition: opacity 600ms;
      }

      span {
        transform: translateX(-1.3rem);
        transition: transform 600ms;
        display: flex;
        align-items: center;
      }
    }

    :hover a {
      background: ${({ theme }) => theme.palette.button.primary};
      color: ${({ theme }) => theme.palette.button.text};

      ${({ theme }) => theme.breakpoints.up('md')} {
        svg {
          opacity: 1;
          stroke: unset;
        }

        span {
          transform: translateX(0);
        }
      }
    }
  }

  overflow: hidden;
  height: 100%;
`;

export const SurveyWrapper = styled(Stack)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 1.6rem;
`;

export const SurveyInformationWrapper = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
`;

export const BrainIllustrationWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 6.4rem;
  min-height: 6.4rem;
  border-radius: 1rem;
  background-color: ${({ theme }) =>
    theme.palette.background.elevated.menuHover};
`;

export const RoleWrapper = styled.h2`
  ${({ theme }) => useTypography(theme.typography.h3)}

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.2rem;

  color: ${({ theme }) => theme.palette.additionalColors.accentLabel};

  margin: 0;
  padding: 0;
`;

export const SurveyName = styled.h2`
  ${({ theme }) => useTypography(theme.typography.h3)}

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 1.6rem;

  color: ${({ theme }) => theme.palette.text.primary};

  margin: 0;
  padding: 0;
`;

export const SurveyInformation = styled.span`
  ${({ theme }) => useTypography(theme.typography.body1)}

  display: flex;
  align-items: baseline;
  text-align: center;
  gap: 0.8rem;

  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${({ theme }) => theme.palette.text.primary};

  svg {
    align-self: center;
    width: 1.6rem;
    height: 1.6rem;

    color: ${({ theme }) => theme.palette.button.primary};
  }

  strong {
    ${({ theme }) => useTypography(theme.typography.h5)}
  }
`;
