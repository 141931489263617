import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Tab, useMediaQuery, useTheme } from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { Helmet } from 'react-helmet-async';
import { GetRegistryResponse } from 'api/interfaces/response';
import { RegistryPerson } from 'api/interfaces/response/getRegistryPersonsResponse';
import { StarIcon } from 'assets/icons';
import { messages } from '../messages';
import { AvailableTabs, RegistryParams } from '../types';
import { FlexTabWrapper } from '../styled';
import { TopPrioritySurveys } from './TopPrioritySurveys';
import { AllSurveys } from './AllSurveys';
import { AboutRegistry } from './AboutRegistry';

interface Props {
  data?: GetRegistryResponse;
  persons?: RegistryPerson[];
}

export const RegistryTabs = ({ data, persons }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, tab } = useParams<RegistryParams>();

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: AvailableTabs,
  ) => {
    navigate(
      generatePath('/registries/:id/:tab', {
        id: id!,
        tab: newValue,
      }),
      { replace: false },
    );
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isNarrowMobile = useMediaQuery('(max-width:340px)');

  const getTabTitle = () => {
    switch (tab) {
      case AvailableTabs.TOP_PRIORITY:
        return isMobile ? t(messages.top()) : t(messages.topPriority());
      case AvailableTabs.ALL_SURVEYS:
        return t(messages.allSurveys());
      case AvailableTabs.ABOUT_REGISTRY:
        return t(messages.aboutRegistry());
      default:
        return '';
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {data?.name} - {t(messages.dashboard())} - {getTabTitle()}
        </title>
      </Helmet>
      <TabContext value={tab ?? AvailableTabs.TOP_PRIORITY}>
        <TabList onChange={handleChange}>
          <Tab
            label={
              <FlexTabWrapper>
                {!isNarrowMobile && <StarIcon width="1.8rem" />}
                {isMobile ? t(messages.top()) : t(messages.topPriority())}
              </FlexTabWrapper>
            }
            value={AvailableTabs.TOP_PRIORITY}
          />
          <Tab
            label={t(messages.allSurveys())}
            value={AvailableTabs.ALL_SURVEYS}
          />
          <Tab
            label={t(messages.aboutRegistry())}
            value={AvailableTabs.ABOUT_REGISTRY}
          />
        </TabList>
        <TabPanel style={{ height: '100%' }} value={AvailableTabs.TOP_PRIORITY}>
          {data && <TopPrioritySurveys data={data} />}
        </TabPanel>
        <TabPanel value={AvailableTabs.ALL_SURVEYS}>
          {data && <AllSurveys data={data} />}
        </TabPanel>
        <TabPanel value={AvailableTabs.ABOUT_REGISTRY}>
          {data && <AboutRegistry data={data} persons={persons} />}
        </TabPanel>
      </TabContext>
    </>
  );
};
