import { memo, useEffect, useState } from 'react';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import Session from 'api/storage/session';
import UserActivityTime from 'api/storage/userActivityTime';
import LastUrlStorage from 'api/storage/lastUrl';
import { useAutofocus } from 'hooks/useAutofocus';
import { Params, FormSteps } from '../../types';
import { getStepName } from '../../helpers';
import { messages } from '../../messages';
import { FormPanelInnerContainer } from '../FormContainer/styled';
import { UsernameStep } from '../UsernameStep';
import { MFAStep } from '../MFAStep';
import { PasswordStep } from '../PasswordStep';
import { VerifyAccountStep } from '../VerifyAccountStep';
import { PasswordResetEmailStep } from '../PasswordResetEmailStep';
import { PasswordResetSuccessScreen } from '../PasswordResetSuccessScreen';
import { PasswordResetCodeStep } from '../PasswordResetCodeStep';
import { SetUpPasswordStep } from '../SetUpPasswordStep';

interface Props {
  route: 'login' | 'register';
}

export const LoginForm = memo(({ route }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const { redirectUri, forwardedState } = (location.state || {}) as Params;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cognitoUser, setCognitoUser] = useState<CognitoUserInterface | null>(
    null,
  );
  const [currentStep, setCurrentStep] = useState<FormSteps>(
    route === 'register'
      ? FormSteps.SET_UP_PASSWORD_STEP
      : FormSteps.USERNAME_STEP,
  );
  const autofocus = useAutofocus(currentStep !== FormSteps.PASSWORD_STEP, [
    currentStep,
  ]);

  // to handle back/forward buttons
  useEffect(() => {
    if (route === 'register') {
      setCurrentStep(FormSteps.SET_UP_PASSWORD_STEP);
    } else setCurrentStep(FormSteps.USERNAME_STEP);
  }, [route]);

  const onLoginSuccess = (user: CognitoUserInterface) => {
    const userId = user.attributes['custom:userId'];
    UserActivityTime.saveUserTime(new Date(), userId);
    Session.saveSession(user.signInUserSession.idToken.jwtToken);

    navigate(LastUrlStorage.load(userId) ?? redirectUri ?? '/registries', {
      state: forwardedState,
    });
    LastUrlStorage.clear();
  };

  const getStepForm = () => {
    switch (currentStep) {
      case FormSteps.USERNAME_STEP:
        return (
          <UsernameStep
            onUsernameSet={newUsername => {
              setUsername(newUsername);
              setCurrentStep(FormSteps.PASSWORD_STEP);
            }}
            onNotConfirmed={newUsername => {
              setUsername(newUsername);
              setCurrentStep(FormSteps.VERIFY_ACCOUNT);
            }}
          />
        );
      case FormSteps.PASSWORD_STEP:
        return (
          <PasswordStep
            onSuccess={onLoginSuccess}
            onMFA={(user, pass) => {
              setCurrentStep(FormSteps.VALIDATION_STEP);
              setCognitoUser(user);
              setPassword(pass);
            }}
            onNotConfirmed={pass => {
              setPassword(pass);
              setCurrentStep(FormSteps.VERIFY_ACCOUNT);
            }}
            onForgotPassword={() => {
              setCurrentStep(FormSteps.RESET_PASSWORD_EMAIL_STEP);
            }}
            onBack={() => {
              setCurrentStep(FormSteps.USERNAME_STEP);
            }}
            username={username}
          />
        );
      case FormSteps.VERIFY_ACCOUNT:
        return (
          <VerifyAccountStep
            username={username}
            onReturn={() => setCurrentStep(FormSteps.PASSWORD_STEP)}
            onLogin={() => {
              navigate({ pathname: `/login`, search });
              setCurrentStep(FormSteps.USERNAME_STEP);
            }}
          />
        );
      case FormSteps.VALIDATION_STEP:
        return (
          <MFAStep
            onSuccess={onLoginSuccess}
            cognitoUser={cognitoUser!}
            username={username}
            password={password}
            onExpire={() => {
              setCognitoUser(null);
              setUsername('');
              setPassword('');
              setCurrentStep(FormSteps.USERNAME_STEP);
            }}
          />
        );
      case FormSteps.RESET_PASSWORD_EMAIL_STEP:
        return (
          <PasswordResetEmailStep
            onSuccess={name => {
              setUsername(name);
              setCurrentStep(FormSteps.RESET_PASSWORD_CODE_STEP);
            }}
            onBack={() => {
              setCurrentStep(FormSteps.PASSWORD_STEP);
            }}
            username={username}
          />
        );
      case FormSteps.RESET_PASSWORD_CODE_STEP:
        return (
          <PasswordResetCodeStep
            onSuccess={() => {
              setCurrentStep(FormSteps.RESET_PASSWORD_SUCCESS_SCREEN);
            }}
            onBack={() => {
              setCurrentStep(FormSteps.RESET_PASSWORD_EMAIL_STEP);
            }}
            onReturn={() => {
              setCognitoUser(null);
              setPassword('');
              setCurrentStep(FormSteps.PASSWORD_STEP);
            }}
            username={username}
          />
        );
      case FormSteps.RESET_PASSWORD_SUCCESS_SCREEN:
        return (
          <PasswordResetSuccessScreen
            onReturn={() => setCurrentStep(FormSteps.USERNAME_STEP)}
          />
        );
      case FormSteps.SET_UP_PASSWORD_STEP:
        return (
          <SetUpPasswordStep
            onLogin={() => {
              navigate({ pathname: `/login`, search });
              setCurrentStep(FormSteps.USERNAME_STEP);
            }}
            onAutologin={onLoginSuccess}
          />
        );
      default:
        return null;
    }
  };

  return (
    <FormPanelInnerContainer {...autofocus}>
      <Helmet>
        <title>
          {t(messages.loginPage())} - {getStepName(currentStep)}
        </title>
      </Helmet>
      {getStepForm()}
    </FormPanelInnerContainer>
  );
});
