import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import printJS from 'print-js';
import { queryKeys } from 'api/queryKeys';
import { useQuery } from '@tanstack/react-query';
import {
  Printer as PrintIcon,
  DirectInbox as DownloadIcon,
} from 'iconsax-react';
import { CloseIcon } from 'components/Modal/styled';
import { EventNames, PdfViewerEventActions } from 'types/analytics';
import { isIOS } from 'helpers/isIOS';
import { LoadingPage } from 'pages/Loading';
import { PDFRenderer } from './PDFRenderer';
import { StyledModal } from './styled';
import { messages } from './messages';

interface Props {
  path?: string;
  title?: string;
  open: boolean;
  onClose(): void;
  gaSource?: string;
}

export const PDFViewer = memo(
  ({ path, title, open, onClose, gaSource }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const sendAnalytics = (action: PdfViewerEventActions) => {
      mixpanel.track(EventNames.PDF_VIEWER, {
        action,
        document_path: path,
        document_title: title,
        source: gaSource,
      });
    };

    useEffect(() => {
      sendAnalytics(PdfViewerEventActions.OPEN_PREVIEW);
    }, []);

    const { data: base64 } = useQuery({
      queryKey: queryKeys.pdf({ path: path! }),
      enabled: path !== undefined,
      staleTime: 60 * 60 * 1000,
      queryFn: async (): Promise<string> => {
        if (!path) return '';

        const response = await fetch(path);
        const blob = await response.blob();

        return new Promise((onSuccess, onError) => {
          try {
            const reader = new FileReader();
            reader.onload = ({ target }) => {
              onSuccess((target?.result ?? path) as string);
            };
            reader.readAsDataURL(blob);
          } catch (e) {
            onError(e);
          }
        });
      },
    });

    const printDocument = () => {
      sendAnalytics(PdfViewerEventActions.PRINT_DOCUMENT);

      if (base64)
        printJS({
          printable: base64.split('data:application/pdf;base64,')[1],
          type: 'pdf',
          base64: true,
        });
      else if (path) printJS(path);
    };

    const sharePDF = async () => {
      sendAnalytics(PdfViewerEventActions.SHARE_DOCUMENT);

      if (!path) return;
      const url = base64 ?? path;

      try {
        const raw = await fetch(url);
        const blob = await raw.blob();
        const file = new File([blob], `${title}.pdf`, {
          type: 'application/pdf',
        });

        const shareData: ShareData = {
          files: [file],
        };

        navigator.share(shareData);
      } catch (_) {
        // if can't share, open in new tab so
        // user can download or share manually
        window.open(url, '_blank')?.focus();
      }
    };

    const downloadPdfProps = () => {
      if (isIOS()) {
        if (!navigator.canShare)
          return { component: 'a', href: path, download: title };
        return { onClick: sharePDF };
      }

      return {
        component: 'a',
        href: base64 ?? path,
        target: '_blank',
        download: `${title}.pdf`,
        onClick: () => sendAnalytics(PdfViewerEventActions.DOWNLOAD_DOCUMENT),
      };
    };

    return (
      <StyledModal open={open} onClose={onClose}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h1">{title}</Typography>
          <CloseIcon onClick={onClose} data-cy="modal-close-icon" />
        </Stack>
        {base64 === undefined ? (
          <LoadingPage
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent',
              color: 'button.primary',
            }}
          />
        ) : (
          <PDFRenderer path={base64} />
        )}
        <Stack
          direction={{
            md: 'row-reverse',
            xs: 'column',
          }}
          gap="2.4rem"
        >
          <Button
            style={{ width: '100%' }}
            variant="alternative"
            data-cy="download-button"
            {...downloadPdfProps()}
          >
            <DownloadIcon />
            {t(messages.download())}
          </Button>
          <Button
            style={{ width: '100%' }}
            variant="alternative"
            onClick={printDocument}
            data-cy="print-button"
          >
            <PrintIcon />
            {isMobile ? t(messages.print()) : t(messages.printDocument())}
          </Button>
        </Stack>
      </StyledModal>
    );
  },
);
