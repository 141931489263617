import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as ForgotPasswordIllustrationLight } from './forgotPassword.svg';
import { ReactComponent as ForgotPasswordIllustrationDark } from './forgotPassword_dark.svg';

export const ForgotPasswordIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return <ForgotPasswordIllustrationDark aria-hidden="true" {...props} />;
    return <ForgotPasswordIllustrationLight aria-hidden="true" {...props} />;
  },
);
