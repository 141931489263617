import { ButtonBase, Stack } from '@mui/material';
import styled from 'styled-components';
import { useTypography } from 'helpers/mixins';

export const StyledRoleButton = styled(ButtonBase)<{ $selected: boolean }>`
  padding: 2rem 2.4rem 2rem 2.4rem;
  min-height: 6.8rem;
  border: ${({ $selected, theme }) =>
    $selected
      ? `0.15rem solid ${theme.palette.elements.primary}`
      : `0.1rem solid ${theme.palette.separator.primary}`};
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.palette.elements.secondary};
  justify-content: flex-start;
  transition: border 300ms ease-in-out;

  &:hover {
    border: ${({ theme }) => `0.15rem solid ${theme.palette.elements.primary}`};
  }

  svg {
    color: ${({ theme }) => theme.palette.elements.primary};
  }
`;

export const RoleButtonContentWrapper = styled(Stack)`
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
`;

export const RoleType = styled.span`
  ${({ theme }) => useTypography(theme.typography.h5)}
  font-size: 1.4rem;
`;

export const RoleMessage = styled.span`
  ${({ theme }) => useTypography(theme.typography.body1)}
  font-size: 1.4rem;
`;
