import { AES } from 'crypto-es/lib/aes';
import { Utf8 as Utf8Encoding } from 'crypto-es/lib/core';
import Session from 'api/storage/session';
import { STORAGE_USER_ROLE } from 'config/constants';

export default class UserRoles {
  public static clear(): void {
    localStorage.removeItem(STORAGE_USER_ROLE);
  }

  public static clearRoleForRegistry(registryId: string): void {
    const roles = this.getRolesForAllRegistries();
    delete roles[registryId];
    this.saveRolesForAllRegistries(roles);
  }

  public static getRolesForAllRegistries(): Record<string, string> {
    const encryptedRoles = localStorage.getItem(STORAGE_USER_ROLE);
    if (!encryptedRoles) return {};

    const id = Session.decodeToken()?.['custom:userId'];
    if (!id) {
      this.clear();
      return {};
    }

    try {
      const decoded = AES.decrypt(encryptedRoles, id).toString(Utf8Encoding);
      return JSON.parse(decoded);
    } catch {
      this.clear();
      return {};
    }
  }

  public static getRoleForRegistry(registryId: string): string | undefined {
    const roles = this.getRolesForAllRegistries();
    return roles[registryId];
  }

  public static saveRolesForAllRegistries(roles: Record<string, string>) {
    const id = Session.decodeToken()?.['custom:userId'];

    if (!id) {
      return;
    }

    const stringified = JSON.stringify(roles);
    const encrypted = AES.encrypt(stringified, id).toString();

    localStorage.setItem(STORAGE_USER_ROLE, encrypted);
  }

  public static saveRoleForRegistry(registryId: string, role: string) {
    const roles = this.getRolesForAllRegistries();
    roles[registryId] = role;
    this.saveRolesForAllRegistries(roles);
  }
}
