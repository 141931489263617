import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { GetRegistryResponse } from 'api/interfaces/response/getRegistryResponse';
import { messages as roleSelectionMessages } from 'components/RoleSelection/messages';
import { CloseIcon } from 'components/Modal/styled';
import { messages } from './messages';
import { UpperBarContainer } from './styled';

interface Props {
  role?: GetRegistryResponse['roles'][0];
  onClose(): void;
  qualificationSurvey?: boolean;
}

export const UpperBar = memo(
  ({ role, onClose, qualificationSurvey }: Props) => {
    const { t } = useTranslation();

    const getRoleText = () => {
      if (!role) return;

      const { perspective, name } = role;
      return t(
        perspective === 'first'
          ? roleSelectionMessages.fillingOutAs()
          : roleSelectionMessages.fillingOutFor(),
        { role: name },
      );
    };

    return (
      <UpperBarContainer>
        {qualificationSurvey ? (
          <>
            <Typography variant="body2" color="text.secondary">
              {t(messages.qualificationSurvey())}
            </Typography>
            <Typography
              variant="body2"
              color="text.primary"
              data-cy="role-preview"
            >
              {getRoleText()}
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant="body2"
              color="text.primary"
              data-cy="role-preview"
            >
              {getRoleText()}
            </Typography>
            <span />
          </>
        )}
        <CloseIcon
          onClick={onClose}
          title="close survey"
          role="button"
          data-cy="close-survey-button"
        />
      </UpperBarContainer>
    );
  },
);
