import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as SurveyInstructionIllustrationLight } from './surveyInstruction.svg';
import { ReactComponent as SurveyInstructionIllustrationDark } from './surveyInstruction_dark.svg';

export const SurveyInstructionIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return (
        <SurveyInstructionIllustrationDark aria-hidden="true" {...props} />
      );
    return <SurveyInstructionIllustrationLight aria-hidden="true" {...props} />;
  },
);
