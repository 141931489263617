import { useTranslation } from 'react-i18next';
import { Typography, Stack, Button } from '@mui/material';
import { Modal } from 'components/Modal';
import { messages } from './messages';

interface Props {
  open: boolean;
  onContinue(): void;
  onCancel(): void;
}

export const ConfirmationModal = ({ open, onContinue, onCancel }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t(messages.doYouWantToAddCaregiverRole())}
      onClose={onCancel}
      open={open}
    >
      <Stack
        marginX={{
          lg: '4.8rem',
          sm: '3.2rem',
          xs: '2.4rem',
        }}
        height="100%"
      >
        <Typography variant="body1" color="textPrimary">
          {t(
            messages.currentlyTheParticipantIsParticipatingInTheStudyIndependentlyDoYouWantToAddANewCaregiverRole(),
          )}
        </Typography>
        <Stack
          gap="1.6rem"
          direction={{ xs: 'column-reverse', sm: 'row' }}
          justifyContent="stretch"
          width="100%"
          marginTop={{ xs: 'auto', sm: '2.4rem' }}
        >
          <Button
            variant="alternative"
            onClick={onCancel}
            style={{ width: '100%' }}
          >
            {t(messages.cancel())}
          </Button>
          <Button
            style={{ width: '100%' }}
            onClick={onContinue}
            variant="contained"
            type="submit"
          >
            {t(messages.continue())}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
