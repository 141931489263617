import styled from 'styled-components';

export const PICTURE_VERTICAL_BREAKPOINT = 900;

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-columns: 42rem 1fr;
  grid-template-rows: 1fr;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

export const ArtworkContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    grid-column: 1 / 3;
    overflow: hidden;

    @media only screen and (min-height: ${PICTURE_VERTICAL_BREAKPOINT}px) {
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    position: sticky;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
  }

  > div {
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: hidden;

    > svg {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;
      object-fit: cover;
    }
  }
`;

export const LogoContainer = styled.div`
  margin: 7.2rem 5.6rem;
  margin-bottom: 1.6rem;
  width: 30rem;
  position: absolute;
  z-index: 2;

  > svg {
    cursor: pointer;
  }
`;

export const FormPanelContainer = styled.main`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    grid-row: 1;
    padding: 4rem 0;
    min-height: 58rem;
    justify-content: center;

    > svg {
      margin-bottom: 5rem;
      max-width: 80%;
      cursor: pointer;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 4rem 0 0 0;

    > svg {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    > svg {
      display: none;
    }
  }
`;

export const FormPanelInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: 1.6rem;
  max-width: 55.2rem;
  width: min(55.2rem, 100%);
  margin: auto;
  height: 100%;

  > * {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 100vw;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 3.2rem;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    height: min-content;
  }

  ${({ theme }) => theme.breakpoints.between('sm', 'lg')} {
    margin: unset;
    justify-content: flex-start;
  }

  > form {
    margin: auto;

    ${({ theme }) => theme.breakpoints.between('sm', 'lg')} {
      margin: unset;
    }
  }
`;
