import { Components } from '@mui/material/styles/components';
import { Theme } from 'styles/theme/themes';

export const MuiSwitch: Components<Theme>['MuiSwitch'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: '4.2rem',
      height: '2.6rem',
      padding: 0,

      '.MuiSwitch-switchBase': {
        padding: 0,
        margin: '0.2rem',
        transitionDuration: '300ms',

        '&.Mui-checked': {
          transform: 'translateX(1.6rem)',
          color: '#fff',

          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.button.primary,
            opacity: 1,
            border: 0,
          },

          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },

        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: theme.palette.button.primary,
          border: `0.6rem solid ${theme.palette.background.elevated.primary}`,
        },
      },

      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: '2.2rem',
        height: '2.2rem',
      },

      '.MuiSwitch-track': {
        borderRadius: '1.3rem',
        backgroundColor: theme.palette.button.disabledBackground,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    }),
  },
};
