import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { gradientBorder } from 'helpers/mixins';
import abstractBackground from 'pages/RegistriesList/assets/bg1/light.svg';
import abstractBackgroundDark from 'pages/RegistriesList/assets/bg1/dark.svg';

export const AbstractBackground = styled.div`
  position: relative;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    :before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: url(${({ theme }) =>
          theme.palette.type === 'dark'
            ? abstractBackgroundDark
            : abstractBackground}),
        url(${({ theme }) =>
          theme.palette.type === 'dark'
            ? abstractBackgroundDark
            : abstractBackground});
      background-position: center 5rem, center 153rem;
      background-size: 237.2rem 148rem;
    }
  }
`;

export const RegistryTileBorder = styled.div`
  ${gradientBorder}
  width: min(100%, 44rem);
  height: 100%;
  border-radius: 2.4rem;
  box-shadow: ${({ theme }) => theme.palette.additionalColors.tileShadow};

  :after {
    border-radius: 2.4rem;
    z-index: 1;
    transition: background 300ms;
  }

  :hover {
    cursor: pointer;
    :after {
      background: ${({ theme }) => theme.palette.button.primary};
    }
  }
`;

export const PublicRegistryTile = styled.div`
  ${gradientBorder}
  width: min(100%, 42rem);
  min-width: min(50%, 42rem);
  height: 58rem;
  border-radius: 2.4rem;

  :after {
    border-radius: 2.4rem;
    z-index: 1;
    background: transparent;
    box-shadow: none;
    transition: background 300ms;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    height: auto;
  }

  :hover {
    cursor: pointer;
    :after {
      background: ${({ theme }) => theme.palette.button.primary};
    }
  }

  > a {
    background: ${({ theme }) =>
      theme.palette.additionalColors.selectedBackground};
  }
`;

export const RegistryTile = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  width: 100%;
  height: 100%;
  border-radius: 2.4rem;
  padding: 0.8rem 0.8rem 3.2rem 0.8rem;
  z-index: 2;
  position: relative;
  background: ${({ theme }) => theme.palette.background.elevated.primary};

  h3 {
    min-height: 6rem;
    width: calc(100% - 4.8rem);
    margin: 0rem 2.4rem 0.8rem;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  p {
    width: calc(100% - 4.8rem);
    margin: 0rem 2.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;

    ${({ theme }) => theme.breakpoints.down('lg')} {
      -webkit-line-clamp: 8;
    }
  }
  button {
    min-height: 5.2rem;
    width: calc(100% - 4.8rem);
    margin: 0rem 2.4rem;
    margin-top: auto;
  }
`;

export const RegistryIllustration = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 40/22;
  border-radius: 2rem 2rem 0 0;
  object-fit: cover;
  object-position: top;
  background: ${({ theme }) => theme.palette.background.primaryMobile};
`;
