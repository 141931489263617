import { Button, ButtonProps } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { PlayIcon } from 'assets/icons';
import { StyledButton } from './styled';

interface Props extends ButtonProps {
  hideIconOnMobile?: boolean;
}

export const StartButton: OverridableComponent<{
  props: Props;
  defaultComponent: typeof Button;
}> = ({ children, hideIconOnMobile = false, ...props }: Props) => (
  <StyledButton $hideIconOnMobile={hideIconOnMobile} {...props}>
    <span>
      <PlayIcon />
      {children}
    </span>
  </StyledButton>
);
