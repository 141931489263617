import * as yup from 'yup';
import i18next from 'i18next';
import { messages } from './messages';

export const lowercaseRegex = /(?=.*[a-z])/;
export const uppercaseRegex = /(?=.*[A-Z])/;
export const numberRegex = /(?=.*\d)/;

export const getPasswordValidationSchema = () =>
  yup
    .string()
    .required(i18next.t(messages.emptyPassword()))
    .min(8, i18next.t(messages.charactersConstraint()))
    .matches(lowercaseRegex, i18next.t(messages.lowercaseConstraint()))
    .matches(uppercaseRegex, i18next.t(messages.uppercaseConstraint()))
    .matches(numberRegex, i18next.t(messages.numberConstraint()));
