import { memo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Fade, Stack, Typography } from '@mui/material';
import Session from 'api/storage/session';
import { BrowseRegistriesIllustration } from 'assets/illustrations';
import { SUPPORT_EMAIL } from 'config/constants';

import { AbsoluteOverlayContainer, OverlayContent } from './styled';
import { messages } from '../messages';

export const NotPartOfResearch = memo(() => {
  const { t } = useTranslation();
  const { current: userEmail } = useRef(Session.decodeToken()?.email);

  return (
    <Fade in={true}>
      <AbsoluteOverlayContainer>
        <OverlayContent
          data-cy="not-in-registry-overlay"
          style={{ marginTop: 'auto' }}
        >
          <Stack gap="2.4rem" direction="column" alignItems="center">
            <BrowseRegistriesIllustration
              style={{ maxWidth: '49.6rem', width: '100%' }}
            />
            <Typography variant="h1">
              {t(messages.youAreNotPartOfAnyRegistry())}
            </Typography>
            <Typography variant="body1">
              {t(messages.doYouWantToBecomePartOfCommunity())}
            </Typography>
          </Stack>
          <Stack justifyContent="stretch" width="100%">
            <Button
              component={Link}
              style={{ width: '100%', marginTop: '0.8rem' }}
              to="/registry"
              data-cy="browse-registries-button"
            >
              {t(messages.browseRegistries())}
            </Button>
          </Stack>
        </OverlayContent>
        <Typography variant="h4" marginTop="auto" paddingTop="2.4rem">
          <Trans
            i18nKey={t(messages.needHelp())}
            values={{ email: SUPPORT_EMAIL }}
            components={[
              <Button
                variant="text"
                component="a"
                href={`mailto:${SUPPORT_EMAIL}?subject=Support request for user ${userEmail}&body=Problem description...`}
                style={{ userSelect: 'unset', verticalAlign: 'initial' }}
                data-cy="email-support-button"
              />,
            ]}
          />
        </Typography>
      </AbsoluteOverlayContainer>
    </Fade>
  );
});
