import { maxBy } from 'lodash-es';
import { useEffect, useState } from 'react';

export const useGetCurrentSection = () => {
  const [currentSectionId, setCurrentSectionId] = useState<string | null>(null);

  useEffect(() => {
    const sections = document.querySelectorAll<HTMLElement>('section[id]');

    // eslint-disable-next-line compat/compat
    const observer = new IntersectionObserver(
      () => {
        const c = maxBy(sections, section => {
          const viewportCenter = window.innerHeight / 2 + window.scrollY;
          const elementOffsetTop = section.offsetTop;

          if (elementOffsetTop > viewportCenter) return -1;
          return elementOffsetTop;
        });
        setCurrentSectionId(c?.id ?? null);
      },
      { threshold: [0, 0.25, 0.5, 0.75, 1] },
    );
    sections.forEach(section => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  return currentSectionId;
};
