import { useState } from 'react';
import {
  Typography,
  Stack,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add, Minus } from 'iconsax-react';
import { messages } from '../../messages';
import { PanelContainer, ShowLessMoreButton, Accordion } from './styled';
import { questionsAndAnswers } from './constants';

export const FrequentlyAskedQuestionsSection = () => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<number | null>(0);

  const handleChange =
    (panelIndex: number) =>
    (_e: React.SyntheticEvent, nowExpanded: boolean) => {
      if (nowExpanded) {
        setExpanded(panelIndex);
      } else setExpanded(null);
    };

  if (!questionsAndAnswers?.length) return null;
  return (
    <PanelContainer id="faq">
      <Stack
        width="100%"
        maxWidth="107.6rem"
        alignItems="center"
        gap={{ lg: '7.6rem', xs: '4rem' }}
      >
        <Stack maxWidth="85.6rem" alignItems="center" gap="1.6rem" width="100%">
          <Typography variant="h1" color="text.primary">
            {t(messages.frequentlyAskedQuestions())}
          </Typography>
          {(showAll
            ? questionsAndAnswers
            : questionsAndAnswers.slice(0, 6)
          ).map(({ question, answer }, i) => (
            <Accordion
              key={i}
              expanded={expanded === i}
              onChange={handleChange(i)}
              variant="flat"
            >
              <AccordionSummary
                expandIcon={expanded === i ? <Minus /> : <Add />}
              >
                {question}
              </AccordionSummary>
              <AccordionDetails>{answer}</AccordionDetails>
            </Accordion>
          ))}
          {questionsAndAnswers.length > 8 && (
            <ShowLessMoreButton
              variant="text"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll
                ? t(messages.showLessQuestions())
                : t(messages.showAllQuestions())}
            </ShowLessMoreButton>
          )}
        </Stack>
      </Stack>
    </PanelContainer>
  );
};
