import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Component as ComponentIcon } from 'iconsax-react';
import { Link, generatePath, useLocation } from 'react-router-dom';
import { GetRegistryResponse } from 'api/interfaces/response';
import { RegistryGoal } from 'api/models/Registry/AdditionalParams';
import { RegistryPerson } from 'api/interfaces/response/getRegistryPersonsResponse';
import { messages } from 'pages/Registry/messages';
import { SurveyLocationState } from 'pages/Survey/types';
import { StyledList } from 'pages/Registry/styled';
import { StyledSVG } from 'components/StyledSVG';
import { InterfaceArrowsIcon } from 'assets/icons/index';
import { StyledGoalList, GoalListIcon } from './styled';
import { CaregiverDetailsSection } from './components/CaregiverDetailsSection';

interface Props {
  data: GetRegistryResponse;
  persons?: RegistryPerson[];
}

export const AboutRegistry = ({ data, persons }: Props) => {
  const { t } = useTranslation();
  const { state: locationState } = useLocation();
  const theme = useTheme();

  const registryName = data.name ?? locationState?.name;
  const goals = data.additionalParams?.introPage?.goals ?? [];
  const moreInfoLink = `${window.location.hostname}/${data.code}`;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const caregiverRole = data?.roles.find(role => role.code === 'caregiver');

  const renderAssignedCohortsInfo = () => {
    if (data?.cohorts.length === 0) {
      return (
        <>
          {t(messages.youAreNotAssignedToAnyOfTheAreas(), {
            registry: registryName,
          })}
          {t(messages.weDisplaySurveysAccordingToGroups())}
        </>
      );
    }

    return (
      <Stack gap="1.2rem">
        {t(messages.youAreCurrentlyAssigned(), { registry: registryName })}
        <StyledList data-cy="user-cohorts-list" style={{}}>
          {data?.cohorts.map(({ id: cohortId, name }) => (
            <li key={cohortId}>
              <ComponentIcon variant="Bold" size="0.8rem" />
              {name}
            </li>
          ))}
        </StyledList>
        {t(messages.weDisplaySurveysAccordingToGroups())}
      </Stack>
    );
  };

  const renderGoal = (goal: RegistryGoal | string) => {
    if (typeof goal === 'string') {
      return (
        <Stack direction="row" gap="0.8rem">
          <div>
            <GoalListIcon variant="Bold" />
          </div>
          {goal}
        </Stack>
      );
    }

    return (
      <Stack direction="row" gap="0.8rem">
        <div>
          <GoalListIcon variant="Bold" />
        </div>
        <Stack gap="0.8rem">
          <Typography variant="body1" fontWeight="bold" width="100%">
            {goal.title}
          </Typography>
          <Typography variant="body1" width="100%" marginBottom="1rem">
            {goal.description}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack gap="3.2rem" paddingBottom="2.8rem">
      <div>
        <Stack
          direction="row"
          justifyContent="space-between"
          marginTop="0.8rem"
          alignItems="center"
        >
          <Typography variant="h4">
            {t(messages.registryAssignments())}
          </Typography>
          <Button
            variant="text"
            component={Link}
            to={generatePath(
              '/registries/:registryId/qualification-survey/:surveyId',
              {
                registryId: data.id,
                surveyId: data.qualification.survey.id,
              },
            )}
            state={
              { returnUrl: window.location.pathname } as SurveyLocationState
            }
            data-cy="refill-qualification-survey-button"
            style={{ padding: '1.6rem 0 1.6rem 2.4rem' }}
          >
            {!isMobile ? t(messages.changeAssignment()) : t(messages.change())}
          </Button>
        </Stack>

        <Typography variant="body1" display="block">
          {renderAssignedCohortsInfo()}
        </Typography>
      </div>
      {caregiverRole !== undefined && (
        <CaregiverDetailsSection
          registryId={data.id}
          registryName={data.name}
          persons={persons}
          caregiverRoleId={caregiverRole.id}
        />
      )}
      <div>
        <Typography variant="h4" paddingY="1.6rem">
          {t(messages.description())}
        </Typography>
        <Typography variant="body1" display="block">
          {data.description}
        </Typography>
      </div>
      <div>
        {Boolean(goals.length) && (
          <>
            <Typography variant="h4" paddingY="1.6rem">
              {t(messages.goals())}
            </Typography>
            <StyledGoalList data-cy="registry-goals">
              {goals!.map(goal => (
                <li>{renderGoal(goal)}</li>
              ))}
            </StyledGoalList>
          </>
        )}
      </div>
      <div>
        <Typography variant="h4" paddingY="1.6rem">
          {t(messages.moreInfo())}
        </Typography>
        <Typography variant="h4" color="text.primary">
          <a href={`/registry/${data.code}`} target="_blank" rel="noreferrer">
            {moreInfoLink}
            <StyledSVG
              as={InterfaceArrowsIcon}
              style={{ marginLeft: '0.8rem' }}
            />
          </a>
        </Typography>
      </div>
    </Stack>
  );
};
