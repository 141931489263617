import styled, { css } from 'styled-components';
import { Button } from '@mui/material';
import { useTypography } from 'helpers/mixins';

export const Wrapper = styled.div`
  max-width: 100%;
  position: relative;

  // styles transformed from tiny-slider scss
  .tns-outer {
    padding: 0 !important; // remove padding: clientWidth = width + padding (0) = width
    [hidden] {
      display: none !important;
    }
    [aria-controls],
    [data-action] {
      cursor: pointer;
    }
  }
  .tns-slider {
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    transition: all 0s;
    > .tns-item {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  .tns-horizontal {
    &.tns-subpixel {
      white-space: nowrap;
      > .tns-item {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
      }
    }
    &.tns-no-subpixel {
      &:after {
        content: '';
        display: table;
        clear: both;
      }
      > .tns-item {
        float: left;
      }
    }
    &.tns-carousel {
      &.tns-no-subpixel {
        > .tns-item {
          margin-right: -100%;
        }
      }
    }
  }
  .tns-no-calc {
    position: relative;
    left: 0;
  }
  .tns-gallery {
    position: relative;
    left: 0;
    min-height: 1px; // make sure slider container is visible
    // overflow: hidden;
    > .tns-item {
      position: absolute;
      left: -100%;
      -webkit-transition: transform 0s, opacity 0s;
      -moz-transition: transform 0s, opacity 0s;
      transition: transform 0s, opacity 0s;
    }
    > .tns-slide-active {
      position: relative;
      left: auto !important;
    }
    > .tns-moving {
      -webkit-transition: all 0.25s;
      -moz-transition: all 0.25s;
      transition: all 0.25s;
    }
  }
  .tns-autowidth {
    display: inline-block;
  }
  .tns-lazy-img {
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    transition: opacity 0.6s;
    opacity: 0.6;
    &.tns-complete {
      opacity: 1;
    }
  }
  .tns-ah {
    -webkit-transition: height 0s;
    -moz-transition: height 0s;
    transition: height 0s;
  }
  .tns-ovh {
    overflow: hidden;
    overflow: visible; // changed
  }
  .tns-visually-hidden {
    position: absolute;
    left: -10000em;
  }
  .tns-transparent {
    opacity: 0;
    visibility: hidden;
  }

  .tns-fadeIn {
    opacity: 1;
    filter: alpha(opacity=100);
    z-index: 0;
  }
  .tns-normal,
  .tns-fadeOut {
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: -1;
  }

  // *** Fix a viewport issue in initialization
  .tns-vpfix {
    white-space: nowrap;
    > div,
    > li {
      display: inline-block;
    }
  }

  // *** Detecting browser capability ***
  .tns-t {
    &-subp2 {
      margin: 0 auto;
      width: 310px;
      position: relative;
      height: 10px;
      overflow: hidden;
    }
    &-ct {
      width: -webkit-calc(100% * 70 / 3);
      width: -moz-calc(100% * 70 / 3);
      width: calc(100% * 70 / 3);
      position: absolute;
      right: 0;
      &:after {
        content: '';
        display: table;
        clear: both;
      }
      > div {
        width: -webkit-calc(100% / #70);
        width: -moz-calc(100% / #70);
        width: calc(100% / #70);
        height: 10pxt;
        float: left;
      }
    }
  }
`;

export const CardsWrapper = styled.ul`
  list-style: none;
  max-width: 100%;
  display: flex !important;
`;

export const CardWrapper = styled.li`
  width: 100%;
  align-items: stretch;
  display: inline-flex !important;
`;

export const CardContainer = styled.div`
  ${({ theme }) => useTypography(theme.typography.body1)}

  padding: 3.2rem;
  gap: 2.4rem;
  width: 100%;

  width: 33.7rem;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 32.4rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 28rem;
  }

  background: ${({ theme }) =>
    theme.palette.additionalColors.selectedBackground};

  color: ${({ theme }) => theme.palette.additionalColors.primaryText};

  box-shadow: ${({ theme }) => theme.palette.additionalColors.sideMenuShadow};

  border-radius: 2.4rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CardNumber = styled.div`
  ${({ theme }) => useTypography(theme.typography.body3)}

  display: flex;
  justify-content: center;
  align-items: center;

  width: 4rem;
  height: 4rem;
  padding: 0;

  border-radius: 1.6rem;

  background: ${({ theme }) => theme.palette.button.primary};
  color: ${({ theme }) => theme.palette.button.text};
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  left: calc((100% - 100vw) / 2);
  bottom: 0;
  left: calc((100% - 100vw) / 2);
  width: 100vw;
`;

export const StyledButton = styled(Button)<{ $arrowMirrored?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 4.8rem;
  max-width: 4.8rem;
  height: 4.8rem;
  padding: 0;

  background: ${({ theme }) => theme.palette.additionalColors.secondaryText}66;
  box-shadow: none;
  border-radius: 1.6rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }

  :disabled {
    display: none;
  }

  :hover {
    box-shadow: none;
    background: ${({ theme }) =>
      theme.palette.additionalColors.secondaryText}a6;
  }

  position: absolute;
  margin: auto;
  z-index: 2;
  top: 0;
  bottom: 0;
  ${({ $arrowMirrored }) =>
    $arrowMirrored
      ? css`
          left: 2rem;
        `
      : css`
          right: 2rem;
        `}

  > svg {
    width: 2.4rem;
    height: 2.4rem;
    margin: unset;

    ${({ $arrowMirrored }) =>
      $arrowMirrored &&
      css`
        transform: scale(-1, 1);
      `}

    * {
      fill: none;
      stroke: ${({ theme }) => theme.palette.additionalColors.primaryText};
    }
  }
`;
