import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Stack, StackProps, Typography } from '@mui/material';
import { NotFoundIllustration } from 'assets/illustrations/index';
import { useProperlyThemedComponent } from 'hooks/useProperlyThemedComponent';
import { WVULogo, WVULogoDark } from 'assets/logo';
import { messages } from './messages';
import { LogoContainer } from './styled';

export const NotFoundPage = memo((props: StackProps) => {
  const { t } = useTranslation();

  const logo = useProperlyThemedComponent({
    light: <WVULogo />,
    dark: <WVULogoDark />,
  });

  return (
    <Stack
      justifyContent={{
        xs: 'space-between',
        lg: 'start',
      }}
      padding={{
        xs: '3.2rem 2.4rem 2.4rem',
        sm: '4rem 3.2rem 3.2rem 3.2rem',
      }}
      alignItems="center"
      gap="3.2rem"
      sx={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        backgroundColor: 'background.default',
        color: 'button.primary',
      }}
      {...props}
    >
      <Helmet>
        <title>{t(messages.pageNotFound())}</title>
      </Helmet>
      <LogoContainer>{logo}</LogoContainer>
      <Stack
        gap={{ xs: '1.6rem', lg: '2.4rem' }}
        alignItems="center"
        marginTop={{ lg: '8.8rem' }}
      >
        <NotFoundIllustration
          style={{ maxWidth: '100%', width: '64.6rem', height: 'auto' }}
        />
        <Typography
          variant="h1"
          marginTop={{ xs: '1.6rem', md: '3.2rem', lg: '4.8rem' }}
        >
          {t(messages.areYouLost())}
        </Typography>
        <Typography variant="body1" textAlign="center">
          {t(messages.pageDoesntExist())}
        </Typography>
      </Stack>
      <Stack
        direction={{
          md: 'row',
          xs: 'column-reverse',
        }}
        gap="2.4rem"
        width="100%"
        maxWidth="64.8rem"
      >
        <Button
          variant="alternative"
          style={{ width: '100%' }}
          component={Link}
          to="/registry"
          data-cy="registry-link-button"
        >
          {t(messages.goToRegistryList())}
        </Button>
        <Button
          style={{ width: '100%' }}
          component={Link}
          to={`/login`}
          data-cy="login-button"
        >
          {t(messages.goToLoginPage())}
        </Button>
      </Stack>
    </Stack>
  );
});
