import { memo } from 'react';
import Session from 'api/storage/session';
import { AppLogout } from 'components/AppLogout';
import { useAutoRefreshSession } from 'hooks/useAutoRefreshSession';
import { LogoutTimerTypes } from 'hooks/useLogoutTimer';
import { logOut } from 'helpers/logOut';
import { LoadingPage } from 'pages/Loading';

interface Props {
  children: React.ReactNode;
}

export const ProtectedRoute = memo(({ children }: Props) => {
  useAutoRefreshSession();

  if (!Session.getSessionToken()) {
    logOut();
    return <LoadingPage />;
  }

  return (
    <>
      <AppLogout onLogout={logOut} type={LogoutTimerTypes.AUTHENTICATED_USER} />
      {children}
    </>
  );
});
