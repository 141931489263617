import { memo, useEffect, useId } from 'react';
import { Modal } from 'components/Modal';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { PasswordInput } from 'components/PasswordInput';
import { PasswordValidator } from 'components/PasswordValidator';
import { ErrorMessage } from 'components/ErrorMessage';
import { useValidationSchema } from 'hooks/useValidationSchema';
import { successToast } from 'helpers/toast';
import { AmplifyError } from 'types/amplify';
import { getChangePasswordValidationSchema } from '../validators';
import { messages } from '../messages';
import { UpdatePassword } from '../requests';

interface Props {
  open: boolean;
  onClose(): void;
}

interface FormTypes {
  newPassword: string;
  currentPassword: string;
}

export const ChangePasswordModal = memo(({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const validationSchema = useValidationSchema(
    getChangePasswordValidationSchema,
  );
  const passwordValidatorId = useId();

  const { mutate, error, isLoading, reset } = useMutation<
    'SUCCESS' | undefined,
    AmplifyError,
    FormTypes
  >({
    mutationFn: UpdatePassword,
    onSuccess: () => {
      successToast(t(messages.passwordSaved()));
      onClose();
    },
  });

  useEffect(reset, [open]);

  const mapError = () => {
    switch (error?.code) {
      case 'NotAuthorizedException':
        return t(messages.incorrectCurrentPassword());
      case 'LimitExceededException':
        return t(messages.limitExceeded());
      default:
    }
  };

  return (
    <Modal open={open} onClose={onClose} title={t(messages.changePassword())}>
      <Formik
        initialValues={{ newPassword: '', currentPassword: '' } as FormTypes}
        onSubmit={form => mutate(form)}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ values }) => (
          <Form data-cy="set-up-password-step" style={{ height: '100%' }}>
            <Stack
              spacing="2.4rem"
              direction="column"
              justifyContent="center"
              marginTop="2rem"
              height="100%"
              padding={{
                xs: '0 2.4rem',
                sm: '0 3.2rem',
                lg: '0 4.8rem',
              }}
            >
              <Typography variant="body1" marginBottom="0.8rem">
                {t(messages.setStrongPassword())}
              </Typography>
              <PasswordInput
                name="currentPassword"
                validationSchema={validationSchema}
                oneWayValidationOnChange={true}
                data-cy="current-password-input"
                label={t(messages.currentPassword())}
                placeholder={t(messages.currentPassword())}
              />
              <PasswordInput
                name="newPassword"
                validationSchema={validationSchema}
                oneWayValidationOnChange={true}
                disableError
                data-cy="new-password-input"
                label={t(messages.newPassword())}
                placeholder={t(messages.newPassword())}
                inputProps={{
                  'aria-describedby': passwordValidatorId,
                }}
              />
              <PasswordValidator
                password={values.newPassword}
                id={passwordValidatorId}
              />
              <ErrorMessage
                message={mapError()}
                visible={!!error}
                style={{ marginTop: '0.8rem' }}
              />
              <Stack
                gap="2.4rem"
                paddingTop="3.8rem"
                style={{
                  marginTop: 'auto',
                }}
                direction={{
                  md: 'row-reverse',
                  xs: 'column',
                }}
              >
                <LoadingButton
                  type="submit"
                  loading={isLoading}
                  color="primary"
                  variant="contained"
                  data-cy="save-changes-button"
                  style={{ width: '100%' }}
                >
                  {t(messages.saveChanges())}
                </LoadingButton>
                <Button
                  onClick={onClose}
                  variant="alternative"
                  data-cy="cancel-button"
                  style={{ width: '100%' }}
                >
                  {t(messages.cancel())}
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Modal>
  );
});
