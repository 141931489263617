import { SHA224 } from 'crypto-es/lib/sha224';
import { STORAGE_LAST_URL_KEY } from 'config/constants';
import Session from './session';

export default class LastUrlStorage {
  public static clear() {
    sessionStorage.removeItem(STORAGE_LAST_URL_KEY);
  }

  public static save() {
    const userId = Session.decodeToken()?.['custom:userId'];
    const userHash = userId ? SHA224(userId).toString() : null;

    const storageItem = JSON.stringify({
      url:
        window.location.pathname +
        window.location.search +
        window.location.hash,
      userHash,
    });

    sessionStorage.setItem(STORAGE_LAST_URL_KEY, storageItem);
  }

  public static load(userId?: string): string | null {
    const storageItem = sessionStorage.getItem(STORAGE_LAST_URL_KEY);
    if (!storageItem) return null;

    try {
      const parsedItem = JSON.parse(storageItem);
      if (!parsedItem.userHash) return parsedItem.url ?? null;

      const userIdStorage = userId ?? Session.decodeToken()?.['custom:userId'];
      if (!userIdStorage) return null;

      const userHash = SHA224(userIdStorage).toString();

      if (parsedItem.userHash !== userHash) return null;

      return parsedItem.url;
    } catch (e) {
      return null;
    }
  }
}
