import { Radio, Stack, Typography } from '@mui/material';
import { ProviderRadioIcon, ProviderRadioWrapper } from './styled';

interface Props {
  name: string;
  iconPath: string;
  onClick(): void;
  checked: boolean;
}

export const ProviderRadio = ({ name, iconPath, onClick, checked }: Props) => {
  return (
    <ProviderRadioWrapper onClick={onClick}>
      <Stack direction="row" alignItems="center">
        <Radio checked={checked} value={name} />
        <Typography variant="body1" component="span">
          {name}
        </Typography>
      </Stack>
      <ProviderRadioIcon src={iconPath} alt={name} />
    </ProviderRadioWrapper>
  );
};
