import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  unknownError: () =>
    _t(
      translations.pages.settings.healthcareProviders.unknownError,
      'Unknown error, try again later',
    ),
};
