/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback } from 'react';
import {
  QueryFunction,
  FetchQueryOptions,
} from '@tanstack/query-core/src/types';
import {
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query/src/types';
import {
  useQueryClient,
  useQuery as useTanstackQuery,
} from '@tanstack/react-query';
import Queries from '../api/queries';

type QueriesType = typeof Queries;
type OneOfQueriesType = keyof typeof Queries;
type QueryKeyOf<T extends OneOfQueriesType = OneOfQueriesType> = ReturnType<
  QueriesType[T]['queryKey']
>;
type QueryFnReturnedValues = {
  [K in OneOfQueriesType]: ReturnType<typeof Queries[K]['queryFn']>;
};
type QueryFnOf<T extends OneOfQueriesType = OneOfQueriesType> =
  QueryFnReturnedValues[T];
type AwaitedQueryFnOf<T extends OneOfQueriesType = OneOfQueriesType> = Awaited<
  QueryFnOf<T>
>;
type KeyArgs = {
  [K in OneOfQueriesType]: Parameters<typeof Queries[K]['queryKey']>[0];
};
type QueryKeyInterface<T extends OneOfQueriesType> =
  KeyArgs[T] extends undefined ? [key: T] : [key: T, dependencies: KeyArgs[T]];

export const usePrefetchQuery = () => {
  const queryClient = useQueryClient();

  const prefetch = useCallback(
    <T extends OneOfQueriesType, TError = unknown>(
      [key, dependencies]: QueryKeyInterface<T>,
      queryFn: () => QueryFnOf<T>,
      options?: FetchQueryOptions<
        QueryFnOf<T>,
        TError,
        Awaited<QueryFnOf<T>>,
        QueryKeyOf<T>
      >,
    ) => {
      const queryKey = Queries[key].queryKey(dependencies as any);
      return queryClient.prefetchQuery(
        queryKey as QueryKeyOf<T>,
        queryFn as QueryFunction<QueryFnOf<T>, QueryKeyOf<T>>,
        options as FetchQueryOptions<
          QueryFnOf<T>,
          TError,
          QueryFnOf<T>,
          QueryKeyOf<T>
        >,
      );
    },
    [queryClient],
  );

  return prefetch;
};

export const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  const invalidate = useCallback(
    <T extends OneOfQueriesType>([key, dependencies]: QueryKeyInterface<T>) => {
      const queryKey = Queries[key].queryKey(dependencies as any);
      queryClient.invalidateQueries(queryKey);
    },
    [queryClient],
  );

  return invalidate;
};

export const useSetQueryData = () => {
  const queryClient = useQueryClient();

  const setQueryData = useCallback(
    <T extends OneOfQueriesType>(
      [key, dependencies]: QueryKeyInterface<T>,
      updateFn:
        | ((data?: AwaitedQueryFnOf<T>) => AwaitedQueryFnOf<T> | undefined)
        | AwaitedQueryFnOf<T>
        | undefined,
    ) => {
      const queryKey = Queries[key].queryKey(dependencies as any);
      queryClient.setQueryData(queryKey, updateFn);
    },
    [queryClient],
  );

  return setQueryData;
};

export const useQuery = <T extends OneOfQueriesType, TError = unknown>({
  queryKey,
  ...otherOptions
}: Omit<
  UseQueryOptions<
    QueryFnOf<T>,
    TError,
    Awaited<QueryFnOf<T>>,
    QueryKeyInterface<T>
  >,
  'initialData'
> & { initialData?: () => undefined }): UseQueryResult<
  Awaited<QueryFnOf<T>>,
  TError
> => {
  const [key, dependencies] = queryKey as QueryKeyInterface<T>;
  const parsedQueryKey = Queries[key].queryKey(
    dependencies as any,
  ) as QueryKeyInterface<T>;
  const options = {
    ...otherOptions,
    queryKey: parsedQueryKey,
  };

  return useTanstackQuery(options);
};

export const useGetQueryData = () => {
  const queryClient = useQueryClient();

  const getQueryData = useCallback(
    <T extends OneOfQueriesType>([key, dependencies]: QueryKeyInterface<T>):
      | AwaitedQueryFnOf<T>
      | undefined => {
      const queryKey = Queries[key].queryKey(dependencies as any);
      return queryClient.getQueryData(queryKey);
    },
    [queryClient],
  );

  return getQueryData;
};
