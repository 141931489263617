import * as React from 'react';
import * as Sentry from '@sentry/react';
import Config from 'config';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const { apiUrl, sentryUrl } = Config.get();

export const lazyLoadReplay = async () => {
  const { Replay } = await import('@sentry/react');
  Sentry.addIntegration(new Replay());
};

export const initSentry = () => {
  Sentry.init({
    dsn: sentryUrl,
    environment: Config.getEnvKey(),
    enabled: import.meta.env.DEV !== true,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', apiUrl],
      }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  lazyLoadReplay();
};
