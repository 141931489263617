import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  provideCaregiverDetails: () =>
    _t(
      translations.pages.registry.caregiverDetailsModal.provideCaregiverDetails,
      'Provide Caregiver details',
    ),
  provideBasicPersonalInformation: () =>
    _t(
      translations.pages.registry.caregiverDetailsModal
        .provideBasicPersonalInformation,
      'Provide your basic personal information and the relationship between you and the member.',
    ),
  relationshipToTheMember: () =>
    _t(
      translations.pages.registry.caregiverDetailsModal.relationshipToTheMember,
      'Relationship to the member',
    ),
  select: () =>
    _t(translations.pages.registry.caregiverDetailsModal.select, 'Select'),
  firstName: () =>
    _t(
      translations.pages.registry.caregiverDetailsModal.firstName,
      'First name',
    ),
  lastName: () =>
    _t(translations.pages.registry.caregiverDetailsModal.lastName, 'Last name'),
  email: () =>
    _t(translations.pages.registry.caregiverDetailsModal.email, 'Email'),
  phoneNumber: () =>
    _t(
      translations.pages.registry.caregiverDetailsModal.phoneNumber,
      'Phone number (optional)',
    ),
  cancel: () =>
    _t(translations.pages.registry.caregiverDetailsModal.cancel, 'Cancel'),
  continue: () =>
    _t(translations.pages.registry.caregiverDetailsModal.continue, 'Continue'),
  confirmAndSubmit: () =>
    _t(
      translations.pages.registry.caregiverDetailsModal.confirmAndSubmit,
      'Confirm and submit',
    ),
  unknownErrorOccurred: () =>
    _t(
      translations.pages.registry.caregiverDetailsModal.unknownErrorOccurred,
      'Unknown error occurred, try again later.',
    ),
  doYouWantToAddCaregiverRole: () =>
    _t(
      translations.pages.registry.caregiverDetailsModal
        .doYouWantToAddCaregiverRole,
      'Do you want to add Caregiver role?',
    ),
  currentlyTheParticipantIsParticipatingInTheStudyIndependentlyDoYouWantToAddANewCaregiverRole:
    () =>
      _t(
        translations.pages.registry.caregiverDetailsModal
          .currentlyTheParticipantIsParticipatingInTheStudyIndependentlyDoYouWantToAddANewCaregiverRole,
        'Currently, the Participant is participating in the study independently. Do you want to add a new Caregiver role?',
      ),
  thisFieldIsRequired: () =>
    _t(
      translations.pages.registry.caregiverDetailsModal.thisFieldIsRequired,
      'This field is required.',
    ),
  invalidEmail: () =>
    _t(
      translations.pages.registry.caregiverDetailsModal.invalidEmail,
      'Invalid email address.',
    ),
};
