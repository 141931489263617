import styled from 'styled-components';

export const NotificationRowContainer = styled.div<{ size: 'big' | 'medium' }>`
  gap: 1.6rem;
  margin-left: ${({ size }) => (size === 'big' ? '3.2rem' : '5.6rem')};
  display: flex;
  flex-direction: column;

  :not(:last-of-type) {
    padding-bottom: 3.2rem;
    margin-bottom: 3.2rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }
`;

export const H4NoStyles = styled.h4`
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
`;
