import { Stack } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'hooks/useScrollTop';
import { Navbar } from './components/Navbar';
import { RegistriesSection } from './components/RegistriesSection';
import { FrequentlyAskedQuestionsSection } from './components/FrequentlyAskedQuestionsSection';
import { ContactSection } from './components/ContactSection';
import { Footer } from './components/Footer';
import { messages } from './messages';

export const RegistriesList = () => {
  const { t } = useTranslation();
  useScrollTop();

  return (
    <Stack>
      <Helmet>
        <title>{t(messages.registriesList())}</title>
      </Helmet>
      <Navbar />
      <RegistriesSection />
      <FrequentlyAskedQuestionsSection />
      <ContactSection />
      <Footer />
    </Stack>
  );
};
