import { GetRegistrySurveyResponse } from 'api/interfaces/response';
import { SurveyForFlutterModule } from 'components/SurveyModule/types';

export const prepareSurveyForFlutterModule = (
  survey: GetRegistrySurveyResponse,
): SurveyForFlutterModule => ({
  name: survey.name,
  description: survey.description,
  studyId: '', // not needed in registry
  frequency: 'once',
  backendIdentifiers: {
    id: survey.id,
    assignmentId: '', // not needed in registry
  },
  canCopySurveyValues: false, // not needed in registry
  questions: survey.questions,
  period: null,
});
