import { useEffect, useState } from 'react';

export const usePageTitle = () => {
  const [pageTitle, setPageTitle] = useState(document.title);

  useEffect(() => {
    const target = document.querySelector('title');
    if (!target) return;

    const observer = new MutationObserver(mutations => {
      const title = mutations[0].target.textContent;
      setPageTitle(title || '');
    });

    const config = { subtree: true, characterData: true, childList: true };
    observer.observe(target, config);

    return () => {
      observer.disconnect();
    };
  }, []);

  return pageTitle;
};
