import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as QualificationBaselineValidationIllustrationLight } from './qualificationBaselineValidation.svg';
import { ReactComponent as QualificationBaselineValidationIllustrationDark } from './qualificationBaselineValidation_dark.svg';

export const QualificationBaselineValidationIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return (
        <QualificationBaselineValidationIllustrationDark
          aria-hidden="true"
          {...props}
        />
      );
    return (
      <QualificationBaselineValidationIllustrationLight
        aria-hidden="true"
        {...props}
      />
    );
  },
);
