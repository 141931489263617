import { memo, useEffect } from 'react';
import { useLocation } from 'react-router';
import mixpanel from 'mixpanel-browser';
import { useDebouncedCallback } from 'use-debounce';
import { usePageTitle } from 'helpers/usePageTitle';

export const LocationTracker = memo(() => {
  const location = useLocation();
  const pageTitle = usePageTitle();
  const debouncedCallback = useDebouncedCallback(
    () => mixpanel.track_pageview(),
    100,
  );

  useEffect(() => {
    debouncedCallback();
  }, [location.pathname, location.search, pageTitle]);

  return null;
});
