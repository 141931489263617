import { Components } from '@mui/material/styles/components';
import { Theme } from 'styles/theme/themes';
import { Loader } from 'components/Loader';

export const MuiLoadingButton: Components<Theme>['MuiLoadingButton'] = {
  defaultProps: {
    loadingIndicator: <Loader />,
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ ownerState, theme }) =>
      ownerState.loading === true
        ? {
            '&&': {
              ...(ownerState.variant === 'contained' && {
                background: theme.palette.text.primary,
                '[role="progressbar"] span': {
                  background: theme.palette.button.text,
                },
              }),

              ...(ownerState.variant === 'outlined' && {
                background: 'transparent',
                '[role="progressbar"] span': {
                  background: theme.palette.text.primary,
                },
              }),

              ...(ownerState.variant === 'alternative' && {
                background: 'transparent',
                '[role="progressbar"] span': {
                  background: theme.palette.text.primary,
                },
              }),
            },
          }
        : {},
  },
};
