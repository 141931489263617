import { memo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import UserRoles from 'api/storage/userRoles';
import { useUserRole } from 'api/storage/userRoles/hook';
import { RegistryPerson } from 'api/interfaces/response/getRegistryPersonsResponse';
import { GetRegistryResponse } from 'api/interfaces/response/getRegistryResponse';
import { Modal } from 'components/Modal';
import { messages } from '../../messages';
import { RoleTile } from './RoleTile';
import { RolesContainer } from './styled';
import { CaregiverDetailsModal } from '../CaregiverDetailsModal';

interface Props {
  registryName: string;
  registryId: string;
  registryRoles: GetRegistryResponse['roles'];
  registryPersons: RegistryPerson[];
}

export const RoleSelectionModal = memo(
  ({ registryName, registryId, registryRoles, registryPersons }: Props) => {
    const { setRole, roleId } = useUserRole(registryId);
    const { t } = useTranslation();
    const [selectedRole, setSelectedRole] = useState<string | null>(null);
    const [showCaregiverDetailsModal, setShowCaregiverDetailsModal] =
      useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const hasRole =
      UserRoles.getRoleForRegistry(registryId) !== undefined ||
      registryRoles?.length < 2;

    if (registryRoles?.length === 1 && !roleId) {
      setRole(registryRoles[0].id);
    }

    const isSelectedRoleCaregiver = (rId: string) => {
      return registryRoles?.find(role => role.id === rId)?.code === 'caregiver';
    };

    const isCaregiverAlreadyAdded = () => {
      return (
        registryPersons?.find(person => person.role === 'caregiver') !==
        undefined
      );
    };

    const handleSubmit = (role = selectedRole) => {
      if (!role) return;

      if (isSelectedRoleCaregiver(role) && !isCaregiverAlreadyAdded()) {
        setShowCaregiverDetailsModal(true);
        return;
      }

      setRole(role);
    };

    const handleSelect = (id: string) => {
      setSelectedRole(id);
      if (!isMobile) {
        handleSubmit(id);
      }
    };

    const onAddCaregiver = () => {
      setShowCaregiverDetailsModal(false);

      if (selectedRole) setRole(selectedRole);
    };

    return (
      <>
        <Modal
          open={!hasRole || showCaregiverDetailsModal}
          disableMobileFullScreen
        >
          <Stack paddingX={{ sm: '4rem', xs: '2.4rem' }} gap="1.2rem">
            <Typography variant="h1" textAlign="center">
              {t(messages.selectYourRole())}
            </Typography>
            <Typography
              variant="body1"
              textAlign="center"
              whiteSpace={{
                sm: 'pre-wrap',
              }}
            >
              <Trans
                i18nKey={messages.selectYourRoleDescription()}
                values={{ registryName }}
              />
            </Typography>
            <RolesContainer>
              {registryRoles?.map(role => (
                <RoleTile
                  {...role}
                  key={role.id}
                  selected={role.id === selectedRole}
                  onClick={handleSelect}
                />
              ))}
            </RolesContainer>
            {isMobile && (
              <Button
                onClick={() => handleSubmit()}
                sx={{
                  marginTop: {
                    xs: '2.4rem',
                    sm: '3.2rem',
                  },
                }}
              >
                {t(messages.continue())}
              </Button>
            )}
          </Stack>
        </Modal>
        {selectedRole && showCaregiverDetailsModal && (
          <CaregiverDetailsModal
            open={showCaregiverDetailsModal}
            registryId={registryId}
            roleId={selectedRole}
            onSuccess={onAddCaregiver}
            onCancel={() => setShowCaregiverDetailsModal(false)}
            requireConfirmation={true}
          />
        )}
      </>
    );
  },
);
