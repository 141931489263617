import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as BrainLeftIllustrationLight } from './brainLeft.svg';
import { ReactComponent as BrainLeftIllustrationDark } from './brainLeft_dark.svg';

export const BrainLeftIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return <BrainLeftIllustrationDark aria-hidden="true" {...props} />;
    return <BrainLeftIllustrationLight aria-hidden="true" {...props} />;
  },
);
