import { useState, Fragment, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Stack, AccordionDetails } from '@mui/material';
import { GetAgreementsResponse } from 'api/interfaces/response';
import {
  AgreementDocument,
  AgreementSelectionType,
} from 'api/models/agreement';
import { RegistryConsent } from 'pages/Qualification/components/AgreementsStep/RegistryConsent';
import {
  CustomAccordionSummary,
  ScrollableAccordion,
  SettingsDivider,
} from 'pages/Settings/styled';
import { messages } from '../../messages';
import { ConsentsWrapper, ConsentsDividerWrapper } from '../../styled';

interface MappedAgreement {
  registryId: string;
  agreements: {
    id: string;
    agreed: boolean;
    agreementDocumentId: string | undefined;
    snapshot: {
      agreement: {
        title: string;
        description: null;
      };
    };
  }[];
}

interface Props {
  data: GetAgreementsResponse;
  setSelectedDocument: React.Dispatch<
    React.SetStateAction<AgreementDocument | null>
  >;
}

export const RegistryConsentsList = ({ data, setSelectedDocument }: Props) => {
  const { t } = useTranslation();
  const {
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    submitForm,
    touched,
    errors,
  } = useFormikContext<MappedAgreement[]>();
  const [expanded, setExpanded] = useState<string | false>(false);

  const debouncedSubmitForm = useDebouncedCallback(submitForm, 1000);

  useEffect(
    () => () => {
      debouncedSubmitForm.flush();
    },
    [debouncedSubmitForm],
  );

  const handleChange =
    (panel: string) => (_e: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const getDisabledMessage = (
    selectionType: AgreementSelectionType,
    agreed: boolean | null,
  ) => {
    if (
      agreed &&
      selectionType !== AgreementSelectionType.OPTIONAL_CHANGE_ALLOWED
    ) {
      return t(messages.youCannotChange());
    }
    return false;
  };

  return (
    <ConsentsWrapper>
      <Stack
        gap="0.6rem"
        overflow="auto"
        display={{
          xs: 'unset',
          sm: 'flex',
        }}
      >
        {values.map(({ registryId, agreements }, r_i) => {
          const { registryName, agreements: baseAgreements } =
            data.registryAgreements.find(
              ({ registryId: id }) => id === registryId,
            )!;

          if (agreements.length === 0) return null;
          return (
            <ScrollableAccordion
              key={registryId}
              expanded={expanded === registryName}
              onChange={handleChange(registryName)}
            >
              {values.length > 1 && (
                <CustomAccordionSummary>{registryName}</CustomAccordionSummary>
              )}
              <AccordionDetails style={{ padding: 0, overflow: 'auto' }}>
                <Stack paddingTop="2rem" paddingBottom="2rem">
                  {agreements.map((agreement, a_i) => {
                    const baseAgreement = baseAgreements.find(
                      ({ id }) => agreement.id === id,
                    );
                    if (!baseAgreement) return;

                    const disabledMessage = getDisabledMessage(
                      baseAgreement.selectionType,
                      agreement.agreed,
                    );

                    return (
                      <Fragment key={agreement.id}>
                        <RegistryConsent
                          agreement={baseAgreement}
                          onOpenDocument={setSelectedDocument}
                          checked={agreement.agreed}
                          disabled={disabledMessage}
                          onCheck={(_e, checked) => {
                            setFieldValue(
                              `${r_i}.agreements.${a_i}.agreed`,
                              checked,
                            );
                            setFieldTouched(
                              `${r_i}.agreements.${a_i}.agreed`,
                              true,
                              false,
                            );
                            if (checked) {
                              setFieldError(
                                `${registryId}.${agreement.id}`,
                                undefined,
                              );
                            }
                            debouncedSubmitForm();
                          }}
                          error={
                            touched[r_i]?.agreements?.[a_i]?.agreed &&
                            (
                              errors as unknown as Record<
                                string,
                                Record<string, string>
                              >
                            )[registryId]?.[agreement.id]
                          }
                        />
                        {a_i < agreements.length - 1 && (
                          <ConsentsDividerWrapper>
                            <SettingsDivider />
                          </ConsentsDividerWrapper>
                        )}
                      </Fragment>
                    );
                  })}
                </Stack>
              </AccordionDetails>
            </ScrollableAccordion>
          );
        })}
      </Stack>
    </ConsentsWrapper>
  );
};
