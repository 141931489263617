import { useCallback, useEffect, useState } from 'react';
import {
  STORAGE_TOP_SURVEYS_PREFIX,
  INITIAL_TOP_SURVEYS_LENGTH,
} from 'config/constants';
import NumberOfTopPrioritySurveys from '.';

export const useNumberOfTopSurveys = (
  registryId: string,
  numberOfSurveys: number,
) => {
  const [submittedTopSurveysLength, setSubmittedTopSurveysLength] = useState(
    () => NumberOfTopPrioritySurveys.getNumberForRegistry(registryId),
  );

  useEffect(() => {
    const onStorageUpdate = () => {
      setSubmittedTopSurveysLength(
        NumberOfTopPrioritySurveys.getNumberForRegistry(registryId),
      );
    };
    onStorageUpdate();
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, [registryId]);

  useEffect(() => {
    NumberOfTopPrioritySurveys.initNumberForRegistry(
      registryId,
      numberOfSurveys,
    );
  }, [registryId, numberOfSurveys]);

  const sendEvent = useCallback(() => {
    const e = new StorageEvent('storage', {
      storageArea: window.localStorage,
      key: STORAGE_TOP_SURVEYS_PREFIX + registryId,
      oldValue: `${submittedTopSurveysLength}`,
      url: window.location.href,
    });
    window.dispatchEvent(e);
  }, [registryId]);

  const incrementTopSurveys = useCallback(() => {
    NumberOfTopPrioritySurveys.incrementNumberForRegistry(registryId);
    sendEvent();
  }, [registryId]);

  const resetTopSurveys = useCallback(() => {
    if (numberOfSurveys === undefined) return;
    NumberOfTopPrioritySurveys.resetNumberForRegistry(
      registryId,
      numberOfSurveys,
    );
    sendEvent();
  }, [registryId, numberOfSurveys]);

  const getRemainingTopSurveys = () => {
    if (numberOfSurveys <= INITIAL_TOP_SURVEYS_LENGTH) {
      const remainingSurveys = numberOfSurveys - submittedTopSurveysLength;
      return remainingSurveys < 0 ? 0 : remainingSurveys;
    }
    const remainingSurveys =
      INITIAL_TOP_SURVEYS_LENGTH - submittedTopSurveysLength;
    return remainingSurveys < 0 ? 0 : remainingSurveys;
  };

  return {
    resetTopSurveys,
    incrementTopSurveys,
    submittedTopSurveysLength,
    remainingTopSurveys: getRemainingTopSurveys(),
  };
};
