import { memo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { LoadingPage } from 'pages/Loading';
import Api from 'api/api';
import { queryKeys } from 'api/queryKeys';
import Session from 'api/storage/session';
import { ParticipationConfirmation } from './overlays/ParticipationConfirmation';
import { FillOutQualificationSurvey } from './overlays/FillOutQualificationSurvey';
import { CongratulationsModal } from './overlays/CongratulationsModal';
import { RoleSelectionModal } from './components/RoleSelectionModal/index';
import { RegistryTabs } from './tabs';
import { RegistryParams } from './types';
import { RegistryHeader } from './components/RegistryHeader';

export const RegistryPage = memo(() => {
  const navigate = useNavigate();
  const { id } = useParams<RegistryParams>();
  const { current: userEmail } = useRef(Session.decodeToken()?.email);

  const { data } = useQuery({
    queryKey: queryKeys.registry({
      registryId: id!,
      userEmail: userEmail!,
    }),
    queryFn: () => Api.getRegistry(id!),
    staleTime: 60 * 60 * 1000,
    enabled: id !== undefined,
    onError: e => {
      if (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e as any)?.response?.data?.message ===
        'Registry does not exist or user has no access to it'
      )
        navigate('/registries');
    },
  });

  const registryPersonsQuery = useQuery({
    queryKey: queryKeys.registryPersons({
      registryId: id!,
      roles: ['caregiver'],
    }),
    queryFn: () =>
      Api.getRegistryPersons({
        registryId: id!,
        roles: ['caregiver'],
      }),
    staleTime: 60 * 60 * 1000,
    enabled: id !== undefined,
  });

  if (!data || registryPersonsQuery.isLoading)
    return <LoadingPage minHeight="100%" />;

  const showQualificationSurveyPopup =
    data?.qualification.surveySubmitted === false;
  const showParticipationConfirmationPopup =
    data?.participation.status === 'initial';

  const hasVisiblePopup =
    showQualificationSurveyPopup || showParticipationConfirmationPopup;

  return (
    <>
      <FillOutQualificationSurvey
        show={showQualificationSurveyPopup}
        qualificationSurveyId={data?.qualification.survey.id}
        hidden={showParticipationConfirmationPopup}
        registryName={data?.name}
      />
      <ParticipationConfirmation
        show={showParticipationConfirmationPopup}
        registry={data}
      />
      <CongratulationsModal
        registryName={data?.name ?? ''}
        nextSurveyId={data?.surveys[0]?.id}
        surveysLength={data?.surveys.length ?? 0}
        registryId={data?.id}
      />
      {!showParticipationConfirmationPopup && (
        <RoleSelectionModal
          registryName={data?.name ?? ''}
          registryId={data?.id ?? ''}
          registryRoles={data?.roles ?? []}
          registryPersons={registryPersonsQuery.data?.persons ?? []}
        />
      )}
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
        aria-hidden={hasVisiblePopup ? 'true' : 'false'}
      >
        <RegistryHeader
          registry={data}
          persons={registryPersonsQuery.data?.persons ?? []}
        />
        <RegistryTabs
          data={data}
          persons={registryPersonsQuery.data?.persons}
        />
      </div>
    </>
  );
});
