/* eslint-disable import/first */
/* eslint-disable import/newline-after-import */
import { memo } from 'react';
import { ResponsivenessHelperDown } from 'helpers/responsiveness';
export { ReactComponent as MessageIllustration } from './message.svg';
export { ReactComponent as SuccessMessageIllustration } from './success-message.svg';

const desktopStyle = { maxWidth: '100%' };
interface Props {
  className?: string;
  style?: React.CSSProperties;
}

import { ReactComponent as ClapIllustration } from './clap.svg';
import { ReactComponent as ClapIllustrationIcon } from './clap_icon.svg';
export const ClapIllustrationResponsive = memo((props: Props) => {
  return (
    <ResponsivenessHelperDown
      breakpoint="sm"
      beforeBreakpoint={<ClapIllustrationIcon {...props} />}
      afterBreakpoint={<ClapIllustration style={desktopStyle} {...props} />}
    />
  );
});

import { ReactComponent as DartIllustration } from './dart.svg';
import { ReactComponent as DartIllustrationIcon } from './dart_icon.svg';
export const DartIllustrationResponsive = memo((props: Props) => {
  return (
    <ResponsivenessHelperDown
      breakpoint="sm"
      beforeBreakpoint={<DartIllustrationIcon {...props} />}
      afterBreakpoint={<DartIllustration style={desktopStyle} {...props} />}
    />
  );
});

import { ReactComponent as TadaIllustration } from './tada.svg';
import { ReactComponent as TadaIllustrationIcon } from './tada_icon.svg';
export const TadaIllustrationResponsive = memo((props: Props) => {
  return (
    <ResponsivenessHelperDown
      breakpoint="sm"
      beforeBreakpoint={<TadaIllustrationIcon {...props} />}
      afterBreakpoint={<TadaIllustration style={desktopStyle} {...props} />}
    />
  );
});

import { ReactComponent as DirectoryIllustration } from './directory.svg';
import { ReactComponent as DirectoryIllustrationIcon } from './directory_icon.svg';
export const DirectoryIllustrationResponsive = memo((props: Props) => {
  return (
    <ResponsivenessHelperDown
      breakpoint="sm"
      beforeBreakpoint={<DirectoryIllustrationIcon {...props} />}
      afterBreakpoint={
        <DirectoryIllustration style={desktopStyle} {...props} />
      }
    />
  );
});

import { ReactComponent as HelpMessageIllustration } from './help-message.svg';
import { ReactComponent as HelpMessageIllustrationIcon } from './help-message_icon.svg';
export const HelpMessageIllustrationResponsive = memo((props: Props) => {
  return (
    <ResponsivenessHelperDown
      breakpoint="sm"
      beforeBreakpoint={<HelpMessageIllustrationIcon {...props} />}
      afterBreakpoint={
        <HelpMessageIllustration style={desktopStyle} {...props} />
      }
    />
  );
});

import { ReactComponent as PeopleIllustration } from './people.svg';
import { ReactComponent as PeopleIllustrationIcon } from './people_icon.svg';
export const PeopleIllustrationResponsive = memo((props: Props) => {
  return (
    <ResponsivenessHelperDown
      breakpoint="sm"
      beforeBreakpoint={<PeopleIllustrationIcon {...props} />}
      afterBreakpoint={<PeopleIllustration style={desktopStyle} {...props} />}
    />
  );
});

import { ReactComponent as SearchIllustration } from './search.svg';
import { ReactComponent as SearchIllustrationIcon } from './search_icon.svg';
export const SearchIllustrationResponsive = memo((props: Props) => {
  return (
    <ResponsivenessHelperDown
      breakpoint="sm"
      beforeBreakpoint={<SearchIllustrationIcon {...props} />}
      afterBreakpoint={<SearchIllustration style={desktopStyle} {...props} />}
    />
  );
});

import { ReactComponent as EmptyDirectoryIllustration } from './empty-directory.svg';
import { ReactComponent as EmptyDirectoryIllustrationIcon } from './empty-directory_icon.svg';
export const EmptyDirectoryIllustrationResponsive = memo((props: Props) => {
  return (
    <ResponsivenessHelperDown
      breakpoint="sm"
      beforeBreakpoint={<EmptyDirectoryIllustrationIcon {...props} />}
      afterBreakpoint={
        <EmptyDirectoryIllustration style={desktopStyle} {...props} />
      }
    />
  );
});

export {
  ClapIllustration,
  DartIllustration,
  TadaIllustration,
  DirectoryIllustration,
  HelpMessageIllustration,
  PeopleIllustration,
  SearchIllustration,
};
