import { memo, useId } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography, SwitchProps } from '@mui/material';
import {
  Agreement,
  AgreementSelectionType,
  AgreementDocument,
} from 'api/models/agreement';
import { TableDocument as DocumentIcon } from 'iconsax-react';
import { Checkbox } from 'components/Checkbox';
import { ErrorMessage } from 'components/ErrorMessage';
import { Tooltip } from 'components/Tooltip';
import { RegistryConsentWrapper } from './styled';
import { messages } from '../../messages';

interface Props {
  agreement: Agreement;
  onOpenDocument(document: AgreementDocument): void;
  checked: boolean;
  onCheck: SwitchProps['onChange'];
  error?: string | false;
  disabled?: string | false;
}

export const RegistryConsent = memo(
  ({
    agreement: { title, selectionType, document },
    onOpenDocument,
    onCheck,
    checked,
    error,
    disabled,
  }: Props) => {
    const { t } = useTranslation();
    const checkboxId = useId();

    const getCheckbox = () => {
      if (disabled) {
        return (
          <Tooltip title={disabled}>
            <span>
              <Checkbox
                id={checkboxId}
                data-cy={checkboxId}
                checked={checked}
                onChange={onCheck}
                disabled
              />
            </span>
          </Tooltip>
        );
      }

      return (
        <Checkbox
          id={checkboxId}
          data-cy={checkboxId}
          checked={checked}
          onChange={onCheck}
        />
      );
    };

    const isAgreementOptional =
      selectionType === AgreementSelectionType.OPTIONAL_CHANGE_ALLOWED ||
      (selectionType ===
        AgreementSelectionType.OPTIONAL_ONLY_CHANGE_AGREE_ALLOWED &&
        !disabled);

    return (
      <div>
        <RegistryConsentWrapper invalid={!!error}>
          {getCheckbox()}
          <Stack gap=".4rem" overflow="clip" width="100%">
            <label htmlFor={checkboxId}>
              <Typography variant="body1" component="span">
                {title}
              </Typography>
              {isAgreementOptional && (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  component="span"
                >
                  {` (${t(messages.optional())})`}
                </Typography>
              )}
            </label>
            {document !== undefined && (
              <Button
                variant="text"
                style={{
                  textAlign: 'left',
                  overflow: 'hidden',
                  display: 'inline-block',
                  width: 'fit-content',
                  maxWidth: 'calc(100% - 2.4rem)',
                  textOverflow: 'ellipsis',
                }}
                onClick={() => onOpenDocument(document)}
              >
                <DocumentIcon
                  size="1.6rem"
                  style={{ marginBottom: '.25rem' }}
                />
                {document.title}
              </Button>
            )}
          </Stack>
        </RegistryConsentWrapper>
        <ErrorMessage message={error || undefined} />
      </div>
    );
  },
);
