import { memo, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import Api from 'api/api';
import Session from 'api/storage/session';
import { LoadingPage } from 'pages/Loading';
import { usePrefetchQuery, useQuery } from 'helpers/queries';
import { NotPartOfResearch } from 'pages/Registry/overlays/NotPartOfResearch';
import { AbsoluteContainer } from './styled';

export const MainPage = memo(() => {
  const navigate = useNavigate();
  const { current: userEmail } = useRef(Session.decodeToken()?.email);
  const prefetchQuery = usePrefetchQuery();

  const registries = useQuery({
    queryKey: [
      'registries',
      {
        userEmail: userEmail!,
      },
    ],
    queryFn: Api.getRegistries,
  });

  const { data } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      'registry',
      {
        registryId: registries.data?.registries[0]?.id ?? '',
        userEmail: userEmail!,
      },
    ],
    queryFn: () => Api.getRegistry(registries.data!.registries[0].id),
    enabled: registries.data?.registries[0] !== undefined,
  });

  useEffect(() => {
    if (!data) return;

    navigate(`/registries/${data.id}`);
  }, [data]);

  useEffect(() => {
    prefetchQuery(
      ['userAgreements', { userEmail: userEmail! }],
      Api.getAgreements,
    );
  }, []);

  if (registries.data?.registries.length === 0) {
    return <NotPartOfResearch />;
  }

  return (
    <AbsoluteContainer>
      <LoadingPage />
    </AbsoluteContainer>
  );
});
