import { useState, useRef, useEffect } from 'react';

interface Props {
  seconds: number;
  onFinish?(): void;
}

export const useCountdown = ({ seconds, onFinish }: Props) => {
  const [value, setValue] = useState(seconds);
  const timer = useRef<NodeJS.Timer | null>(null);

  const decreaseSeconds = () => {
    setValue(secondsLeft => {
      if (secondsLeft <= 0) {
        stopTimer();
        return 0;
      }

      return secondsLeft - 1;
    });
  };

  const startTimer = () => {
    setValue(seconds);
    timer.current = setInterval(decreaseSeconds, 1000);
  };

  const stopTimer = () => {
    if (timer.current) clearInterval(timer.current);
    timer.current = null;
  };

  const resetTimer = () => {
    stopTimer();
    startTimer();
  };

  useEffect(() => {
    if (onFinish && value === 0) onFinish();
  }, [value]);

  return {
    secondsLeft: value,
    startTimer,
    stopTimer,
    resetTimer,
  };
};
