import { TypographyVariantsOptions, BreakpointsOptions } from '@mui/material';
import {
  TypographyOptions,
  TypographyStyleOptions,
} from '@mui/material/styles/createTypography';
import { lightPalette } from './colors';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    input: TypographyOptions;
    tableHead: TypographyOptions;
    tableBody: TypographyOptions;
    pagination: TypographyOptions;
    body3: TypographyOptions;
    body4: TypographyOptions;
    caption1: TypographyOptions;
    xxl: TypographyOptions;
    xxs: TypographyOptions;
  }

  interface TypographyVariantsOptions {
    input: TypographyStyleOptions;
    tableHead: TypographyStyleOptions;
    tableBody: TypographyStyleOptions;
    pagination: TypographyStyleOptions;
    body3: TypographyStyleOptions;
    body4: TypographyStyleOptions;
    caption1: TypographyStyleOptions;
    xxl: TypographyStyleOptions;
    xxs: TypographyStyleOptions;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    input: true;
    tableHead: true;
    tableBody: true;
    pagination: true;
    body3: true;
    body4: true;
    caption1: true;
    xxl: true;
    xxs: true;
  }
}

export const typography = (
  palette: typeof lightPalette,
  breakpoints: BreakpointsOptions,
): TypographyVariantsOptions => ({
  // XXL
  xxl: {
    color: palette.text.primary,
    margin: 0,
    fontFamily: 'AvertaBold',
    fontSize: '3.6rem',
    fontWeight: 'normal',
    lineHeight: '130%',
    letterSpacing: '0%',
    textAlign: 'left',
    [`@media (max-width:${breakpoints.values!.lg - 1}px)`]: {
      fontSize: '3.2rem',
      lineHeight: '130%',
    },
  },
  // XL
  h1: {
    color: palette.text.primary,
    margin: 0,
    fontFamily: 'AvertaSemiBold',
    fontSize: '2.8rem',
    fontWeight: 'normal',
    lineHeight: '130%',
    letterSpacing: '0%',
    textAlign: 'left',
    [`@media (max-width:${breakpoints.values!.lg - 1}px)`]: {
      fontSize: '2.4rem',
      lineHeight: '130%',
    },
  },
  // L
  h2: {
    color: palette.text.primary,
    fontSize: '2.4rem',
    fontFamily: 'AvertaSemiBold',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '150%',
    margin: '0',
    [`@media (max-width:${breakpoints.values!.lg - 1}px)`]: {
      fontSize: '2.1rem',
      lineHeight: '130%',
    },
  },
  // M
  h3: {
    color: palette.text.primary,
    fontSize: '1.8rem',
    fontFamily: 'AvertaSemiBold',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '150%',
    margin: '0',
  },
  // S
  h4: {
    color: palette.text.primary,
    fontSize: '1.6rem',
    fontFamily: 'AvertaBold',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '150%',
    margin: '0',
  },
  // XS
  h5: {
    color: palette.text.primary,
    fontSize: '1.6rem',
    fontFamily: 'AvertaSemiBold',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '150%',
    margin: '0',
  },
  body1: {
    color: palette.additionalColors.bodyText,
    fontSize: '1.6rem',
    fontFamily: 'AvertaRegular',
    fontWeight: 'normal',
    lineHeight: '150%',
    letterSpacing: 0,
    textAlign: 'left',
  },
  body2: {
    color: palette.additionalColors.bodyText,
    fontSize: '1.4rem',
    fontFamily: 'AvertaRegular',
    fontWeight: 'normal',
    lineHeight: '150%',
    letterSpacing: 0,
    textAlign: 'left',
  },
  body3: {
    fontSize: '1.2rem',
    fontFamily: 'AvertaBold',
    lineHeight: '130%',
    color: palette.text.primary,
  },
  body4: {
    fontSize: '1.2rem',
    fontFamily: 'AvertaRegular',
    lineHeight: '130%',
    color: palette.text.primary,
    letterSpacing: '1.5%',
    textTransform: 'uppercase',
  },
  button: {
    color: palette.button.primary,
    fontFamily: 'AvertaBold, Arial, Helvetica, sans-serif',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '150%',
  },
  input: {
    color: palette.text.primary,
    fontFamily: 'AvertaRegular, Arial, Helvetica, sans-serif',
    fontWeight: 700,
  },
  tableHead: {
    color: palette.text.secondary,
    fontSize: '1.2rem',
    fontWeight: 700,
  },
  tableBody: {
    fontSize: '1.6rem',
    fontFamily: 'AvertaRegular',
  },
  pagination: {
    fontSize: '1.6rem',
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: '1.2rem',
    fontFamily: 'AvertaSemiBold',
    fontWeight: 'normal',
    lineHeight: '130%',
    color: palette.text.secondary,
  },
  caption1: {
    fontSize: '1rem',
    fontFamily: 'AvertaSemiBold',
    color: palette.text.secondary,
  },
  xxs: {
    color: palette.text.primary,
    fontFamily: 'AvertaSemiBold',
    fontSize: '1.4rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '150%',
  },
});
