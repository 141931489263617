import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import {
  HealthcareProviderResponse,
  EhrState,
} from 'api/models/HealthcareProviders';
import { useConfig } from 'hooks/useConfig';
import { ProviderWrapper, ProviderIcon } from './styled';
import { messages } from './messages';

type Props = HealthcareProviderResponse;

export const Provider = ({ id, name, iconPath, status, ehr }: Props) => {
  const { t } = useTranslation();
  const { ehrRedirectUri } = useConfig();

  const getColor = () => {
    if (status === EhrState.unpaired) return 'message.text.error';
    return 'message.text.success';
  };

  const getStatusLabel = () => {
    if (status === EhrState.unpaired) return t(messages.disconnected());
    return t(messages.connected());
  };

  const onReconnect = () => {
    window.location.href = `${ehr.auth.configuration.webAuthUrl}&redirect_uri=${ehrRedirectUri}&state=${id}`;
  };

  return (
    <ProviderWrapper>
      <Stack direction="row" alignItems="center" gap="2.4rem">
        <ProviderIcon src={iconPath} />
        <Stack direction="column">
          <Typography variant="h5">{name}</Typography>
          <Typography variant="body1" color={getColor()}>
            {getStatusLabel()}
          </Typography>
        </Stack>
      </Stack>
      {status === EhrState.unpaired && (
        <Button size="small" onClick={onReconnect}>
          {t(messages.reconnect())}
        </Button>
      )}
    </ProviderWrapper>
  );
};
