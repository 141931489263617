import styled, { css } from 'styled-components';
import { Drawer, Toolbar, ButtonBase } from '@mui/material';
import { useTypography } from 'helpers/mixins';

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 100%;
    height: 100%;
    min-width: 100vw;
    background: ${({ theme }) => theme.palette.background.primaryMobile};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding-bottom: 5.4rem;

    > nav {
      display: flex;
      flex-direction: column;
      gap: 4.8rem;
    }

    > span {
      ${({ theme }) => useTypography(theme.typography.body1)}
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`;

export const StyledToolbar = styled(Toolbar)`
  background: ${({ theme }) => theme.palette.background.primaryMobile};
  padding: 1.6rem 4.8em;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 1.6rem 2.4em;
  }
`;

export const StyledDesktopToolbar = styled(Toolbar)`
  background: ${({ theme }) => theme.palette.background.primaryMobile};
  padding: 1.6rem 7.2rem;
  display: flex;
  gap: 1.6rem;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  > nav {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 4rem;
  }

  > span {
    ${({ theme }) => useTypography(theme.typography.body1)}
    color: ${({ theme }) => theme.palette.text.primary};
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 0.6rem;
  }
`;

export const NavLink = styled.a<{ current?: boolean }>`
  && {
    position: relative;
    ${({ theme }) => useTypography(theme.typography.body1)}
    color: ${({ theme }) => theme.palette.text.primary};

    :after {
      content: '';
      position: absolute;
      top: calc(100% + 0.6rem);
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 0.4rem;
      background: transparent;
      transition: background 300ms;
    }

    :hover:after {
      background: ${({ theme }) => theme.palette.background.color.secondary};
    }

    :active:after {
      background: ${({ theme }) => theme.palette.button.primary};
    }

    ${({ current, theme }) =>
      current &&
      css`
        ${useTypography(theme.typography.h5)}
        :after, :hover:after {
          background: ${theme.palette.button.primary};
        }
      `}
  }
`;

export const SidebarButton = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 4.4rem;
  min-width: 4.4rem;
  height: 4.4rem;
  margin-left: 4.8rem;
  background: ${({ theme }) => theme.palette.button.primary};
  border-radius: 0.8rem;

  path,
  line {
    stroke: ${({ theme }) => theme.palette.button.text};
  }

  :hover {
    border-radius: 0.8rem;
  }
`;

export const CloseButton = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;

  width: 4.4rem;
  height: 4.4rem;

  background: transparent;
  border-radius: 1.2rem;

  path,
  line {
    stroke: ${({ theme }) => theme.palette.button.primary};
  }

  margin: 2rem 2.4rem;
`;

export const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
