import { Components } from '@mui/material/styles/components';
import { Theme } from 'styles/theme/themes';

export const MuiDivider: Components<Theme>['MuiDivider'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderColor: theme.palette.separator.primary,
      width: '100%',
    }),
  },
};
