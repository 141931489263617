import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { SurveyCompletedIllustration } from 'assets/illustrations';
import { Illustration, CohortList } from './styled';
import { messages } from '../messages';
import { RegistryLocationState } from '../types';

type Props = NonNullable<RegistryLocationState['cohorts']> & {
  registryName: string;
};

export const FirstQualificationSurveyModalContent = memo(
  ({ newCohorts, registryName }: Props) => {
    const { t } = useTranslation();

    const contentWithCohorts = () => (
      <Typography variant="body1" textAlign="center" component="span">
        {t(messages.youEnrolledAndWereAssigned(), {
          registry: registryName,
        })}
        <CohortList data-cy="cohorts-list">
          {newCohorts!.map(({ name, id }) => (
            <li key={id}>{name}</li>
          ))}
        </CohortList>
        {t(messages.completeInAnyOrderYouChoose())}
      </Typography>
    );

    const contentWithoutCohorts = () => (
      <Typography variant="body1" textAlign="center">
        {t(messages.youEnrolledWillInformYou(), {
          registry: registryName,
        })}
      </Typography>
    );

    return (
      <>
        <Illustration as={SurveyCompletedIllustration} />
        <Typography variant="h1" marginY="1.6rem" textAlign="center">
          {t(messages.qualificationSurveyCompleted())}
        </Typography>
        {(newCohorts?.length ?? 0) > 0
          ? contentWithCohorts()
          : contentWithoutCohorts()}
      </>
    );
  },
);
