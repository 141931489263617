import { GetRegistryNotificationsResponse } from 'api/interfaces/response';
import { useState, Fragment } from 'react';
import { Stack, AccordionDetails } from '@mui/material';
import {
  CustomAccordionSummary,
  ScrollableAccordion,
} from 'pages/Settings/styled';
import { ConsentsWrapper } from '../../styled';
import { NotificationRow } from './NotificationRow';
import { useNotificationForm } from './useNotificationForm';
import { H4NoStyles } from './styled';

interface Props {
  data: GetRegistryNotificationsResponse;
}

export const NotificationList = ({ data }: Props) => {
  const { values, handleChange } = useNotificationForm(
    data.registryNotifications,
  );

  const [expanded, setExpanded] = useState<string | false>(false);

  const handlePanelChange =
    (panel: string) => (_e: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const getSwitchHandler =
    (registryId: string, email: string) => (value: boolean) => {
      handleChange(registryId, email, value);
    };

  const hasMoreThanOneRegistry = values.length > 1;

  return (
    <ConsentsWrapper>
      <Stack
        gap="0.6rem"
        overflow="auto"
        display={{
          xs: 'unset',
          sm: 'flex',
        }}
      >
        {values.map(({ registryId, registryName, surveysNotifications }) => {
          const member = surveysNotifications.participation;
          const nonMemberPersons = surveysNotifications.persons.filter(
            person => person.email !== member.email,
          );

          return (
            <ScrollableAccordion
              key={registryId}
              expanded={expanded === registryId}
              onChange={handlePanelChange(registryId)}
            >
              {values.length > 1 && (
                <CustomAccordionSummary>
                  <H4NoStyles>{registryName}</H4NoStyles>
                </CustomAccordionSummary>
              )}
              <AccordionDetails style={{ padding: 0, overflow: 'auto' }}>
                <Stack paddingTop="2rem" paddingBottom="2rem">
                  <NotificationRow
                    type="account"
                    email={member.email}
                    value={member.isActive}
                    onChange={getSwitchHandler(registryId, member.email)}
                    size={hasMoreThanOneRegistry ? 'medium' : 'big'}
                  />
                  {nonMemberPersons.map(notification => {
                    return (
                      <Fragment key={notification.personId}>
                        <NotificationRow
                          type="caregiver"
                          email={notification.email}
                          value={notification.isActive}
                          onChange={getSwitchHandler(
                            registryId,
                            notification.email,
                          )}
                          size={hasMoreThanOneRegistry ? 'medium' : 'big'}
                        />
                      </Fragment>
                    );
                  })}
                </Stack>
              </AccordionDetails>
            </ScrollableAccordion>
          );
        })}
      </Stack>
    </ConsentsWrapper>
  );
};
