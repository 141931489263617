import { useTranslation } from 'react-i18next';
import { Stack, Switch, Typography } from '@mui/material';
import { maskEmail } from 'helpers/email';
import { messages } from '../../messages';
import { NotificationRowContainer } from './styled';

interface Props {
  type: 'account' | 'caregiver';
  email: string;
  value: boolean;
  onChange: (value: boolean) => void;
  size: 'big' | 'medium';
}

export const NotificationRow = ({
  type,
  email,
  value,
  onChange,
  size,
}: Props) => {
  const { t } = useTranslation();

  const title = t(
    type === 'account' ? messages.accountEmail() : messages.caregiverEmail(),
  );

  return (
    <NotificationRowContainer size={size}>
      <Stack gap="0.8rem" direction="row">
        <Typography variant={size === 'medium' ? 'xxs' : 'h5'} component="h5">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          ({maskEmail(email)})
        </Typography>
      </Stack>
      <Stack gap="0.8rem" direction="row" justifyContent="space-between">
        <Typography variant={'body1'} component="h5">
          {t(messages.receiveEmailNotifications())}
          <strong>{t(value ? messages.yes() : messages.no())}</strong>
        </Typography>
        <Switch checked={value} onChange={() => onChange(!value)} />
      </Stack>
    </NotificationRowContainer>
  );
};
