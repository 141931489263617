import { memo, ChangeEvent, useId } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useField } from 'formik';
import * as yup from 'yup';
import { ErrorMessage } from 'components/ErrorMessage/index';
import { FormikTextFieldLabel } from './styled';

type BaseProps = TextFieldProps & {
  name: string;
  disableError?: boolean;
  'data-cy'?: string;
  oneWayValidationOnChange?: false;
  validationSchema?: undefined;
  label?: string;
};

type PropsWithOneWayValidation = Omit<
  BaseProps,
  'oneWayValidationOnChange' | 'validationSchema'
> & {
  oneWayValidationOnChange: true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema: yup.ObjectSchema<any>;
};

export type FormikTextFieldProps = BaseProps | PropsWithOneWayValidation;

export const FormikTextField = memo(
  ({
    name,
    disableError = false,
    oneWayValidationOnChange,
    validationSchema,
    label,
    'data-cy': dataCy,
    ...otherProps
  }: FormikTextFieldProps) => {
    const fieldErrorId = useId();
    const [{ onChange, ...field }, meta, helpers] = useField(name);

    const customOnChange = async (
      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      onChange(e);

      if (!validationSchema) return;
      if (meta.error) {
        validationSchema
          .validateAt(field.name, {
            [field.name]: e.target.value,
          })
          .then(() => {
            helpers.setError(undefined);
          })
          // suppress error
          .catch(() => {});
      }
    };

    const configTextField = {
      ...field,
      ...otherProps,
      onChange: oneWayValidationOnChange ? customOnChange : onChange,
    };

    const isError = Boolean(meta && meta.touched && meta.error);

    const notNullDataCy = dataCy ?? `${field.name}-input`;

    return (
      <div style={{ width: '100%' }}>
        {label && (
          <FormikTextFieldLabel htmlFor={notNullDataCy}>
            {label}
          </FormikTextFieldLabel>
        )}
        <TextField
          {...configTextField}
          data-cy={`${notNullDataCy}-container`}
          inputProps={{
            'aria-invalid': isError ? 'true' : 'false',
            'aria-errormessage': fieldErrorId,
            'data-cy': `${notNullDataCy}`,
            id: notNullDataCy,
            ...otherProps.inputProps,
          }}
          InputProps={{
            ...otherProps.InputProps,
            className: `${isError ? 'InvalidInputValue' : ''} ${
              otherProps.InputProps?.className ?? ''
            }`,
          }}
          fullWidth
        />
        <ErrorMessage
          message={meta.error}
          visible={!disableError && isError}
          style={{ marginTop: '0.8rem' }}
          data-cy={`${notNullDataCy}-error`}
          id={fieldErrorId}
        />
      </div>
    );
  },
);
