import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { messages } from 'pages/Login/messages';
import { Form, Formik } from 'formik';
import { capitalize } from 'lodash-es';
import { FormikTextField } from '../../../../components/FormikTextField';
import { ErrorMessage } from '../../../../components/ErrorMessage';
import { useValidationSchema } from '../../../../hooks/useValidationSchema';
import { getValidationSchema } from './validators';
import {
  BackIcon,
  CloseIcon,
  IconButtonsContainer,
  FormModalStepContainer,
  FormStackWrapper,
} from '../../styled';
import { ResponsivenessHelperDown } from '../../../../helpers/responsiveness';

interface FormTypes {
  username: string;
}

interface Props {
  onSuccess(username: string): void;
  onBack(): void;
  username?: string;
}

export const PasswordResetEmailStep = memo(
  ({ onBack, onSuccess, username }: Props) => {
    const { t } = useTranslation();
    const validationSchema = useValidationSchema(getValidationSchema);

    const formContent = (
      <FormStackWrapper spacing="2.4rem" data-cy="password-reset-email-step">
        <Typography variant="h1">{t(messages.passwordReset())}</Typography>
        <Typography variant="body1" style={{ marginTop: '1.6rem' }}>
          {t(messages.passwordResetDescription())}
        </Typography>

        <Formik
          initialValues={{ username } as FormTypes}
          onSubmit={values => onSuccess(values.username)}
          validationSchema={validationSchema}
          validateOnChange={false}
        >
          {({ errors, touched }) => (
            <Form data-cy="password-reset-email-step">
              <Stack
                spacing="2.4rem"
                direction="column"
                justifyContent="center"
              >
                <FormikTextField
                  name="username"
                  type="email"
                  placeholder={capitalize(t(messages.emailOrPhoneNumber()))}
                  validationSchema={validationSchema}
                  oneWayValidationOnChange={true}
                  data-cy="username-input"
                  label={capitalize(t(messages.email()))}
                />
                <ErrorMessage
                  message={errors.username}
                  visible={!(errors.username && touched.username)}
                  style={{ marginTop: '0.8rem' }}
                />
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  data-cy="password-reset-email-step-submit-button"
                >
                  {t(messages.continue())}
                </Button>
                <Button variant="text" onClick={onBack} data-cy="back-button">
                  {t(messages.back())}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </FormStackWrapper>
    );

    return (
      <ResponsivenessHelperDown
        breakpoint={'sm'}
        beforeBreakpoint={
          <FormModalStepContainer>
            <IconButtonsContainer>
              <BackIcon onClick={onBack} />
              <CloseIcon onClick={onBack} />
            </IconButtonsContainer>
            {formContent}
          </FormModalStepContainer>
        }
        afterBreakpoint={formContent}
      />
    );
  },
);
