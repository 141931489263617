import { useTranslation, Trans } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { useProperlyThemedComponent } from 'hooks/useProperlyThemedComponent';
import securityIllustrationLight from './assets/security.svg';
import securityIllustrationDark from './assets/security_dark.svg';
import { messages } from '../../messages';
import { IllustrationWrapper } from './styled';

export const SecuritySection = () => {
  const { t } = useTranslation();

  const securityIllustration = useProperlyThemedComponent({
    light: securityIllustrationLight,
    dark: securityIllustrationDark,
  });

  return (
    <Stack
      padding={{
        xxl: '12rem 7.2rem',
        lg: '9.6rem 7.2rem',
        md: '7.2rem 4.8rem',
        xs: '5.6rem 2.4rem',
      }}
      maxWidth="144rem"
      margin="auto"
      boxSizing="content-box"
      alignItems="center"
      direction={{ lg: 'row-reverse', xs: 'column' }}
      gap={{ lg: '2.4', md: '7.2rem', xs: '5.6rem' }}
    >
      <Stack
        gap="1.6rem"
        width={{
          lg: '50%',
          xs: '100%',
        }}
      >
        <Typography variant="h1">{t(messages.yourDataIsSafe())}</Typography>
        <Stack gap="0.8rem">
          <Typography variant="body1">
            <Trans
              t={t}
              i18nKey={t(messages.onlyAuthorizedPersonnelHasAccess())}
              components={[<Typography variant="h5" component="span" />]}
            />
          </Typography>
          <Typography variant="body1">
            <Trans
              t={t}
              i18nKey={t(messages.weDidNotDisplayData())}
              components={[<Typography variant="h5" component="span" />]}
            />
          </Typography>
          <Typography variant="body1">
            {t(messages.autoLogoutFeature())}
          </Typography>
          <Typography variant="body1">
            {t(messages.appIsHipaaCompliant())}
          </Typography>
        </Stack>
      </Stack>
      <IllustrationWrapper>
        <img src={securityIllustration} alt="" />
      </IllustrationWrapper>
    </Stack>
  );
};
