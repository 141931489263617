import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as NotFoundIllustrationLight } from './notFound.svg';
import { ReactComponent as NotFoundIllustrationDark } from './notFound_dark.svg';

export const NotFoundIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return <NotFoundIllustrationDark aria-hidden="true" {...props} />;
    return <NotFoundIllustrationLight aria-hidden="true" {...props} />;
  },
);
