import styled, { css } from 'styled-components';
import { scrollbar, useTypography } from 'helpers/mixins';
import { ErrorIcon as UnstyledErrorIcon } from 'assets/icons';
import { ButtonBase, Popper as PopperMaterialUi } from '@mui/material';

export const StyledButtonBase = styled(ButtonBase)<{
  $open: boolean;
  $disabled: boolean;
}>`
  &:hover {
    & > div {
      background: ${({ theme, $open }) =>
        $open
          ? theme.palette.background.elevated.tetriary
          : theme.palette.background.elevated.menuHover};
    }
  }

  & > div {
    ${({ $disabled }) =>
      $disabled &&
      css`
        color: ${({ theme }) => theme.palette.button.disabledText};
        background: ${({ theme }) => theme.palette.button.disabledBackground};

        svg {
          fill: ${({ theme }) => theme.palette.button.disabledText};
        }
      `};
  }
`;

export const MainLabel = styled.div<{
  open: boolean;
  capitalizeFirstLetter?: boolean;
  showBottomBorder: boolean;
  error: boolean;
  variant: 'small' | 'medium';
}>`
  background: ${({ theme, open }) =>
    open
      ? theme.palette.background.elevated.tetriary
      : theme.palette.button.background};

  height: ${({ variant }) => (variant === 'small' ? '4.2rem' : '5rem')};
  width: 100%;
  transition: border-color 300ms;
  border: 0.15rem solid ${({ theme }) => theme.palette.separator.primary};
  ${({ showBottomBorder }) =>
    !showBottomBorder &&
    css`
      border-bottom: none;
    `};
  ${({ error, open, theme }) =>
    error &&
    !open &&
    css`
      border-color: ${theme.palette.message.text.error};
    `}
  border-radius: ${({ open, variant }) => {
    const baseRadius = variant === 'small' ? '1.2rem' : '1.6rem';
    return open ? `${baseRadius} ${baseRadius} 0 0` : baseRadius;
  }};
  padding: 0.5rem 1.6rem;
  font-size: 1.6rem;
  line-height: 150%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ open }) =>
    open &&
    `
    box-shadow: 0px 4px 30px rgba(49, 48, 111, 0.08);
    border-bottom-width: '0.1rem';
  `}

  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.typography.input.fontFamily};
  font-weight: normal;
  ${({ capitalizeFirstLetter }) =>
    capitalizeFirstLetter && 'text-transform:capitalize;'}

  svg {
    transform: ${({ open }) => (open ? 'rotateZ(180deg)' : 'rotateZ(0deg)')};
    width: 1.8rem;
    min-width: 1.8rem;
    height: 1.8rem;

    * {
      fill: ${({ theme }) => theme.palette.text.secondary};
    }
  }

  > div {
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 2.4rem);
    white-space: nowrap;
    text-align: start;
  }
`;

export const ValueWrapper = styled.span<{ colorSecondary?: boolean }>`
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  ${({ theme }) => useTypography(theme.typography.h5)}
  word-break: keep-all;
  white-space: nowrap;
  color: ${({ theme, colorSecondary }) =>
    colorSecondary && theme.palette.text.primary};
`;

export const Popper = styled(PopperMaterialUi)<{
  $capitalizeFirstLetter?: boolean;
  $width?: number;
  $overlapButton?: boolean; // to decrease margin
}>`
  width: ${({ $width }) => ($width ? `${$width}px` : '40.4rem')};
  background: ${({ theme }) => theme.palette.background.elevated.tetriary};
  border: 0.15rem solid ${({ theme }) => theme.palette.separator.primary};
  border-top: none;
  border-radius: 0 0 1.6rem 1.6rem;
  ${({ $capitalizeFirstLetter }) =>
    $capitalizeFirstLetter && 'text-transform:capitalize;'}
  box-shadow: 0 0.4rem 3rem rgba(49, 48, 111, 0.08);
  z-index: 1500;
  overflow: hidden;
  overflow-y: auto;
  clip-path: inset(0 -3rem -3rem -3rem);

  ${({ $overlapButton }) =>
    $overlapButton &&
    css`
      top: -1.2rem !important; // override auto-applied styles
    `}

  ${({ theme }) =>
    scrollbar({
      thumbBackground: theme.palette.separator.primary,
      thumbHovered: theme.palette.separator.hover,
    })}

  div:last-of-type {
    border-bottom: none;
  }
`;

export const Placeholder = styled.span`
  ${({ theme }) => useTypography(theme.typography.body1)}
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const OptionWrapper = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 0.1rem solid ${({ theme }) => theme.palette.separator.primary};
  padding: 1rem 1.5rem;

  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      background: ${theme.palette.background.elevated.menuHover};
    `}

  &:hover {
    background: ${({ theme }) => theme.palette.background.elevated.menuHover};
  }

  color: ${({ theme }) => theme.palette.text.primary};
  cursor: pointer;
`;

export const Option = styled.div`
  ${({ theme }) => useTypography(theme.typography.body1)}
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const DisabledOption = styled(OptionWrapper)`
  cursor: default;
  color: ${({ theme }) => theme.palette.button.disabledText};
  font-size: 1.6rem;
  line-height: 150%;

  &:hover {
    background: transparent;
  }
`;

export const OptionDescription = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 1.4rem;
`;

export const SelectedOptionDescription = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 1.4rem;
  padding: 0 1.5rem 1rem;
  border-bottom: 0.15rem solid ${({ theme }) => theme.palette.separator.primary};
  cursor: pointer;
  background: ${({ theme }) => theme.palette.background.elevated.tetriary};
  position: sticky;
  top: 0;
  left: 0;
`;

export const ErrorIcon = styled(UnstyledErrorIcon)`
  margin-right: 0.8rem;
  min-width: 1.6rem;
  width: 1.6rem;
  min-height: 1.6rem;
  height: 1.6rem;

  * {
    fill: ${({ theme }) => theme.palette.message.text.error};
  }
`;
