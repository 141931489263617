import { memo } from 'react';
import { CardContainer, CardWrapper, CardNumber } from './styled';

interface Props {
  number?: number;
  children: React.ReactNode;
}

export const Card = memo(({ number, children }: Props) => {
  return (
    <CardWrapper>
      <CardContainer>
        {number !== undefined && <CardNumber>{number}</CardNumber>}
        <div>{children}</div>
      </CardContainer>
    </CardWrapper>
  );
});
