import { Stack } from '@mui/material';
import styled from 'styled-components';
import ShowMoreText from 'react-show-more-text';

export const RegistryTitleSectionWrapper = styled(Stack)`
  padding-left: 4rem;
  border-radius: 1.6rem;
  gap: 2.4rem;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.additionalColors.banner};

  ${({ theme }) => theme.breakpoints.down('mdl')} {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
`;

export const RegistryTitleSectionContent = styled(Stack)`
  justify-content: center;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  gap: 1.6rem;
  width: 100%;
`;

export const RegistryBannerIllustrationWrapper = styled.div`
  min-width: 23rem;
  min-height: 20.3rem;
  overflow: hidden;
  border-radius: 1.6rem;
  display: flex;
  align-items: end;
`;

export const RegistryBannerIllustration = styled.img`
  margin-left: 0.3rem;
  margin-bottom: -1.4rem;
  margin-top: -1.4rem;
`;

export const Assignments = styled(ShowMoreText)`
  font-size: 1.6rem;
  font-family: 'AvertaRegular';
  color: ${({ theme }) => theme.palette.text.primary};

  .showMoreLessButton {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 1.6rem;
    text-decoration: none;
    font-family: 'AvertaBold';
    white-space: nowrap;
  }
`;
