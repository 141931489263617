import { useTranslation } from 'react-i18next';
import { Clock as ClockIcon } from 'iconsax-react';
import { Button, Stack, Typography, Fade } from '@mui/material';
import { SurveyInstructionIllustration } from 'assets/illustrations';
import { GetRegistrySurveyResponse } from 'api/interfaces/response';
import { SurveyInformation } from 'components/ActivityCard/styled';
import { MarkdownRenderer } from 'components/MarkdownRenderer';
import { getEstimatedTime } from 'helpers/estimatedTime';
import { useBooleanState } from 'hooks/useBooleanState';
import { SurveyInstructionWrapper } from './styled';
import { messages } from './messages';

interface Props {
  survey?: GetRegistrySurveyResponse;
  registryLoaded?: boolean;
}

export const SurveyInstruction = ({ survey, registryLoaded }: Props) => {
  const { t } = useTranslation();
  const { value: isVisible, setFalse: hide } = useBooleanState(true);
  const { name, instruction, statistics } = survey ?? {};

  const estimatedTime = statistics ? getEstimatedTime(statistics) : null;

  const isLoaded =
    isVisible &&
    registryLoaded &&
    survey !== undefined &&
    !!instruction?.length;

  if (!isLoaded) return null;
  return (
    <Fade in={isLoaded} appear={false}>
      <SurveyInstructionWrapper data-cy="instruction-overlay">
        <Stack
          gap={{
            lg: '2.4rem',
            sm: '2rem',
            xs: '1.6rem',
          }}
        >
          <SurveyInstructionIllustration style={{ alignSelf: 'center' }} />
          <Typography variant="h1" textAlign="center">
            {name}
          </Typography>
          {estimatedTime !== null && (
            <Stack direction="row" spacing="2rem" justifyContent="center">
              <SurveyInformation
                data-cy="estimated-time"
                aria-label={`${t(
                  messages.estimatedTime(),
                )} ${estimatedTime} ${t(messages.minutes())},`}
              >
                <ClockIcon />
                <span>{t(messages.estimatedTime())}</span>
                <strong>
                  {estimatedTime} {t(messages.min())}
                </strong>
              </SurveyInformation>
            </Stack>
          )}
          <Typography variant="h4">
            <MarkdownRenderer>{instruction ?? ''}</MarkdownRenderer>
          </Typography>
          <Button onClick={hide}>{t(messages.continue())}</Button>
        </Stack>
      </SurveyInstructionWrapper>
    </Fade>
  );
};
