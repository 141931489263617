import styled from 'styled-components';
import { Button, Stack, Typography } from '@mui/material';

export const CaregiverSectionWrapper = styled.div`
  width: 100%;
  padding: 3.2rem;
  margin-top: 0.95rem;
  border: 0.1rem solid ${({ theme }) => theme.palette.elements.separator};
  border-radius: 1.6rem;
  background: ${({ theme }) => theme.palette.background.elevated.primary};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
`;

export const AddCaregiverWrapper = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: flex-start;
    gap: 2.4rem;
  }
`;

export const CaregiverInfoWrapper = styled(Stack)`
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    & > *:not(:last-child) {
      border-bottom: 0.1rem solid
        ${({ theme }) => theme.palette.elements.separator};
    }
  }
`;

export const AddCaregiverButton = styled(Button)`
  white-space: nowrap;
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  padding-left: 1.6rem;

  * svg {
    width: 1.8rem;
    height: 1.8rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 0;
  }
`;

export const CaregiverInfoRowWrapper = styled(Stack)`
  flex-direction: row;
  gap: 2.4rem;
  width: 100%;
  align-items: baseline;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 0.8rem;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    align-items: flex-start;

    & > *:first-child {
      padding-top: 0;
    }

    & > *:last-child {
      padding-bottom: 0;
    }
  }
`;

export const InfoTypeWrapper = styled(Typography)`
  width: 20.4rem;
  flex-shrink: 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 14rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 20.4rem;
  }
`;
