import styled from 'styled-components';

export const IllustrationWrapper = styled.div`
  width: 50%;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
    display: flex;
    align-items: center;
  }

  img {
    margin-left: -10.9rem;
    height: 54.4rem;
    width: auto;
    max-width: 50vw;

    ${({ theme }) => theme.breakpoints.down('lg')} {
      height: 43.87rem;
      margin-left: 5rem;
      max-width: 100vw;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      height: 34.4rem;
      margin-left: -6.9rem;
    }
  }
`;
