import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Drawer,
  ListItemText,
  Typography,
  ButtonBase,
  List,
} from '@mui/material';

import { Tooltip } from 'components/Tooltip';
import { useTypography } from 'helpers/mixins';
import { WarningIcon } from 'assets/icons';

export const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background: ${({ theme }) => theme.palette.additionalColors.gradient};
    box-shadow: ${({ theme }) => theme.palette.additionalColors.sideMenuShadow};
    transition: width 300ms;

    margin: 0.8rem;
    height: calc(100% - 1.6rem);
    border-radius: 1.6rem;

    ${({ theme }) => theme.breakpoints.down('lg')} {
      width: 42.3rem;
      height: 100%;
      margin: 0;
      border-radius: 1.6rem 0 0 1.6rem;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 100%;
      border-radius: 0;
    }

    > div {
      height: 100%;

      .MuiListItemIcon-root {
        min-width: 3rem;
        justify-content: center;
        padding-right: 1rem;
        margin-left: -0.2rem;
      }
    }
    .MuiToolbar-root {
      display: none;
    }
  }
`;

export const LogoContainer = styled.div`
  > svg {
    display: block;
    width: 23.787rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3.6rem;
    margin-bottom: 7.2rem;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: none;
  }
`;

export const PageIconWrapper = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  width: 4rem;
  min-width: 4rem;
  height: 4rem;

  transition: background 300ms;
  background: ${({ theme, active }) =>
    active
      ? theme.palette.button.primary
      : theme.palette.additionalColors.selectedBackground};
  border-radius: 1.2rem;

  svg {
    width: 1.8rem;
    height: 1.8rem;
  }

  svg * {
    transition: color 300ms;
    color: ${({ theme, active }) =>
      active
        ? theme.palette.button.text
        : theme.palette.additionalColors.primaryText};
  }
`;

export const RegistriesAssignedHeader = styled(Typography)`
  margin-left: 3.2rem;
  margin-bottom: 1.6rem;

  && {
    color: ${({ theme }) => theme.palette.additionalColors.secondaryText};
  }
`;

export const ProfileInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.6rem;
  margin-bottom: 3.2rem;
  margin-top: 2.4rem;
`;

export const EmailWrapper = styled(Tooltip)`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.palette.additionalColors.primaryText};
  margin-left: 1.2rem;
  margin-right: 1.6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const SidebarLink = styled(Link)<{ $active?: boolean }>`
  padding: 0.8rem 1.2rem 0.8rem 0.8rem;
  gap: 0.8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 1.6rem;

  && {
    transition: background-color 300ms;
  }

  background: ${({ theme, $active }) =>
    $active
      ? theme.palette.additionalColors.selectedBackground
      : 'transparent'};

  :hover {
    background: ${({ theme }) =>
      theme.palette.additionalColors.selectedBackground};
  }
`;

export const ListItemRegistry = styled(ListItemText)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const RegistrySurveysIndicator = styled.span`
  ${({ theme }) => useTypography(theme.typography.body3)}
  background: ${({ theme }) => theme.palette.additionalColors.accentSuccess};
  color: ${({ theme }) => theme.palette.additionalColors.tertiaryText};
  min-width: 1.8rem;
  height: 1.8rem;
  line-height: 1.8rem;
  border-radius: 1.1rem;
  text-align: center;
  white-space: nowrap;
  padding-left: 0.37rem;
  padding-right: 0.37rem;
  flex-shrink: 0;
  margin-left: 1.6rem;
`;

export const RegistryWarningIndicator = styled(WarningIcon)`
  ${({ theme }) => useTypography(theme.typography.body3)}
  color: ${({ theme }) => theme.palette.additionalColors.tertiaryText};
  min-width: 1.8rem;
  height: 1.8rem;
  line-height: 1.8rem;
  border-radius: 1.1rem;
  margin-left: 1.6rem;

  *[fill='#002E5F' i] {
    fill: ${({ theme }) => theme.palette.additionalColors.tertiaryText};
  }
  *[fill='#FF7B74' i] {
    fill: ${({ theme }) => theme.palette.additionalColors.accentError};
  }
`;

export const CloseButton = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;

  width: 4.4rem;
  height: 4.4rem;

  background: transparent;
  border-radius: 1.2rem;

  path,
  line {
    stroke: ${({ theme }) => theme.palette.additionalColors.primaryText};
  }

  margin: 2rem 2.4rem;
`;

export const SidebarButton = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2rem 4.8rem;
  width: 4.6rem;
  min-width: 4.6rem;
  height: 4.6rem;
  background: ${({ theme }) => theme.palette.button.primary};
  border-radius: 0.8rem;
  path,
  line {
    stroke: ${({ theme }) => theme.palette.button.text};
  }
  :hover {
    border-radius: 0.8rem;
  }
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 4.8rem;
  transition: padding 300ms;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 2rem 2.4rem;
  }

  > svg {
    margin-right: 5.8rem;
  }

  > button {
    margin: 0;
  }
`;

export const IllustrationWrapper = styled.div`
  height: 100%;
  height: calc(100% * max(1px, calc(32.2rem - 100%)));
  margin-top: auto;
  position: relative;
  overflow: hidden;

  max-height: 37.2rem;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    max-height: 50.4rem;
  }

  > svg {
    position: absolute;
    width: 100%;
    height: auto;
    top: 1.5rem;
    transition: opacity 300ms;
    transition-delay: 50ms;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      display: none;
    }

    ${({ theme }) => theme.breakpoints.down('lg')} {
      transform: scaleX(-1);

      .rni-logo {
        transform: scale(-1, 1) translate(49%, 13%) rotate(38deg);
        transform-origin: center;
      }
    }
  }

  > div {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
`;

export const RegistriesWrapper = styled(List)`
  margin-left: 5.2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0;

  .MuiListItemText-root {
    margin: 0;
  }

  .MuiListItem-root:first-child {
    margin-top: 1.8rem;
  }

  .MuiListItem-root:last-child {
    margin-bottom: 1rem;
  }

  .MuiListItemButton-root {
    ${({ theme }) => useTypography(theme.typography.body1)}
    transition: background-color 300ms;
    border-radius: 1.6rem;
    padding: 0;
    position: relative;
    color: ${({ theme }) => theme.palette.additionalColors.secondaryText};

    :before {
      content: '';
      background: transparent;
      transition: background 300ms;
      width: 0.8rem;
      height: 0.8rem;
      position: absolute;
      border-radius: 50%;
      top: auto;
      bottom: auto;
      left: -3.6rem;
    }

    :hover {
      :before {
        background: ${({ theme }) =>
          theme.palette.additionalColors.secondaryText};
      }
    }
  }

  .Mui-selected {
    color: ${({ theme }) => theme.palette.additionalColors.primaryText};
    :before {
      background: ${({ theme }) => theme.palette.additionalColors.primaryText};
    }

    :hover {
      background: ${({ theme }) =>
        theme.palette.additionalColors.selectedBackground};

      :before {
        background: ${({ theme }) =>
          theme.palette.additionalColors.primaryText};
      }
    }
  }
`;
