import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import Toolbar from '@mui/material/Toolbar';
import {
  DrawerProps,
  Stack,
  useMediaQuery,
  useTheme,
  Typography,
  ButtonBase,
} from '@mui/material';

import { StyleConstants } from 'styles/StyleConstants';
import { useProperlyThemedComponent } from 'hooks/useProperlyThemedComponent';
import { WVULogo, WVULogoDark } from 'assets/logo';
import {
  Setting2 as SettingsIcon,
  Book1 as HelpIcon,
  LogoutCurve as LogoutIcon,
} from 'iconsax-react';
import { CloseIcon, HamburgerIcon } from 'assets/icons';
import { useBooleanState } from 'hooks/useBooleanState';
import { logOut } from 'helpers/logOut';
import { EventNames } from 'types/analytics';

import {
  LogoContainer,
  StyledDrawer,
  CloseButton,
  TopBar,
  PageIconWrapper,
  SidebarButton,
  SidebarLink,
} from './styled';
import { messages } from './messages';
import { ResponsiveIllustration } from './ResponsiveIllustration';
import { RegistriesLinks } from './RegistriesLinks';

const drawerWidth = StyleConstants.SIDE_BAR_WIDTH;

export const SideBar = memo(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const {
    value: drawerOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer,
  } = useBooleanState(false);
  const location = useLocation();

  const ThemedWVULogo = useProperlyThemedComponent({
    light: <WVULogo aria-hidden="true" />,
    dark: <WVULogoDark aria-hidden="true" />,
  });

  const getDrawerContent = () => {
    const isSettingsActive = location.pathname.split('/')[1] === 'settings';
    const isHelpActive = location.pathname.split('/')[1] === 'help';

    return (
      <div>
        <Toolbar />
        <Stack direction="column" justifyContent="flex-start" height="100%">
          {isMobile ? (
            <Stack alignItems="flex-end">
              <CloseButton onClick={closeDrawer} data-cy="close-sidebar-button">
                <CloseIcon />
              </CloseButton>
            </Stack>
          ) : (
            <LogoContainer>
              <WVULogoDark aria-hidden="true" />
            </LogoContainer>
          )}
          <Stack
            gap="2rem"
            marginX={{
              xs: '2.4rem',
              sm: '4.8rem',
              lg: '1.6rem',
              xxl: '2rem',
            }}
            height={{ xs: '100%', sm: 'auto' }}
            paddingBottom={{ xs: '2.4rem', sm: '4.8rem', md: 'unset' }}
            style={{ transition: 'margin 300ms' }}
          >
            <RegistriesLinks onClick={closeDrawer} />
            <SidebarLink
              to="/settings"
              data-cy="registry-settings-link"
              onClick={closeDrawer}
              $active={isSettingsActive}
              {...(isSettingsActive && { 'aria-current': 'page' })}
            >
              <PageIconWrapper active={isSettingsActive}>
                <SettingsIcon variant="Bold" />
              </PageIconWrapper>
              <Typography
                variant="h5"
                color={
                  isSettingsActive
                    ? 'additionalColors.primaryText'
                    : 'additionalColors.secondaryText'
                }
              >
                {t(messages.settings())}
              </Typography>
            </SidebarLink>
            <SidebarLink
              to="/help"
              data-cy="registry-help-link"
              onClick={closeDrawer}
              $active={isHelpActive}
              {...(isHelpActive && { 'aria-current': 'page' })}
            >
              <PageIconWrapper active={isHelpActive}>
                <HelpIcon variant="Bold" />
              </PageIconWrapper>
              <Typography
                variant="h5"
                color={
                  isHelpActive
                    ? 'additionalColors.primaryText'
                    : 'additionalColors.secondaryText'
                }
              >
                {t(messages.help())}
              </Typography>
            </SidebarLink>
            <SidebarLink
              as={ButtonBase}
              data-cy="log-out-button"
              disableRipple
              onClick={() => {
                mixpanel.track(EventNames.MANUALLY_LOGGED_OUT);
                logOut({ saveLocation: false });
              }}
            >
              <PageIconWrapper>
                <LogoutIcon
                  variant="Bold"
                  style={{ transform: 'scale(-1, 1)' }}
                />
              </PageIconWrapper>
              <Typography variant="h5" color={'additionalColors.secondaryText'}>
                {t(messages.logOut())}
              </Typography>
            </SidebarLink>
          </Stack>
          <ResponsiveIllustration />
        </Stack>
      </div>
    );
  };

  const getTopBar = () => {
    if (!isMobile) return null;

    return (
      <TopBar>
        {ThemedWVULogo}
        <SidebarButton onClick={openDrawer} data-cy="open-sidebar-button">
          <HamburgerIcon />
        </SidebarButton>
      </TopBar>
    );
  };

  const getDesktopProps = () =>
    ({
      variant: 'permanent',
      sx: {
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
        },
      },
      open: true,
    } as DrawerProps);

  const getMobileProps = () =>
    ({
      variant: 'temporary',
      open: drawerOpen,
      onClose: closeDrawer,
      anchor: 'right',
      ModalProps: {
        keepMounted: true,
      },
      sx: {
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100%', lg: '42.3rem' },
        },
      },
    } as DrawerProps);

  return (
    <>
      <StyledDrawer {...(isMobile ? getMobileProps() : getDesktopProps())}>
        {getDrawerContent()}
      </StyledDrawer>
      {getTopBar()}
    </>
  );
});
