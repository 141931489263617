import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: ${({ theme }) => theme.palette.background.elevated.primary};
  color: ${({ theme }) => theme.palette.text.primary};

  display: flex;
  flex-direction: column;
`;
