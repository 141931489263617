import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Formik, FormikProps } from 'formik';
import { Select } from 'components/Select';
import { ErrorMessage } from 'components/ErrorMessage';
import { FormikTextField } from 'components/FormikTextField';
import { useValidationSchema } from 'hooks/useValidationSchema';
import { formatPhoneNumber } from 'helpers/phoneNumbers';
import { FormikPhoneInput } from 'components/PhoneInput';
import {
  CaregiverContactDetailsForm,
  CaregiverDetailsForm,
} from '../../../../types';
import { caregiverRelationships } from '../../../../constants';
import { messages } from '../../../../messages';
import { DataRowWrapper } from '../../styled';
import { SettingSection } from '../../SettingsSection';
import { getValidationSchema } from './validators';

interface FormType {
  caregiverDetails: CaregiverDetailsForm;
  caregiverContactDetails: CaregiverContactDetailsForm;
}

interface Props {
  active: boolean;
  disabled: boolean;
  onCancel: () => void;
  onSubmit: (newValues: FormType) => void;
  onEdit: () => void;
  caregiverDetails: CaregiverDetailsForm;
  caregiverContactDetails: CaregiverContactDetailsForm;
}

export const CaregiverDetailsSection = ({
  active,
  disabled,
  onCancel,
  onSubmit,
  onEdit,
  caregiverDetails,
  caregiverContactDetails,
}: Props) => {
  const { t } = useTranslation();
  const validationSchema = useValidationSchema(getValidationSchema);

  const details = (
    <Stack direction="column" spacing="0.6rem">
      <DataRowWrapper>
        <div>{t(messages.firstName())}</div>
        <div>{caregiverDetails.firstName}</div>
      </DataRowWrapper>
      <DataRowWrapper>
        <div>{t(messages.lastName())}</div>
        <div>{caregiverDetails.lastName}</div>
      </DataRowWrapper>
      <DataRowWrapper>
        <div>{t(messages.relationshipWithMember())}</div>
        <div>{caregiverDetails.relationship}</div>
      </DataRowWrapper>
      <DataRowWrapper>
        <div>{t(messages.email())}</div>
        <div>{caregiverContactDetails.email}</div>
      </DataRowWrapper>
      <DataRowWrapper>
        <div>{t(messages.phoneNumber())}</div>
        <div>
          {caregiverContactDetails.phone
            ? formatPhoneNumber(caregiverContactDetails.phone)
            : '-'}
        </div>
      </DataRowWrapper>
    </Stack>
  );

  const form = ({
    values,
    setFieldValue,
    errors,
    touched,
  }: FormikProps<FormType>) => (
    <Stack direction="column" spacing="1.6rem">
      <FormikTextField
        name="caregiverDetails.firstName"
        label={t(messages.firstName())}
        validationSchema={validationSchema}
        oneWayValidationOnChange={true}
        placeholder={t(messages.firstName())}
        data-cy="caregiver-first-name-input"
      />
      <FormikTextField
        name="caregiverDetails.lastName"
        label={t(messages.lastName())}
        validationSchema={validationSchema}
        oneWayValidationOnChange={true}
        placeholder={t(messages.lastName())}
        data-cy="caregiver-last-name-input"
      />
      <div>
        <Typography variant="subtitle1" textTransform="uppercase">
          {t(messages.relationshipWithMember())}
        </Typography>
        <Select
          changeValue={value =>
            setFieldValue('caregiverDetails.relationship', value, true)
          }
          values={caregiverRelationships.map(relationship => ({
            value: relationship,
            label: relationship,
          }))}
          capitalizeFirstLetter
          value={values.caregiverDetails.relationship || undefined}
          placeholder={t(messages.select())}
          aria-invalid={
            errors.caregiverDetails?.relationship &&
            touched.caregiverDetails?.relationship
              ? 'true'
              : 'false'
          }
          data-cy="relationship-input"
        />
        <ErrorMessage
          message={errors.caregiverDetails?.relationship}
          visible={Boolean(
            errors.caregiverDetails?.relationship &&
              touched.caregiverDetails?.relationship,
          )}
          style={{ marginTop: '0.8rem' }}
          data-cy={`relationship-input-error`}
        />
      </div>
      <FormikTextField
        name="caregiverContactDetails.email"
        type="email"
        placeholder={t(messages.email())}
        validationSchema={validationSchema}
        oneWayValidationOnChange={true}
        data-cy="email-input"
        label={t(messages.email())}
      />
      <FormikPhoneInput
        name="caregiverContactDetails.phone"
        placeholder={t(messages.phoneNumber())}
        validationSchema={validationSchema}
        oneWayValidationOnChange={true}
        data-cy="phone-number-input"
        label={`${t(messages.phoneNumber())} (${t(messages.optional())})`}
      />
    </Stack>
  );

  return (
    <Formik
      initialValues={{ caregiverDetails, caregiverContactDetails }}
      onSubmit={values => onSubmit(values)}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      {formikHelpers => (
        <SettingSection
          disabled={disabled}
          active={active}
          onCancel={() => {
            onCancel();
            formikHelpers.resetForm();
          }}
          onEdit={onEdit}
          sectionName={t(messages.caregiverDetails())}
        >
          {active ? form(formikHelpers) : details}
        </SettingSection>
      )}
    </Formik>
  );
};
