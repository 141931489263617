import styled from 'styled-components';
import { useTypography } from 'helpers/mixins';

export const FormikTextFieldLabel = styled.label`
  ${({ theme }) => useTypography(theme.typography.subtitle1)}
  margin: 0 0 0.4rem 0;
  padding: 0;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
