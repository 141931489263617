import { useState } from 'react';
import {
  Typography,
  Stack,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add, Minus } from 'iconsax-react';
import mixpanel from 'mixpanel-browser';
import { EventNames } from 'types/analytics';
import { messages } from '../../messages';
import { PanelContainer, ShowLessMoreButton } from './styled';

interface Props {
  questionsAndAnswers?: {
    question: string;
    answer: string;
  }[];
  registryId?: string;
  registryCode?: string;
}

export const FrequentlyAskedQuestions = ({
  questionsAndAnswers,
  registryId,
  registryCode,
}: Props) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<number | null>(0);

  const handleChange =
    (panelIndex: number) =>
    (_e: React.SyntheticEvent, nowExpanded: boolean) => {
      if (nowExpanded) {
        setExpanded(panelIndex);
        mixpanel.track(EventNames.REGISTRY_FAQ_EXPANDED, {
          registry_code: registryCode,
          registry_id: registryId,
        });
      } else setExpanded(null);
    };

  if (!questionsAndAnswers?.length) return null;
  return (
    <PanelContainer>
      <Stack
        width="100%"
        maxWidth="107.6rem"
        alignItems="center"
        gap={{ lg: '7.6rem', xs: '4rem' }}
      >
        <Stack maxWidth="85.6rem" alignItems="center" gap="1.6rem" width="100%">
          <Typography variant="h1" color="additionalColors.primaryText">
            {t(messages.frequentlyAskedQuestions())}
          </Typography>
          {(showAll
            ? questionsAndAnswers
            : questionsAndAnswers.slice(0, 6)
          ).map(({ question, answer }, i) => (
            <Accordion
              key={i}
              expanded={expanded === i}
              onChange={handleChange(i)}
              variant="flat"
            >
              <AccordionSummary
                expandIcon={expanded === i ? <Minus /> : <Add />}
              >
                {question}
              </AccordionSummary>
              <AccordionDetails>{answer}</AccordionDetails>
            </Accordion>
          ))}
          {questionsAndAnswers.length > 8 && (
            <ShowLessMoreButton
              variant="text"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll
                ? t(messages.showLessQuestions())
                : t(messages.showAllQuestions())}
            </ShowLessMoreButton>
          )}
        </Stack>
      </Stack>
    </PanelContainer>
  );
};
