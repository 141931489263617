import { healthcareProviders } from './healthcareProviders';
import { publicRegistries } from './publicRegistries';
import { publicRegistry } from './publicRegistry';
import { registries } from './registries';
import { registry } from './registry';
import { registryPersons } from './registryPersons';
import { survey } from './survey';
import { userAgreements } from './userAgreements';
import { userEmail } from './userEmail';
import { userRegistryNotifications } from './userRegistryNotifications';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  healthcareProviders,
  publicRegistries,
  publicRegistry,
  registries,
  registry,
  registryPersons,
  survey,
  userAgreements,
  userEmail,
  userRegistryNotifications,
};
