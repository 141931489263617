import { memo, useEffect, useState, useId } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import Api from 'api/api';
import { queryKeys } from 'api/queryKeys';
import { PasswordInput } from 'components/PasswordInput';
import { PasswordValidator } from 'components/PasswordValidator';
import { useValidationSchema } from 'hooks/useValidationSchema';
import { errorToast, infoToast } from 'helpers/toast';
import { Checkbox } from 'components/Checkbox';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingPage } from 'pages/Loading';

import { TermsAndConditions } from 'components/TermsAndConditions';
import { messages } from '../../messages';
import { getValidationSchema } from './validators';
import { Tooltip } from '../../../../components/Tooltip';
import { AlreadyHaveAccountContainer } from './styled';

interface FormTypes {
  password: string;
  agreedToTermsAndConditions: boolean;
}

interface Props {
  onLogin(): void;
  onAutologin(user: CognitoUserInterface): void;
}

export const SetUpPasswordStep = memo(({ onLogin, onAutologin }: Props) => {
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const registryId = searchParams.get('rid');
  const participationId = searchParams.get('pid');
  const emailHash = searchParams.get('eha');
  const validationSchema = useValidationSchema(getValidationSchema);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const passwordValidatorId = useId();

  const { data, isLoading: fetchingIsLoading } = useQuery({
    queryKey: queryKeys.userEmail({
      registryId: registryId!,
      participationId: participationId!,
      emailHash: emailHash!,
    }),
    queryFn: () => {
      if (registryId && participationId && emailHash)
        return Api.getRegistrationEmail({
          registryId,
          participationId,
          emailHash,
        });
    },
    enabled: Boolean(registryId && participationId && emailHash),
    staleTime: 5 * 60 * 1000,
    onError: error => {
      const message =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error as any).response?.data?.message ?? (error as any).message;
      if (message) errorToast(message);
      onLogin();
    },
  });

  const { mutate } = useMutation<
    ISignUpResult,
    Record<string, unknown>,
    { password: string }
  >({
    mutationFn: async ({ password }) => {
      setShowLoader(true);
      return Auth.signUp({
        username: data!.email,
        password,
        attributes: {
          email: data!.email,
        },
        autoSignIn: {
          enabled: true,
        },
        validationData: {
          registrySignUpNoConfirmationCode: `rid=${registryId}&pid=${participationId}&eha=${emailHash}`,
        },
      });
    },
  });

  useEffect(() => {
    const hubListenerCancelToken = Hub.listen('auth', ({ payload }) => {
      const { event } = payload;

      if (event === 'autoSignIn') {
        const user = payload.data;
        onAutologin(user);
      } else if (event === 'autoSignIn_failure') {
        setShowLoader(false);
        switch (payload.data?.code) {
          case 'UsernameExistsException':
            infoToast(t(messages.accountAlreadyExists()), {
              position: 'top-right',
            });
            onLogin();
            break;
          default:
            errorToast(t(messages.errorOccurredTryAgain()), {
              position: 'top-right',
            });
        }
      }
    });

    return hubListenerCancelToken;
  }, [onAutologin, onLogin]);

  useEffect(() => {
    if (!(registryId && participationId && emailHash)) onLogin();
  }, [registryId, participationId, emailHash]);

  const submitForm = ({ password }: FormTypes) => {
    mutate(
      { password },
      {
        onError: e => {
          setShowLoader(false);
          switch (e.code) {
            case 'UsernameExistsException':
              infoToast(t(messages.accountAlreadyExists()), {
                position: 'top-right',
              });
              onLogin();
              break;
            default:
              errorToast(t(messages.errorOccurredTryAgain()), {
                position: 'top-right',
              });
          }
        },
      },
    );
  };

  if (fetchingIsLoading) return <LoadingPage />;

  return (
    <Formik
      initialValues={
        { password: '', agreedToTermsAndConditions: false } as FormTypes
      }
      onSubmit={submitForm}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form data-cy="set-up-password-step">
          <Stack spacing="2.4rem" direction="column" justifyContent="center">
            <Typography variant="h1">
              {t(messages.createAnAccount())}
            </Typography>
            {isMobile || (
              <Typography variant="body1" style={{ marginTop: '1.6rem' }}>
                {t(messages.setUpPasswordDescription())}
              </Typography>
            )}
            <Tooltip title={data?.email as string} showTooltipOnlyOnOverflow>
              <Typography
                variant="h5"
                color="text.secondary"
                style={{ marginTop: '1.6rem' }}
              >
                {data?.email}
              </Typography>
            </Tooltip>
            <PasswordInput
              name="password"
              validationSchema={validationSchema}
              oneWayValidationOnChange={true}
              disableError
              data-cy="password-input"
              label={t(messages.password())}
              inputProps={{
                'aria-describedby': passwordValidatorId,
              }}
            />
            <PasswordValidator
              password={values.password}
              id={passwordValidatorId}
            />
            <Checkbox
              data-cy="terms-and-conditions-checkbox"
              label={
                <span>
                  {t(messages.iAgreeTo())}
                  <TermsAndConditions />
                </span>
              }
              checked={values.agreedToTermsAndConditions}
              onChange={e => {
                setFieldValue(
                  'agreedToTermsAndConditions',
                  e.target.checked,
                  true,
                );
              }}
              style={{ marginLeft: '-0.9rem' }}
            />
            <ErrorMessage
              data-cy="terms-and-conditions-validation-error-message"
              message={errors.agreedToTermsAndConditions}
              visible={Boolean(
                errors.agreedToTermsAndConditions &&
                  touched.agreedToTermsAndConditions,
              )}
              style={{ marginTop: '1.2rem' }}
            />
            <LoadingButton
              type="submit"
              loading={showLoader}
              color="primary"
              variant="contained"
              data-cy="create-account-submit-button"
            >
              {t(messages.createAccount())}
            </LoadingButton>
            <Stack direction="row" justifyContent="center">
              <AlreadyHaveAccountContainer>
                <div>{t(messages.alreadyHaveAccount())}</div>
                <Button
                  variant="text"
                  onClick={onLogin}
                  data-cy="already-have-account-button"
                >
                  {t(messages.login())}
                </Button>
              </AlreadyHaveAccountContainer>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
});
