import { useId, InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import {
  DesktopDatePicker as DateField,
  DesktopDatePickerProps as DateFieldProps,
} from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import { ErrorMessage } from 'components/ErrorMessage/index';
import { FormikTextFieldLabel } from 'components/FormikTextField/styled';

interface BaseProps<TInputDate, TDate>
  extends Omit<
    DateFieldProps<TInputDate, TDate>,
    'onChange' | 'renderInput' | 'value'
  > {
  name: string;
  disableError?: boolean;
  'data-cy'?: string;
  label?: string;
  oneWayValidationOnChange?: false;
  validationSchema?: undefined;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
}

type PropsWithOneWayValidation<TInputDate, TDate> = Omit<
  BaseProps<TInputDate, TDate>,
  'oneWayValidationOnChange' | 'validationSchema'
> & {
  oneWayValidationOnChange: true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema: yup.ObjectSchema<any>;
};

export type FormikDateFieldProps<TInputDate, TDate> =
  | BaseProps<TInputDate, TDate>
  | PropsWithOneWayValidation<TInputDate, TDate>;

export const FormikDateField = <TInputDate, TDate>({
  name,
  disableError = false,
  oneWayValidationOnChange,
  validationSchema,
  label,
  'data-cy': dataCy,
  ...otherProps
}: FormikDateFieldProps<TInputDate, TDate>) => {
  const fieldErrorId = useId();
  const [{ onChange, value, ...field }, meta, helpers] = useField(name);

  const updateValue = (newValue: TDate | null) => {
    helpers.setValue(newValue, false);
  };

  const customOnChange = async (newValue: TDate | null) => {
    updateValue(newValue);

    if (!validationSchema) return;
    if (meta.error) {
      validationSchema
        .validateAt(field.name, {
          [field.name]: newValue,
        })
        .then(() => {
          helpers.setError(undefined);
        })
        // suppress error
        .catch(() => {});
    }
  };

  const configDateField = {
    ...otherProps,
    onChange: oneWayValidationOnChange ? customOnChange : updateValue,
  };

  const isError = Boolean(meta && meta.touched && meta.error);

  const notNullDataCy = dataCy ?? `${field.name}-input`;

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {label && (
        <FormikTextFieldLabel htmlFor={notNullDataCy}>
          {label}
        </FormikTextFieldLabel>
      )}
      <DateField
        {...configDateField}
        data-cy={`${notNullDataCy}-container`}
        disableOpenPicker={true}
        value={value}
        renderInput={params => (
          <TextField
            {...field}
            inputProps={{
              'aria-invalid': isError ? 'true' : 'false',
              'aria-errormessage': fieldErrorId,
              'data-cy': `${notNullDataCy}`,
              id: notNullDataCy,
              ...otherProps.inputProps,
            }}
            {...params}
          />
        )}
        InputProps={{
          ...otherProps.InputProps,
          className: `${isError ? 'InvalidInputValue' : ''} ${
            otherProps.InputProps?.className ?? ''
          }`,
        }}
      />
      <ErrorMessage
        message={meta.error}
        visible={!disableError && isError}
        style={{ marginTop: '0.8rem' }}
        data-cy={`${notNullDataCy}-error`}
        id={fieldErrorId}
      />
    </div>
  );
};
