import { createTheme } from '@mui/material/styles';
import { lightPalette, darkPalette } from './colors';
import { typography } from './fonts';
import { breakpoints } from './breakpoints';
import { components } from './components';

const spacing = (factor: number) =>
  `${Math.min(factor === 0 ? 0 : 0.1 * 2 ** (factor + 1), 64)}rem`;

const lightTheme = createTheme({
  palette: lightPalette,
  spacing,
  breakpoints,
  components,
  typography: typography(lightPalette, breakpoints),
});

const darkTheme = createTheme({
  palette: darkPalette,
  spacing,
  breakpoints,
  components,
  typography: typography(darkPalette, breakpoints),
});

export type Theme = typeof lightTheme;

export const themes = {
  light: lightTheme,
  dark: darkTheme,
};
