import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useValidationSchema = <T extends yup.ObjectSchema<any>>(
  getValidationSchema: () => T,
): T => {
  const { t } = useTranslation();

  return useMemo(() => getValidationSchema(), [t]);
};
