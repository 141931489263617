import {
  CaregiverDetailsForm,
  CaregiverContactDetailsForm,
  GlobalFormState,
  QualificationSteps,
  QualificationStepsTransitions,
  MemberDetailsForm,
  CaregiverRelationships,
  AccountDetailsForm,
  RoleSelectionForm,
  ConfirmMemberApplicationForm,
  AgreementsForm,
} from './types';

export const FIRST_STEP = QualificationSteps.ROLE_SELECTION;

const getWelcomePageNext = (
  role: string,
  onlySingleRoleAvailable: boolean,
): QualificationSteps => {
  if (onlySingleRoleAvailable) {
    if (role === 'caregiver') {
      return QualificationSteps.CAREGIVER_DETAILS;
    }

    return QualificationSteps.MEMBER_DETAILS;
  }

  return QualificationSteps.ROLE_SELECTION;
};

const getMemberDetailsPrev = (
  role: string,
  onlySingleRoleAvailable: boolean,
): QualificationSteps => {
  if (onlySingleRoleAvailable) {
    if (role === 'caregiver') {
      return QualificationSteps.CAREGIVER_CONTACT_DETAILS;
    }

    return QualificationSteps.WELCOME_PAGE;
  }

  if (role === 'caregiver') {
    return QualificationSteps.CAREGIVER_CONTACT_DETAILS;
  }

  return QualificationSteps.ROLE_SELECTION;
};

export const getStepMachine = (
  role: string,
  onlySingleRoleAvailable: boolean,
  noAgreements: boolean,
): QualificationStepsTransitions => {
  const stepsTransitions = {} as {
    [value in QualificationSteps]?: {
      previous: QualificationSteps;
      next: QualificationSteps;
    };
  };

  stepsTransitions[QualificationSteps.WELCOME_PAGE] = {
    previous: QualificationSteps.WELCOME_PAGE,
    next: getWelcomePageNext(role, onlySingleRoleAvailable),
  };

  stepsTransitions[QualificationSteps.ROLE_SELECTION] = {
    previous: QualificationSteps.WELCOME_PAGE,
    next:
      role === 'caregiver'
        ? QualificationSteps.CAREGIVER_DETAILS
        : QualificationSteps.MEMBER_DETAILS,
  };

  stepsTransitions[QualificationSteps.CAREGIVER_DETAILS] = {
    previous: onlySingleRoleAvailable
      ? QualificationSteps.WELCOME_PAGE
      : QualificationSteps.ROLE_SELECTION,
    next: QualificationSteps.CAREGIVER_CONTACT_DETAILS,
  };

  stepsTransitions[QualificationSteps.CAREGIVER_CONTACT_DETAILS] = {
    previous: QualificationSteps.CAREGIVER_DETAILS,
    next: QualificationSteps.MEMBER_DETAILS,
  };

  stepsTransitions[QualificationSteps.MEMBER_DETAILS] = {
    previous: getMemberDetailsPrev(role, onlySingleRoleAvailable),
    next: QualificationSteps.ACCOUNT_DETAILS,
  };

  stepsTransitions[QualificationSteps.ACCOUNT_DETAILS] = {
    previous: QualificationSteps.MEMBER_DETAILS,
    next: noAgreements
      ? QualificationSteps.CONFIRM_APPLICATION
      : QualificationSteps.AGREEMENTS,
  };

  stepsTransitions[QualificationSteps.AGREEMENTS] = {
    previous: QualificationSteps.ACCOUNT_DETAILS,
    next: QualificationSteps.CONFIRM_APPLICATION,
  };

  stepsTransitions[QualificationSteps.CONFIRM_APPLICATION] = {
    previous: noAgreements
      ? QualificationSteps.ACCOUNT_DETAILS
      : QualificationSteps.AGREEMENTS,
    next: QualificationSteps.SURVEY_START,
  };

  stepsTransitions[QualificationSteps.SURVEY_START] = {
    previous: QualificationSteps.WELCOME_PAGE,
    next: QualificationSteps.QUALIFICATION_SURVEY,
  };

  stepsTransitions[QualificationSteps.QUALIFICATION_SURVEY] = {
    previous: QualificationSteps.QUALIFICATION_SURVEY,
    next: QualificationSteps.NEXT_STEP,
  };

  stepsTransitions[QualificationSteps.NEXT_STEP] = {
    previous: QualificationSteps.WELCOME_PAGE,
    next: QualificationSteps.WELCOME_PAGE,
  };

  return stepsTransitions;
};

export const caregiverRelationships: CaregiverRelationships[] = [
  'spouse',
  'sibling',
  'child',
  'grandchild',
  'parent',
];

export const initialCaregiverDetailsForm: CaregiverDetailsForm = {
  firstName: '',
  lastName: '',
  relationship: '',
};

export const initialCaregiverContactDetailsForm: CaregiverContactDetailsForm = {
  email: '',
  phone: '',
};

export const initialMemberDetailsForm: MemberDetailsForm = {
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  phone: '',
};

export const initialRoleSelectionForm: RoleSelectionForm = {
  role: {
    code: '',
    id: '',
    name: '',
    perspective: 'first',
    agreements: [],
  },
};

export const initialAccountDetailsForm: AccountDetailsForm = {
  email: '',
};

export const initialAgreementsForm: AgreementsForm = { agreements: [] };

export const initialConfirmMemberApplicationForm: ConfirmMemberApplicationForm =
  {
    participationId: '',
  };

export const initialGlobalState: GlobalFormState = {
  [QualificationSteps.MEMBER_DETAILS]: initialMemberDetailsForm,
  [QualificationSteps.CAREGIVER_DETAILS]: initialCaregiverDetailsForm,
  [QualificationSteps.CAREGIVER_CONTACT_DETAILS]:
    initialCaregiverContactDetailsForm,
  [QualificationSteps.ROLE_SELECTION]: initialRoleSelectionForm,
  [QualificationSteps.ACCOUNT_DETAILS]: initialAccountDetailsForm,
  [QualificationSteps.AGREEMENTS]: initialAgreementsForm,
  [QualificationSteps.CONFIRM_APPLICATION]: initialConfirmMemberApplicationForm,
};
