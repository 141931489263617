import { useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
let __PAGE_FIRST_LOAD__ = true;

export const useAutofocus = (enable = true, dependency = [] as unknown[]) => {
  const focusElement = useCallback((ref: HTMLElement | null) => {
    // changing active element on first load
    // prevents reader from reading whole page
    if (__PAGE_FIRST_LOAD__) {
      __PAGE_FIRST_LOAD__ = false;
      return;
    }

    // missing prioperty in type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref?.focus({ focusVisible: false });
  }, dependency);

  return {
    tabIndex: -1,
    ref: enable ? focusElement : undefined,
  };
};
