import styled from 'styled-components';
import { ButtonBase } from '@mui/material';

export const RoleTileWrapper = styled(ButtonBase)<{ $selected: boolean }>`
  position: relative;
  width: 100%;
  padding: 2.4rem 0.8rem;
  overflow: hidden;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid ${({ theme }) => theme.palette.elements.separator};
  background: ${({ theme }) => theme.palette.elements.secondary};
  transition: border 0.3s;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 12.6rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 2rem 2.4rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    transition: transform 0.3s;
    width: 100%;

    > svg {
      width: 2.8rem;
      min-width: 2.8rem;
      height: 2.8rem;
      color: ${({ theme }) => theme.palette.elements.primary};
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      flex-direction: row;
      justify-content: start;
    }
  }

  .hover-effect {
    transform: translateY(100%);
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;

    ${({ theme }) => theme.breakpoints.down('lg')} {
      display: none;
    }
  }

  :hover {
    border-color: ${({ theme }) => theme.palette.elements.primary};

    ${({ theme }) => theme.breakpoints.up('lg')} {
      .hover-effect {
        opacity: 1;
        transform: translateY(0);
      }

      > div {
        transform: translateY(-1.4rem);
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    ${({ $selected, theme }) =>
      $selected &&
      `
		border-color: ${theme.palette.elements.primary};
			`}
  }
`;

export const ButtonLabel = styled.span`
  position: absolute;
  bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  font-size: 1.2354rem;
  width: 100%;

  * {
    color: ${({ theme }) => theme.palette.elements.primary};
  }

  > svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const RolesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  width: 100%;
  margin-top: 1.2rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    gap: 2rem;
  }
`;
