export enum GlobalKeys {
  FORCE_REDIRECT = 'GLOBAL_FORCE_REDIRECT',
}

interface GlobalKeysValues {
  [GlobalKeys.FORCE_REDIRECT]?: boolean;
}

export const setGlobalVariable = <T extends GlobalKeys>(
  key: T,
  value: GlobalKeysValues[T],
) => {
  (window as unknown as Record<string, unknown>)[key] = value;
};

export const getGlobalVariable = <T extends GlobalKeys>(
  key: T,
): GlobalKeysValues[T] => {
  return (window as unknown as Record<T, GlobalKeysValues[T]>)[key];
};
