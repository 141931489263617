import { memo, useCallback } from 'react';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useBooleanState } from 'hooks/useBooleanState';
import { getMaskedEmail } from 'helpers/user';
import { EventNames, SettingsEventActions } from 'types/analytics';
import { ChangePasswordModal } from './ChangePasswordModal';
import { messages } from '../messages';
import { EmailStack, PasswordStack, SettingsDivider, Wrapper } from '../styled';

export const PersonalDetailsSection = memo(() => {
  const { t } = useTranslation();
  const {
    value: isOpen,
    setTrue: onOpen,
    setFalse: onClose,
  } = useBooleanState();

  const handleOpen = useCallback(() => {
    mixpanel.track(EventNames.SETTINGS_PAGE, {
      action: SettingsEventActions.OPEN_PASSWORD_MANAGEMENT_MODAL,
    });

    onOpen();
  }, [onOpen]);

  const getMaskedPassword = (): string => {
    return '*'.repeat(14);
  };

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <ChangePasswordModal open={isOpen} onClose={onClose} />
      <Stack gap="1.6rem">
        <Typography variant="h3">{t(messages.personalDetails())}</Typography>
        <Wrapper>
          <EmailStack>
            <Typography variant="h5" fontSize="1.4rem" width="18rem">
              {t(messages.emailAddress())}
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              style={{ overflowWrap: 'anywhere' }}
            >
              {getMaskedEmail()}
            </Typography>
          </EmailStack>
          <SettingsDivider />
          {isTablet ? (
            <PasswordStack>
              <Stack gap="0.8rem">
                <Typography
                  variant="h5"
                  fontSize="1.4rem"
                  maxWidth="18rem"
                  width="100%"
                >
                  {t(messages.password())}
                </Typography>
                <Typography variant="h5" fontSize="1.4rem" width="100%">
                  {getMaskedPassword()}
                </Typography>
              </Stack>
              <Button variant="text" onClick={handleOpen}>
                {isMobile ? t(messages.change()) : t(messages.changePassword())}
              </Button>
            </PasswordStack>
          ) : (
            <PasswordStack>
              <Typography
                variant="h5"
                fontSize="1.4rem"
                maxWidth="18rem"
                width="100%"
              >
                {t(messages.password())}
              </Typography>
              <Typography variant="h5" fontSize="1.4rem" width="100%">
                {getMaskedPassword()}
              </Typography>
              <Button variant="text" onClick={handleOpen}>
                {t(messages.changePassword())}
              </Button>
            </PasswordStack>
          )}
        </Wrapper>
      </Stack>
    </>
  );
});
