import { useEffect, Suspense } from 'react';
import { lazily } from 'react-lazily';
import mixpanel from 'mixpanel-browser';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Amplify } from '@aws-amplify/core';
import WebFont from 'webfontloader';
import { ToastContainer } from 'material-react-toastify';
import CssBaseline from '@mui/material/CssBaseline';
import { GlobalStyle } from 'styles/global-styles';
import { useSetWindowHeightVariable } from 'hooks/useSetWindowHeightVariable';
import { ProtectedRoute } from 'components/PrivateRoute';
import { CloseToastButton } from 'components/CloseToastButton';
import { MouseDetector } from 'components/MouseDetector';
import { LocationTracker } from 'components/LocationTracker';
import { LoadingPage } from 'pages/Loading';
import { LoginPage } from 'pages/Login';
import { SurveyPage } from 'pages/Survey';
import { logo_url, logoUAT_url } from 'assets/logo';
import Config from 'config';
import Session from 'api/storage/session';
import { PageWrapper } from 'components/PageWrapper';
import { RegistriesList } from 'pages/RegistriesList';
import { SettingsPage } from 'pages/Settings';
import { HelpPage } from 'pages/Help';
import { RegistryPage } from 'pages/Registry';
import { MainPage } from 'pages/MainPage';
import { QualificationPage } from 'pages/Qualification';
import { NotFoundPage } from 'pages/NotFound';
import { IntroPage } from 'pages/IntroPage';
import { HealthcareProvidersCallback } from 'pages/Callbacks/HealthcareProviders';

import { messages } from './messages';

const { DeleteAccount } = lazily(() => import('pages/Mobile/DeleteAccount'));

Amplify.configure(Config.get().amplify);

WebFont.load({
  custom: {
    families: [
      'AvertaBlack',
      'AvertaBold',
      'AvertaExtraBold',
      'AvertaExtraThin',
      'AvertaLight',
      'AvertaRegular',
      'AvertaSemiBold',
      'AvertaThin',
    ],
  },
});

const PageWithSideBar = () => {
  return (
    <PageWrapper>
      <Outlet />
    </PageWrapper>
  );
};

export default function App() {
  const { i18n, t } = useTranslation();
  useSetWindowHeightVariable();

  useEffect(() => {
    const token = Session.decodeToken();

    if (token) mixpanel.identify(token['custom:userId']);
  }, []);

  return (
    <>
      <GlobalStyle />
      <CssBaseline />
      <MouseDetector />
      <LocationTracker />
      <Helmet
        titleTemplate={t(messages.titleTemplate())}
        defaultTitle={t(messages.defaultTitle())}
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content={t(messages.description())} />
        <link
          rel="icon"
          type="image/x-icon"
          href={Config.getEnvKey() === 'uat' ? logoUAT_url : logo_url}
        />
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        closeButton={({ closeToast }) => (
          <CloseToastButton timeout={5000} onClick={closeToast} />
        )}
      />
      <Routes>
        <Route
          path="/loading"
          element={
            <ProtectedRoute>
              <LoadingPage />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<RegistriesList />} />
        <Route
          path="/mobile/delete-account"
          element={
            <Suspense fallback={<>...</>}>
              <DeleteAccount />
            </Suspense>
          }
        />
        <Route path="/login" element={<LoginPage route="login" />} />
        <Route path="/register" element={<LoginPage route="register" />} />
        <Route path="/registry" element={<RegistriesList />} />
        <Route
          path="/registry/:registryCode/intro-page"
          element={<IntroPage />}
        />
        <Route
          path="/registry/:registryCode/:step"
          element={<QualificationPage />}
        />
        <Route path="/registry/:registryCode/" element={<IntroPage />} />
        <Route element={<PageWithSideBar />}>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <MainPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registries"
            element={
              <ProtectedRoute>
                <MainPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registries/:id/:tab?"
            element={
              <ProtectedRoute>
                <RegistryPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/loading"
            element={
              <ProtectedRoute>
                <LoadingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <SettingsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/help"
            element={
              <ProtectedRoute>
                <HelpPage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/registries/:registryId/survey/:surveyId"
          element={
            <ProtectedRoute>
              <SurveyPage qualificationSurvey={false} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/registries/:registryId/survey/:surveyId/select-role"
          element={
            <ProtectedRoute>
              <SurveyPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/registries/:registryId/qualification-survey/:surveyId"
          element={
            <ProtectedRoute>
              <SurveyPage qualificationSurvey={true} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/registries/:registryId/qualification-survey/:surveyId/select-role"
          element={
            <ProtectedRoute>
              <SurveyPage qualificationSurvey={true} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/callbacks/healthcare-providers"
          element={<HealthcareProvidersCallback />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}
