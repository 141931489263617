import { Tooltip } from './index';

export const wrapInTooltip = (
  component: React.ReactElement,
  title?: string,
  disabledTitle?: string,
  disabled?: boolean,
) => {
  let message = disabled ? disabledTitle : title;
  message ??= title;

  if (!message) return component;
  return (
    <Tooltip title={message} placement="top">
      <span>{component}</span>
    </Tooltip>
  );
};
