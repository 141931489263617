import axios, { AxiosInstance } from 'axios';
import { Auth } from '@aws-amplify/auth';
import { secondsToMilliseconds, minutesToMilliseconds } from 'date-fns';
import Config from 'config/index';
import { logOut } from 'helpers/logOut';
import {
  GetRegistriesResponse,
  GetRegistryResponse,
  GetRegistrySurveyResponse,
  SubmitQualificationSurveyResponse,
  GetRegistrationEmailResponse,
  GetPublicRegistriesResponse,
  GetPublicRegistryResponse,
  GetAgreementsResponse,
  GetHealthcareProvidersResponse,
  GetRegistryNotificationsResponse,
  GetRegistryPersonsResponse,
} from './interfaces/response';
import {
  SetQualificationBaselineStatusRequest,
  GetRegistrySurveyRequest,
  AddRepositorySurveyResponseRequest,
  GetRegistrationEmailRequest,
  AddPublicQualificationSurveyResponseRequest,
  UpdateRegistryAgreements,
  ConnectHealthcareProviderRequest,
  UpdateRegistryNotificationsRequest,
  GetRegistryPersonsRequest,
  AddRegistryPersonRequest,
  DeleteRegistryPersonRequest,
} from './interfaces/request';
import Session from './storage/session';
import { AddRegistryParticipationResponse } from './interfaces/response/addRegistryParticipationResponse';
import { AddRegistryParticipationRequest } from './interfaces/request/addRegistryParticipationRequest';

interface ClientOptions {
  publicEndpoint?: boolean;
}

export default class Api {
  public static createClient({
    publicEndpoint = false,
  }: ClientOptions = {}): AxiosInstance {
    const headers = Api.getHeaders();

    const instance = axios.create({
      baseURL: Config.get().apiUrl,
      timeout: Config.get().apiTimeout ?? 30000,
      headers,
    });

    // Disable refreshing for test env
    if (!Config.isTestEnv()) {
      // Before sending request check whether user session is still active
      // If not, automatically clear user session
      // If yes, send request and extend user session
      instance.interceptors.request.use(async config => {
        const isSessionActive = await Session.isSessionActive();
        const decodedToken = Session.decodeToken();

        if (isSessionActive && decodedToken) {
          const expTime = secondsToMilliseconds(decodedToken.exp);

          // if time to expire is less than 3 minutes
          // try to refresh access token
          if (expTime - new Date().getTime() < minutesToMilliseconds(3)) {
            await Session.refreshCognitoSession();
          }

          // logout only for non-public endpoints
        } else if (!publicEndpoint) {
          logOut();
        }
        return config;
      });

      // When API returns 401, automatically clear user session
      instance.interceptors.response.use(
        response => response,
        error => {
          if (error.response && error.response.status === 401) {
            window.location.assign('/loading');
            Session.clearSession();
            window.location.reload();
          }
          return Promise.reject(error);
        },
      );
    }

    return instance;
  }

  public static getHeaders() {
    const headers = {
      'Content-Type': 'application/json',
    } as Record<string, string | number | boolean>;
    const token = Session.getSessionToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return headers;
  }

  public static async checkIfUserExists(email: string) {
    try {
      await Auth.signIn(email, 'fake'); // fake password
      throw new Error('');

      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (authError: any) {
      switch (authError?.code) {
        case 'UserNotFoundException':
          // we can only be sure that the email does not exist
          // if this exception is thrown
          return false;
        case 'LimitExceededException':
        case 'UserNotConfirmedException':
          throw authError;
        default:
      }

      // therefore we return true by default
      // to not block the user on the log in screen in case of unexpected error
      return true;
    }
  }

  public static async getRegistries(): Promise<GetRegistriesResponse> {
    const response = await Api.createClient().get('/registry/registries');

    return response?.data;
  }

  public static async getRegistry(id: string): Promise<GetRegistryResponse> {
    const { data } = await Api.createClient().get<GetRegistryResponse>(
      `/registry/registries/${id}`,
    );
    const sortedData = {
      ...data,
      surveys: data.surveys.sort((a, b) => a.priority - b.priority),
    };
    return sortedData;
  }

  public static async getPublicRegistry(
    code: string,
  ): Promise<GetPublicRegistryResponse> {
    const response = await Api.createClient({ publicEndpoint: true }).get(
      `public/registry/registries/${code}`,
    );
    return response?.data;
  }

  public static async getPublicRegistries(): Promise<GetPublicRegistriesResponse> {
    const response = await Api.createClient({ publicEndpoint: true }).get(
      `public/registry/registries`,
    );
    return response?.data;
  }

  public static async setQualificationBaselineStatus({
    registryId,
    registryParticipationId,
    status,
  }: SetQualificationBaselineStatusRequest): Promise<void> {
    const response = await Api.createClient().patch(
      `/registry/registries/${registryId}/participations/${registryParticipationId}`,
      { status },
    );
    return response?.data;
  }

  public static async getRegistrySurvey({
    registryId,
    surveyId,
  }: GetRegistrySurveyRequest): Promise<GetRegistrySurveyResponse> {
    const response = await Api.createClient().get(
      `/registry/registries/${registryId}/surveys/${surveyId}`,
    );
    return response?.data;
  }

  public static async getRegistryQualificationSurvey({
    registryId,
    surveyId,
  }: GetRegistrySurveyRequest): Promise<GetRegistrySurveyResponse> {
    const response = await Api.createClient({ publicEndpoint: true }).get(
      `/public/registry/registries/${registryId}/surveys/${surveyId}`,
    );
    return response?.data;
  }

  public static async submitQualificationSurvey({
    registryId,
    ...body
  }: AddRepositorySurveyResponseRequest): Promise<SubmitQualificationSurveyResponse> {
    const response = await Api.createClient().post(
      `/registry/registries/${registryId}/qualifications`,
      body,
    );
    return response?.data;
  }

  public static async submitPublicQualificationSurvey({
    registryId,
    participationId,
    ...body
  }: AddPublicQualificationSurveyResponseRequest): Promise<SubmitQualificationSurveyResponse> {
    const response = await Api.createClient({ publicEndpoint: true }).post(
      `/public/registry/registries/${registryId}/participations/${participationId}/qualifications`,
      body,
    );
    return response?.data;
  }

  public static async submitSurvey({
    registryId,
    ...body
  }: AddRepositorySurveyResponseRequest): Promise<void> {
    const response = await Api.createClient().post(
      `/registry/registries/${registryId}/surveys/${body.surveyId}/results`,
      body,
    );
    return response?.data;
  }

  public static async getRegistrationEmail({
    registryId,
    participationId,
    emailHash,
  }: GetRegistrationEmailRequest): Promise<GetRegistrationEmailResponse> {
    const response = await Api.createClient({ publicEndpoint: true }).get(
      `/public/registry/registries/${registryId}/participations/${participationId}/emails/${emailHash}`,
    );
    return response?.data;
  }

  public static async getAgreements(): Promise<GetAgreementsResponse> {
    const response = await Api.createClient().get(`/registry/agreements`);
    return response?.data;
  }

  public static async updateUserAgreements(
    body: UpdateRegistryAgreements,
  ): Promise<void> {
    const response = await Api.createClient().post(
      `/registry/agreements`,
      body,
    );
    return response?.data;
  }

  public static async getHealthcareProviders(): Promise<GetHealthcareProvidersResponse> {
    const response = await Api.createClient().get(`/healthcareProviders`);
    return response?.data;
  }

  public static async connectHealthcareProvider({
    providerId,
    code,
    redirectUri,
  }: ConnectHealthcareProviderRequest): Promise<void> {
    const response = await Api.createClient().post(
      `/healthcareProviders/${providerId}/connect`,
      {
        auth: { code, redirectUri },
      },
    );
    return response?.data;
  }

  public static async addRegistryParticipation(
    registryId: string,
    data: AddRegistryParticipationRequest,
  ): Promise<AddRegistryParticipationResponse> {
    const response = await Api.createClient({ publicEndpoint: true }).post(
      `/public/registry/registries/${registryId}/participations`,
      data,
    );

    return response?.data;
  }

  public static async getRegistryPersons({
    registryId,
    roles,
  }: GetRegistryPersonsRequest): Promise<GetRegistryPersonsResponse> {
    const response = await Api.createClient().get<GetRegistryPersonsResponse>(
      `/registry/registries/${registryId}/persons?roles=${roles.join(',')}`,
    );

    return response?.data;
  }

  public static async addRegistryPerson({
    registryId,
    personId,
    data,
  }: AddRegistryPersonRequest): Promise<void> {
    const response = await Api.createClient().put<void>(
      `/registry/registries/${registryId}/persons/${personId}`,
      data,
    );

    return response?.data;
  }

  public static async deleteRegistryPerson({
    registryId,
    personId,
  }: DeleteRegistryPersonRequest): Promise<void> {
    const response = await Api.createClient().delete<void>(
      `/registry/registries/${registryId}/persons/${personId}`,
    );

    return response?.data;
  }

  public static async getRegistryNotifications(): Promise<GetRegistryNotificationsResponse> {
    const response = await Api.createClient().get(`/registry/notifications`);
    return response?.data;
  }

  public static async updateRegistryNotifications(
    data: UpdateRegistryNotificationsRequest,
  ): Promise<void> {
    const response = await Api.createClient().post(
      `/registry/notifications`,
      data,
    );
    return response?.data;
  }
}
