import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useProperlyThemedComponent } from 'hooks/useProperlyThemedComponent';
import { useGetCurrentSection } from 'hooks/useGetCurrentSection';
import { WVULogo, WVULogoDark } from 'assets/logo';
import { StyledDesktopToolbar, NavLink, LogoContainer } from './styled';
import { messages } from '../../messages';

export const DesktopNavbar = () => {
  const currentSectionId = useGetCurrentSection();
  const { t } = useTranslation();
  const ThemedWVULogo = useProperlyThemedComponent({
    light: WVULogo,
    dark: WVULogoDark,
  });

  return (
    <StyledDesktopToolbar>
      <LogoContainer>
        <ThemedWVULogo aria-label="WVU Rockefeller Neuroscience Institute logo" />
      </LogoContainer>
      <nav>
        <NavLink href="#registries" current={currentSectionId === 'registries'}>
          {t(messages.registries())}
        </NavLink>
        <NavLink href="#faq" current={currentSectionId === 'faq'}>
          {t(messages.faq())}
        </NavLink>
        <NavLink href="#contact" current={currentSectionId === 'contact'}>
          {t(messages.contact())}
        </NavLink>
      </nav>
      <Typography component="span">
        {t(messages.alreadyMember())}
        <Link to="/login">{t(messages.logIn())}</Link>
      </Typography>
    </StyledDesktopToolbar>
  );
};
