import { Cohort } from 'api/interfaces/response/submitQualificationSurveyResponse';

export interface RegistryLocationState {
  cohorts?: {
    newCohorts?: Cohort[];
    previousCohorts?: Cohort[];
    firstCompletion: boolean;
  };
  surveyCompleted?: boolean;
}

export enum AvailableTabs {
  TOP_PRIORITY = 'top-priority-surveys',
  ALL_SURVEYS = 'all-surveys',
  ABOUT_REGISTRY = 'about-registry',
}

export type RegistryParams = {
  id: string;
  tab?: AvailableTabs;
};
