import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { messages } from 'pages/Registry/messages';
import { formatPhoneNumber } from 'helpers/phoneNumbers';
import { RegistryPerson } from 'api/interfaces/response/getRegistryPersonsResponse';
import {
  CaregiverInfoRowWrapper,
  CaregiverInfoWrapper,
  InfoTypeWrapper,
} from './styled';

export interface Props {
  caregiver: RegistryPerson;
}

export const CaregiverInfoRow = ({ caregiver }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { relationship, firstName, lastName, phone, email } = caregiver;

  const caregiverInfoRow = (infoType: string, info: string) => {
    return (
      <CaregiverInfoRowWrapper>
        <InfoTypeWrapper variant="h3" fontSize="1.4rem">
          {infoType}
        </InfoTypeWrapper>
        <Typography
          variant="body1"
          component="span"
          color={theme.palette.text.primary}
          sx={{ wordBreak: 'break-all' }}
        >
          {info}
        </Typography>
      </CaregiverInfoRowWrapper>
    );
  };

  return (
    <CaregiverInfoWrapper>
      {caregiverInfoRow(
        t(messages.relationshipToMember()),
        relationship ?? '-',
      )}
      {caregiverInfoRow(t(messages.firstName()), firstName)}
      {caregiverInfoRow(t(messages.lastName()), lastName)}
      {caregiverInfoRow(
        t(messages.phoneNumber()),
        phone ? formatPhoneNumber(phone) : '-',
      )}
      {caregiverInfoRow(t(messages.emailAddress()), email ?? '-')}
    </CaregiverInfoWrapper>
  );
};
