import { Stack, Typography } from '@mui/material';
import { EmptyFolderIllustration } from 'assets/illustrations';
import { useTranslation } from 'react-i18next';
import { IllustrationContainer } from './styled';
import { messages } from '../../messages';

export const NoSurveysLeft = () => {
  const { t } = useTranslation();

  return (
    <Stack height="100%" justifyContent="space-between">
      <IllustrationContainer>
        <EmptyFolderIllustration style={{ maxWidth: '100%' }} />
        <Stack gap="0.8rem" maxWidth="37.5rem">
          <Typography variant="h1" textAlign="center">
            {t(messages.allGoodForNow())}
          </Typography>
          <Typography variant="body1" textAlign="center">
            {t(messages.weWillEmailYou())}
          </Typography>
        </Stack>
      </IllustrationContainer>
    </Stack>
  );
};
