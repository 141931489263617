import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Typography, Stack } from '@mui/material';
import { maxBy } from 'lodash-es';
import { useProperlyThemedComponent } from 'hooks/useProperlyThemedComponent';
import { WVULogo, WVULogoDark } from 'assets/logo';
import { HamburgerIcon, CloseIcon } from 'assets/icons';
import { messages } from '../../messages';
import {
  StyledDrawer,
  StyledToolbar,
  SidebarButton,
  NavLink,
  CloseButton,
} from './styled';

export const MobileNavbar = () => {
  const [currentSectionId, setCurrentSectionId] = useState<string | null>(null);
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const ThemedWVULogo = useProperlyThemedComponent({
    light: WVULogo,
    dark: WVULogoDark,
  });

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen(prevState => !prevState);
  }, []);

  useEffect(() => {
    if (!drawerOpen) return;

    const sections = document.querySelectorAll<HTMLElement>('section[id]');

    const c = maxBy(sections, section => {
      const viewportCenter = window.innerHeight / 2 + window.scrollY;
      const elementOffsetTop = section.offsetTop;

      if (elementOffsetTop > viewportCenter) return -1;
      return elementOffsetTop;
    });
    setCurrentSectionId(c?.id ?? null);
  }, [drawerOpen]);

  return (
    <>
      <StyledDrawer
        anchor="right"
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // may need to disable so section indicator isnt calculated all the time
        }}
      >
        <Stack alignItems="flex-end" width="100%">
          <CloseButton
            onClick={handleDrawerToggle}
            data-cy="close-sidebar-button"
          >
            <CloseIcon />
          </CloseButton>
        </Stack>
        <nav>
          <NavLink
            href="#registries"
            current={currentSectionId === 'registries'}
            onClick={handleDrawerToggle}
          >
            {t(messages.registries())}
          </NavLink>
          <NavLink
            href="#faq"
            current={currentSectionId === 'faq'}
            onClick={handleDrawerToggle}
          >
            {t(messages.faq())}
          </NavLink>
          <NavLink
            href="#contact"
            current={currentSectionId === 'contact'}
            onClick={handleDrawerToggle}
          >
            {t(messages.contact())}
          </NavLink>
        </nav>
        <Typography component="span">
          {t(messages.alreadyMember())}
          <Link to="/login">{t(messages.logIn())}</Link>
        </Typography>
      </StyledDrawer>
      <StyledToolbar>
        <ThemedWVULogo aria-label="WVU Rockefeller Neuroscience Institute logo" />
        <SidebarButton
          aria-label="open sidebar"
          onClick={handleDrawerToggle}
          data-cy="open-sidebar-button"
        >
          <HamburgerIcon />
        </SidebarButton>
      </StyledToolbar>
    </>
  );
};
