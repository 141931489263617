import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: grid;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-template-columns: auto 1fr;
  }
  ${({ theme }) => theme.breakpoints.down('lg')} {
    grid-template-rows: 1fr;
  }
`;

export const FormContainer = styled.div`
  max-width: 100vw;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 6.4rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 4rem 2.4rem;
    min-height: unset;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 2.4rem;
  }
`;

export const FormWrapper = styled.div`
  max-width: 55.2rem;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  > form {
    width: 100%;
    margin: auto;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 44.8rem;
    padding: 0;
    height: unset;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;
