/**
 *
 * Select
 *
 */
// component taken from webportal
// check if can be improved with new mui
import { memo, useState, useRef, useEffect, useMemo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { kebabCase } from 'lodash-es';
import { ArrowDown2 } from 'iconsax-react';
import { Typography } from '@mui/material';
import {
  MainLabel,
  ValueWrapper,
  Popper,
  OptionWrapper,
  Option,
  OptionDescription,
  SelectedOptionDescription,
  DisabledOption,
  StyledButtonBase,
  Placeholder,
  ErrorIcon,
} from './styles';
import { messages } from './messages';
import { Tooltip } from '../Tooltip/index';

interface ValueType<T> {
  label: string;
  description?: string;
  warning?: boolean;
  colorSecondary?: boolean;
  value: T;
}

interface Utils {
  open: boolean;
  setOpen(open: boolean): void;
}

interface Props<T> {
  value?: T | null;
  changeValue(newValue: T): void;
  values: ValueType<T>[];
  placeholder?: string;
  capitalizeFirstLetter?: boolean;
  'data-cy'?: string;
  'aria-invalid'?: 'true' | 'false';
  'aria-describedby'?: string;
  disabled?: boolean;
  disabledMessage?: string;
  customRender?: (value: ValueType<T>, utils: Utils) => ReactNode;
  valuePrefix?: string;
  variant?: 'small' | 'medium';
}

function SelectFunc<T>({
  value,
  changeValue,
  values,
  placeholder,
  capitalizeFirstLetter = false,
  disabled = false,
  disabledMessage,
  customRender,
  valuePrefix,
  variant = 'medium',
  ...props
}: Props<T>) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const popperAnchor = useRef<HTMLButtonElement>(null);
  const popperElement = useRef<HTMLDivElement>(null);
  const observer = useRef(
    // eslint-disable-next-line compat/compat
    new IntersectionObserver(
      ([entry]) => entry.isIntersecting === false && setOpen(false),
      {
        threshold: 0.99,
      },
    ),
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        event.target &&
        !popperElement?.current?.contains(event.target as Node) &&
        !popperAnchor?.current?.contains(event.target as Node) &&
        open
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [popperElement, open]);

  const currentValue = useMemo(
    () => values.find(option => option.value === value),
    [value, values],
  );

  const currentValueLabel = currentValue?.label ?? value;
  const currentValueDescription = currentValue?.description;

  useEffect(() => {
    if (!popperAnchor.current) return;
    observer.current.observe(popperAnchor.current);

    return () => {
      observer.current.disconnect();
    };
  }, [popperAnchor.current]);

  const getOptions = () => {
    if (!values.length) {
      return <DisabledOption>{t(messages.nothingToSelect())}</DisabledOption>;
    }
    if (customRender) {
      const utils = {
        open,
        setOpen,
      };

      return values.map(v => customRender(v, utils));
    }

    return values.map(option => (
      <OptionWrapper
        key={option.label}
        onClick={() => {
          changeValue(option.value);
          setOpen(false);
        }}
        $isSelected={option.value === value}
      >
        <Option data-cy={kebabCase(`menu-item-${option.value}`)}>
          {option.warning && <ErrorIcon />}
          {option.label}
        </Option>
        {option.description && (
          <OptionDescription>{option.description}</OptionDescription>
        )}
      </OptionWrapper>
    ));
  };

  const wrapInTooltip = (children: ReactNode) =>
    disabledMessage && disabled ? (
      <Tooltip title={disabledMessage} placement="right" arrow={true}>
        <div>{children}</div>
      </Tooltip>
    ) : (
      children
    );

  return (
    <>
      {wrapInTooltip(
        <StyledButtonBase
          data-cy={props['data-cy'] ?? 'select'}
          disableRipple
          onClick={() => setOpen(!open)}
          ref={popperAnchor}
          style={{ width: '100%' }}
          $open={open}
          disabled={disabled}
          $disabled={disabled}
        >
          <MainLabel
            open={open}
            capitalizeFirstLetter={capitalizeFirstLetter}
            aria-invalid={props['aria-invalid']}
            error={props['aria-invalid'] === 'true'}
            data-cy={open ? `menu-item-${value}` : undefined}
            showBottomBorder={!open || currentValueDescription === undefined}
            variant={variant}
          >
            {currentValueLabel !== undefined ? (
              <div>
                {valuePrefix && (
                  <Typography variant="body1" component="span">
                    {valuePrefix}&nbsp;
                  </Typography>
                )}
                <ValueWrapper colorSecondary={currentValue?.colorSecondary}>
                  {currentValue?.warning && <ErrorIcon />}
                  {currentValueLabel as string}
                </ValueWrapper>
              </div>
            ) : (
              <Placeholder>{placeholder}</Placeholder>
            )}
            <ArrowDown2 variant="Bold" />
          </MainLabel>
        </StyledButtonBase>,
      )}
      <Popper
        open={open}
        anchorEl={popperAnchor.current}
        placement="bottom-start"
        ref={popperElement}
        data-cy="tooltip"
        aria-describedby={props['aria-describedby']}
        $capitalizeFirstLetter={capitalizeFirstLetter}
        $width={popperAnchor.current?.offsetWidth}
        $overlapButton={currentValueDescription !== undefined}
      >
        {currentValueDescription !== undefined && (
          <SelectedOptionDescription onClick={() => setOpen(false)}>
            {currentValueDescription}
          </SelectedOptionDescription>
        )}
        {getOptions()}
      </Popper>
    </>
  );
}

export const Select = memo(SelectFunc) as typeof SelectFunc;
