import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { generatePath, useNavigate } from 'react-router-dom';
import { QualificationSteps, Params } from './types';
import { FIRST_STEP, getStepMachine } from './constants';

interface Props {
  role?: string;
  onlySingleRoleAvailable: boolean;
  noAgreements: boolean;
}

export const useStepsStateMachine = ({
  role = 'member',
  onlySingleRoleAvailable,
  noAgreements,
}: Props) => {
  const navigate = useNavigate();
  const firstRender = useRef(true);
  const { registryCode, step: urlStep } = useParams<Params>();
  const [step, setStep] = useState<QualificationSteps>(FIRST_STEP);

  const getNext = (nextState: Required<Props>) => {
    if (step === null) return undefined;
    const stepMachine = getStepMachine(
      nextState.role,
      nextState.onlySingleRoleAvailable,
      nextState.noAgreements,
    );
    const currentStep = stepMachine[step]!;
    return currentStep.next;
  };

  const next = (
    nextState: Required<Props> = {
      role,
      onlySingleRoleAvailable,
      noAgreements,
    },
  ) => {
    const nextStep = getNext(nextState) ?? FIRST_STEP;
    setStep(nextStep);
  };

  const getPrev = (
    nextState: Required<Props> = {
      role,
      onlySingleRoleAvailable,
      noAgreements,
    },
  ) => {
    if (step === null) return undefined;
    const stepMachine = getStepMachine(
      nextState.role,
      nextState.onlySingleRoleAvailable,
      nextState.noAgreements,
    );
    const currentStep = stepMachine[step]!;
    return currentStep.previous;
  };

  const prev = (
    nextState: Required<Props> = {
      role,
      onlySingleRoleAvailable,
      noAgreements,
    },
  ) => {
    const prevStep = getPrev(nextState) ?? FIRST_STEP;

    setStep(prevStep);
  };

  const synchronizeURL = () => {
    navigate(
      generatePath('/registry/:registryCode/:step', {
        registryCode: registryCode!,
        step,
      }),

      // needed in order to allow user to go back to the
      // previous page outside qualification baseline
      { replace: firstRender.current },
    );
  };

  useEffect(() => {
    if (step !== urlStep) {
      synchronizeURL();
    }
  }, [step]);

  useEffect(() => {
    // don't check location on first render
    // to disable infinite loop if
    // urlStep === getNext() on first visit
    if (firstRender.current) {
      synchronizeURL();
      firstRender.current = false;
      return;
    }

    if (urlStep === getPrev({ role, onlySingleRoleAvailable, noAgreements })) {
      return prev();
    }

    if (urlStep === getNext({ role, onlySingleRoleAvailable, noAgreements })) {
      return next();
    }

    if (step !== urlStep) synchronizeURL();
  }, [urlStep]);

  return { step, next, prev, setStep, getPrev };
};
