import { forwardRef, memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as ScientistIllustrationLight } from './scientist.svg';
import { ReactComponent as ScientistIllustrationDark } from './scientist_dark.svg';

export const ScientistIllustration = memo(
  forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, ref) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return (
        <ScientistIllustrationDark aria-hidden="true" {...props} ref={ref} />
      );
    return (
      <ScientistIllustrationLight aria-hidden="true" {...props} ref={ref} />
    );
  }),
);
