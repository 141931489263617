import { Components } from '@mui/material/styles/components';
import { Theme } from 'styles/theme/themes';

export const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderBottom: `0.1rem solid ${theme.palette.separator.primary}`,
      margin: `1.3rem 0 2.4rem`,
      height: '4.8rem',

      '.MuiTabs-indicator': {
        backgroundColor: theme.palette.button.primary,
        borderRadius: '1.6rem',
      },

      '.MuiTabs-flexContainer': {
        gap: '3.2rem',
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'space-between',
          gap: '0',
        },
      },
    }),
  },
};
