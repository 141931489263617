import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { generatePath } from 'react-router-dom';
import { Typography, Stack } from '@mui/material';
import { StepsPreview } from 'components/StepsPreview';
import { Params } from 'pages/Qualification/types';
import { FIRST_STEP } from 'pages/Qualification/constants';
import { messages } from '../../messages';
import { PanelContainer } from './styled';

export const StepsPreviewPanel = memo(() => {
  const { registryCode } = useParams<Params>();
  const { t } = useTranslation();

  return (
    <PanelContainer>
      <Stack
        width="100%"
        maxWidth="107.6rem"
        alignItems="center"
        gap={{ lg: '7.6rem', xs: '4rem' }}
      >
        <Stack maxWidth="85.6rem" alignItems="center" gap="1.6rem">
          <Typography variant="h1" color="additionalColors.primaryText">
            {t(messages.howItWorks())}
          </Typography>
          <Typography variant="body1" color="additionalColors.primaryText">
            {t(messages.weTriedToMakeProcessEasy())}
          </Typography>
        </Stack>
        <StepsPreview
          steps={[
            {
              title: t(messages.clickOnJoinRegistryButton()),
              description: t(messages.asRegistryMemberYouPlay()),
              button: {
                title: t(messages.joinTheRegistry()),
                href: generatePath('/registry/:registryCode/:step', {
                  registryCode: registryCode!,
                  step: FIRST_STEP,
                }),
              },
            },
            {
              title: t(messages.fillOutApplicationForm()),
              description: t(messages.provideBasicPersonalInfo()),
            },
            {
              title: t(messages.fillOutQualificationSurvey()),
              description: t(messages.qualificationSurveyPlaysKeyRole()),
            },
            {
              title: t(messages.createAnAccount()),
              description: t(messages.weWillSendLinkToCreateAccount()),
            },
            {
              title: t(messages.startCompletingSurveys()),
              description: t(messages.youCanContributeYourData()),
            },
          ]}
        />
      </Stack>
    </PanelContainer>
  );
});
