import styled from 'styled-components';
import { HelpMessageIllustration } from 'assets/illustrations_old';

type IllustitionType = typeof HelpMessageIllustration;

// Not all colors are mapped in order to keep bundle size reasonable.
// If needed add more colors basing on theme
export const StyledSVG = styled.svg<{
  as: IllustitionType;
}>`
  max-width: 100%;

  *[stroke='#002E5F' i] {
    stroke: ${({ theme }) => theme.palette.text.primary};
  }
  *[fill='#002E5F' i] {
    fill: ${({ theme }) => theme.palette.text.primary};
  }

  *[stroke='#8C94AA' i] {
    stroke: ${({ theme }) => theme.palette.text.secondary};
  }
  *[fill='#8C94AA' i] {
    fill: ${({ theme }) => theme.palette.text.secondary};
  }

  *[stroke='#006ADC' i] {
    stroke: ${({ theme }) => theme.palette.button.primary};
  }
  *[fill='#006ADC' i] {
    fill: ${({ theme }) => theme.palette.button.primary};
  }

  *[stroke='#D6EAFF' i] {
    stroke: ${({ theme }) => theme.palette.background.color.primary};
  }
  *[fill='#D6EAFF' i] {
    fill: ${({ theme }) => theme.palette.background.color.primary};
  }

  *[stroke='#ACD4FF' i] {
    stroke: ${({ theme }) => theme.palette.background.color.secondary};
  }
  *[fill='#ACD4FF' i] {
    fill: ${({ theme }) => theme.palette.background.color.secondary};
  }

  *[stroke='#D9DEEB' i] {
    stroke: ${({ theme }) => theme.palette.separator.primary};
  }
  *[fill='#D9DEEB' i] {
    fill: ${({ theme }) => theme.palette.separator.primary};
  }

  *[stroke='#FFFFFF' i] {
    stroke: ${({ theme }) => theme.palette.button.text};
  }
  *[fill='#FFFFFF' i] {
    fill: ${({ theme }) => theme.palette.button.text};
  }

  *[stroke='#FF7B74' i] {
    stroke: ${({ theme }) => theme.palette.additionalColors.accentError};
  }
  *[fill='#FF7B74' i] {
    fill: ${({ theme }) => theme.palette.additionalColors.accentError};
  }
`;
