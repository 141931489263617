import { memo } from 'react';
import { StackProps } from '@mui/material';
import { LoadingPage } from 'pages/Loading';
import { FixedFadeContainer } from './styled';

interface Props {
  in: boolean;
  appear?: boolean;
  loaderProps?: StackProps;
  zIndex?: number;
}

export const LoadingOverlay = memo(
  ({ loaderProps, zIndex, ...props }: Props) => {
    return (
      <FixedFadeContainer $zIndex={zIndex} {...props} unmountOnExit>
        <div data-cy="loading-overlay">
          <LoadingPage {...loaderProps} />
        </div>
      </FixedFadeContainer>
    );
  },
);
