import { BreakpointsOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    mdl: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 496,
    md: 640,
    mdl: 768,
    lg: 1000,
    xl: 1400,
    xxl: 1800,
  },
};
