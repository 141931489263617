import { Components } from '@mui/material/styles/components';
import { Theme } from 'styles/theme/themes';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    alternative: true;
    selected: true;
  }
}

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    size: 'large',
    disableFocusRipple: true,
  },
  variants: [
    {
      props: { variant: 'alternative' },
      style: {},
    },
  ],
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      color: theme.palette.button.text,
      textTransform: 'none',
      borderRadius: '1.6rem',
      transition: 'all 0.2s ease-in-out',
      fontFamily: 'AvertaBold',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '1.6rem',
      lineHeight: '150%',

      ':hover, :active, :focus-visible': {
        background: theme.palette.text.primary,
        color: theme.palette.button.text,

        svg: {
          stroke: theme.palette.button.text,
        },
      },

      // override weird sticky hover on touch devices
      ...(ownerState.variant !== 'text' && {
        '@media (hover: none)': {
          ':hover': {
            background: theme.palette.button.primary,
            color: theme.palette.button.text,
          },
          ':active': {
            background: theme.palette.text.primary,
            color: theme.palette.button.text,
          },
        },
      }),

      ...(ownerState.size === 'large' && {
        height: '5.2rem',
        minWidth: '10rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 1.6rem',

        '> .MuiButton-label': {
          display: '-webkit-box',
          '-webkit-line-clamp': 2,
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
        },
      }),

      ...(ownerState.size === 'small' && {
        height: '3.2rem',
        padding: '0 1.6rem',
        borderRadius: '1rem',
        '&, &:hover': { boxShadow: 'none' },
      }),

      ...(ownerState.variant === 'text' && {
        height: 'auto',
        width: 'auto',
        minWidth: 'unset',
        padding: '0',

        span: {
          display: 'none',
        },

        ':focus': {
          background: 'none',
        },

        ':focus-visible': {
          background: 'none',
        },

        '&&': {
          'svg *[stroke]': {
            stroke: theme.palette.button.primary,
          },
          'svg *[fill="#002E5F" i]': {
            fill: theme.palette.button.primary,
            stroke: 'none',
          },

          ':active': {
            color: theme.palette.button.text,

            'svg *[stroke]': {
              stroke: theme.palette.button.text,
            },
            'svg *[fill="#002E5F" i]': {
              fill: theme.palette.button.text,
            },
          },

          ':hover': {
            color: theme.palette.text.primary,

            svg: { color: theme.palette.text.primary },

            'svg *[stroke]': {
              stroke: theme.palette.text.primary,
            },
            'svg *[fill="#002E5F" i]': {
              fill: theme.palette.text.primary,
            },
          },
        },
      }),

      ...(ownerState.variant === 'outlined' && {
        border: `0.15rem dashed ${theme.palette.separator.primary}`,
        filter: 'drop-shadow(0 0.4rem 3rem rgba(49, 48, 111, 0.04))',
        borderRadius: '1.6rem',
        boxSizing: 'border-box',
        background: 'transparent',
        color: theme.palette.button.primary,

        ':hover': {
          background: theme.palette.button.text,
          color: theme.palette.text.primary,
          filter: 'drop-shadow(0 0.4rem 3rem rgba(49, 48, 111, 0.04))',

          'svg *': {
            stroke: theme.palette.text.primary,
          },
        },

        ...(ownerState.disabled && {
          background: theme.palette.button.disabledBackground,
          color: theme.palette.button.disabledText,

          'svg *': {
            stroke: theme.palette.button.disabledText,
          },
        }),
      }),

      ...(ownerState.color === 'primary' && {
        background: theme.palette.button.primary,
        color: theme.palette.button.text,

        svg: {
          marginRight: '0.8rem',
          '*': {
            stroke: theme.palette.text.primary,
            transition: 'stroke 300ms ease-in-out, fill 300ms',
          },
        },
      }),

      ...(ownerState.color === 'secondary' && {
        background: 'transparent',
        color: theme.palette.button.primary,
        border: 'none',
        filter: 'none',
        boxShadow: 'none',

        svg: {
          '*': {
            stroke: theme.palette.button.primary,
          },
        },

        ':hover': {
          background: theme.palette.button.background,
          boxShadow: 'none',
        },
      }),

      ...(ownerState.variant === 'alternative' && {
        background: 'transparent',
        color: theme.palette.text.primary,
        border: `0.15rem solid ${theme.palette.separator.primary}`,

        svg: {
          marginRight: '0.8rem',
          width: '1.8rem',
          height: '1.8rem',

          '*': {
            transition: 'stroke 300ms',
            stroke: theme.palette.text.primary,
          },
        },

        ':hover': {
          color: theme.palette.button.primary,
          background: theme.palette.background.elevated.pressed,

          svg: {
            '*': {
              stroke: theme.palette.button.primary,
            },
          },
        },
      }),

      ...(ownerState.variant === 'selected' && {
        background: 'transparent',
        color: theme.palette.button.primary,
        border: `0.15rem solid ${theme.palette.button.primary}`,

        ':hover': {
          color: theme.palette.button.primary,
          background: theme.palette.background.elevated.pressed,

          svg: {
            '*': {
              stroke: theme.palette.button.primary,
            },
          },
        },
      }),

      ...(ownerState.color === 'error' && {
        border: `0.15rem solid ${theme.palette.error.main}`,
        background: 'transparent',
        color: theme.palette.error.main,

        '&:hover': {
          background: theme.palette.error.main,
          color: theme.palette.button.text,

          'svg *[stroke]': {
            stroke: theme.palette.button.text,
          },
        },

        '&:disabled': {
          border: 'none',
        },

        svg: {
          marginRight: '0.8rem',
          '*[stroke]': {
            stroke: theme.palette.error.main,
            transition: 'stroke 0.2s ease-in-out',
          },
        },
      }),

      ...(ownerState.variant === 'text' && {
        borderRadius: '0',
        overflow: 'unset',
        boxSizing: 'border-box',
        background: 'transparent',
        color: theme.palette.button.primary,
        fontSize: '1.6rem',

        ':hover, :active, :focus-visible': {
          background: 'transparent',
          color: theme.palette.text.primary,
        },
      }),

      ...(ownerState.disabled && {
        color: theme.palette.button.disabledText,
        backgroundColor: theme.palette.button.disabledBackground,
      }),

      '&.Mui-disabled': {
        color: theme.palette.button.disabledText,
        backgroundColor: theme.palette.button.disabledBackground,
      },

      '.MuiCircularProgress-root svg': {
        margin: 0,
        stroke: theme.palette.button.disabledText,
        circle: {
          stroke: theme.palette.button.disabledText,
        },
      },
    }),
  },
};
