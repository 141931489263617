export enum EventNames {
  TIMEOUT_STATE_CHANGE = 'timeout state change',
  SETTINGS_PAGE = 'settings page',
  REGISTRY_PAGE = 'registry page',
  HELP_PAGE = 'help page',
  PDF_VIEWER = 'pdf viewer',
  SURVEY_COMPLETED = 'survey completed',
  SURVEY_COMPLETED_MODAL = 'survey completed modal',
  SURVEY_MODULE = 'survey module',
  BASELINE_SURVEY_MODULE = 'qualification baseline survey module',
  ENDPOINT_ERROR = 'endpoint error',
  SURVEY_QUESTION_ANSWERED = 'survey question answered',
  MANUALLY_LOGGED_OUT = 'manually logged out',
  PUBLIC_REGISTRY_NOTIFICATION_CLICKED = 'public registry notification clicked',
  REGISTRY_FAQ_EXPANDED = 'registry faq expanded',
}

export enum TimeoutEventActions {
  OPEN_EXTEND_SESSION_MODAL = 'open extend session modal',
  NO_LAST_ACTIVITY_TIME = 'no last activity time',
  RETURN_TO_EXPIRED = 'return to expired',
  RETURN_TO_EXTEND_SESSION_MODAL = 'return to extend session modal',
  RETURN_BEFORE_TIMEOUT = 'return before timeout',
  EXTEND_SESSION_MODAL_TIMEOUT = 'extend session modal timeout',
  EXTEND_SESSION_MODAL_EXTENDED = 'extend session modal extended',
  EXTEND_SESSION_MODAL_CLOSED = 'extend session modal closed',
}

export enum SettingsEventActions {
  OPEN_CONSENT_MANAGEMENT_MODAL = 'open consent management modal',
  OPEN_PASSWORD_MANAGEMENT_MODAL = 'open password management modal',
}

export enum RegistryPageEventActions {
  OPEN_ABOUT_REGISTRY_MODAL = 'open about registry modal',
}

export enum PdfViewerEventActions {
  OPEN_PREVIEW = 'open preview',
  PRINT_DOCUMENT = 'print document',
  DOWNLOAD_DOCUMENT = 'download document',
  SHARE_DOCUMENT = 'share document',
}

export enum SurveyCompletedEventActions {
  START_NEXT_SURVEY = 'start next survey',
  NOT_NOW = 'not now',
  MODAL_CLOSED = 'modal closed',
}

export enum SurveyModuleEventActions {
  SURVEY_CLOSED = 'survey closed',
}

export enum HelpPageEventActions {
  OPEN_PANEL = 'open panel',
  EMAIL_CLICKED = 'support email clicked',
}
