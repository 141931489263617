import { memo } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { useValidationSchema } from 'hooks/useValidationSchema';
import { FormikTextField } from 'components/FormikTextField';
import { FormikPhoneInput } from 'components/PhoneInput';
import { getValidationSchema } from './validators';
import { messages } from '../../messages';
import { CaregiverContactDetailsForm } from '../../types';

interface Props {
  onBack: (values: CaregiverContactDetailsForm) => void;
  onSubmit: (values: CaregiverContactDetailsForm) => void;
  stepForm: CaregiverContactDetailsForm;
  registryName: string;
}

export const CaregiverContactDetailsStep = memo(
  ({ onBack, onSubmit, stepForm, registryName }: Props) => {
    const { t } = useTranslation();
    const validationSchema = useValidationSchema(getValidationSchema);

    return (
      <Formik
        initialValues={stepForm}
        onSubmit={values => onSubmit(values)}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ errors, values }) => (
          <Form data-cy="username-step">
            <Stack spacing="1.6rem" direction="column" justifyContent="center">
              <Typography
                variant="body4"
                textAlign="center"
                color="button.primary"
              >
                {registryName}
              </Typography>
              <Typography variant="h1" textAlign="center">
                {t(messages.caregiverContactDetails())}
              </Typography>
              <Typography variant="body1">
                {t(messages.inCaseOfNotBeingAble())}
              </Typography>
              <FormikTextField
                name="email"
                type="email"
                placeholder={t(messages.email())}
                validationSchema={validationSchema}
                oneWayValidationOnChange={true}
                data-cy="email-input"
                label={t(messages.email())}
              />
              <FormikPhoneInput
                name="phone"
                placeholder={t(messages.phoneNumber())}
                validationSchema={validationSchema}
                oneWayValidationOnChange={true}
                data-cy="phone-number-input"
                label={`${t(messages.phoneNumber())} (${t(
                  messages.optional(),
                )})`}
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                data-cy="caregiver-contact-details-step-continue-button"
                style={{ marginTop: '3rem' }}
                disabled={Object.values(errors).length > 0}
              >
                {t(messages.continue())}
              </Button>
              <Button
                onClick={() => onBack(values)}
                color="primary"
                variant="text"
                data-cy="caregiver-contact-details-step-back-button"
              >
                {t(messages.back())}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    );
  },
);
