import i18next from 'i18next';
import { messages } from './messages';

export const getFAQ = () => [
  {
    question: i18next.t(messages.whyIsQualificationSurveyImportant()),
    answer: i18next.t(messages.whyIsQualificationSurveyImportantAnswer()),
  },
  {
    question: i18next.t(messages.howAssignmentWork()),
    answer: i18next.t(messages.howAssignmentWorkAnswer()),
  },
  {
    question: i18next.t(messages.whyHaveNoAssignment()),
    answer: i18next.t(messages.whyHaveNoAssignmentAnswer()),
  },
  {
    question: i18next.t(messages.whyLoggedOut()),
    answer: i18next.t(messages.whyLoggedOutAnswer()),
  },
  {
    question: i18next.t(messages.whyCantWithdraw()),
    answer: i18next.t(messages.whyCantWithdrawAnswer()),
  },
];
