import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  placeholder: () =>
    _t(translations.components.PasswordInput.placeholder, 'Password'),
  passwordHidden: () =>
    _t(translations.components.PasswordInput.passwordHidden, 'Password hidden'),
  passwordVisible: () =>
    _t(
      translations.components.PasswordInput.passwordVisible,
      'Password visible',
    ),
};
