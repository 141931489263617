import i18next from 'i18next';
import { FormSteps } from './types';
import { messages } from './messages';

export const getStepName = (step: FormSteps) => {
  switch (step) {
    case FormSteps.USERNAME_STEP:
      return i18next.t(messages.emailStep());
    case FormSteps.PASSWORD_STEP:
      return i18next.t(messages.passwordStep());
    case FormSteps.VALIDATION_STEP:
      return i18next.t(messages.validationStep());
    case FormSteps.VERIFY_ACCOUNT:
      return i18next.t(messages.verifyAccount());
    case FormSteps.RESET_PASSWORD_EMAIL_STEP:
      return i18next.t(messages.resetPasswordEmailStep());
    case FormSteps.RESET_PASSWORD_CODE_STEP:
      return i18next.t(messages.resetPasswordCodeStep());
    case FormSteps.RESET_PASSWORD_SUCCESS_SCREEN:
      return i18next.t(messages.resetPasswordSuccessScreen());
    case FormSteps.SET_UP_PASSWORD_STEP:
      return i18next.t(messages.setUpPasswordStep());
    default:
      return null;
  }
};
