import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { XIcon } from 'assets/icons';
import { useTypography } from 'helpers/mixins';

export const Placeholder = styled.div`
  width: 100%;
  height: 5.2rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    height: 6.2rem;
  }
  ${({ theme }) => theme.breakpoints.down('mdl')} {
    height: 8.4rem;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 8.4rem;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 5.2rem;
  }
`;

export const Wrapper = styled.div`
  background: ${({ theme }) =>
    theme.palette.additionalColors.selectedBackground};
  border: 0.1rem solid
    ${({ theme }) => theme.palette.additionalColors.background};
  position: fixed;
  top: -1px;
  left: 0;
  right: 0;
  z-index: 5;
  width: 100%;
  height: 5.2rem;
  display: flex;
  gap: 1.2rem;
  padding: 0 6rem 0 7.2rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    height: auto;
    padding: 0.8rem 3.6rem 0.6rem 4.8rem;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0.6rem 1.6rem;
  }
`;

export const CloseIcon = styled(XIcon)`
  cursor: pointer;
  width: 2.4em;
  min-width: 2.4rem;
  max-width: 2.4rem;
  height: 2.4rem;
  box-sizing: content-box;
  border-radius: 1.6rem;

  path {
    stroke: ${({ theme }) => theme.palette.additionalColors.primaryText};
    transition: stroke 300ms;
  }

  :hover path {
    stroke: ${({ theme }) => theme.palette.additionalColors.secondaryText};
  }
`;

export const HealthyRegistryLink = styled(Link)`
  ${({ theme }) => useTypography(theme.typography.h4)}
  display: flex;
  gap: 1.6rem;
  transition: color 300ms;
  flex-shrink: 0;
  max-width: 100%;

  && {
    color: ${({ theme }) => theme.palette.additionalColors.primaryText};

    :hover {
      color: ${({ theme }) => theme.palette.additionalColors.secondaryText};
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 1.2rem;
  }
`;
