import { Link, LinkProps } from 'react-router-dom';
import { ButtonProps, Typography } from '@mui/material';
import { StartButton } from 'components/StartButton';
import { Step } from './Step';
import { StepsWrapper, ListWrapper, StepNumberIndicator } from './styled';

interface StepButton extends ButtonProps {
  title: string;
}

type StepLinkButton = {
  href: string;
} & LinkProps &
  StepButton;

export interface StepType {
  title: string;
  description: string;
  button?: StepLinkButton | StepButton;
}

interface Props {
  steps: StepType[];
}

export const StepsPreview = ({ steps }: Props) => {
  const renderButton = ({ title, ...props }: StepLinkButton | StepButton) => (
    <StartButton
      hideIconOnMobile
      style={{ textAlign: 'center' }}
      {...(props.href !== undefined && { component: Link, to: props.href })}
      {...props}
    >
      {title}
    </StartButton>
  );

  const mapStep = ({ title, description, button }: StepType, index: number) => (
    <div key={title}>
      <StepNumberIndicator>{index + 1}</StepNumberIndicator>
      <Step direction={index % 2 ? 'right' : 'left'}>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
        {button && renderButton(button)}
      </Step>
    </div>
  );

  return (
    <StepsWrapper>
      <ListWrapper role="list">{steps.map(mapStep)}</ListWrapper>
    </StepsWrapper>
  );
};
