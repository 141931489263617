import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

export const StyledButton = styled(Button)<{ $hideIconOnMobile?: boolean }>`
  svg {
    height: 1.4rem;
    max-height: 1.4rem;
    min-height: 1.4rem;
    width: 1.4rem;
    max-width: 1.4rem;
    margin-right: 0.8rem;
    margin-bottom: unset;

    * {
      fill: ${({ theme }) => theme.palette.button.text};
      stroke: unset;
    }
  }

  :hover {
    svg {
      stroke: unset;
    }
  }

  span {
    display: flex;
    align-items: center;
  }

  ${({ $hideIconOnMobile, theme }) =>
    $hideIconOnMobile &&
    css`
      ${theme.breakpoints.down('md')} {
        svg {
          display: none;
        }
      }
    `}

  // animation only on desktop
  ${({ theme }) => theme.breakpoints.up('md')} {
    svg {
      opacity: 0;
      transition: opacity 600ms;
    }

    span {
      transform: translateX(-1.3rem);
      transition: transform 600ms;
    }

    :hover {
      background: ${({ theme }) => theme.palette.button.primary};
      color: ${({ theme }) => theme.palette.button.text};

      svg {
        opacity: 1;
      }

      span {
        transform: translateX(0);
      }
    }
  }
`;
