import { useEffect, useState, useCallback } from 'react';
import { STORAGE_USER_ROLE } from 'config/constants';
import UserRoles from '.';

export const useUserRole = (registryId: string) => {
  const [userRole, setUserRole] = useState(() =>
    UserRoles.getRoleForRegistry(registryId),
  );

  useEffect(() => {
    const onStorageUpdate = () => {
      setUserRole(UserRoles.getRoleForRegistry(registryId));
    };

    onStorageUpdate();
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, [registryId]);

  const sendEvent = useCallback(() => {
    const e = new StorageEvent('storage', {
      storageArea: window.localStorage,
      key: STORAGE_USER_ROLE,
      url: window.location.href,
    });
    window.dispatchEvent(e);
  }, [registryId]);

  const setRole = useCallback(
    (role: string) => {
      UserRoles.saveRoleForRegistry(registryId, role);
      sendEvent();
    },
    [registryId],
  );

  const clearRole = useCallback(() => {
    UserRoles.clearRoleForRegistry(registryId);
    sendEvent();
  }, [registryId]);

  return { roleId: userRole, setRole, clearRole };
};
