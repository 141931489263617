import * as React from 'react';
import { Stack, Box } from '@mui/material';

import { SideBar } from 'components/SideBar';
import { StyleConstants } from 'styles/StyleConstants';

const drawerWidth = StyleConstants.SIDE_BAR_WIDTH;

interface Props {
  children: React.ReactNode;
}

export const PageWrapper = (props: Props) => {
  const { children } = props;

  return (
    <Stack sx={{ flexDirection: { lg: 'row', xs: 'column' } }} minHeight="100%">
      <Box
        component="nav"
        sx={{
          width: drawerWidth,
          minWidth: drawerWidth,
          marginLeft: { lg: '0.8rem' },
        }}
      >
        <SideBar />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: {
            xs: '2.8rem 2.4rem 1.6rem',
            sm: '2.8rem 4.8rem 2.4rem',
            xl: '4.8rem 7.2rem 2.4rem',
          },
          transition: 'padding 300ms',
          position: 'relative',
        }}
        minHeight="100%"
        display="grid"
      >
        {children}
      </Box>
    </Stack>
  );
};
