import { Cohort } from 'api/interfaces/response/submitQualificationSurveyResponse';

// true - changed
// false - unchanged
export const compareCohorts = (a?: Cohort[], b?: Cohort[]) => {
  if (!a === !!b) return true;
  if (!a && !b) return false;

  if (a!.length !== b!.length) return true;
  return !a!.every(({ id: a_id }) => b!.some(({ id: b_id }) => a_id === b_id));
};
