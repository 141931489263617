import styled from 'styled-components';

export const StyledFooter = styled.div`
  background: ${({ theme }) => theme.palette.additionalColors.background};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 4.8rem;

  padding: 3.2rem 7.2rem;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 3.2rem 4.8rem;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 2.4rem;
    flex-direction: column;
    padding: 3.2rem 2.4rem 1.6rem;
  }

  && button {
    color: ${({ theme }) => theme.palette.additionalColors.primaryText};

    :hover {
      color: ${({ theme }) => theme.palette.additionalColors.secondaryText};
    }
  }
`;
