/**
 *
 * PasswordInput
 *
 */
import React, { memo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment } from '@mui/material';
import { Eye as ViewOffIcon, EyeSlash as ViewIcon } from 'iconsax-react';

import {
  FormikTextField,
  FormikTextFieldProps,
} from 'components/FormikTextField';
import { messages } from './messages';
import { IconButton } from './styled';

type PasswordInputProps = FormikTextFieldProps & {
  name: string;
  'data-cy'?: string;
};

export const PasswordInput = memo(({ ...props }: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);

    if (inputRef.current) {
      const { selectionStart, selectionEnd } = inputRef.current;
      setTimeout(() => {
        if (selectionStart && selectionEnd)
          inputRef.current?.setSelectionRange(selectionStart, selectionEnd);
      }, 0);
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <FormikTextField
      placeholder={t(messages.placeholder())}
      type={showPassword ? 'text' : 'password'}
      inputRef={inputRef}
      aria-required="true"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <p aria-live="polite" className="sr-only">
              {showPassword
                ? t(messages.passwordVisible())
                : t(messages.passwordHidden())}
            </p>
            <IconButton
              aria-label={t(messages.passwordVisible())}
              aria-pressed={showPassword ? 'true' : 'false'}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
});
