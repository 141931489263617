import { memo, useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetPublicRegistryResponse } from 'api/interfaces/response/getPublicRegistryResponse';
import { RoleSelection } from 'components/RoleSelection';
import { messages } from '../../messages';

interface Props {
  prev(): void;
  roles: GetPublicRegistryResponse['roles'];
  onSelect(role: GetPublicRegistryResponse['roles'][0]): void;
  registryName: string;
}

export const RoleSelectionStep = memo(
  ({ prev, roles, onSelect, registryName }: Props) => {
    const { t } = useTranslation();
    const [selectedRole, setSelectedRole] = useState<string | undefined>(
      undefined,
    );

    useEffect(() => {
      if (roles.length === 1) onSelect(roles[0]);
    }, [roles]);

    const handleSelect = () => {
      if (!selectedRole) return;
      const role = roles.find(({ id }) => id === selectedRole);
      if (role) onSelect(role);
    };

    return (
      <Stack gap="3.2rem" justifyContent="center" height="100%" width="100%">
        <Stack gap="1.2rem">
          <Typography
            variant="body4"
            textAlign="center"
            marginBottom="0.4rem"
            color="button.primary"
          >
            {registryName}
          </Typography>
          <Typography variant="h1" textAlign="center">
            {t(messages.pleaseSelectYourRole())}
          </Typography>
          <Typography variant="body1">
            {t(messages.participantsHaveTheOption())}
          </Typography>
        </Stack>
        <RoleSelection
          roles={roles}
          selectedId={selectedRole}
          onSelect={setSelectedRole}
          isEnrollSelection
        />
        <Stack gap="1.6rem">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            data-cy="role-selection-step-continue-button"
            onClick={handleSelect}
            disabled={!selectedRole}
          >
            {t(messages.continue())}
          </Button>
          <Button
            onClick={prev}
            color="primary"
            variant="text"
            data-cy="role-selection-step-back-button"
            style={{ padding: '1.6rem 0 1.6rem 0' }}
          >
            {t(messages.back())}
          </Button>
        </Stack>
      </Stack>
    );
  },
);
