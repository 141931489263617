import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import { Component as ComponentIcon } from 'iconsax-react';
import { StyledReactMarkdown } from './styled';

export const MarkdownRenderer = (markdownProps: ReactMarkdownOptions) => (
  <StyledReactMarkdown
    components={{
      li: ({ node, ordered, ...props }) =>
        ordered ? (
          <li {...props} />
        ) : (
          <li {...props}>
            <ComponentIcon variant="Bold" size="0.8rem" />
            {props.children}
          </li>
        ),
    }}
    {...markdownProps}
  />
);
