import { Agreement } from 'api/interfaces/request/addRegistryParticipationRequest';
import { GetPublicRegistryResponse } from 'api/interfaces/response/getPublicRegistryResponse';

export enum QualificationSteps {
  WELCOME_PAGE = '',
  ROLE_SELECTION = 'role-selection',
  CAREGIVER_DETAILS = 'caregiver-details',
  CAREGIVER_CONTACT_DETAILS = 'caregiver-contact-details',
  MEMBER_DETAILS = 'member-details',
  ACCOUNT_DETAILS = 'account-details',
  AGREEMENTS = 'agreements',
  CONFIRM_APPLICATION = 'confirm-application',
  SURVEY_START = 'survey-start',
  QUALIFICATION_SURVEY = 'qualification-survey',
  NEXT_STEP = 'next-step',
}

export type CaregiverRelationships =
  | 'spouse'
  | 'sibling'
  | 'child'
  | 'grandchild'
  | 'parent';

export interface QualificationStepTransition {
  next: QualificationSteps;
  previous: QualificationSteps;
}

export type QualificationStepsTransitions = {
  [value in QualificationSteps]?: QualificationStepTransition;
};

export type Params = {
  registryCode: string;
  step?: string;
};

export type QualificationFormRole = GetPublicRegistryResponse['roles'][0];
export interface RoleSelectionForm {
  role: QualificationFormRole;
}

export type CaregiverDetailsForm = {
  firstName: string;
  lastName: string;
  relationship: CaregiverRelationships | '';
};

export interface CaregiverContactDetailsForm {
  email: string;
  phone?: string;
}

export interface MemberDetailsForm {
  firstName: string;
  lastName: string;
  dateOfBirth: Date | null;
  phone?: string;
}

export interface AccountDetailsForm {
  email: string;
}

export type AgreementsForm = {
  agreements: Array<Agreement>;
};

export interface ConfirmMemberApplicationForm {
  participationId: string;
}

export interface GlobalFormState {
  [QualificationSteps.MEMBER_DETAILS]: MemberDetailsForm;
  [QualificationSteps.CAREGIVER_DETAILS]: CaregiverDetailsForm;
  [QualificationSteps.CAREGIVER_CONTACT_DETAILS]: CaregiverContactDetailsForm;
  [QualificationSteps.ROLE_SELECTION]: RoleSelectionForm;
  [QualificationSteps.ACCOUNT_DETAILS]: AccountDetailsForm;
  [QualificationSteps.AGREEMENTS]: AgreementsForm;
  [QualificationSteps.CONFIRM_APPLICATION]: ConfirmMemberApplicationForm;
}
