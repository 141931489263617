import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  header: () =>
    _t(
      translations.components.ShareHealthRecordsCard.header,
      'Share your health records',
    ),
  description: () =>
    _t(
      translations.components.ShareHealthRecordsCard.description,
      'Sync your healthcare provider account with your RNI account.',
    ),
  connectAccount: () =>
    _t(
      translations.components.ShareHealthRecordsCard.connectAccount,
      'Connect account',
    ),
};
