import styled, { css } from 'styled-components';

export const Bar = styled.div<{ numberOfElements: number }>`
  max-width: ${({ numberOfElements }) => 32.4 * numberOfElements}rem;
  width: calc(100% - 4.8rem);
  padding: 3.2rem 7.2rem;
  border-radius: 2.4rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-bottom: 5.6rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 2.8rem 4.8rem;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-wrap: wrap;
    padding: 2rem;
    gap: 2.4rem;

    > div {
      margin: auto;
    }
  }

  background: ${({ theme }) => theme.palette.background.elevated.primary};

  ${({ theme }) =>
    theme.palette.type !== 'dark' &&
    css`
      :after {
        content: '';
        z-index: -1;
        background: ${theme.palette.text.primary};
        opacity: 0.06;
        filter: blur(2rem);
        border-radius: 0 0 3.2rem 3.2rem;
        height: 6.4rem;
        position: absolute;
        bottom: -3.2rem;
        left: 4.8rem;
        right: 4.8rem;
      }
    `}

  svg {
    height: 3.2rem;
    width: 3.2rem;

    * {
      fill: ${({ theme }) => theme.palette.text.primary};
    }
  }
`;

export const Separator = styled.span`
  width: 0.15rem;
  min-width: 0.15rem;
  height: 3.2rem;
  background: ${({ theme }) => theme.palette.separator.primary};

  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: none;
  }
`;

export const Statistics = styled.span`
  color: ${({ theme }) => theme.palette.button.primary};
  font-family: 'AvertaRegular';
  font-style: normal;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 100%;
`;
