import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Modal } from 'components/Modal';
import { LoadingButton } from '@mui/lab';
import { messages } from '../messages';

interface Props {
  open: boolean;
  onClose(): void;
  onConfirm(state: 'rejected'): void;
  registryName: string;
  isLoading: boolean;
}

export const ParticipationConfirmationModal = memo(
  ({ open, onClose, registryName, isLoading, onConfirm }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
      <Modal
        open={open}
        onClose={onClose}
        disableMobileFullScreen
        closeOnClickAway
        title={
          isMobile
            ? t(messages.areYouSureYourWerentEnrolled())
            : t(messages.areYouSureYourWerentEnrolledTo(), {
                registry: registryName,
              })
        }
        data-cy="participation-denial-confirmation-modal"
      >
        <Stack
          direction="column"
          marginX={{
            lg: '4.8rem',
            sm: '3.2rem',
            xs: '2.4rem',
          }}
          gap="1.6rem"
        >
          <Typography variant="body1">
            {t(messages.onceConfirmedYouCantChange())}
          </Typography>
        </Stack>
        <Stack
          gap="1.6rem"
          direction={isMobile ? 'column-reverse' : 'row'}
          justifyContent="stretch"
          width="100%"
          paddingX={{
            lg: '4.8rem',
            sm: '3.2rem',
            xs: '2.4rem',
          }}
          marginTop="4rem"
        >
          <Button
            variant="alternative"
            onClick={onClose}
            style={{ width: '100%' }}
            disabled={isLoading}
            data-cy="go-back-button"
          >
            {t(messages.goBack())}
          </Button>
          <LoadingButton
            style={{ width: '100%' }}
            loading={isLoading}
            variant="contained"
            onClick={() => onConfirm('rejected')}
            data-cy="sure-wasnt-enrolled-button"
          >
            {t(messages.iSureIwasntEnrolled())}
          </LoadingButton>
        </Stack>
      </Modal>
    );
  },
);
