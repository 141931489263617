import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { SUPPORT_EMAIL } from 'config/constants';
import { useProperlyThemedComponent } from 'hooks/useProperlyThemedComponent';
import contactIllustrationLight from './assets/contact.svg';
import contactIllustrationDark from './assets/contact_dark.svg';
import { messages } from '../../messages';
import { IllustrationWrapper, MailIcon, Section } from './styled';

export const ContactSection = () => {
  const { t } = useTranslation();

  const contactIllustration = useProperlyThemedComponent({
    light: contactIllustrationLight,
    dark: contactIllustrationDark,
  });

  return (
    <Section id="contact">
      <Stack
        padding={{
          xxl: '12rem 0 12rem 7.2rem',
          lg: '9.6rem 0 9.6rem 7.2rem',
          md: '7.2rem 4.8rem',
          xs: '5.6rem 2.4rem',
        }}
        maxWidth="144rem"
        margin="auto"
        boxSizing="content-box"
        alignItems="center"
        direction={{ sm: 'row', xs: 'column' }}
        gap={{ lg: '2.4', md: '7.2rem', xs: '5.6rem' }}
        overflow="hidden"
        sx={{
          backgroundColor: 'additionalColors.background',
        }}
      >
        <Stack
          gap="1.6rem"
          width={{
            sm: '50%',
            xs: '100%',
          }}
        >
          <Typography variant="h1" color="additionalColors.primaryText">
            {t(messages.contactUs())}
          </Typography>
          <Typography variant="body1" color="additionalColors.primaryText">
            {t(messages.asProfessionalsWeWelcomeFeedback())}
          </Typography>
          <Stack
            gap="2.4rem"
            direction="row"
            marginTop="1.6rem"
            alignItems="center"
          >
            <MailIcon />
            <Typography
              variant="h5"
              component="a"
              href={`mailto:${SUPPORT_EMAIL}`}
            >
              {SUPPORT_EMAIL}
            </Typography>
          </Stack>
        </Stack>
        <IllustrationWrapper>
          <img src={contactIllustration} alt="" />
        </IllustrationWrapper>
      </Stack>
    </Section>
  );
};
