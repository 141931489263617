import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as DataDeletionIllustrationLight } from './dataDeletion.svg';
import { ReactComponent as DataDeletionIllustrationDark } from './dataDeletion_dark.svg';

export const DataDeletionIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return <DataDeletionIllustrationDark aria-hidden="true" {...props} />;
    return <DataDeletionIllustrationLight aria-hidden="true" {...props} />;
  },
);
