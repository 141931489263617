import { memo } from 'react';
import { kebabCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { ArrowRight2, Clock as ClockIcon } from 'iconsax-react';
import { Stack, ButtonBase } from '@mui/material';
import { GetRegistryResponse } from 'api/interfaces/response/getRegistryResponse';
import { SurveyLocationState } from 'pages/Survey/types';
import { getEstimatedTime } from 'helpers/estimatedTime';
import { BrainLeftIllustration } from 'assets/illustrations/brainLeft';

import {
  Container,
  SurveyName,
  SurveyWrapper,
  SurveyInformation,
  BrainIllustrationWrapper,
  SurveyInformationWrapper,
  RoleWrapper,
} from './styled';
import { messages } from './messages';

interface Props {
  registryId: GetRegistryResponse['id'];
  roles: GetRegistryResponse['roles'];
  survey: GetRegistryResponse['surveys'][0];
}

export const ActivityCard = memo(({ registryId, survey, roles }: Props) => {
  const { t } = useTranslation();
  const { id, name, statistics, roleId } = survey;
  const surveyRoleName = roles?.find(
    ({ id: surveyRoleId }) => surveyRoleId === roleId,
  )?.name;

  const estimatedTime = statistics ? getEstimatedTime(statistics) : null;

  return (
    <Container>
      <ButtonBase
        component={Link}
        to={generatePath('/registries/:registryId/survey/:surveyId', {
          registryId,
          surveyId: id,
        })}
        state={{ returnUrl: window.location.pathname } as SurveyLocationState}
        data-cy={`survey-${kebabCase(name)}`}
      >
        <SurveyWrapper>
          <SurveyInformationWrapper>
            <BrainIllustrationWrapper>
              <BrainLeftIllustration />
            </BrainIllustrationWrapper>
            <Stack alignItems="baseline" gap="0.4rem">
              {roleId && (
                <RoleWrapper data-cy="role-name">
                  {t(messages.forRoleOnly(), { role: surveyRoleName })}
                </RoleWrapper>
              )}
              <SurveyName
                data-cy="survey-name"
                aria-label={`Survey name: ${name},`}
              >
                {name}
              </SurveyName>
              {estimatedTime !== null && (
                <Stack direction="row" spacing="2rem" justifyContent="center">
                  <SurveyInformation
                    data-cy="estimated-time"
                    aria-label={`${t(
                      messages.estimatedTime(),
                    )} ${estimatedTime} ${t(messages.minutes())},`}
                  >
                    <ClockIcon />
                    <span>
                      {estimatedTime} {t(messages.min())}
                    </span>
                  </SurveyInformation>
                </Stack>
              )}
            </Stack>
          </SurveyInformationWrapper>
          <ArrowRight2 />
        </SurveyWrapper>
      </ButtonBase>
    </Container>
  );
});
