import { ActivityCard } from 'components/ActivityCard';
import { SurveysContainer } from 'pages/Registry/styled';
import { GetRegistryResponse } from 'api/interfaces/response/getRegistryResponse';
import { useUserRole } from 'api/storage/userRoles/hook';
import { NoSurveysAssigned } from '../common/NoSurveysAssigned';
import { NoSurveysLeft } from '../common/NoSurveysLeft';

interface Props {
  data: GetRegistryResponse;
}

export const AllSurveys = ({ data }: Props) => {
  const { roleId } = useUserRole(data.id);

  const filteredSurveys = data?.surveys?.filter(
    survey => survey.roleId === roleId || !survey.roleId,
  );

  if (data.cohorts.length === 0) return <NoSurveysAssigned />;
  if (filteredSurveys.length === 0) return <NoSurveysLeft />;
  return (
    <SurveysContainer>
      {filteredSurveys.map(survey => (
        <ActivityCard
          registryId={data.id}
          roles={data.roles}
          survey={survey}
          key={survey.id}
        />
      ))}
    </SurveysContainer>
  );
};
