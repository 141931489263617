import styled from 'styled-components';
import { Dialog } from '@mui/material';
import { Document } from 'react-pdf';
import { scrollbar } from 'helpers/mixins';

export type ModalWidth = 'regular' | 'narrow';

export const StyledModal = styled(Dialog)`
  display: flex;
  justify-content: center;

  > .MuiDialog-container {
    width: 100%;

    > .MuiDialog-paper {
      background: transparent;
      vertical-align: top;
      max-height: 100%;
      background: ${({ theme }) => theme.palette.background.elevated.primary};
      overflow: hidden;

      ${({ theme }) => theme.breakpoints.up('md')} {
        margin: 5rem 7.2rem;
        max-height: calc(100vh - 10rem);
        height: 100%;
        width: 100%;
        min-width: calc(100% - 14.2rem);
        border-radius: 1.6rem;
      }

      ${({ theme }) => theme.breakpoints.down('md')} {
        width: unset;
        margin: unset;
        height: 100%;
        max-width: unset;
        width: 100%;
      }

      ${({ theme }) => theme.breakpoints.up('xs')} {
        padding: 2.4rem;
      }

      ${({ theme }) => theme.breakpoints.up('sm')} {
        padding: 3.2rem;
      }

      ${({ theme }) => theme.breakpoints.up('lg')} {
        padding: 4rem;
      }
    }
  }
`;

export const StyledDocument = styled(Document)`
  margin: 3.2rem 0;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  max-height: 100%;
  display: grid;
  grid-gap: 2.4rem;
  grid-template-columns: 1fr 5fr;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }

  > div {
    overflow-y: auto;
    height: 100%;

    ${({ theme }) =>
      scrollbar({
        thumbBackground: theme.palette.separator.primary,
        thumbHovered: theme.palette.separator.hover,
      })}
  }

  .react-pdf__message {
    width: 100%;
    height: 100%;
    grid-column: 1/3;
  }
`;

export const PageWrapper = styled.div`
  border-radius: 0.8rem;

  .react-pdf__Page {
    > * {
      max-width: 100%;
    }

    > canvas {
      width: 100% !important;
      height: auto !important;
    }
  }

  .react-pdf__Page:first-of-type > canvas {
    border-radius: 0.8rem 0.8rem 0 0;
  }

  .react-pdf__Page:last-of-type > canvas {
    border-radius: 0 0 0.8rem 0.8rem;
  }
`;

export const TableOfContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }

  .react-pdf__Page {
    > *:not(canvas) {
      display: none;
    }
    > canvas {
      width: 100% !important;
      max-width: 100%;
      height: auto !important;
      border-radius: 0.8rem;
      cursor: pointer;
    }
  }
`;
