import { memo, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Fade } from '@mui/material';
import { RoleSelection } from 'components/RoleSelection';
import { GetRegistryResponse } from 'api/interfaces/response';
import UserRoles from 'api/storage/userRoles';
import { UpperBar } from './UpperBar';
import { RolesOverlay, RolesOverlayContent } from './styled';
import { messages } from './messages';

interface Props {
  onConfirm(roleId: string): void;
  roles: GetRegistryResponse['roles'];
  in: boolean;
  qualificationSurvey: boolean;
  onClose(): void;
}

export const RoleManagement = memo(
  ({ onConfirm, roles, in: visible, qualificationSurvey, onClose }: Props) => {
    const { t } = useTranslation();
    const { registryId } = useParams<{
      registryId: string;
      surveyId: string;
    }>();
    const [selectedRole, setSelectedRole] = useState<string | undefined>(
      undefined,
    );

    const saveAndConfirm = () => {
      if (!selectedRole) return;
      if (registryId) UserRoles.saveRoleForRegistry(registryId, selectedRole);
      onConfirm(selectedRole);
    };

    const loadSavedRole = () => {
      if (!registryId) return;

      const savedRole = UserRoles.getRoleForRegistry(registryId);
      if (savedRole && roles.some(({ id }) => id === savedRole))
        onConfirm(savedRole);
    };

    // synchronous useEffect causes less "jumping" here
    useLayoutEffect(() => {
      loadSavedRole();
      if (roles.length === 1) onConfirm(roles[0].id);
    }, [roles]);

    if (!roles.length) return null;

    return (
      <Fade in={visible}>
        <RolesOverlay data-cy="role-selection-overlay">
          <UpperBar
            qualificationSurvey={qualificationSurvey}
            onClose={onClose}
          />
          <RolesOverlayContent>
            <RoleSelection
              roles={roles}
              selectedId={selectedRole}
              onSelect={setSelectedRole}
              showTitle
            />
            <Button
              onClick={saveAndConfirm}
              disabled={!selectedRole}
              style={{
                maxWidth: '63.6rem',
                width: '100%',
              }}
              data-cy="confirm-role-button"
            >
              {qualificationSurvey
                ? t(messages.startQualificationSurvey())
                : t(messages.startSurvey())}
            </Button>
          </RolesOverlayContent>
        </RolesOverlay>
      </Fade>
    );
  },
);
