import * as yup from 'yup';
import i18next from 'i18next';
import { getPasswordValidationSchema } from 'components/PasswordValidator';
import { messages } from '../../messages';

export const getValidationSchema = () =>
  yup.object({
    code: yup.array().compact().length(6, i18next.t(messages.emptyCode())),
    password: getPasswordValidationSchema(),
  });
