import styled, { css } from 'styled-components';
import { Fade } from '@mui/material';

export const FixedFadeContainer = styled(Fade)<{ $zIndex?: number }>`
  position: fixed;
  height: 100%;
  width: 100%;

  ${({ $zIndex }) =>
    $zIndex &&
    css`
      z-index: ${$zIndex};
    `}
`;
