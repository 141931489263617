import { useQuery } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import Api from 'api/api';
import { queryKeys } from 'api/queryKeys';
import { errorToast } from 'helpers/toast';
import { useScrollTop } from 'hooks/useScrollTop';
import { Params } from 'pages/Qualification/types';
import { LoadingPage } from 'pages/Loading';
import { StatisticsBar } from './components/StatisticsBar';
import { AboutRegistry } from './components/AboutRegistry';
import { StepsPreviewPanel } from './components/StepsPreviewPanel';
import { FrequentlyAskedQuestions } from './components/FrequentlyAskedQuestions';
import { ParticipationSection } from './components/ParticipationSection';
import { IntroSection } from './components/IntroSection';
import { SecuritySection } from './components/SecuritySection';
import { ContactSection } from './components/ContactSection';
import { PublicRegistriesNotification } from './components/PublicRegistriesNotification';
import { TopBar } from './components/TopBar';
import { Footer } from './components/Footer';
import { PageWrapper } from './styled';

export const IntroPage = () => {
  const { registryCode } = useParams<Params>();
  const navigate = useNavigate();
  useScrollTop();

  const { data, isLoading } = useQuery({
    queryKey: queryKeys.publicRegistry({ registryCode: registryCode! }),
    queryFn: () => Api.getPublicRegistry(registryCode!),
    staleTime: 30 * 60 * 1000,
    onError: e => {
      if (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e as any)?.response?.data?.message ===
        'Registry does not exist or user has no access to it'
      )
        return navigate('/not-found');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const message = (e as any)?.response?.data?.message ?? (e as any).message;
      errorToast(message);
    },
  });

  const getRegistries = useQuery({
    queryKey: queryKeys.publicRegistries(),
    queryFn: Api.getPublicRegistries,
    staleTime: 30 * 60 * 1000,
  });

  if (!data || isLoading || getRegistries.status === 'loading')
    return <LoadingPage />;

  const { name, description, statistics, additionalParams } = data;
  const introPage = additionalParams?.introPage;
  return (
    <PageWrapper>
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <PublicRegistriesNotification />
      <TopBar />
      <IntroSection
        name={name}
        description={description}
        registryIllustration={introPage?.registryIllustration}
        registryIllustrationDark={introPage?.registryIllustrationDark}
      />
      <StatisticsBar
        researchers={introPage?.researchers}
        beneficiaries={introPage?.beneficiaries}
        {...statistics}
      />
      <AboutRegistry
        longDescription={introPage?.longDescription}
        goals={introPage?.goals}
      />
      <ParticipationSection
        participantDescription={
          introPage?.participation?.participantDescription
        }
        participationRequirements={
          introPage?.participation?.participationRequirements
        }
      />
      <StepsPreviewPanel />
      <SecuritySection />
      <FrequentlyAskedQuestions
        questionsAndAnswers={introPage?.frequentlyAskedQuestions}
        registryCode={registryCode}
        registryId={data.id}
      />
      <ContactSection />
      <Footer />
    </PageWrapper>
  );
};
