import { getTodayDate } from 'helpers/dates';

export const queryKeys = {
  pdf: (dependencies: { path: string }) => ['pdf', dependencies] as const,
  allRegistries: () => ['registries'],
  registries: (dependencies: { userEmail: string }) =>
    [...queryKeys.allRegistries(), dependencies] as const,
  registry: (dependencies: { userEmail: string; registryId: string }) =>
    // time is added to refetch daily because of recurring surveys
    [...queryKeys.allRegistries(), dependencies, getTodayDate()] as const,
  publicRegistries: () => [...queryKeys.allRegistries()] as const,
  publicRegistry: (dependencies: { registryCode: string }) =>
    [...queryKeys.allRegistries(), dependencies] as const,
  userAgreements: (dependencies: { userEmail: string }) =>
    ['agreements', dependencies] as const,
  userRegistryNotifications: (dependencies: { userEmail: string }) =>
    ['notifications', dependencies] as const,
  survey: (dependencies: {
    registryId?: string;
    surveyId?: string;
    qualificationSurvey: boolean;
  }) => ['survey', dependencies] as const,
  userEmail: (dependencies: {
    registryId: string;
    participationId: string;
    emailHash: string;
  }) => ['email', dependencies] as const,
  healthcareProviders: () => ['healthcareProviders'] as const,
  registryPersons: (dependencies: { registryId: string; roles: string[] }) =>
    ['registryPersons', dependencies] as const,
};
