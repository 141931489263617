/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  settings: () => _t(translations.pages.settings.settings, 'Settings'),
  manageYourSettings: () =>
    _t(
      translations.pages.settings.manageYourSettings,
      'Manage your account settings and preferences.',
    ),
  password: () => _t(translations.pages.settings.password, 'Password'),
  chooseStrongPassword: () =>
    _t(
      translations.pages.settings.chooseStrongPassword,
      'Choose a strong password and don’t reuse it for other accounts.',
    ),
  changePassword: () =>
    _t(translations.pages.settings.changePassword, 'Change password'),
  consents: () => _t(translations.pages.settings.consents, 'Consents'),
  someConsentsAreRequirred: () =>
    _t(
      translations.pages.settings.someConsentsAreRequirred,
      'Some of the consents are required to take part in the registries.',
    ),
  manageConsents: () =>
    _t(translations.pages.settings.manageConsents, 'Manage consents'),

  unknownError: () =>
    _t(
      translations.pages.settings.unknownError,
      'Unknown error occurred, try again later',
    ),

  // password modal
  setStrongPassword: () =>
    _t(
      translations.pages.settings.setStrongPassword,
      'Set a strong password and don’t reuse it for other accounts.',
    ),
  currentPassword: () =>
    _t(translations.pages.settings.currentPassword, 'Current password'),
  newPassword: () =>
    _t(translations.pages.settings.newPassword, 'New password'),
  cancel: () => _t(translations.pages.settings.cancel, 'Cancel'),
  saveChanges: () =>
    _t(translations.pages.settings.saveChanges, 'Save changes'),
  passwordSaved: () =>
    _t(
      translations.pages.settings.passwordSaved,
      'Your new password saved successfully.',
    ),
  consentSaved: () =>
    _t(
      translations.pages.settings.consentSaved,
      'Consents settings saved successfully.',
    ),
  incorrectCurrentPassword: () =>
    _t(
      translations.pages.settings.incorrectCurrentPassword,
      'Incorrect current password',
    ),
  passwordChanged: () =>
    _t(translations.pages.settings.passwordChanged, 'Password changed'),
  limitExceeded: () =>
    _t(
      translations.pages.settings.limitExceeded,
      'Attempt limit exceeded, please try after some time.',
    ),

  // consent modal
  youCannotChange: () =>
    _t(
      translations.pages.settings.youCannotChange,
      'You cannot change this consent as it is required to take part in this registry.',
    ),
  thisConsentIsRequired: () =>
    _t(
      translations.pages.qualification.thisConsentIsRequired,
      'This consent is required to take part in the research.',
    ),
  personalDetails: () =>
    _t(translations.pages.settings.personalDetails, 'Personal details'),
  emailAddress: () =>
    _t(translations.pages.settings.emailAddress, 'E-mail address'),
  change: () => _t(translations.pages.settings.change, 'Change'),

  // notifications
  notifications: () =>
    _t(translations.pages.settings.notifications, 'Notifications'),
  receiveEmailNotifications: () =>
    _t(
      translations.pages.settings.receiveEmailNotifications,
      'Receive email notifications about available surveys: ',
    ),
  yes: () => _t(translations.pages.settings.yes, 'Yes'),
  no: () => _t(translations.pages.settings.no, 'No'),
  accountEmail: () =>
    _t(translations.pages.settings.accountEmail, 'Account email'),
  caregiverEmail: () =>
    _t(
      translations.pages.settings.caregiverEmail,
      'Participant’s Caregiver email',
    ),
};
