import styled from 'styled-components';
import { IconButton as IconButtonUnstyled } from '@mui/material';

export const IconButton = styled(IconButtonUnstyled)`
  &&&& svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.4rem;
    fill: none;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;
