import styled from 'styled-components';

export const PageWrapper = styled.div`
  overflow-x: hidden;
  background: ${({ theme }) => theme.palette.background.primaryMobile};
`;

export const SecondaryContainer = styled.div`
  padding: 9.6rem 7.2rem;
  width: 100%;
  background: ${({ theme }) => theme.palette.additionalColors.background};
`;
