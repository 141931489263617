import { useEffect, useRef } from 'react';
import { secondsToMilliseconds } from 'date-fns';
import Session from 'api/storage/session';

const REFRESH_THRESHOLD = 60 * 1000; // one minute

export const useAutoRefreshSession = () => {
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const extendSession = async () => {
      const decodedToken = Session.decodeToken();
      const isSessionActive = await Session.isSessionActive();

      if (!isSessionActive || !decodedToken) return;

      const expTime = secondsToMilliseconds(decodedToken.exp);

      const timeToRefresh = expTime - new Date().getTime() - REFRESH_THRESHOLD;

      if (timeToRefresh <= 0) {
        await Session.refreshCognitoSession();
        extendSession(); // recalculate timeout
      } else {
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(extendSession, timeToRefresh);
      }
    };

    extendSession();

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);
};
