import { memo } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { useValidationSchema } from 'hooks/useValidationSchema';
import { Select } from 'components/Select';
import { FormikTextField } from 'components/FormikTextField';
import { ErrorMessage } from 'components/ErrorMessage';
import { getValidationSchema } from './validators';
import { messages } from '../../messages';
import { CaregiverDetailsForm } from '../../types';
import { caregiverRelationships } from '../../constants';

interface Props {
  onBack: (values: CaregiverDetailsForm) => void;
  onSubmit: (values: CaregiverDetailsForm) => void;
  stepForm: CaregiverDetailsForm;
  registryName: string;
}

export const CaregiverDetailsStep = memo(
  ({ onBack, onSubmit, stepForm, registryName }: Props) => {
    const { t } = useTranslation();
    const validationSchema = useValidationSchema(getValidationSchema);

    return (
      <Formik
        initialValues={stepForm}
        onSubmit={values => onSubmit(values)}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form data-cy="caregiver-details-step">
            <Stack spacing="1.6rem" direction="column" justifyContent="center">
              <Typography
                variant="body4"
                textAlign="center"
                marginBottom="0.4rem"
                color="button.primary"
              >
                {registryName}
              </Typography>
              <Typography variant="h1" textAlign="center">
                {t(messages.caregiverDetails())}
              </Typography>
              <Typography variant="body1">
                {t(messages.letUsKnowRelationship())}
              </Typography>
              <FormikTextField
                name="firstName"
                placeholder={t(messages.firstName())}
                validationSchema={validationSchema}
                oneWayValidationOnChange={true}
                data-cy="caregiver-first-name-input"
                label={t(messages.firstName())}
              />
              <FormikTextField
                name="lastName"
                placeholder={t(messages.lastName())}
                validationSchema={validationSchema}
                oneWayValidationOnChange={true}
                data-cy="caregiver-last-name-input"
                label={t(messages.lastName())}
              />
              <div>
                <Typography variant="subtitle1" textTransform="uppercase">
                  {t(messages.relationshipWithMember())}
                </Typography>
                <Select
                  changeValue={value =>
                    setFieldValue('relationship', value, true)
                  }
                  values={caregiverRelationships.map(relationship => ({
                    value: relationship,
                    label: relationship,
                  }))}
                  capitalizeFirstLetter
                  value={values.relationship || undefined}
                  placeholder={t(messages.select())}
                  aria-invalid={
                    errors.relationship && touched.relationship
                      ? 'true'
                      : 'false'
                  }
                  data-cy="relationship-input"
                />
                <ErrorMessage
                  message={errors.relationship}
                  visible={Boolean(errors.relationship && touched.relationship)}
                  style={{ marginTop: '0.8rem' }}
                  data-cy={`relationship-input-error`}
                />
              </div>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                data-cy="caregiver-details-step-continue-button"
                style={{ marginTop: '3.2rem' }}
                disabled={Object.values(errors).length > 0}
              >
                {t(messages.continue())}
              </Button>
              <Button
                onClick={() => onBack(values)}
                color="primary"
                variant="text"
                data-cy="caregiver-details-step-back-button"
                style={{ marginTop: '3rem' }}
              >
                {t(messages.back())}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    );
  },
);
