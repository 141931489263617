import {
  INITIAL_TOP_SURVEYS_LENGTH,
  STORAGE_TOP_SURVEYS_PREFIX,
} from 'config/constants';

export default class NumberOfTopPrioritySurveys {
  public static resetNumberForRegistry(
    registryId: string,
    numberOfAllSurveys: number,
  ) {
    if (!registryId) return;
    const storageKey: string = STORAGE_TOP_SURVEYS_PREFIX + registryId;
    localStorage.setItem(storageKey, `0`);
  }

  public static initNumberForRegistry(
    registryId: string,
    numberOfAllSurveys: number,
  ) {
    if (!registryId) return;
    const storageKey: string = STORAGE_TOP_SURVEYS_PREFIX + registryId;
    if (localStorage.getItem(storageKey) === null)
      this.resetNumberForRegistry(registryId, numberOfAllSurveys);
  }

  public static getNumberForRegistry(registryId: string) {
    const storageKey: string = STORAGE_TOP_SURVEYS_PREFIX + registryId;
    const storedValue: string | null = localStorage.getItem(storageKey);
    return storedValue ? Number(storedValue) : 0;
  }

  public static incrementNumberForRegistry(registryId: string) {
    const storageKey: string = STORAGE_TOP_SURVEYS_PREFIX + registryId;
    const currentLength = this.getNumberForRegistry(registryId);
    if (currentLength > INITIAL_TOP_SURVEYS_LENGTH) return;
    localStorage.setItem(storageKey, `${currentLength + 1}`);
  }
}
