/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  startSurvey: () => _t(translations.pages.survey.startSurvey, 'Start survey'),
  startQualificationSurvey: () =>
    _t(
      translations.pages.survey.startQualificationSurvey,
      'Start qualification survey',
    ),
  qualificationSurvey: () =>
    _t(translations.pages.survey.qualificationSurvey, 'Qualification survey'),
  continue: () => _t(translations.pages.survey.continue, 'Continue'),
  estimatedTime: () =>
    _t(translations.pages.survey.estimatedTime, 'Estimated time: '),
  min: () => _t(translations.pages.survey.min, 'min'),
  minutes: () => _t(translations.pages.survey.minutes, 'minutes'),
  survey: () => _t(translations.pages.survey.survey, 'Survey'),
  roleSelection: () =>
    _t(translations.pages.survey.roleSelection, 'Role selection'),
};
