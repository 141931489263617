import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import { ConnectHealthRecordsIllustration } from 'assets/illustrations';
import {
  Params as SettingsParams,
  RedirectActions,
} from 'pages/Settings/types';
import {
  Container,
  Card,
  StyledAddIcon,
  StyledCloseIcon,
  CloseIconWrapper,
  BackgroundImageWrapper,
  ConnectButton,
} from './styled';
import { messages } from './messages';

interface Props {
  show: boolean;
  onClose: () => void;
  displaySmallerCard?: boolean;
}

export const ShareHealthRecordsCard = forwardRef<HTMLDivElement, Props>(
  ({ show, onClose, displaySmallerCard = false }, ref) => {
    const { t } = useTranslation();

    return (
      <Container show={show} ref={ref} aria-hidden={show}>
        <Card>
          {displaySmallerCard && (
            <BackgroundImageWrapper>
              <ConnectHealthRecordsIllustration />
            </BackgroundImageWrapper>
          )}

          <CloseIconWrapper onClick={onClose} disableRipple>
            <StyledCloseIcon />
          </CloseIconWrapper>
          {!displaySmallerCard && (
            <ConnectHealthRecordsIllustration
              style={{
                width: '12rem',
                height: '9.3rem',
                marginBottom: '1.6rem',
              }}
            />
          )}

          <Typography
            variant="h5"
            maxWidth={displaySmallerCard ? 'calc(100% - 3.6rem)' : '100%'}
          >
            {t(messages.header())}
          </Typography>
          <Typography variant="body1">{t(messages.description())}</Typography>
          <ConnectButton
            to="/settings"
            state={
              {
                redirectAction: RedirectActions.CONNECT_PROVIDER,
              } as SettingsParams
            }
            data-cy="connect-ehr-account"
          >
            <StyledAddIcon />
            {t(messages.connectAccount())}
          </ConnectButton>
        </Card>
      </Container>
    );
  },
);
