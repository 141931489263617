import { Skeleton } from '@mui/material';

export const NotificationsSectionSkeleton = () => {
  return (
    <Skeleton
      variant="rectangular"
      width="100%"
      height="13rem"
      style={{
        borderRadius: '1.6rem',
      }}
    />
  );
};
