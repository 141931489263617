import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Auth } from '@aws-amplify/auth';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';

import { ErrorMessage } from 'components/ErrorMessage';
import { PasswordInput } from 'components/PasswordInput';
import { useValidationSchema } from 'hooks/useValidationSchema';
import { messages } from '../../messages';
import { getValidationSchema } from './validators';

interface FormTypes {
  password: string;
}

interface Props {
  onSuccess(cognitoUser: CognitoUserInterface): void;
  onMFA(cognitoUser: CognitoUserInterface, password: string): void;
  onNotConfirmed(password: string): void;
  onForgotPassword(): void;
  onBack(): void;
  username: string;
}

export const PasswordStep = memo(
  ({
    onSuccess,
    onMFA,
    onNotConfirmed,
    onForgotPassword,
    onBack,
    username,
  }: Props) => {
    const { t } = useTranslation();
    const validationSchema = useValidationSchema(getValidationSchema);
    const { mutate, isLoading, error } = useMutation<
      CognitoUserInterface,
      Record<string, unknown>,
      FormTypes
    >({ mutationFn: async ({ password }) => Auth.signIn(username, password) });

    const submitForm = (form: FormTypes) => {
      mutate(form, {
        onSuccess: cognitoUser => {
          if (cognitoUser.challengeName === 'SMS_MFA')
            onMFA(cognitoUser, form.password);
          else {
            onSuccess(cognitoUser);
          }
        },
        onError: e => {
          if (e?.name === 'UserNotConfirmedException')
            onNotConfirmed(form.password);
        },
      });
    };

    const mapError = () => {
      if (error?.code === 'NotAuthorizedException')
        return t(messages.incorrectPassword());
    };

    return (
      <>
        <Formik
          initialValues={{ password: '' } as FormTypes}
          onSubmit={submitForm}
          validationSchema={validationSchema}
          validateOnChange={false}
        >
          <Form data-cy="password-step">
            <Stack spacing="2.4rem" direction="column" justifyContent="center">
              <Typography variant="h1">{t(messages.login())}</Typography>
              <Typography
                variant="body1"
                style={{ marginTop: '1.6rem' }}
                sx={{ color: 'text.secondary' }}
              >
                {username}
              </Typography>
              <PasswordInput
                name="password"
                validationSchema={validationSchema}
                oneWayValidationOnChange={true}
                data-cy="password-input"
                autoFocus
              />
              <ErrorMessage
                message={mapError()}
                style={{ marginTop: '0.8rem' }}
              />
              <LoadingButton
                type="submit"
                loading={isLoading}
                color="primary"
                variant="contained"
                data-cy="password-step-submit-button"
              >
                {t(messages.next())}
              </LoadingButton>
              <Button
                variant="text"
                onClick={onForgotPassword}
                data-cy="forgot-password-button"
              >
                {t(messages.forgotPassword())}
              </Button>
              <Button
                variant="text"
                onClick={onBack}
                data-cy="back-button"
                style={{ marginTop: '3.7rem' }}
              >
                {t(messages.back())}
              </Button>
            </Stack>
          </Form>
        </Formik>
      </>
    );
  },
);
