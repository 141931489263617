import { memo, useCallback, useState } from 'react';
import { format } from 'date-fns';
import Api from 'api/api';
import { AddRegistryParticipationRequest } from 'api/interfaces/request/addRegistryParticipationRequest';
import { GetPublicRegistryResponse } from 'api/interfaces/response';
import { AddRegistryParticipationResponse } from 'api/interfaces/response/addRegistryParticipationResponse';
import { AxiosError } from 'axios';
import { errorToast } from 'helpers/toast';
import { useBlockExit } from 'hooks/useBlockExit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { LogoutTimerTypes } from 'hooks/useLogoutTimer';
import { useAutofocus } from 'hooks/useAutofocus';
import { GlobalKeys, setGlobalVariable } from 'helpers/globalVariables';
import { AppLogout } from 'components/AppLogout';
import { AccountDetailsStep } from './components/AccountDetailsStep';
import { AgreementsStep } from './components/AgreementsStep';
import { CaregiverContactDetailsStep } from './components/CaregiverContactDetailsStep';
import { CaregiverDetailsStep } from './components/CaregiverDetailsStep';
import { ConfirmMemberApplicationModal } from './components/ConfirmMemberApplicationModalStep';
import { MemberDetailsStep } from './components/MemberDetailsStep';
import { NextStepsStep } from './components/NextStepsStep';
import { QualificationSurveyStep } from './components/QualificationSurveyStep';
import { RoleSelectionStep } from './components/RoleSelectionStep';
import { SurveyStartStep } from './components/SurveyStartStep';
import { getSectionName, getStepName } from './helpers';
import { messages } from './messages';
import { FormWrapper, FormContainer } from './styled';
import {
  GlobalFormState,
  QualificationSteps,
  RoleSelectionForm,
} from './types';
import { useStepsStateMachine } from './useStepsStateMachine';

interface Props {
  registry: GetPublicRegistryResponse;
  initialFormState: GlobalFormState;
}

export const QualificationFormManager = memo(
  ({ registry, initialFormState }: Props) => {
    const [globalForm, setGlobalForm] =
      useState<GlobalFormState>(initialFormState);
    const [invalidEmail, setInvalidEmail] = useState<string | null>(null);
    const { step, next, prev, setStep, getPrev } = useStepsStateMachine({
      role: globalForm[QualificationSteps.ROLE_SELECTION].role?.code,
      onlySingleRoleAvailable: registry.roles.length <= 1,
      noAgreements:
        globalForm[QualificationSteps.ROLE_SELECTION].role?.agreements
          ?.length === 0 ?? true,
    });
    const { t } = useTranslation();
    const autofocus = useAutofocus(true, [step]);
    const navigate = useNavigate();
    useBlockExit(
      ![QualificationSteps.NEXT_STEP, QualificationSteps.WELCOME_PAGE].includes(
        step,
      ),
    );

    const setGlobalFormStep = <K extends keyof GlobalFormState>(
      formStep: K,
      submittedStepValues: GlobalFormState[K],
    ) => {
      const nextState = {
        ...globalForm,
        [formStep]: submittedStepValues,
      };
      setGlobalForm(nextState);
      return nextState;
    };

    const getRequestData = (): AddRegistryParticipationRequest => {
      const hasCaregiverRole =
        globalForm[QualificationSteps.ROLE_SELECTION].role.code === 'caregiver';

      const requestData: AddRegistryParticipationRequest = {
        roleId: globalForm[QualificationSteps.ROLE_SELECTION].role.id,
        ...globalForm[QualificationSteps.AGREEMENTS],
        email: globalForm[QualificationSteps.ACCOUNT_DETAILS].email,
        persons: [
          {
            roleCode: 'member',
            ...globalForm[QualificationSteps.MEMBER_DETAILS],
            dateOfBirth: globalForm[QualificationSteps.MEMBER_DETAILS]
              .dateOfBirth
              ? format(
                  globalForm[QualificationSteps.MEMBER_DETAILS].dateOfBirth!,
                  'yyyy-MM-dd',
                )
              : '',
            phone:
              globalForm[QualificationSteps.MEMBER_DETAILS].phone || undefined,
          },
          ...(hasCaregiverRole
            ? ([
                {
                  roleCode: 'caregiver' as const,
                  ...globalForm[QualificationSteps.CAREGIVER_DETAILS],
                  ...globalForm[QualificationSteps.CAREGIVER_CONTACT_DETAILS],
                  phone:
                    globalForm[QualificationSteps.CAREGIVER_CONTACT_DETAILS]
                      .phone || undefined,
                },
              ] as const)
            : ([] as const)),
        ],
      };

      return requestData;
    };

    const { mutate, isLoading: isLoadingAddRegistryParticipation } =
      useMutation<
        AddRegistryParticipationResponse,
        AxiosError,
        AddRegistryParticipationRequest
      >({
        mutationFn: requestData =>
          Api.addRegistryParticipation(registry.id, requestData),
        onSuccess: response => {
          setInvalidEmail(null);
          setGlobalFormStep(QualificationSteps.CONFIRM_APPLICATION, response);
          next();
        },
        onError: (err, requestData) => {
          if (err.response?.status !== 409) {
            errorToast(t(messages.unknownErrorOccured()));
          } else {
            setInvalidEmail(requestData.email);
            setStep(QualificationSteps.ACCOUNT_DETAILS);
          }
        },
      });

    const addRegistryParticipation = () => {
      return mutate(getRequestData());
    };

    const nextWithSaveForm = <T extends keyof GlobalFormState>(
      formStep: T,
      values: GlobalFormState[T],
    ) => {
      setGlobalFormStep(formStep, values);
      next();
    };

    const prevWithSaveForm = <T extends keyof GlobalFormState>(
      formStep: T,
      values: GlobalFormState[T],
    ) => {
      setGlobalFormStep(formStep, values);
      prev();
    };

    const resetFormState = useCallback(() => {
      setGlobalForm(initialFormState);
      setInvalidEmail(null);
    }, [setGlobalForm, setInvalidEmail]);

    const onRoleSelect = (role: RoleSelectionForm['role']) => {
      const nextState = setGlobalFormStep(QualificationSteps.ROLE_SELECTION, {
        role,
      });
      next({
        role: nextState[QualificationSteps.ROLE_SELECTION].role.code,
        onlySingleRoleAvailable: registry.roles.length <= 1,
        noAgreements:
          nextState[QualificationSteps.ROLE_SELECTION].role?.agreements
            ?.length === 0 ?? true,
      });
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getStepComponent = (step: QualificationSteps): React.ReactNode => {
      switch (step) {
        case QualificationSteps.MEMBER_DETAILS:
          return (
            <MemberDetailsStep
              onBack={values =>
                prevWithSaveForm(QualificationSteps.MEMBER_DETAILS, values)
              }
              onSubmit={values =>
                nextWithSaveForm(QualificationSteps.MEMBER_DETAILS, values)
              }
              stepForm={globalForm[QualificationSteps.MEMBER_DETAILS]}
              registryName={registry.name}
            />
          );
        case QualificationSteps.WELCOME_PAGE:
        case QualificationSteps.ROLE_SELECTION:
          return (
            <RoleSelectionStep
              onSelect={onRoleSelect}
              prev={() => prev()}
              roles={registry.roles}
              registryName={registry.name}
            />
          );
        case QualificationSteps.CAREGIVER_DETAILS:
          return (
            <CaregiverDetailsStep
              onBack={values =>
                prevWithSaveForm(QualificationSteps.CAREGIVER_DETAILS, values)
              }
              onSubmit={values =>
                nextWithSaveForm(QualificationSteps.CAREGIVER_DETAILS, values)
              }
              stepForm={globalForm[QualificationSteps.CAREGIVER_DETAILS]}
              registryName={registry.name}
            />
          );
        case QualificationSteps.CAREGIVER_CONTACT_DETAILS:
          return (
            <CaregiverContactDetailsStep
              onBack={values =>
                prevWithSaveForm(
                  QualificationSteps.CAREGIVER_CONTACT_DETAILS,
                  values,
                )
              }
              onSubmit={values =>
                nextWithSaveForm(
                  QualificationSteps.CAREGIVER_CONTACT_DETAILS,
                  values,
                )
              }
              stepForm={
                globalForm[QualificationSteps.CAREGIVER_CONTACT_DETAILS]
              }
              registryName={registry.name}
            />
          );
        case QualificationSteps.ACCOUNT_DETAILS:
          return (
            <AccountDetailsStep
              onBack={values =>
                prevWithSaveForm(QualificationSteps.ACCOUNT_DETAILS, values)
              }
              onSubmit={values =>
                nextWithSaveForm(QualificationSteps.ACCOUNT_DETAILS, values)
              }
              stepForm={globalForm[QualificationSteps.ACCOUNT_DETAILS]}
              role={globalForm[QualificationSteps.ROLE_SELECTION].role.code}
              perspective={
                globalForm[QualificationSteps.ROLE_SELECTION].role.perspective
              }
              invalidEmail={invalidEmail}
              registryName={registry.name}
            />
          );
        case QualificationSteps.AGREEMENTS:
          return (
            <AgreementsStep
              onBack={agreements => {
                prevWithSaveForm(QualificationSteps.AGREEMENTS, agreements);
              }}
              next={agreements => {
                nextWithSaveForm(QualificationSteps.AGREEMENTS, agreements);
              }}
              stepForm={globalForm[QualificationSteps.AGREEMENTS]}
              agreements={
                globalForm[QualificationSteps.ROLE_SELECTION].role.agreements
              }
              registryName={registry.name}
            />
          );
        case QualificationSteps.CONFIRM_APPLICATION:
          return getStepComponent(getPrev() as QualificationSteps);
        case QualificationSteps.SURVEY_START:
          return (
            <SurveyStartStep
              next={next}
              notNow={() => {
                setStep(QualificationSteps.NEXT_STEP);
              }}
              registryName={registry.name}
            />
          );
        case QualificationSteps.QUALIFICATION_SURVEY:
          return (
            <QualificationSurveyStep
              role={globalForm[QualificationSteps.ROLE_SELECTION].role!}
              next={next}
              back={() => setStep(QualificationSteps.SURVEY_START)}
              registryId={registry.id}
              participationId={
                globalForm[QualificationSteps.CONFIRM_APPLICATION]
                  .participationId
              }
              qualificationSurveyId={registry.qualification.survey.id}
            />
          );
        case QualificationSteps.NEXT_STEP:
          return (
            <NextStepsStep
              email={globalForm[QualificationSteps.ACCOUNT_DETAILS].email}
              next={() => {
                resetFormState();
                navigate(`/registry/${registry.code}`);
              }}
            />
          );
        default:
          return null;
      }
    };

    const onLogout = useCallback(() => {
      resetFormState();
      setGlobalVariable(GlobalKeys.FORCE_REDIRECT, true);
      navigate(`/registry/${registry.code}`);
      setStep(QualificationSteps.WELCOME_PAGE);
    }, [setStep, resetFormState]);

    const getPageTitle = () =>
      [getSectionName(step), registry.name, getStepName(step)]
        .filter(Boolean)
        .join(' - ');

    return (
      <FormContainer>
        <Helmet>
          <title>{getPageTitle()}</title>
        </Helmet>
        <AppLogout onLogout={onLogout} type={LogoutTimerTypes.QUALIFICATION} />
        <FormWrapper {...autofocus}>{getStepComponent(step)}</FormWrapper>
        <ConfirmMemberApplicationModal
          isOpen={step === QualificationSteps.CONFIRM_APPLICATION}
          onSubmit={addRegistryParticipation}
          onClose={() => prev()}
          role={globalForm[QualificationSteps.ROLE_SELECTION].role.code}
          formState={globalForm}
          updateForm={setGlobalForm}
          isLoading={isLoadingAddRegistryParticipation}
          registryName={registry.name}
        />
      </FormContainer>
    );
  },
);
