import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as NoAssignmentsIllustrationLight } from './noAssignments.svg';
import { ReactComponent as NoAssignmentsIllustrationDark } from './noAssignments_dark.svg';

export const NoAssignmentsIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return <NoAssignmentsIllustrationDark aria-hidden="true" {...props} />;
    return <NoAssignmentsIllustrationLight aria-hidden="true" {...props} />;
  },
);
