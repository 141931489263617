import { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Fade, Stack, Typography } from '@mui/material';
import { StartButton } from 'components/StartButton';
import { QualificationSurveyValidationIllustration } from 'assets/illustrations';
import { useAutofocus } from 'hooks/useAutofocus';

import { AbsoluteOverlayContainer, OverlayContent } from './styled';
import { messages } from '../messages';

interface Props {
  show: boolean;
  qualificationSurveyId?: string;
  registryName?: string;
  hidden?: boolean;
}

export const FillOutQualificationSurvey = memo(
  ({ show, qualificationSurveyId, registryName, hidden }: Props) => {
    const { t } = useTranslation();
    const autofocus = useAutofocus(!hidden && show);

    return (
      <Fade in={show} appear={false}>
        <AbsoluteOverlayContainer aria-hidden={hidden ? 'true' : 'false'}>
          <OverlayContent
            data-cy="fill-out-qualification-survey-overlay"
            {...autofocus}
          >
            <Stack gap="2.4rem" direction="column" alignItems="center">
              <QualificationSurveyValidationIllustration
                style={{ maxWidth: '49.6rem', width: '100%' }}
              />
              <Typography variant="h1">
                {t(messages.youAreAlmostThere())}
              </Typography>
              <Typography variant="body1">
                <Trans
                  i18nKey={t(
                    messages.itLooksLikeYouDidntFillQualificationSurvey(),
                  )}
                  values={{ registry: registryName }}
                  components={[<strong />]}
                />
              </Typography>
            </Stack>
            <Stack justifyContent="stretch" width="100%">
              <StartButton
                component={Link}
                style={{ width: '100%', marginTop: '0.8rem' }}
                to={`qualification-survey/${qualificationSurveyId}`}
                state={{ firstCompletion: true }}
                data-cy="fill-out-qualification-survey"
              >
                {t(messages.fillOutQualificationSurvey())}
              </StartButton>
            </Stack>
          </OverlayContent>
        </AbsoluteOverlayContainer>
      </Fade>
    );
  },
);
