import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { AreasAssignedIllustration } from 'assets/illustrations';
import { compareCohorts } from 'helpers/cohorts';
import { Illustration, CohortList } from './styled';
import { messages } from '../messages';
import { RegistryLocationState } from '../types';

type Props = NonNullable<RegistryLocationState['cohorts']> & {
  registryName: string;
};

export const AnotherQualificationSurveyModalContent = memo(
  ({ newCohorts, previousCohorts, registryName }: Props) => {
    const { t } = useTranslation();
    const cohortsChanged = useMemo(
      () => compareCohorts(newCohorts, previousCohorts),
      [newCohorts, previousCohorts],
    );

    const getCopy = () => {
      if (newCohorts?.length === 0)
        return t(messages.accordingToYourLatestWeWill(), {
          registry: registryName,
        });
      if (cohortsChanged) return t(messages.weHaveChangedYourAreas());

      return t(messages.didNotChangeDiagnosis());
    };

    return (
      <>
        <Illustration as={AreasAssignedIllustration} />
        <Typography
          variant="h1"
          marginY="1.6rem"
          textAlign="center"
          data-cy={
            cohortsChanged
              ? 'diagnosis-changed-title'
              : 'diagnosis-unchanged-title'
          }
        >
          {cohortsChanged
            ? t(messages.registryAreasChanged())
            : t(messages.registryAreasUnchanged())}
        </Typography>
        <Typography variant="body1" textAlign="center" component="span">
          {t(getCopy(), {
            registry: registryName,
          })}
          <CohortList data-cy="new-cohorts-list">
            {newCohorts?.map(({ name, id }) => (
              <li key={id}>{name}</li>
            ))}
          </CohortList>
        </Typography>
      </>
    );
  },
);
