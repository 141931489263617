import { Button } from '@mui/material';
import styled from 'styled-components';
import { useTypography, scrollbar } from 'helpers/mixins';
import { StyleConstants } from 'styles/StyleConstants';

export const Label = styled(Button).attrs(() => ({
  disableTouchRipple: true,
  variant: 'text',
}))`
  && {
    display: inline;
    ${({ theme }) => useTypography(theme.typography.h4)}
    color: ${({ theme }) => theme.palette.button.primary};
    vertical-align: initial;
  }
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => useTypography(theme.typography.body1)}
  width: 100%;
  padding-right: calc(2rem + ${StyleConstants.SCROLLBAR_WIDTH}px);
  overflow-y: scroll;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    margin-left: 0;
    width: 100%;
  }

  ${({ theme }) =>
    scrollbar({
      thumbBackground: theme.palette.separator.primary,
      thumbHovered: theme.palette.separator.hover,
    })}

  p {
    ${({ theme }) => useTypography(theme.typography.body1)}
    padding-bottom: 3.2rem;
  }
`;
