import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Stack, Typography } from '@mui/material';
import { FeatureToggles } from 'types';
import { useAutofocus } from 'hooks/useAutofocus';
import { Feature } from 'helpers/featureToggles';
import { messages } from './messages';
import { HealthcareProviders } from './components/HealthcareProviders';
import { PersonalDetailsSection } from './components/PersonalDetailsSection';
import { ConsentsSection } from './components/ConsentsSection';
import { NotificationsSection } from './components/NotificationsSection';

export const SettingsPage = memo(() => {
  const { t } = useTranslation();
  const autofocus = useAutofocus();

  return (
    <Stack gap="3.2rem">
      <Helmet>
        <title>{t(messages.settings())}</title>
      </Helmet>
      <Stack gap="0.8rem">
        <Typography variant="h1" {...autofocus}>
          {t(messages.settings())}
        </Typography>
        <Typography variant="body1">
          {t(messages.manageYourSettings())}
        </Typography>
      </Stack>
      <Stack gap="4rem">
        <PersonalDetailsSection />
        {Feature({
          name: FeatureToggles.HealthcareProviders,
          feature: <HealthcareProviders />,
        })}
        <ConsentsSection />
        <NotificationsSection />
      </Stack>
    </Stack>
  );
});
