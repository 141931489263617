import styled from 'styled-components';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { useTypography } from 'helpers/mixins';

export const StyledCheckbox = styled(MuiCheckbox)`
  color: ${({ theme }) => theme.palette.button.primary} !important;
  margin-right: 0.8rem;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.6rem;

    > path:nth-child(1) {
      fill: ${({ theme }) => theme.palette.separator.primary};
    }
  }

  &.Mui-checked {
    svg {
      > path:nth-child(1) {
        fill: ${({ theme }) => theme.palette.button.primary};
      }
      > path:nth-child(2) {
        fill: ${({ theme }) => theme.palette.button.text};
      }
    }
  }

  &.Mui-disabled {
    svg {
      > path:nth-child(1) {
        fill: ${({ theme }) => theme.palette.button.disabledBackground};
      }
      > path:nth-child(2) {
        fill: ${({ theme }) => theme.palette.separator.primary};
      }
    }
  }

  &.Mui-disabled.Mui-checked {
    svg {
      > path:nth-child(1) {
        fill: ${({ theme }) => theme.palette.button.disabledBackground};
      }
      > path:nth-child(2) {
        fill: ${({ theme }) => theme.palette.separator.primary};
      }
      > path:nth-child(3) {
        fill: ${({ theme }) => theme.palette.separator.primary};
      }
    }
  }
`;

export const LabelWrapper = styled.div`
  ${({ theme }) => useTypography(theme.typography.h5)}

  color: ${({ theme }) => theme.palette.text.primary};

  a {
    color: ${({ theme }) => theme.palette.button.primary};
  }
`;
