import { useField } from 'formik';
import { CountryData, PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import * as yup from 'yup';
import { ChangeEvent } from 'react';
import { FormikTextFieldLabel } from 'components/FormikTextField/styled';
import { ErrorMessage } from 'components/ErrorMessage/index';
import { BasePhoneInput } from './BasePhoneInput';

type BaseProps = PhoneInputProps & {
  name: string;
  disableError?: boolean;
  'data-cy'?: string;
  oneWayValidationOnChange?: false;
  validationSchema?: undefined;
  label?: string;
};

type PropsWithOneWayValidation = Omit<
  BaseProps,
  'oneWayValidationOnChange' | 'validationSchema'
> & {
  oneWayValidationOnChange: true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema: yup.ObjectSchema<any>;
};

export type FormikPhoneInputProps = BaseProps | PropsWithOneWayValidation;

export const FormikPhoneInput = ({
  name,
  disableError = false,
  oneWayValidationOnChange,
  validationSchema,
  label,
  'data-cy': dataCy,
  ...otherProps
}: FormikPhoneInputProps) => {
  const [{ onChange, ...field }, meta, helpers] = useField(name);

  const customOnChange = async (
    value: string,
    _data: Record<string, unknown> | CountryData,
    _event: ChangeEvent<HTMLInputElement>,
    _formattedValue: string,
  ) => {
    helpers.setValue(value);

    if (!oneWayValidationOnChange) return;
    if (!validationSchema) return;
    if (meta.error) {
      validationSchema
        .validateAt(field.name, {
          [field.name]: value,
        })
        .then(() => {
          helpers.setError(undefined);
        })
        // suppress error
        .catch(() => {});
    }
  };

  const combinedProps = {
    ...field,
    ...otherProps,
    onChange: customOnChange,
  };

  const isError = Boolean(meta && meta.touched && meta.error);

  const notNullDataCy = dataCy ?? `${field.name}-input`;

  return (
    <div style={{ width: '100%' }}>
      {label && (
        <FormikTextFieldLabel htmlFor={notNullDataCy}>
          {label}
        </FormikTextFieldLabel>
      )}
      <BasePhoneInput
        {...combinedProps}
        data-cy={`${notNullDataCy}-container`}
        inputProps={{
          'aria-invalid': isError ? 'true' : 'false',
          'data-cy': `${notNullDataCy}`,
          name,
          id: notNullDataCy,
          ...otherProps.inputProps,
        }}
        {...otherProps}
      ></BasePhoneInput>
      <ErrorMessage
        message={meta.error}
        visible={!disableError && isError}
        style={{ marginTop: '0.8rem' }}
        data-cy={`${notNullDataCy}-error`}
      />
    </div>
  );
};
