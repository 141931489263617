import Api from 'api/api';
import { RegistryNotifications } from 'api/interfaces/response/getRegistryNotificationsResponse';
import { mapNotifications } from './helpers';

export const updateNotificationSettings = async (
  settings: RegistryNotifications[],
) => {
  const body = mapNotifications(settings);
  return Api.updateRegistryNotifications(body);
};
