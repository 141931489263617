import { Question, QuestionContentTypes } from 'api/models/Question/Question';

export interface SurveyForFlutterModule {
  name: string;
  description: string;
  studyId: string;
  frequency: string;
  backendIdentifiers: {
    id: string;
    assignmentId: string;
  };
  canCopySurveyValues: boolean;
  questions: Question[];
  period: null;
}

export type FlutterMessageEvent<T = unknown | undefined> = MessageEvent<{
  source: 'rni_web_surveys';
  type: FlutterMessageEventTypes;
  data: T;
}>;

export enum FlutterMessageEventTypes {
  MODULE_READY = 'module_ready',
  SURVEY_COMPLETED = 'survey_completed',
  QUESTION_CHANGED = 'question_changed',
  USER_INPUT_EVENT = 'user_input_event',
}

export interface SurveyResult {
  assignmentId?: string;
  startDate: Date;
  endDate: Date;
  startDateLocal: Date;
  endDateLocal: Date;
  schemaVersion: string | null;
  results: SurveyResultItem[];
  studyId: string;
}

interface SurveyResultItem {
  questionVersionId: string;
  questionType: QuestionContentTypes;
  answerDate: Date;
  answers: Answer[] | null;
}

interface Answer {
  answerId: string | null;
  value: string | boolean | number | null;
  data: unknown;
}
