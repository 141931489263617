import { themes } from 'styles/theme/themes';

export type ThemeKeyType = keyof typeof themes | 'system';

/* istanbul ignore next line */
export const isSystemDark = window?.matchMedia
  ? window.matchMedia('(prefers-color-scheme: dark)')?.matches
  : undefined;

export function saveTheme(theme: ThemeKeyType) {
  if (window.localStorage) localStorage.setItem('selectedTheme', String(theme));
}

/* istanbul ignore next line */
export function getThemeFromStorage(): ThemeKeyType | null {
  return window.localStorage
    ? (localStorage.getItem('selectedTheme') as ThemeKeyType) || null
    : null;
}

export const getSystemTheme = () => (isSystemDark ? themes.dark : themes.light);
