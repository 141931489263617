/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  alreadyMember: () =>
    _t(
      translations.pages.welcomePage.alreadyMember,
      'Already member of this registry?',
    ),
  login: () => _t(translations.pages.welcomePage.login, 'Log in'),
  joinTheRegistry: () =>
    _t(translations.pages.welcomePage.joinTheRegistry, 'Join the registry'),
  healthyVolunteersAreJustAsImportant: () =>
    _t(
      translations.pages.welcomePage.healthyVolunteersAreJustAsImportant,
      'Did you know that healthy volunteers are just as important as people with medical conditions?',
    ),
  goToRegistryForHealthy: () =>
    _t(
      translations.pages.welcomePage.goToRegistryForHealthy,
      'Go to the registry for healthy population',
    ),
  goBackToList: () =>
    _t(
      translations.pages.welcomePage.goBackToList,
      'Go back to the List of registries',
    ),
  members: () => _t(translations.pages.welcomePage.members, 'members'),
  completedSurveys: () =>
    _t(translations.pages.welcomePage.completedSurveys, 'completed surveys'),
  researchers: () =>
    _t(translations.pages.welcomePage.researchers, 'researchers'),
  peopleWeCanHelp: () =>
    _t(translations.pages.welcomePage.peopleWeCanHelp, 'people we can help'),
  aboutRegistry: () =>
    _t(translations.pages.welcomePage.aboutRegistry, 'About registry'),
  whyItsImportant: () =>
    _t(translations.pages.welcomePage.whyItsImportant, 'Why it’s important'),
  whatIsActiveParticipation: () =>
    _t(
      translations.pages.welcomePage.whatIsActiveParticipation,
      'What is active participation?',
    ),
  yourInputIsImportant: () =>
    _t(
      translations.pages.welcomePage.yourInputIsImportant,
      'Your input is extremely important to us. As a team of researchers we try to collect as much data as we can. As a registry member, you will be asked to <0>complete online surveys</0>. Questions are health-related and can ask about your symptoms, current health conditions, and medical history. Your de-identified data will be studied by our researchers.',
    ),
  weUsuallyAssign: () =>
    _t(
      translations.pages.welcomePage.weUsuallyAssign,
      'We usually assign a <0>few surveys per year</0>. And we inform about new ones via optional email notifications. The longer you continue to contribute data, the more value you can add to the registry.',
    ),
  participationIsColuntary: () =>
    _t(
      translations.pages.welcomePage.participationIsColuntary,
      'Participation in this registry is entirely <0>voluntary</0>, and you are free to withdraw from the research at any time.',
    ),
  whoCanParticipate: () =>
    _t(
      translations.pages.welcomePage.whoCanParticipate,
      'Who can participate?',
    ),
  toParticipateMeetConditions: () =>
    _t(
      translations.pages.welcomePage.toParticipateMeetConditions,
      'To participate in the register, the following conditions must be met:',
    ),
  howItWorks: () =>
    _t(translations.pages.welcomePage.howItWorks, 'How it works?'),
  weTriedToMakeProcessEasy: () =>
    _t(
      translations.pages.welcomePage.weTriedToMakeProcessEasy,
      'We tried to make your application process as easy as it can be. Everyone should be able to handle it, but remember that you can always join the registry with a help of your loved ones.',
    ),
  clickOnJoinRegistryButton: () =>
    _t(
      translations.pages.welcomePage.clickOnJoinRegistryButton,
      'Click on Join the registry button',
    ),
  asRegistryMemberYouPlay: () =>
    _t(
      translations.pages.welcomePage.asRegistryMemberYouPlay,
      'As a registry member you will play an important role in helping researchers discover better treatments and therapies for future generations. Your input will be extremely valuable for us!',
    ),
  fillOutApplicationForm: () =>
    _t(
      translations.pages.welcomePage.fillOutApplicationForm,
      'Fill out the application form',
    ),
  provideBasicPersonalInfo: () =>
    _t(
      translations.pages.welcomePage.provideBasicPersonalInfo,
      'In this step, you will be asked to provide some basic personal information to us, including contact information. We will not share this information with others, your data is safe with us.',
    ),
  fillOutQualificationSurvey: () =>
    _t(
      translations.pages.welcomePage.fillOutQualificationSurvey,
      'Fill out the qualification survey',
    ),
  qualificationSurveyPlaysKeyRole: () =>
    _t(
      translations.pages.welcomePage.qualificationSurveyPlaysKeyRole,
      'The qualification survey plays a key role in adding you to the proper registry assignment. Thanks to that, we will be able to personalize your RNI web app experience and show you only surveys that are suitable for you.',
    ),
  createAnAccount: () =>
    _t(translations.pages.welcomePage.createAnAccount, 'Create an account'),
  weWillSendLinkToCreateAccount: () =>
    _t(
      translations.pages.welcomePage.weWillSendLinkToCreateAccount,
      'After completing the qualification survey we will send you an email with a link to create an account. After setting a password you will get the access to the RNI web app with surveys.',
    ),
  startCompletingSurveys: () =>
    _t(
      translations.pages.welcomePage.startCompletingSurveys,
      'Start completing your surveys',
    ),
  youCanContributeYourData: () =>
    _t(
      translations.pages.welcomePage.youCanContributeYourData,
      'You’re in! You can now contribute your data by completing the set of surveys we prepared for you!',
    ),
  yourDataIsSafe: () =>
    _t(
      translations.pages.welcomePage.yourDataIsSafe,
      'Your data is safe with us',
    ),
  onlyAuthorizedPersonnelHasAccess: () =>
    _t(
      translations.pages.welcomePage.onlyAuthorizedPersonnelHasAccess,
      'We do our best to ensure that registry members’ personal information is kept private. To minimize the risk of leaks, <0>only authorized personnel</0> will have access to your personal information when it is collected via surveys.',
    ),
  weDidNotDisplayData: () =>
    _t(
      translations.pages.welcomePage.weDidNotDisplayData,
      'We did not display any of your data on the web app, so in the event that someone gains access to your account, they will only see <0>an interface without any identifying information</0>.',
    ),
  autoLogoutFeature: () =>
    _t(
      translations.pages.welcomePage.autoLogoutFeature,
      'To ensure members’ safety, we have decided to add an auto-logout feature. After 5 minutes of inactivity (i.e. 5 minutes without a single click in the app), the user will be automatically logged out.  Thanks to auto-logout, the data of our members remain confidential.',
    ),
  appIsHipaaCompliant: () =>
    _t(
      translations.pages.welcomePage.appIsHipaaCompliant,
      'Our application is HIPAA compliant, meaning that it meets the necessary standards to protect sensitive healthcare information and maintain compliance with the regulations outlined by the Health Insurance Portability and Accountability Act.',
    ),
  frequentlyAskedQuestions: () =>
    _t(
      translations.pages.welcomePage.frequentlyAskedQuestions,
      'Frequently asked questions',
    ),
  showAllQuestions: () =>
    _t(translations.pages.welcomePage.showAllQuestions, 'Show all questions'),
  showLessQuestions: () =>
    _t(translations.pages.welcomePage.showLessQuestions, 'Show less questions'),
  contactUs: () => _t(translations.pages.welcomePage.contactUs, 'Contact us'),
  weWelcomeFeedback: () =>
    _t(
      translations.pages.welcomePage.weWelcomeFeedback,
      "As a group of professionals, we welcome your feedback, questions, and comments. Please use the following contact information to get in touch with us. We'll do our best to respond to your inquiry as soon as possible. We’re here to help!",
    ),
  termsAndConditions: () =>
    _t(
      translations.pages.welcomePage.termsAndConditions,
      'Terms and conditions',
    ),
};
