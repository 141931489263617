import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  registries: () =>
    _t(translations.pages.registriesList.registries, 'Registries'),
  registriesList: () =>
    _t(translations.pages.registriesList.registriesList, 'Registries List'),
  faq: () => _t(translations.pages.registriesList.faq, 'FAQ'),
  contact: () => _t(translations.pages.registriesList.contact, 'Contact'),
  alreadyMember: () =>
    _t(translations.pages.registriesList.alreadyMember, 'Already a member? '),
  logIn: () => _t(translations.pages.registriesList.logIn, 'Log in'),
  togetherWeCanMakeDifference: () =>
    _t(
      translations.pages.registriesList.togetherWeCanMakeDifference,
      'Together we can make a difference',
    ),
  registriesAreCrucial: () =>
    _t(
      translations.pages.registriesList.registriesAreCrucial,
      'Registries are crucial to medical research and improving patient care. Whether you are a healthy individual looking to contribute to medical knowledge, or someone living with a specific condition, your participation is essential. By providing valuable data through the online platform, you can help our researchers gain a better understanding of diseases and conditions, as well as improve treatments and outcomes for patients. Choose the most suitable registry for you and be a part of our solution!',
    ),
  listOfConditionBasedRegistries: () =>
    _t(
      translations.pages.registriesList.listOfConditionBasedRegistries,
      'List of condition-based registries',
    ),
  joinOutControlGroup: () =>
    _t(
      translations.pages.registriesList.joinOutControlGroup,
      'Join our control group as a healthy person',
    ),
  healthyVolunteersAreJustAsImportant: () =>
    _t(
      translations.pages.registriesList.healthyVolunteersAreJustAsImportant,
      'Healthy volunteers are just as important as people with medical conditions. By participating in our specially designed registry, you can contribute to medical research and ultimately make a difference in the lives of millions of people.',
    ),
  yourParticipationInControlGroupIsEssential: () =>
    _t(
      translations.pages.registriesList
        .yourParticipationInControlGroupIsEssential,
      'Your participation as a control group member is essential in helping us to compare and evaluate medical treatments and therapies. This valuable data can lead to the development of more effective treatments, better understanding of diseases, and ultimately improve healthcare for all.',
    ),
  goToTheRegistry: () =>
    _t(translations.pages.registriesList.goToTheRegistry, 'Go to the registry'),
  frequentlyAskedQuestions: () =>
    _t(
      translations.pages.registriesList.frequentlyAskedQuestions,
      'Frequently asked questions',
    ),
  showAllQuestions: () =>
    _t(
      translations.pages.registriesList.showAllQuestions,
      'Show all questions',
    ),
  showLessQuestions: () =>
    _t(
      translations.pages.registriesList.showLessQuestions,
      'Show less questions',
    ),
  contactUs: () =>
    _t(translations.pages.registriesList.contactUs, 'Contact us'),
  asProfessionalsWeWelcomeFeedback: () =>
    _t(
      translations.pages.registriesList.asProfessionalsWeWelcomeFeedback,
      "As a group of professionals, we welcome your feedback, questions, and comments. Please use the following contact information to get in touch with us. We'll do our best to respond to your inquiry as soon as possible. We’re here to help!",
    ),
  termsAndConditions: () =>
    _t(
      translations.pages.registriesList.termsAndConditions,
      'Terms and conditions',
    ),
  privacyPolicy: () =>
    _t(translations.pages.registriesList.privacyPolicy, 'Privacy Policy'),
};
