import styled from 'styled-components';

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;

  > svg {
    width: 40rem;
    max-width: 100%;
    height: auto;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 32rem;
    }
  }
`;
