/**
 *
 * Modal
 *
 */
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { kebabCase } from 'lodash-es';

import { CloseIcon, StyledModal, ModalWidth } from './styled';

export interface ModalProps {
  open: boolean;
  onClose?(): void;
  children: React.ReactNode;
  bottomButtons?: React.ReactNode;
  title?: string;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  disableEnforceFocus?: boolean;
  disableMobileFullScreen?: boolean;
  closeOnClickAway?: boolean;
  onSubmit?: (e: object) => void;
  'data-cy'?: string;
  $width?: ModalWidth;
}

export const Modal = ({
  open,
  onClose,
  children,
  title,
  bottomButtons,
  ariaLabelledBy,
  ariaDescribedBy,
  onSubmit,
  disableEnforceFocus = false,
  disableMobileFullScreen = false,
  closeOnClickAway = false,
  $width,
  ...props
}: ModalProps) => {
  const handleClose = (
    _event: React.MouseEvent<HTMLElement>,
    reason: string,
  ) => {
    if (!closeOnClickAway && reason && reason === 'backdropClick') return;
    if (onClose) onClose();
  };

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      scroll="body"
      disableEnforceFocus={disableEnforceFocus}
      $disableMobileFullScreen={disableMobileFullScreen}
      data-cy={
        props['data-cy']
          ? props['data-cy']
          : kebabCase(`modal-${ariaDescribedBy}`)
      }
      onSubmit={onSubmit}
      $width={$width}
    >
      <div>
        {title && (
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              margin: {
                lg: '0 4.8rem .8rem 4.8rem',
                sm: '0 3.2rem .8rem 3.2rem',
                xs: '0 2.4rem .8rem 2.4rem',
              },
            }}
            alignItems="start"
          >
            <Typography variant="h1">{title}</Typography>
            <CloseIcon
              onClick={onClose}
              data-cy="modal-close-icon"
              role="button"
              title="close dialog"
              aria-label="close dialog"
            />
          </Stack>
        )}
        {children}
      </div>
    </StyledModal>
  );
};
