export const STORAGE_SESSION_KEY = '@rni-app/user/token';
export const STORAGE_SESSION_TIMEOUT = '@rni-app/user/time';
export const STORAGE_LAST_URL_KEY = '@rni-web/last_location';
export const STORAGE_FOCUSED = '@rni-app/window/focused';
export const STORAGE_LOGOUT = '@rni-app/window/logout';
export const STORAGE_USER_ROLE = '@rni-app/user/user-roles';
export const STORAGE_EHR_DIALOG_STATE = '@rni-app/user/ehr-dialog-state';
export const STORAGE_TOP_SURVEYS_PREFIX =
  '@rni-app/user/number-of-priority-surveys/';
export const SUPPORT_EMAIL = 'support@amazingrni.atlassian.net';

export const STABLE_BETWEEN_LOGIN_SESSION_KEYS = [STORAGE_LAST_URL_KEY];

export const INITIAL_TOP_SURVEYS_LENGTH = 5;
