import { Auth } from '@aws-amplify/auth';
import mixpanel from 'mixpanel-browser';
import jwt_decode from 'jwt-decode';

import { STORAGE_SESSION_KEY } from 'config/constants';

interface DecodedToken {
  'cognito:username': string;
  'custom:userId': string;
  phone_number: string;
  phone_number_verified: boolean;
  email: string;
  email_verified: boolean;
  auth_time: number;
  exp: number;
  iat: number;
}

export default class Session {
  public static saveSession(token: string): void {
    localStorage.setItem(STORAGE_SESSION_KEY, token);

    mixpanel.identify(jwt_decode<DecodedToken>(token)['custom:userId']);
  }

  public static clearSession(): void {
    localStorage.clear();

    mixpanel.reset();
  }

  public static async refreshCognitoSession() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      return await new Promise(resolve => {
        cognitoUser.refreshSession(
          currentSession.getRefreshToken(),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (err: any, session: any) => {
            if (!err) {
              const token = session.getIdToken().getJwtToken();
              Session.saveSession(token);
              resolve(token);
            }
          },
        );
      });
    } catch (e) {
      /* tslint:disable:no-empty */
    }
  }

  public static getSessionToken(): string | null {
    const token = localStorage.getItem(STORAGE_SESSION_KEY);
    if (token === 'undefined' || !token) {
      return null;
    }
    return token;
  }

  public static decodeToken(): DecodedToken | null {
    const token = Session.getSessionToken();
    if (token) return jwt_decode(token);

    return null;
  }

  public static async isSessionActive() {
    try {
      const currentSession = await Auth.currentSession();
      return currentSession.isValid();
    } catch (error) {
      return false;
    }
  }
}
