import { memo, ReactNode } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { SideIllustration } from 'assets/illustrations';
import { WVULogoDark, WVULogo } from 'assets/logo';
import { useProperlyThemedComponent } from 'hooks/useProperlyThemedComponent';
import {
  ArtworkContainer,
  FormPanelContainer,
  Wrapper,
  LogoContainer,
} from './styled';

interface Props {
  children?: ReactNode;
}

const refreshPage = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.location.reload(false);
};

export const FormContainer = memo<Props>(({ children }) => {
  const theme = useTheme();
  const renderSideArtwork = useMediaQuery(theme.breakpoints.up('lg'));
  const ThemedWVULogo = useProperlyThemedComponent({
    light: <WVULogo aria-hidden="true" />,
    dark: <WVULogoDark aria-hidden="true" />,
  });

  return (
    <Wrapper>
      {renderSideArtwork && (
        <ArtworkContainer>
          <div>
            <LogoContainer>
              <WVULogoDark
                aria-label="return to login page"
                role="button"
                onClick={refreshPage}
              />
            </LogoContainer>
            <SideIllustration />
          </div>
        </ArtworkContainer>
      )}

      <FormPanelContainer>
        {!renderSideArtwork && ThemedWVULogo}
        {children}
      </FormPanelContainer>
    </Wrapper>
  );
});
