import styled from 'styled-components';

export const IllustrationWrapper = styled.div`
  width: 50%;
  display: flex;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
    justify-content: center;
  }

  img {
    height: 45.2rem;
    width: auto;
    max-width: 50vw;

    ${({ theme }) => theme.breakpoints.down('lg')} {
      height: 35.91rem;
      max-width: 100vw;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      height: auto;
      max-width: 100vw;
    }
  }
`;

export const PreviewIllustrationWrapper = styled.div`
  width: 50%;
  display: flex;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
    justify-content: center;
  }

  img {
    height: 45.2rem;
    width: auto;
    max-width: 60vw;

    ${({ theme }) => theme.breakpoints.down('lg')} {
      height: 33.5rem;
      max-width: 100vw;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      height: auto;
      max-width: 80vw;
    }
  }
`;
