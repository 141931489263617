import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  deleteCaregiverRole: () =>
    _t(
      translations.pages.registry.deleteCaregiverRole,
      'Delete Caregiver role',
    ),
  thisWillRemoveCaregiverRole: () =>
    _t(
      translations.pages.registry.thisWillRemoveCaregiverRole,
      'This will remove the Caregiver role from the “{{ registryName }}”. Are you sure you want to continue?',
    ),
  cancel: () => _t(translations.pages.registry.cancel, 'Cancel'),
  delete: () => _t(translations.pages.registry.delete, 'Delete'),
  participantsCaregiverRemoved: () =>
    _t(
      translations.pages.registry.participantsCaregiverRemoved,
      'Participant’s Caregiver has been removed.',
    ),
  unknownError: () =>
    _t(translations.pages.registry.unknownError, 'Unknown error'),
};
