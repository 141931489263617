import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2.4rem;

  width: 42rem;
  padding: 4.8rem;
  position: sticky;
  top: 0;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: none;
    width: 100%;
    height: auto;
    order: 1;
    align-items: center;
  }

  > svg {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 0%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  * {
    color: #eaeaea;
  }
`;

export const LogoContainer = styled.div`
  width: min(26rem, 90%);
  position: relative;
  z-index: 2;

  > svg {
    width: 100%;
    cursor: pointer;
  }
`;
