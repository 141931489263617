import { STORAGE_EHR_DIALOG_STATE } from 'config/constants';

export class EhrDialogState {
  public static clear(): void {
    localStorage.removeItem(STORAGE_EHR_DIALOG_STATE);
  }

  public static saveState = (isOpened: boolean) => {
    localStorage.setItem(STORAGE_EHR_DIALOG_STATE, JSON.stringify(isOpened));
  };

  public static isOpened = (): boolean => {
    const state = localStorage.getItem(STORAGE_EHR_DIALOG_STATE);
    if (state === 'false') return false;
    return true;
  };
}
