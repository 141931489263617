export type Params = {
  healthcareProviders?: HealthcareProvidersParams;
  redirectAction?: RedirectActions;
};

export enum RedirectActions {
  CONNECT_PROVIDER = 'connect-provider',
}
export interface HealthcareProvidersParams {
  code?: string;
  providerId?: string;
  error?: string;
}
