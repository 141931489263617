import { Stack, Button } from '@mui/material';
import styled from 'styled-components';

export const SuccessIllustrationStack = styled(Stack)`
  height: 43.9rem;
  padding-top: 0;
  padding-bottom: 0;
  gap: 3.2rem;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    height: auto;
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
`;

export const StyledDiv = styled.div`
  width: 100%;
  border: 0.1rem solid;
  border-color: ${({ theme }) => theme.palette.separator.primary};
  border-radius: 1.6rem;
  background-color: ${({ theme }) => theme.palette.background.elevated};
`;

export const StyledStack = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 3.2rem;
  padding: 1.6rem 3.2rem 1.6rem 3.2rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 2.4rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
`;

export const StyledButton = styled(Button)`
  width: 24.8rem;
  min-width: 16.8rem;
  height: 5.4rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const RegistryIllustrationWrapper = styled.div`
  padding-top: 4rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  overflow: hidden;
  margin-bottom: -10rem;
`;

export const RegistryIllustration = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: -10rem;
`;
