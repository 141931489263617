import { ButtonBase } from '@mui/material';
import styled from 'styled-components';

export const StyledButton = styled(ButtonBase)<{
  $color: 'text' | 'primary' | 'warning';
  $size: 'large' | 'medium';
}>`
  --icon-color: ${({ theme, $color }) => {
    switch ($color) {
      case 'text':
        return theme.palette.text.primary;
      case 'primary':
        return theme.palette.elements.primary;
      case 'warning':
        return theme.palette.additionalColors.accentError;
      default:
        return 'primary';
    }
  }};

  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: ${({ $size }) => ($size === 'large' ? '4rem' : '3.2rem')};
    height: ${({ $size }) => ($size === 'large' ? '4rem' : '3.2rem')};
    transition: background 300ms;

    background: ${({ theme }) =>
      theme.palette.type === 'dark'
        ? theme.palette.elements.secondaryBackground
        : theme.palette.elements.mainBackground};
    border: 0.1rem solid ${({ theme }) => theme.palette.elements.separator};
    border-radius: 0.8rem;

    :hover,
    &.active {
      background: ${({ theme }) => theme.palette.elements.hoverState};
    }

    color: var(--icon-color);

    svg {
      width: 2.2rem;
      height: 2.2rem;

      *[stroke] {
        stroke: var(--icon-color);
      }
      *[fill]:not(mask) {
        fill: var(--icon-color);
      }
    }

    &.Mui-disabled {
      --icon-color: ${({ theme }) => theme.palette.elements.separator};
      background: ${({ theme }) => theme.palette.elements.disabledBackground};
    }
  }
`;
