import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Profile, Profile2User, SecurityUser, ArrowRight } from 'iconsax-react';
import { RoleTileWrapper, ButtonLabel } from './styled';
import { messages } from '../../messages';

interface Props {
  name: string;
  code: string;
  id: string;
  selected: boolean;
  onClick(id: string): void;
}

export const RoleTile = ({ name, code, id, selected, onClick }: Props) => {
  const { t } = useTranslation();

  const getIcon = () => {
    switch (code) {
      case 'caregiver':
        return <Profile2User />;
      case 'doctor':
        return <SecurityUser />;
      case 'participant':
      default:
        return <Profile />;
    }
  };

  return (
    <RoleTileWrapper $selected={selected} onClick={() => onClick(id)}>
      <div>
        {getIcon()}
        <Typography variant="xxs">{name}</Typography>
      </div>
      <ButtonLabel className="hover-effect">
        <Typography variant="body1">{t(messages.selectRole())}</Typography>
        <ArrowRight />
      </ButtonLabel>
    </RoleTileWrapper>
  );
};
