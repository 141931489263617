import { Components } from '@mui/material/styles/components';
import { Theme } from 'styles/theme/themes';
import { typography } from 'styles/theme/themes/fonts';

declare module '@mui/material/Accordion' {
  interface AccordionProps {
    variant?: 'simplified' | 'neumorphic' | 'flat';
  }
}

export const MuiAccordion: Components<Theme>['MuiAccordion'] = {
  defaultProps: {
    disableGutters: true,
    elevation: 0,
    variant: 'simplified',
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      borderColor: theme.palette.divider,
      width: '100%',
      padding: 0,
      background: 'transparent',
      backgroundColor: 'transparent',

      ':before': {
        content: 'none',
      },

      ...(ownerState.variant === 'neumorphic' && {
        gap: '1.6rem',
        position: 'relative',
        borderRadius: '1.6rem !important',
        backgroundColor: theme.palette.background.elevated.primary,

        padding: '3.2rem',
        [theme.breakpoints.down('lg')]: {
          padding: '2.8rem',
        },

        // gradient border
        ':after': {
          content: "''",
          position: 'absolute',
          top: '-0.1rem',
          bottom: '-0.1rem',
          left: '-0.1rem',
          right: '-0.1rem',
          zIndex: '-1',
          background: theme.palette.additionalColors.surveyCard,
          borderRadius: '1.6rem',
          boxShadow: theme.palette.additionalColors.tileShadow,
        },

        '.MuiAccordionSummary-expandIconWrapper': {
          transform: 'unset !important',
        },

        '.MuiAccordionSummary-root': {
          flexDirection: 'row-reverse',
          gap: '1.6rem',
          minHeight: 'unset',
          alignItems: 'center',
          margin: '-3.2rem',
          padding: '3.2rem',

          svg: {
            width: '2.4rem',
            height: '2.4rem',
            transform: 'unset !important',

            '*[stroke]': {
              stroke: theme.palette.button.primary,
            },

            '*[fill]': {
              fill: theme.palette.button.primary,
            },
          },
        },

        '.MuiAccordionSummary-content': {
          ...typography(theme.palette, theme.breakpoints).h5,
          transition: 'color 300ms',
          margin: 0,
          padding: 0,
        },

        '.MuiAccordionSummary-content.Mui-expanded': {
          color: theme.palette.button.primary,
        },

        '.MuiAccordionDetails-root': {
          ...typography(theme.palette, theme.breakpoints).body1,
          color: theme.palette.text.primary,
          margin: 0,
          padding: 0,
          marginLeft: '3.2rem',
          marginTop: '1.6rem',
          whiteSpace: 'pre-line',
        },
      }),

      ...(ownerState.variant === 'flat' && {
        gap: '1.6rem',
        padding: '0',

        '.MuiAccordionSummary-expandIconWrapper': {
          transform: 'unset !important',
          borderRadius: '1.6rem',
          background: theme.palette.button.primary,
          width: '4rem',
          minWidth: '4rem',
          height: '4rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          svg: {
            width: '2.4rem',
            height: '2.4rem',
            transform: 'unset !important',

            '*[stroke]': {
              stroke: theme.palette.button.text,
            },

            '*[fill]': {
              fill: theme.palette.button.text,
            },
          },
        },

        '.MuiAccordionSummary-root': {
          flexDirection: 'row-reverse',
          gap: '2.4rem',
          minHeight: 'unset',
          alignItems: 'center',
          margin: 0,
          padding: 0,
        },

        '.MuiAccordionSummary-content': {
          ...typography(theme.palette, theme.breakpoints).h5,
          color: theme.palette.additionalColors.primaryText,
          transition: 'color 300ms',
          margin: 0,
          padding: 0,
        },

        '.MuiAccordionDetails-root': {
          ...typography(theme.palette, theme.breakpoints).body1,
          color: theme.palette.additionalColors.primaryText,
          margin: '1.6rem 6.4rem 0',
          padding: 0,
          whiteSpace: 'pre-line',
        },
      }),
    }),
  },
};
