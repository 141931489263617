/**
 *
 * ModalContent
 *
 */
import React from 'react';
import { StyledModalContent } from './styled';

export const ModalContent = ({
  ref,
  as,
  ...props
}: React.HTMLProps<HTMLDivElement>) => (
  <StyledModalContent id="modal-content" {...props} />
);
