/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  helpCenter: () => _t(translations.pages.help.helpCenter, 'Help center'),
  helpCenterDescription: () =>
    _t(
      translations.pages.help.helpCenterDescription,
      "We've compiled a list of commonly asked questions to provide you with quick and easy answers. If you have any additional questions or concerns, please don't hesitate to email us. We're here to help!",
    ),
  whyIsQualificationSurveyImportant: () =>
    _t(
      translations.pages.help.whyIsQualificationSurveyImportant,
      'Why is qualification survey so important?',
    ),
  whyIsQualificationSurveyImportantAnswer: () =>
    _t(
      translations.pages.help.whyIsQualificationSurveyImportantAnswer,
      'A qualification survey is a questionnaire that has to be completed before any other survey in the registry. It is an essential step in ensuring that accurate and up-to-date information is collected and maintained. The qualification survey plays a key role in adding you to proper registry assignment. After adding you to the adequate assignment, we will be able to personalize your RNI web app experience and show you only surveys that are suitable for you.',
    ),
  howAssignmentWork: () =>
    _t(
      translations.pages.help.howAssignmentWork,
      'How does the assignment work?',
    ),
  howAssignmentWorkAnswer: () =>
    _t(
      translations.pages.help.howAssignmentWorkAnswer,
      `The assignment is in other words the area of the registry to which you have been qualified after completing the qualification survey. Each registry has at least one assignment.
	If you feel that your health has changed since the last time you completed the qualification survey you can always click on “Change assignment” and fill out the qualification survey again. We will find the most suitable assignment for you.`,
    ),
  whyHaveNoAssignment: () =>
    _t(
      translations.pages.help.whyHaveNoAssignment,
      'Why don’t I have an assignment?',
    ),
  whyHaveNoAssignmentAnswer: () =>
    _t(
      translations.pages.help.whyHaveNoAssignmentAnswer,
      "Sometimes it may happen that after completing the qualification survey, we will not add you to any assignments, and you won't have any surveys assigned. Nothing wrong with that. It means we have every data we need from you for now. If we need more information, we will assign you proper surveys.",
    ),
  whyLoggedOut: () =>
    _t(
      translations.pages.help.whyLoggedOut,
      'Why am I automatically logged out?',
    ),
  whyLoggedOutAnswer: () =>
    _t(
      translations.pages.help.whyLoggedOutAnswer,
      'We care about our members and their safety. Some of the members fill out the surveys using public computers. To ensure their safety, we have decided to add the auto-logout after 5 min of inactivity (5 minutes without single click in the app). Thanks to auto-logout, the data of our members remain confidential.',
    ),
  whyCantWithdraw: () =>
    _t(
      translations.pages.help.whyCantWithdraw,
      "Why can't I withdraw my consent?",
    ),
  whyCantWithdrawAnswer: () =>
    _t(
      translations.pages.help.whyCantWithdrawAnswer,
      'Before joining the registry, you were asked to agree to some consents in the application form. Some of them were required, and some of them were optional. In Settings, you can edit only optional consent. Required ones are critical to take part in the registry. If you disagree with any of the required consent, please remember that participating in the registry is voluntary, and you can always choose to withdraw from it.',
    ),
};
