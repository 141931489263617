import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as SurveyCompletedIllustrationLight } from './surveyCompleted.svg';
import { ReactComponent as SurveyCompletedIllustrationDark } from './surveyCompleted_dark.svg';

export const SurveyCompletedIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return <SurveyCompletedIllustrationDark aria-hidden="true" {...props} />;
    return <SurveyCompletedIllustrationLight aria-hidden="true" {...props} />;
  },
);
