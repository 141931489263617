import * as yup from 'yup';
import i18next from 'i18next';
import { messages } from '../../messages';

export const getValidationSchema = () =>
  yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required(
        i18next.t(messages.pleaseFillIn(), {
          field: i18next.t(messages.firstName()).toLocaleLowerCase(),
        }),
      ),
    lastName: yup
      .string()
      .trim()
      .required(
        i18next.t(messages.pleaseFillIn(), {
          field: i18next.t(messages.lastName()).toLocaleLowerCase(),
        }),
      ),
    relationship: yup.string().required(
      i18next.t(messages.pleaseFillIn(), {
        field: i18next.t(messages.relationshipWithMember()).toLocaleLowerCase(),
      }),
    ),
  });
