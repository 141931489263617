import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add } from 'iconsax-react';
import { messages } from 'pages/Registry/messages';
import { AddCaregiverButton, AddCaregiverWrapper } from './styled';

interface Props {
  onClick(): void;
}

export const AddCaregiverRow = ({ onClick }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <AddCaregiverWrapper>
      <Typography
        variant="body1"
        color={theme.palette.additionalColors.secondaryText}
      >
        {t(messages.noCaregiverParticipates())}
      </Typography>
      <AddCaregiverButton
        variant="text"
        data-cy="add-caregiver-button"
        onClick={onClick}
      >
        <Add />
        {t(messages.addCaregiver())}
      </AddCaregiverButton>
    </AddCaregiverWrapper>
  );
};
