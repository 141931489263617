import { useMemo, useState } from 'react';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { Select } from 'components/Select';
import { GetRegistryResponse } from 'api/interfaces/response';
import { useUserRole } from 'api/storage/userRoles/hook';
import { RegistryPerson } from 'api/interfaces/response/getRegistryPersonsResponse';
import scientistBannerIllustrationDark from 'assets/illustrations/scientistBanner/scientist_banner_dark.svg';
import scientistBannerIllustrationLight from 'assets/illustrations/scientistBanner/scientist_banner.svg';
import { useConfig } from 'hooks/useConfig';
import { useAutofocus } from 'hooks/useAutofocus';
import { RegistryParams } from 'pages/Registry/types';
import { messages } from '../../messages';
import {
  RegistryBannerIllustration,
  RegistryTitleSectionContent,
  RegistryTitleSectionWrapper,
  RegistryBannerIllustrationWrapper,
  Assignments,
} from './styled';
import { CaregiverDetailsModal } from '../CaregiverDetailsModal';

export interface Props {
  registry: GetRegistryResponse;
  persons: RegistryPerson[];
}

export const RegistryHeader = ({ registry, persons }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const config = useConfig();
  const autofocus = useAutofocus();
  const { id } = useParams<RegistryParams>();
  const { roleId, setRole } = useUserRole(id ?? '');
  const [showCaregiverDetailsModal, setShowCaregiverDetailsModal] =
    useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('mdl'));
  const locationState = location.state;
  const registryName = registry?.name ?? locationState?.name;

  const registryHasOnlyOneRole = registry?.roles?.length === 1;

  const showQualificationSurveyPopup =
    registry?.qualification.surveySubmitted === false;
  const showParticipationConfirmationPopup =
    registry?.participation.status === 'initial';

  const hasVisiblePopup =
    showQualificationSurveyPopup || showParticipationConfirmationPopup;

  const renderAssignedCohortsInfo = () => {
    if (!registry?.cohorts.length) return null;

    const cohorts = registry?.cohorts.map(item => item.name).join(', ');
    const combinedText = `${t(messages.yourAssignments())} ${cohorts}`;

    return (
      <Assignments
        lines={2}
        anchorClass="showMoreLessButton"
        more={t(messages.showMoreAssignments())}
        less={t(messages.showLessAssignments())}
      >
        {combinedText}
      </Assignments>
    );
  };

  const getBannerIllustration = () => {
    if (!registry?.additionalParams?.introPage)
      return scientistBannerIllustrationLight;

    const { bannerIllustration, bannerIllustrationDark } =
      registry.additionalParams.introPage;

    if (theme.palette.type === 'dark')
      return bannerIllustrationDark
        ? `${config.s3PublicBucketUrl}${bannerIllustrationDark}`
        : scientistBannerIllustrationDark;
    return bannerIllustration
      ? `${config.s3PublicBucketUrl}${bannerIllustration}`
      : scientistBannerIllustrationLight;
  };

  const roles = useMemo(
    () =>
      registry?.roles.map(role => ({
        value: role.id,
        label: role.name,
      })) ?? [],
    [registry?.roles],
  );

  const isSelectedRoleCaregiver = (rId: string) => {
    return registry?.roles?.find(role => role.id === rId)?.code === 'caregiver';
  };

  const isCaregiverAlreadyAdded = () => {
    return persons?.find(person => person.role === 'caregiver') !== undefined;
  };

  const handleRoleChange = (newRoleId: string) => {
    if (isSelectedRoleCaregiver(newRoleId) && !isCaregiverAlreadyAdded()) {
      setShowCaregiverDetailsModal(true);
      return;
    }
    setRole(newRoleId);
  };

  const caregiverRole = registry.roles.find(role => role.code === 'caregiver');

  const onAddCaregiver = () => {
    if (!registry) return;

    setShowCaregiverDetailsModal(false);

    if (caregiverRole) setRole(caregiverRole.id);
  };

  return (
    <RegistryTitleSectionWrapper>
      <RegistryTitleSectionContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={registryHasOnlyOneRole ? 'center' : 'flex-start'}
          {...(!hasVisiblePopup && autofocus)}
        >
          <Typography variant="h1" data-cy="registry-name">
            {t(messages.registry(), { name: registryName })}
          </Typography>
        </Stack>
        {renderAssignedCohortsInfo()}
        {!registryHasOnlyOneRole && (
          <div style={{ maxWidth: '32rem' }}>
            <Select
              changeValue={handleRoleChange}
              values={roles}
              value={roleId}
              valuePrefix={t(messages.yourRole())}
              variant="small"
            />
          </div>
        )}
      </RegistryTitleSectionContent>
      {!isMobile && (
        <RegistryBannerIllustrationWrapper>
          <RegistryBannerIllustration src={getBannerIllustration()} alt="" />
        </RegistryBannerIllustrationWrapper>
      )}
      {roleId && showCaregiverDetailsModal && (
        <CaregiverDetailsModal
          open={showCaregiverDetailsModal}
          registryId={registry.id}
          roleId={caregiverRole?.id ?? ''}
          onSuccess={onAddCaregiver}
          onCancel={() => setShowCaregiverDetailsModal(false)}
          requireConfirmation={true}
        />
      )}
    </RegistryTitleSectionWrapper>
  );
};
