import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { IconButton } from 'components/IconButton';
import { EditIcon } from 'assets/icons';
import { messages } from '../../messages';
import { SectionWrapper } from './styled';

interface Props {
  active: boolean;
  disabled: boolean;
  onCancel: () => void;
  onEdit: () => void;
  children: React.ReactNode;
  sectionName: string;
}

export const SettingSection = ({
  active,
  disabled,
  onCancel,
  onEdit,
  children,
  sectionName,
}: Props) => {
  const { t } = useTranslation();

  const formButtons = (
    <Stack
      direction="row"
      spacing="1.6rem"
      width={{ xs: '100%', md: 'auto' }}
      justifyContent={{ xs: 'stretch', md: 'end' }}
    >
      <Button variant="alternative" onClick={onCancel}>
        {t(messages.cancel())}
      </Button>
      <Button color="primary" type="submit">
        {t(messages.save())}
      </Button>
    </Stack>
  );

  const editButton = (
    <IconButton
      hoverMessage={t(messages.editDetails())}
      disabled={disabled}
      onClick={onEdit}
    >
      <EditIcon aria-label={t(messages.editDetails())} />
    </IconButton>
  );

  return (
    <SectionWrapper
      $active={active}
      $disabled={disabled}
      role={active ? 'form' : 'section'}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        height={active ? '5.2rem' : '4rem'}
        style={{
          transition: 'height 0.3s',
        }}
      >
        <Typography variant="h4">{sectionName}</Typography>
        {active ? formButtons : editButton}
      </Stack>
      {children}
    </SectionWrapper>
  );
};
