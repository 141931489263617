import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

export const useClearLocationState = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return useCallback(() => {
    navigate(location.pathname, {});
  }, [navigate, location]);
};
