import { Dialog } from '@mui/material';

import styled, { css } from 'styled-components';
import { scrollbar, useTypography } from 'helpers/mixins';
import { XIcon } from 'assets/icons';

export type ModalWidth = 'regular' | 'narrow';

export const StyledModal = styled(Dialog)<{
  $width?: 'regular' | 'narrow';
  $disableMobileFullScreen: boolean;
}>`
  display: flex;
  justify-content: center;

  > .MuiDialog-container {
    width: 100%;

    > .MuiDialog-paper {
      background: transparent;
      vertical-align: top;
      max-height: 100%;

      ${({ theme }) => theme.breakpoints.up('md')} {
        margin: 5rem 0;
        max-height: calc(100vh - 10rem);
        width: 63.6rem;
        max-width: 63.6rem;
        border-radius: 1.6rem;
      }

      ${({ theme }) => theme.breakpoints.down('md')} {
        width: unset;

        ${({ $disableMobileFullScreen }) =>
          $disableMobileFullScreen
            ? css`
                margin: 3.6rem 2.4rem;
                border-radius: 1.6rem;
                max-height: calc(var(--window-height) - 7.2rem);
              `
            : css`
                margin: unset;
                height: 100%;
                max-width: unset;
                width: 100%;
                max-height: var(--window-height);

                > div {
                  height: 100%;
                }
              `};
      }

      > div,
      .formik-form {
        display: flex;
        flex-direction: column;
        background: ${({ theme }) => theme.palette.background.elevated.primary};
        max-height: 100%;

        ${({ theme }) => theme.breakpoints.up('md')} {
          max-height: calc(100vh - 10rem);
          width: 63.6rem;
        }

        padding: 4.8rem 0;
        overflow: hidden;

        ${({ theme }) => theme.breakpoints.down('md')} {
          padding: 3.2rem 0 2.4rem;
        }

        .formik-form {
          padding: 0;
        }

        form {
          display: flex;
          flex-direction: column;
          overflow: auto;
        }
      }
    }
  }
`;

export const StyledModalContent = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  scrollbar-gutter: stable;

  ${({ theme }) => theme.breakpoints.up('xs')} {
    margin: 0 2.4rem;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 0 3.2rem;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin: 0 4.8rem;
  }

  ${({ theme }) =>
    scrollbar({
      thumbBackground: theme.palette.separator.primary,
      thumbHovered: theme.palette.separator.hover,
    })}

  p {
    ${({ theme }) => useTypography(theme.typography.body1)}
    margin: 0;
  }
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('xs')} {
    margin: 0 2.4rem;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 0 3.2rem;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin: 0 4.8rem;
  }
`;

export const CloseIcon = styled(XIcon)`
  cursor: pointer;
  width: 2.2em;
  min-width: 2.2rem;
  max-width: 2.2rem;
  height: 2.2rem;
  max-height: 2.2rem;
  padding: 0.8rem;
  box-sizing: content-box;
  border-radius: 1.6rem;
  margin-top: -0.2rem;
  margin-bottom: -0.2rem;
  transition: background 0.3s;

  path {
    stroke: ${({ theme }) => theme.palette.button.primary};
  }

  &:hover {
    background: ${({ theme }) => theme.palette.background.elevated.menuHover};
  }
`;
