import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as AreasAssignedIllustrationLight } from './areasAssigned.svg';
import { ReactComponent as AreasAssignedIllustrationDark } from './areasAssigned_dark.svg';

export const AreasAssignedIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return <AreasAssignedIllustrationDark aria-hidden="true" {...props} />;
    return <AreasAssignedIllustrationLight aria-hidden="true" {...props} />;
  },
);
