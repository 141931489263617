import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { StartButton } from 'components/StartButton';
import { messages } from '../../messages';

interface Props {
  next: () => void;
  notNow: () => void;
  registryName: string;
}
export const SurveyStartStep = memo(({ next, notNow, registryName }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack gap="1.6rem" justifyContent="center" height="100%">
      {' '}
      <Typography variant="body4" textAlign="center" color="button.primary">
        {registryName}
      </Typography>
      <Typography variant="h1" textAlign="center">
        {t(messages.findTheBestFit())}
      </Typography>
      <Typography variant="body1">
        {t(messages.completeAQualificationSurvey())}
      </Typography>
      <StartButton onClick={next} style={{ margin: '1.6rem 0' }}>
        {t(messages.startQualificationSurvey())}
      </StartButton>
      <Button onClick={notNow} variant="text">
        {t(messages.notNow())}
      </Button>
    </Stack>
  );
});
