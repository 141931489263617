import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as ConnectHealthRecordsIllustrationLight } from './connectHealthRecords.svg';
import { ReactComponent as ConnectHealthRecordsIllustrationDark } from './connectHealthRecords_dark.svg';

export const ConnectHealthRecordsIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return (
        <ConnectHealthRecordsIllustrationDark aria-hidden="true" {...props} />
      );
    return (
      <ConnectHealthRecordsIllustrationLight aria-hidden="true" {...props} />
    );
  },
);
