/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  qualificationBaseline: () =>
    _t(
      translations.pages.qualification.qualificationBaseline,
      'Qualification Baseline',
    ),
  qualificationSurvey: () =>
    _t(
      translations.pages.qualification.qualificationSurvey,
      'Qualification Survey',
    ),
  joinAndMakeDifference: () =>
    _t(
      translations.pages.qualification.joinAndMakeDifference,
      'Join the {{registryName}} and make a difference!',
    ),
  joinTheRegistry: () =>
    _t(translations.pages.qualification.joinTheRegistry, 'Join the registry'),
  howWouldYouLikeToJoin: () =>
    _t(
      translations.pages.qualification.howWouldYouLikeToJoin,
      'How would you like to join?',
    ),
  alreadyMember: () =>
    _t(
      translations.pages.qualification.alreadyMember,
      'Already member of this registry?',
    ),
  logIn: () => _t(translations.pages.qualification.logIn, 'Log in'),
  inCaseOfNotBeingAble: () =>
    _t(
      translations.pages.qualification.inCaseOfNotBeingAble,
      'In case of not being able to reach out to the member, researchers might use this information to contact you instead.',
    ),
  email: () => _t(translations.pages.qualification.email, 'Email'),
  caregiverDetails: () =>
    _t(translations.pages.qualification.caregiverDetails, 'Caregiver details'),
  letUsKnowRelationship: () =>
    _t(
      translations.pages.qualification.letUsKnowRelationship,
      'Provide your basic personal information and the relationship between you and the member.',
    ),
  firstName: () => _t(translations.pages.qualification.firstName, 'First name'),
  lastName: () => _t(translations.pages.qualification.lastName, 'Last name'),
  dateOfBirth: () =>
    _t(translations.pages.qualification.dateOfBirth, 'Date of birth'),
  relationshipWithMember: () =>
    _t(
      translations.pages.qualification.relationshipWithMember,
      'Relationship to the member',
    ),
  select: () => _t(translations.pages.qualification.select, 'Select'),
  phoneNumber: () =>
    _t(translations.pages.qualification.phoneNumber, 'Phone number'),
  optional: () => _t(translations.pages.qualification.optional, 'optional'),
  continue: () => _t(translations.pages.qualification.continue, 'Continue'),
  back: () => _t(translations.pages.qualification.back, 'Back'),
  pleaseFillIn: () =>
    _t(
      translations.pages.qualification.pleaseFillIn,
      'Please fill in {{field}}',
    ),
  emptyEmail: () =>
    _t(translations.pages.qualification.emptyEmail, 'Please fill in email'),
  emptyFirstName: () =>
    _t(
      translations.pages.qualification.emptyFirstName,
      'Please fill in first name',
    ),
  emptyLastName: () =>
    _t(
      translations.pages.qualification.emptyLastName,
      'Please fill in last name',
    ),
  emptyDateOfBirth: () =>
    _t(
      translations.pages.qualification.emptyDateOfBirth,
      'Please fill in date of birth',
    ),
  dateOfBirthMustBeBeforeToday: () =>
    _t(
      translations.pages.qualification.dateOfBirthMustBeBeforeToday,
      'Date of birth must be before today',
    ),
  invalidDateOfBirth: () =>
    _t(
      translations.pages.qualification.invalidDateOfBirth,
      'Invalid date of birth',
    ),
  incorrectEmail: () =>
    _t(translations.pages.qualification.incorrectEmail, 'Incorrect email'),
  incorrectPhoneNumberFormat: () =>
    _t(
      translations.pages.qualification.incorrectPhoneNumberFormat,
      'Incorrect phone number format',
    ),
  memberDetails: () =>
    _t(translations.pages.qualification.memberDetails, 'Member details'),
  caregiverContactDetails: () =>
    _t(
      translations.pages.qualification.caregiverContactDetails,
      'Caregiver contact details',
    ),
  tellUsSomeDetails: () =>
    _t(
      translations.pages.qualification.tellUsSomeDetails,
      'Tell us some details, that relate to this research. Information will be used by researchers to get to know our members better.',
    ),
  accountDetails: () =>
    _t(translations.pages.qualification.accountDetails, 'Account details'),
  theEmailAddressGivenBelowMember: () =>
    _t(
      translations.pages.qualification.theEmailAddressGivenBelowMember,
      'The email address given below will be used to validate the your application and it will be needed to participate in the registry.',
    ),
  theEmailAddressGivenBelowDoctor: () =>
    _t(
      translations.pages.qualification.theEmailAddressGivenBelowDoctor,
      'The email address given below will be used to validate the member’s application and it will be needed to participate in the registry.',
    ),
  theEmailAddressGivenBelowCaregiver: () =>
    _t(
      translations.pages.qualification.theEmailAddressGivenBelowCaregiver,
      "The email address given below will be used to validate the member’s application and it will be needed to participate in the registry. You can enter either your e-mail address or the member's e-mail address.",
    ),
  weWillUseTheDataProvided: () =>
    _t(
      translations.pages.qualification.weWillUseTheDataProvided,
      'We will use the data provided to enroll member in {{registryName}}. Please check if all data is typed correctly.',
    ),
  memberAgreements: () =>
    _t(translations.pages.qualification.memberAgreements, 'Member agreements'),
  someConsentsAreRequired: () =>
    _t(
      translations.pages.qualification.someConsentsAreRequired,
      'Some consents are required to join the registry. Please read the following user agreements.',
    ),
  thisConsentIsRequired: () =>
    _t(
      translations.pages.qualification.thisConsentIsRequired,
      'This consent is required to take part in the research.',
    ),
  confirmAndSubmit: () =>
    _t(translations.pages.qualification.confirmAndSubmit, 'Confirm and submit'),
  accountEmailAddress: () =>
    _t(
      translations.pages.qualification.accountEmailAddress,
      'Account email address',
    ),
  confirmMembersApplication: () =>
    _t(
      translations.pages.qualification.confirmMembersApplication,
      "Confirm member's application",
    ),
  completeAQualificationSurvey: () =>
    _t(
      translations.pages.qualification.completeAQualificationSurvey,
      'Take a moment to complete a qualification survey that will help us personalize the RNI registry account and show there only the most relevant surveys. The qualification survey includes questions related to demographic data, current symptoms, medical history, and current health conditions.',
    ),
  startQualificationSurvey: () =>
    _t(
      translations.pages.qualification.startQualificationSurvey,
      'Start qualification survey',
    ),
  notNow: () => _t(translations.pages.qualification.notNow, 'Not now'),
  findTheBestFit: () =>
    _t(translations.pages.qualification.findTheBestFit, 'Find the best fit'),
  unknownErrorOccured: () =>
    _t(
      translations.pages.qualification.unknownErrorOccured,
      'Unknown error occurred, try again later.',
    ),
  anAccountWithThisEmailAlready: () =>
    _t(
      translations.pages.qualification.anAccountWithThisEmailAlready,
      'An account with this email already has access to {{registryName}}. Please check if your email address is typed correctly or <0>log in to your account</0>.',
    ),
  weAreExcitedToHaveYou: () =>
    _t(
      translations.pages.qualification.weAreExcitedToHaveYou,
      'We are excited to have you on board',
    ),
  checkMailForNextSteps: () =>
    _t(
      translations.pages.qualification.checkMailForNextSteps,
      'Please check your email for the next steps: <0>{{userEmail}}<0>',
    ),
  youWillFindInstructionsHowToCreateAccount: () =>
    _t(
      translations.pages.qualification
        .youWillFindInstructionsHowToCreateAccount,
      'You will find instructions on how to create your account and complete the necessary surveys.',
    ),
  finishAndGotToRegistryPage: () =>
    _t(
      translations.pages.qualification.finishAndGotToRegistryPage,
      'Finish and go to registry welcome page',
    ),
  pleaseSelectYourRole: () =>
    _t(
      translations.pages.qualification.pleaseSelectYourRole,
      'Please select your role',
    ),
  participantsHaveTheOption: () =>
    _t(
      translations.pages.qualification.participantsHaveTheOption,
      'Participants have the option to join the Registry either independently or with the support of a dedicated Caregiver. If a Participant receives assistance from a medical facility employee, please select appropriate option.',
    ),
  editDetails: () =>
    _t(translations.pages.qualification.editDetails, 'Edit details'),
  cancel: () => _t(translations.pages.qualification.cancel, 'Cancel'),
  save: () => _t(translations.pages.qualification.save, 'Save'),
  roleSelection: () =>
    _t(translations.pages.qualification.roleSelection, 'Role selection'),
  nextSteps: () => _t(translations.pages.qualification.nextSteps, 'Next steps'),
  surveyStart: () =>
    _t(translations.pages.qualification.surveyStart, 'Survey start'),
  agreements: () =>
    _t(translations.pages.qualification.agreements, 'Agreements'),
  confirmApplication: () =>
    _t(
      translations.pages.qualification.confirmApplication,
      'Confirm application',
    ),
};
