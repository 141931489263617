import styled from 'styled-components';
import { useTypography } from 'helpers/mixins';

export const Message = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.message.text.error};
  margin-top: 0.8rem;

  a {
    color: ${({ theme }) => theme.palette.message.text.error};
    font-family: AvertaSemiBold;
  }

  && {
    flex-direction: row;
    align-items: center;
  }

  > svg {
    margin-right: 1.1rem;
    width: 1.6rem;
    min-width: 1.6rem;

    * {
      fill: ${({ theme }) => theme.palette.message.text.error};
    }
  }

  .MuiTypography-body2 {
    ${({ theme }) => useTypography(theme.typography.body2)}
    color: ${({ theme }) => theme.palette.message.text.error};
  }
`;
