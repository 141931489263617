import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { produce } from 'immer';
import mixpanel from 'mixpanel-browser';
import { Form, Formik } from 'formik';
import { Stack, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';
import Api from 'api/api';
import Session from 'api/storage/session';
import { errorToast } from 'helpers/toast';
import { useInvalidateQuery, useSetQueryData } from 'helpers/queries';
import { useValidationSchema } from 'hooks/useValidationSchema';
import { EventNames } from 'types/analytics';
import { caregiverRelationships } from 'pages/Qualification/constants';
import { Modal } from 'components/Modal';
import { FormikTextField } from 'components/FormikTextField';
import { FormikPhoneInput } from 'components/PhoneInput';
import { ErrorMessage } from 'components/ErrorMessage';
import { Select } from 'components/Select';
import { messages } from './messages';
import { ConfirmationModal } from './ConfirmationModal';
import { getValidationSchema } from './validators';

interface Props {
  requireConfirmation: boolean;
  open: boolean;
  registryId: string;
  roleId: string;
  personId?: string;
  onCancel(): void;
  onSuccess(): void;
  initialValues?: {
    firstName: string;
    lastName: string;
    relationship?: string;
    email?: string;
    phone?: string;
  };
}

const defaultFormValues: Props['initialValues'] = {
  firstName: '',
  lastName: '',
  relationship: '',
  email: '',
  phone: '',
};

export const CaregiverDetailsModal = ({
  requireConfirmation,
  open,
  registryId,
  roleId,
  personId,
  onSuccess,
  onCancel,
  initialValues = defaultFormValues,
}: Props) => {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(requireConfirmation);
  const validationSchema = useValidationSchema(getValidationSchema);
  const { current: userEmail } = useRef(Session.decodeToken()?.email);

  const setQueryData = useSetQueryData();
  const invalidateQuery = useInvalidateQuery();
  const personMutation = useMutation({
    mutationFn: Api.addRegistryPerson,
    onSuccess: (_, variables) => {
      onSuccess();
      setQueryData(
        [
          'registryPersons',
          {
            registryId,
            roles: ['caregiver'],
          },
        ],
        data => ({
          persons: [
            {
              id: variables.personId,
              ...data?.persons[0],
              ...variables.data,
              role: 'caregiver',
            },
          ],
        }),
      );
      invalidateQuery([
        'registryPersons',
        {
          registryId,
          roles: ['caregiver'],
        },
      ]);

      setQueryData(
        ['userRegistryNotifications', { userEmail: userEmail! }],
        data => {
          if (!data) return data;

          return produce(data, draft => {
            if (!variables.data.email) return;
            const registry = draft.registryNotifications.find(
              ({ registryId: rId }) => rId === registryId,
            );
            if (!registry) return;
            if (!registry.surveysNotifications.persons.length) {
              registry.surveysNotifications.persons = [
                {
                  personId: variables.personId,
                  email: variables.data.email,
                  isActive: true,
                },
              ];
              return;
            }

            registry.surveysNotifications.persons[0].email =
              variables.data.email;
          });
        },
      );
      invalidateQuery(['userRegistryNotifications', { userEmail: userEmail! }]);
    },
    onError: e => {
      errorToast(t(messages.unknownErrorOccurred()));

      mixpanel.track(EventNames.ENDPOINT_ERROR, {
        action: 'caregiver details modal',
        endpoint: 'PUT /registry/registries/{registryId}/persons/{personId}',
        error: e,
      });
    },
  });

  const onSubmit = (values: typeof initialValues) => {
    personMutation.mutate({
      registryId,
      personId: personId ?? uuid(),
      data: {
        firstName: values.firstName,
        lastName: values.lastName,
        relationship: values.relationship,
        email: values.email,
        roleId,
        ...(values.phone?.length ? { phone: values.phone } : undefined),
      },
    });
  };

  return (
    <>
      <Modal
        open={!showConfirmation && open}
        onClose={onCancel}
        title={t(messages.provideCaregiverDetails())}
      >
        <Formik
          initialValues={{
            firstName: initialValues.firstName,
            lastName: initialValues.lastName,
            relationship: initialValues.relationship ?? '',
            email: initialValues.email ?? '',
            phone: initialValues.phone ?? '',
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form style={{ display: 'flex', height: '100%' }}>
              <Stack
                height="100%"
                justifyContent="space-between"
                gap={{ sm: '0.8rem' }}
                marginX={{
                  lg: '4.8rem',
                  sm: '3.2rem',
                  xs: '2.4rem',
                }}
              >
                <Stack overflow="hidden">
                  <Stack gap="1.8rem" overflow="auto" maxHeight="100%">
                    <Typography variant="body1" marginBottom="2.4rem">
                      {t(messages.provideBasicPersonalInformation())}
                    </Typography>
                    <div>
                      <Typography variant="subtitle1" textTransform="uppercase">
                        {t(messages.relationshipToTheMember())}
                      </Typography>
                      <Select
                        changeValue={value =>
                          setFieldValue('relationship', value, true)
                        }
                        values={caregiverRelationships.map(relationship => ({
                          value: relationship,
                          label: relationship,
                        }))}
                        capitalizeFirstLetter
                        value={values.relationship || undefined}
                        placeholder={t(messages.select())}
                        aria-invalid={
                          errors.relationship && touched.relationship
                            ? 'true'
                            : 'false'
                        }
                        data-cy="relationship-input"
                      />
                      <ErrorMessage
                        message={errors.relationship}
                        visible={Boolean(
                          errors.relationship && touched.relationship,
                        )}
                        style={{ marginTop: '0.8rem' }}
                        data-cy={`relationship-input-error`}
                      />
                    </div>
                    <FormikTextField
                      name="firstName"
                      label={t(messages.firstName())}
                      placeholder={t(messages.firstName())}
                    />
                    <FormikTextField
                      name="lastName"
                      label={t(messages.lastName())}
                      placeholder={t(messages.lastName())}
                    />
                    <FormikTextField
                      name="email"
                      type="email"
                      label={t(messages.email())}
                      placeholder={t(messages.email())}
                    />
                    <FormikPhoneInput
                      name="phone"
                      label={t(messages.phoneNumber())}
                      placeholder={t(messages.phoneNumber())}
                    />
                  </Stack>
                </Stack>
                <Stack
                  gap="1.6rem"
                  direction={{ xs: 'column-reverse', sm: 'row' }}
                  justifyContent="stretch"
                  width="100%"
                  marginTop={{ xs: '1.6rem', sm: '2.4rem' }}
                >
                  <Button
                    variant="alternative"
                    onClick={onCancel}
                    style={{ width: '100%' }}
                    disabled={personMutation.isLoading}
                  >
                    {t(messages.cancel())}
                  </Button>
                  <LoadingButton
                    style={{ width: '100%' }}
                    loading={personMutation.isLoading}
                    variant="contained"
                    type="submit"
                  >
                    {t(messages.confirmAndSubmit())}
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Modal>
      <ConfirmationModal
        open={showConfirmation && open}
        onCancel={onCancel}
        onContinue={() => setShowConfirmation(false)}
      />
    </>
  );
};
