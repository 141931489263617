import { memo } from 'react';
import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

interface ResponsivenessHelperProps {
  breakpoint: Breakpoint;
  beforeBreakpoint: React.ReactElement;
  afterBreakpoint: React.ReactElement;
}

export const ResponsivenessHelperDown = memo(
  ({
    breakpoint,
    beforeBreakpoint,
    afterBreakpoint,
  }: ResponsivenessHelperProps) => {
    const theme = useTheme();
    const isMediaQueryTrue = useMediaQuery(theme.breakpoints.down(breakpoint));

    return isMediaQueryTrue ? beforeBreakpoint : afterBreakpoint;
  },
);

export const ResponsivenessHelperUp = memo(
  ({
    breakpoint,
    beforeBreakpoint,
    afterBreakpoint,
  }: ResponsivenessHelperProps) => {
    const theme = useTheme();
    const isMediaQueryTrue = useMediaQuery(theme.breakpoints.up(breakpoint));

    return isMediaQueryTrue ? beforeBreakpoint : afterBreakpoint;
  },
);

interface ResponsivenessHelperBetweenProps {
  breakpointStart: Breakpoint;
  breakpointEnd: Breakpoint;
  betweenBreakpoints: React.ReactElement;
  outsideBreakpoints: React.ReactElement;
}

export const ResponsivenessHelperBetween = memo(
  ({
    breakpointStart,
    breakpointEnd,
    betweenBreakpoints,
    outsideBreakpoints,
  }: ResponsivenessHelperBetweenProps) => {
    const theme = useTheme();
    const isMediaQueryTrue = useMediaQuery(
      theme.breakpoints.between(breakpointStart, breakpointEnd),
    );

    return isMediaQueryTrue ? betweenBreakpoints : outsideBreakpoints;
  },
);
