import { Button } from '@mui/material';
import styled from 'styled-components';
import { useTypography, scrollbar } from 'helpers/mixins';
import { StyleConstants } from 'styles/StyleConstants';

export const Label = styled(Button).attrs(() => ({
  disableTouchRipple: true,
  variant: 'text',
}))`
  && {
    display: inline;
    ${({ theme }) => useTypography(theme.typography.h4)}
    color: ${({ theme }) => theme.palette.button.primary};
    vertical-align: initial;
  }
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => useTypography(theme.typography.body1)}
  width: 100%;
  padding-right: calc(2rem + ${StyleConstants.SCROLLBAR_WIDTH}px);
  overflow-y: scroll;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    margin-left: 0;
    width: 100%;
  }

  ${({ theme }) =>
    scrollbar({
      thumbBackground: theme.palette.separator.primary,
      thumbHovered: theme.palette.separator.hover,
    })}

  p {
    ${({ theme }) => useTypography(theme.typography.body1)}
    padding: 0;
    margin: 0;
  }

  > div > ol > li {
    border-top: 0.16rem solid ${({ theme }) => theme.palette.separator.primary};
    margin-top: 2.4rem;
    padding-top: 2.4rem;
  }

  ol {
    counter-reset: section;
    font-weight: bold;
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 1rem;

    h2 {
      margin-top: 0;
    }

    li:before {
      counter-increment: section;
      content: 'Section ' counters(section, ':') ': ';
      left: 0;
      width: auto;
      position: relative;
      text-transform: none;
      font-size: 1.25em;
    }
  }

  li ol {
    counter-reset: section;
    font-weight: normal;
    list-style-type: none;
    padding-left: 6rem;

    > li {
      position: relative;
      margin: 1rem 0;
    }

    > li:before {
      counter-increment: section;
      content: counters(section, '.') ' ';
      left: -6rem;
      position: absolute;
      top: 0;
      width: 6rem;
    }
  }
`;
