import { memo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Auth } from '@aws-amplify/auth';
import { Button, Typography, Stack } from '@mui/material';
import { Email2Illustration } from 'assets/illustrations';
import { messages } from '../../messages';
import {
  CloseIcon,
  FormModalStepContainer,
  FormStackWrapper,
  IconButtonsContainer,
} from '../../styled';
import { ResponsivenessHelperDown } from '../../../../helpers/responsiveness';
import { TypographyResend } from './styled';

interface Props {
  onReturn(): void;
  onLogin(): void;
  username: string;
}

export const VerifyAccountStep = memo(
  ({ onReturn, onLogin, username }: Props) => {
    const { t } = useTranslation();
    const [isResendActionBlocked, setIsResendActionBlocked] = useState(false);

    const resendEmail = () => {
      if (!isResendActionBlocked) {
        setIsResendActionBlocked(true);
        Auth.resendSignUp(username);

        // Resent actions is blocked for 1 minute
        setTimeout(() => setIsResendActionBlocked(false), 60 * 1000);
      }
    };

    const content = (
      <>
        <FormStackWrapper
          spacing="2.4rem"
          data-cy="verify-account-step"
          width="100%"
        >
          <Email2Illustration style={{ maxWidth: '100%', height: 'auto' }} />
          <Typography
            variant="h1"
            textAlign={{
              xs: 'center',
              lg: 'left',
            }}
          >
            {t(messages.verifyYourAccount())}
          </Typography>
          <Typography
            variant="body1"
            textAlign={{
              xs: 'center',
              lg: 'left',
            }}
            style={{ marginTop: '1.6rem', whiteSpace: 'pre-line' }}
          >
            <Trans
              i18nKey={t(messages.verifyYourAccountDescription())}
              values={{ userEmail: username }}
              components={[
                <strong
                  data-cy="participant-email"
                  style={{ wordBreak: 'break-word' }}
                />,
              ]}
            />
          </Typography>
          <Button
            onClick={onLogin}
            style={{ width: '100%', marginTop: '2.4rem' }}
          >
            {t(messages.login())}
          </Button>
        </FormStackWrapper>

        <TypographyResend variant="h4">
          <Trans
            i18nKey={t(messages.didntGetLink())}
            components={[
              <Button
                variant="text"
                sx={{ marginLeft: '.5rem' }}
                onClick={resendEmail}
                data-cy="didnt-get-link-button"
              />,
            ]}
          />
        </TypographyResend>
      </>
    );

    return (
      <ResponsivenessHelperDown
        breakpoint={'sm'}
        beforeBreakpoint={
          <FormModalStepContainer>
            <IconButtonsContainer style={{ justifyContent: 'flex-end' }}>
              <CloseIcon onClick={onReturn} />
            </IconButtonsContainer>
            {content}
          </FormModalStepContainer>
        }
        afterBreakpoint={
          <Stack justifyContent="center" height="100%" alignItems="center">
            {content}
          </Stack>
        }
      />
    );
  },
);
