import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as QualificationSurveyValidationIllustrationLight } from './qualificationSurveyValidation.svg';
import { ReactComponent as QualificationSurveyValidationIllustrationDark } from './qualificationSurveyValidation_dark.svg';

export const QualificationSurveyValidationIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return (
        <QualificationSurveyValidationIllustrationDark
          aria-hidden="true"
          {...props}
        />
      );
    return (
      <QualificationSurveyValidationIllustrationLight
        aria-hidden="true"
        {...props}
      />
    );
  },
);
