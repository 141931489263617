import * as yup from 'yup';
import i18next from 'i18next';
import { messages } from '../../messages';

export const getValidationSchema = () =>
  yup.object().shape({
    password: yup
      .string()
      .required(i18next.t(messages.passwordEmptyFieldMsg())),
  });
