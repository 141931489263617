import styled from 'styled-components';
import { Button, Accordion as UnstyledAccordion } from '@mui/material';
import abstractBackground from '../../assets/bg2/light.svg';
import abstractBackgroundDark from '../../assets/bg2/dark.svg';

export const PanelContainer = styled.section`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.palette.background.primaryMobile};

  padding: 9.6rem 7.2rem 7.2rem;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 7.2rem 4.8rem 5.6rem;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 5.6rem 2.4rem 3.2rem;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    :before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: url(${({ theme }) =>
        theme.palette.type === 'dark'
          ? abstractBackgroundDark
          : abstractBackground});
      background-repeat: repeat-y;
      background-position: center -22rem;
      background-size: 228rem 124.7rem;
    }
  }
`;

export const ShowLessMoreButton = styled(Button)`
  align-self: center;
  margin-top: 6.6rem;
  transition: none;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    align-self: flex-start;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 5.8rem;
  }

  color: ${({ theme }) => theme.palette.button.primary};

  &&:hover,
  &&:active {
    background: transparent;
    color: ${({ theme }) => theme.palette.button.primary};
  }

  &&:focus-visible {
    border-radius: 1.6rem;
    border: ${({ theme }) => theme.palette.additionalColors.accentSuccess}B2
      0.4rem solid !important;
    padding: 0.4rem;
    margin -0.8rem; 
    margin-top: 5rem;
  }
`;

export const Accordion = styled(UnstyledAccordion)`
  .MuiAccordionSummary-content {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .MuiAccordionDetails-root {
    color: ${({ theme }) => theme.palette.additionalColors.bodyText};
  }

  .MuiAccordionSummary-expandIconWrapper svg * {
    stroke: ${({ theme }) => theme.palette.button.text} !important;
  }
`;
