import { useEffect } from 'react';
import {
  getGlobalVariable,
  GlobalKeys,
  setGlobalVariable,
} from 'helpers/globalVariables';

export const useBlockExit = (condition = true) => {
  useEffect(() => {
    if (!condition) return;

    setGlobalVariable(GlobalKeys.FORCE_REDIRECT, false);
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (getGlobalVariable(GlobalKeys.FORCE_REDIRECT)) {
        setGlobalVariable(GlobalKeys.FORCE_REDIRECT, false);
        return;
      }

      event.returnValue = ' ';
      return ' ';
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [condition]);
};
