import { FeatureToggles } from 'types/index';
import Config from 'config/index';

const { featureToggles } = Config.get();

interface Arguments<T, G> {
  name: FeatureToggles;
  feature: T;
  replacement: G;
}

export function hasFeature(name: FeatureToggles) {
  return featureToggles[name] !== false;
}

export function Feature<T>({
  name,
  feature,
}: Omit<Arguments<T, undefined>, 'replacement'>): T | undefined;
export function Feature<T>({
  name,
  feature,
  replacement,
}: Arguments<T, undefined> & { replacement: undefined }): T | undefined;
export function Feature<T>({ name, feature, replacement }: Arguments<T, T>): T;
export function Feature<T, G>({
  name,
  feature,
  replacement,
}: Arguments<T, G>): T | G;
export function Feature<T, G = undefined>({
  name,
  feature,
  replacement,
}:
  | Arguments<T, G>
  | {
      name: FeatureToggles;
      feature: T;
      replacement?: G;
    }): T | G | undefined {
  return hasFeature(name) ? feature : replacement;
}
