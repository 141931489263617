import { Statistics } from 'api/models/Survey';

export const roundToMinutes = (seconds: number) => {
  if (seconds === 0) return 0;
  return Math.round(seconds / 60);
};

export const getEstimatedTime = ({
  maxEstimatedDurationInSeconds,
  minEstimatedDurationInSeconds,
}: Pick<
  Statistics,
  'maxEstimatedDurationInSeconds' | 'minEstimatedDurationInSeconds'
>): string => {
  const roundedMin = roundToMinutes(minEstimatedDurationInSeconds);
  const roundedMax = roundToMinutes(maxEstimatedDurationInSeconds);

  if (
    minEstimatedDurationInSeconds < 60 &&
    maxEstimatedDurationInSeconds < 60
  ) {
    return '< 1';
  }

  if (minEstimatedDurationInSeconds < 60 && roundedMax === 1) {
    return '1';
  }

  if (minEstimatedDurationInSeconds < 60) {
    return `< 1 - ${roundedMax}`;
  }

  if (roundedMin === roundedMax) {
    return String(roundedMin);
  }

  return `${roundedMin} - ${roundedMax}`;
};
