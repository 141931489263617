// File for styled-component mixin-like functions
import { css } from 'styled-components';
import {
  TypographyStyleOptions,
  TypographyOptions,
} from '@mui/material/styles/createTypography';
import { StyleConstants } from '../styles/StyleConstants';

// Thumb border must be the same as the rest of the component's
// background to look good. It makes the padding effect of the
// thumb, as the regular padding property doesn't work in this case.

export const scrollbar = ({
  thumbBackground,
  thumbHovered,
}: {
  thumbBackground: string;
  thumbHovered: string;
}) => css`
  scrollbar-color: ${thumbBackground} transparent;

  &::-webkit-scrollbar {
    background: transparent;
    height: 100%;
    width: ${StyleConstants.SCROLLBAR_WIDTH}px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${thumbBackground};
    border-radius: 1rem;
    //border: 4px solid transparent;
    background-clip: content-box;

    &:hover {
      background: ${thumbHovered};
    }
  }
`;

export const buttonHoverEffect = (color: string) =>
  css`
    &:hover::after {
      visibility: visible;
    }

    &::after {
      position: absolute;
      content: '';
      left: 0;
      width: 100%;
      height: 3.2rem;
      background: ${color};
      opacity: 0.08;
      border-radius: 1.8rem;
      box-sizing: content-box;

      visibility: hidden;
    }
  `;

export const useTypography = (
  typography: TypographyStyleOptions | TypographyOptions,
) => css`
  font-size: ${typography.fontSize};
  font-family: ${typography.fontFamily};
  color: ${(typography as TypographyStyleOptions).color};
  font-weight: ${(typography as TypographyStyleOptions).fontWeight};
  line-height: ${(typography as TypographyStyleOptions).lineHeight};
  letter-spacing: ${(typography as TypographyStyleOptions).letterSpacing};
`;

export const gradientBorder = css`
  position: relative;

  // gradient border
  :after {
    content: '';
    position: absolute;
    top: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem;
    right: -0.1rem;
    z-index: -1;
    background: ${({ theme }) => theme.palette.additionalColors.surveyCard};
    border-radius: 1.6rem;
    box-shadow: ${({ theme }) => theme.palette.additionalColors.tileShadow};
  }
`;
