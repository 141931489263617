import { PublicRegistry } from 'api/interfaces/response/getPublicRegistriesResponse';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@mui/material';
import Config from 'config';
import scientistIllustrationLight from 'pages/IntroPage/components/IntroSection/assets/scientist.svg';
import scientistIllustrationDark from 'pages/IntroPage/components/IntroSection/assets/scientist_dark.svg';
import { StartButton } from 'components/StartButton';
import {
  RegistryTileBorder,
  RegistryTile,
  RegistryIllustration,
} from './styled';
import { messages } from '../../messages';

interface Props {
  registry: PublicRegistry;
}

export const Registry = ({ registry }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getIllustration = () => {
    const { registryIllustrationDark, registryIllustration } =
      registry.additionalParams?.introPage ?? {};

    if (theme.palette.type === 'dark')
      return registryIllustrationDark
        ? `${Config.get().s3PublicBucketUrl}${registryIllustrationDark}`
        : scientistIllustrationDark;
    return registryIllustration
      ? `${Config.get().s3PublicBucketUrl}${registryIllustration}`
      : scientistIllustrationLight;
  };

  return (
    <RegistryTileBorder>
      <RegistryTile to={`/registry/${registry.code}`}>
        <RegistryIllustration src={getIllustration()} alt="" />
        <span>
          <Typography variant="h2" component="h3">
            {registry.name}
          </Typography>
          <Typography variant="body1">{registry.description}</Typography>
        </span>
        <StartButton>{t(messages.goToTheRegistry())}</StartButton>
      </RegistryTile>
    </RegistryTileBorder>
  );
};
