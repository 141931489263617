import { memo } from 'react';
import { Wrapper } from 'components/Loader/styled';

export const Loader = memo(() => (
  <Wrapper role="progressbar">
    <span />
    <span />
    <span />
    <p aria-live="polite" className="sr-only">
      Loading
    </p>
  </Wrapper>
));
