import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/Modal';
import { ModalContent } from 'components/Modal/ModalContent';
import { MarkdownRenderer } from 'components/MarkdownRenderer';
import { privacyPolicyEn } from 'assets/documents/privacyPolicyEn';
import { useBooleanState } from 'hooks/useBooleanState';

import { messages } from './messages';
import { ContentWrapper, Label } from './styled';

export const PrivacyPolicy = memo(() => {
  const { t, i18n } = useTranslation();
  const {
    value: showModal,
    setTrue: onOpen,
    setFalse: onClose,
  } = useBooleanState(false);

  const getProperTranslation = () => {
    switch (i18n.language) {
      case 'en':
      default:
        return privacyPolicyEn;
    }
  };

  return (
    <>
      <Label onClick={onOpen} data-cy="privacy-policy-link">
        {t(messages.privacyPolicy())}
      </Label>
      <Modal
        open={showModal}
        onClose={onClose}
        title={t(messages.privacyPolicy())}
        closeOnClickAway
      >
        <ModalContent>
          <ContentWrapper>
            <MarkdownRenderer>{getProperTranslation()}</MarkdownRenderer>
          </ContentWrapper>
        </ModalContent>
      </Modal>
    </>
  );
});
