/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { createGlobalStyle, css } from 'styled-components';
import calendarIcon from 'assets/icons/calendar.svg';
import { scrollbar, buttonHoverEffect, useTypography } from 'helpers/mixins';
/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`

  *{
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    font-size: 62.5%;
  }

  #root {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'AvertaRegular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: ${({ theme } /* @ts-ignore */) =>
      theme.palette.background.base.primary};

    ${({ theme }) =>
      scrollbar({
        thumbBackground: theme.palette.separator.primary,
        thumbHovered: theme.palette.separator.hover,
      })}
  }
  
  p,
  label {
    line-height: 1.5em;
  }

  ul {
    padding-left: 3.2rem;
    margin: 0.8rem 0;
  }

  a:not(.MuiButtonBase-root) {
    ${({ theme }) => useTypography(theme.typography.h5)}
    color: ${({ theme }) => theme.palette.button.primary};
    text-decoration: none;
    transition: color 300ms;

    svg * {
      transition: stroke 300ms;
    }

    :hover {
      color: ${({ theme }) => theme.palette.text.primary};
        svg {
          *[stroke="#006ADC"] {
            stroke: ${({ theme }) => theme.palette.text.primary};
          }
        }
    }

  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    background-color: ${({ theme } /* @ts-ignore */) =>
      theme.palette.background.base.primary};
  }

  h1,h2,h3 {
    font-family: 'AvertaBold';
    font-weight: normal;
  }

  ul {
    list-style-position: inside;
    padding: 0;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    mask-image: url(${calendarIcon});
    background-image: none;
    height: 1em;
    width: 0.85em;
    background-color: ${({ theme } /* @ts-ignore */) =>
      theme.palette.text.secondary};
    cursor: pointer;
  }

  // a11y
  body:not(.using-mouse)  *:focus-visible:not(input) {
    border-radius: 1.6rem;
    border: ${({ theme }) =>
      theme.palette.additionalColors.accentSuccess}B2 .4rem solid !important;
  }
  // need more specific query to force higher priority
  // remove after .focus({ focusVisible: false }) is
  // supported in chrome
  body:not(.using-mouse), body.using-mouse { 
    div:focus-visible, h1:focus-visible, h2:focus-visible, h3:focus-visible, form:focus-visible {
      border: none !important;
      outline: none !important;
    }
  }
   body.using-mouse div:focus {
      outline: none;
  }
  body.using-mouse .MuiTextField-root:focus-within {
    border: none !important;
    outline: none !important;
  }

  // screen reader only
  .sr-only {
      border: 0 none;
      clip: rect(0px,0px,0px,0px);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
  }
  
  // Mui Tooltip
  .MuiTooltip-tooltip {
    && {
      margin: 0.2rem 0;
      padding: 0.8rem 1.6rem; 
      border-radius: 0.8rem;
      font-family: 'AvertaRegular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: ${({ theme }) =>
        /* @ts-ignore */
        theme.palette.button.text};
      background-color: ${({ theme } /* @ts-ignore */) =>
        theme.palette.text.primary};
      }
  }

  .MuiTooltip-arrow:before {
      color: ${({ theme } /* @ts-ignore */) =>
        theme.palette.background.elevated.menuHover};
      }
  }

  // date picker
  .MuiPickersModal-dialogRoot {
    &&{
      border-radius: 0.8rem;
      background-color: ${({ theme }) =>
        theme.palette.background.elevated.primary};
      filter: drop-shadow(0px 4px 30px rgba(49, 48, 111, 0.08));

      * {
        font-family: 'AvertaRegular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-weight: normal;
      }

      .MuiButton-label {
        font-family: 'AvertaBold';
        background: transparent !important;
        ${({ theme }) => buttonHoverEffect(theme.palette.button.primary)}}
      }

      .MuiPickersCalendarHeader-transitionContainer > p {
        font-size: 1.6rem;
        line-height: 150%;
      }

      .MuiPickersToolbarText-toolbarTxt {
        font-family: 'AvertaBold';
        color: ${({ theme }) => theme.palette.button.text};
        font-size: 2.4rem;
        line-height: 3rem;
        opacity: 0.5;
        transition: opacity 0.3s;

        &.MuiTypography-subtitle1 {
          font-family: 'AvertaRegular';
          font-size: 1.6rem;
          line-height: 150%;
        }

        &.MuiPickersToolbarText-toolbarBtnSelected {
          opacity: 1;
        }
      }

      .MuiPickersToolbarButton-toolbarBtn {
        .MuiTouchRipple-root { 
          display: none;
        }
      }

      .MuiPickersYear-root  {
        font-family: 'AvertaRegular';
        font-size: 1.6rem;
        line-height: 150%;
        color: ${({ theme }) => theme.palette.text.primary};
        margin: auto;
        position: relative;

        &:focus {
          color: ${({ theme }) => theme.palette.text.primary};
        }

        &.MuiPickersYear-yearDisabled {
          color: ${({ theme }) => theme.palette.text.secondary};
        }

        &.MuiPickersYear-yearSelected {
          color: ${({ theme }) => theme.palette.button.primary};
        }

        &:before {
          content: '';
          position: absolute;
          width: 4.5em;
          height: 2em;
          border-radius: 1.8rem;
          background: green;
          background: ${({ theme }) => theme.palette.text.primary};
          opacity: 0;
          transition: opacity 0.3s;
        }

        &:hover:before {
          opacity: 0.08;
        }
      }

      .MuiPickersDatePickerRoot-toolbar  {
        background-color: ${({ theme }) => theme.palette.button.primary};
        color: ${({ theme }) => theme.palette.button.text};
      }

      .MuiPickersCalendarHeader-iconButton {
        color: ${({ theme }) => theme.palette.text.secondary};
        background-color: transparent;
        margin: 0 0.6rem;

        > span {
          ${({ theme }) => buttonHoverEffect(theme.palette.text.secondary)}
        }

        &.Mui-disabled svg * {
          stroke: ${({ theme }) =>
            theme.palette.button.disabledBackground} !important;
        }
      }

      .MuiPickersCalendarHeader-dayLabel {
        font-size: 1.4rem;
        line-height: 150%;
        color: ${({ theme }) => theme.palette.text.secondary};
      }

      .MuiPickersDay-day {
        color: ${({ theme }) => theme.palette.text.primary};
        transition: background-color 0.3s, color 0.3s;

        p {
          font-size: 1.6rem;
          line-height: 150%;
        }
      }

      .MuiPickersDay-dayDisabled {
        color: ${({ theme }) => theme.palette.text.secondary};
      }

      .MuiPickersDay-current {
        color: ${({ theme }) => theme.palette.button.primary};
      }

      .MuiPickersDay-daySelected {
        background-color: ${({ theme }) =>
          theme.palette.button.primary} !important;
        color: ${({ theme }) => theme.palette.button.text} !important;
      }

      .MuiDialogActions-root button {
        border-radius: 1.6rem;
        color: ${({ theme }) => theme.palette.button.primary};
          font-family: 'AvertaBold';

        span {
          font-size: 1.5rem;
          line-height: 1.9rem;
        }
      }
    }
  

  // material-react-toastify
  .Toastify__toast-container {
    width: auto;
    min-width: min(50rem, 90vw);
    max-width: 90vw;
  }
  .Toastify__toast-container--top-right {
    top: 1em;
    left: 75%;
    transform: translateX(-50%);

    ${({ theme }) => theme.breakpoints.down('lg')} {
      left: 50%;
    }
  }
  .Toastify__toast-container--top-center {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .Toastify__toast--error {
  ${({ theme }) => useTypography(theme.typography.body1)}
    background-color: ${({ theme } /* @ts-ignore */) =>
      theme.palette.message.background.error};
    color: ${({ theme } /*   @ts-ignore   */) =>
      theme.palette.message.text.neutral};
  }
  .Toastify__toast-icon--error {
    color: ${({ theme } /*   @ts-ignore   */) =>
      theme.palette.message.text.error};
    margin-right: 1rem;
  }
  .Toastify__toast--error .Toastify__close-button,
  .Toastify__toast--success .Toastify__close-button {
    color: ${({ theme } /*   @ts-ignore   */) =>
      theme.palette.message.text.neutral};
  }
  .Toastify__toast--success {
  ${({ theme }) => useTypography(theme.typography.body1)}
    background-color: ${({ theme } /* @ts-ignore */) =>
      theme.palette.message.background.success};
    color: ${({ theme } /*   @ts-ignore   */) =>
      theme.palette.message.text.neutral};
  }
  .Toastify__toast--info {
  ${({ theme }) => useTypography(theme.typography.body1)}
    background-color: ${({ theme } /* @ts-ignore */) =>
      theme.palette.message.background.informative};
    color: ${({ theme }) => theme.palette.text.primary};
  }
  .Toastify__toast-icon--success {
    color: ${({ theme } /* @ts-ignore */) =>
      theme.palette.message.text.success};
    margin-right: 1rem;
  }
  .Toastify__toast-icon--info {
    color: ${({ theme } /* @ts-ignore */) => theme.palette.text.primary};
    margin-right: 1rem;
  }
  .Toastify__progress-bar--error {
    background-color: ${({ theme } /* @ts-ignore */) =>
      theme.palette.message.text.error};
    height: 3px;
  }
  .Toastify__progress-bar--success {
    background-color: ${({ theme } /* @ts-ignore */) =>
      theme.palette.message.text.success};
    height: 3px;
  }
  .Toastify__progress-bar--info {
    background-color: ${({ theme } /* @ts-ignore */) =>
      theme.palette.message.text.informative};
    height: 3px;
  }

  @keyframes pulse-border {
    ${({ theme }: { theme: { palette: any } }) => css`
      0% {
        border-color: ${theme.palette.separator.primary};
      }
      25% {
        border-color: ${theme.palette.message.text.error};
      }
      50% {
        border-color: ${theme.palette.separator.primary};
      }
      75% {
        border-color: ${theme.palette.message.text.error};
      }
      100% {
        border-color: ${theme.palette.separator.primary};
      }
    `}
  }

@keyframes pulse-border-active {
  ${({ theme }: { theme: { palette: any } }) => css`
    0% {
      border-color: ${theme.palette.separator.primary};
    }
    25% {
      border-color: ${theme.palette.message.text.error};
    }
    50% {
      border-color: ${theme.palette.separator.primary};
    }
    75% {
      border-color: ${theme.palette.message.text.error};
    }
    100% {
      border-color: ${theme.palette.button.primary};
    }
  `}
}
`;
