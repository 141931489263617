import { Components } from '@mui/material/styles/components';
import { Theme } from 'styles/theme/themes';

export const MuiTabPanel: Components<Theme>['MuiTabPanel'] = {
  styleOverrides: {
    root: () => ({
      padding: 0,
    }),
  },
};
