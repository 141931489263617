import { memo } from 'react';
import { CheckboxProps, Stack } from '@mui/material';

import {
  CheckboxIcon,
  CheckboxDisabledIcon,
  CheckboxCheckedIcon,
  CheckboxCheckedDisabledIcon,
} from 'assets/icons';
import { LabelWrapper, StyledCheckbox } from './styles';

export interface Props extends CheckboxProps {
  label?: string | JSX.Element;
  'data-cy'?: string;
}

export const Checkbox = memo(
  ({ checked, label, onChange, ...props }: Props) => {
    const getIcon = () =>
      props.disabled ? <CheckboxDisabledIcon /> : <CheckboxIcon />;
    const getCheckedIcon = () =>
      props.disabled ? (
        <CheckboxCheckedDisabledIcon />
      ) : (
        <CheckboxCheckedIcon />
      );

    if (label) {
      return (
        <Stack direction="row" alignItems="center">
          <div>
            <StyledCheckbox
              data-cy={props['data-cy'] || 'checkbox'}
              checked={checked}
              color="primary"
              onChange={onChange}
              icon={getIcon()}
              checkedIcon={getCheckedIcon()}
              {...props}
            />
          </div>
          <LabelWrapper>{label}</LabelWrapper>
        </Stack>
      );
    }

    return (
      <StyledCheckbox
        checked={checked}
        color="primary"
        onChange={onChange}
        icon={getIcon()}
        checkedIcon={getCheckedIcon()}
        {...props}
      />
    );
  },
);
