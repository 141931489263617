import { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Stack, Typography } from '@mui/material';
import { errorToast } from 'helpers/toast';
import Api from 'api/api';
import Session from 'api/storage/session';
import { queryKeys } from 'api/queryKeys';
import { ErrorMessage } from 'components/ErrorMessage';
import { messages } from '../../messages';
import { NotificationsSectionSkeleton } from './skeleton';
import { NotificationList } from './NotificationList';

export const NotificationsSection = memo(() => {
  const { t } = useTranslation();
  const { current: userEmail } = useRef(Session.decodeToken()?.email);

  const NotificationsQuery = useQuery({
    queryKey: queryKeys.userRegistryNotifications({ userEmail: userEmail! }),
    queryFn: Api.getRegistryNotifications,
    staleTime: 5 * 60 * 1000,
    onError: () => {
      errorToast(t(messages.unknownError()));
    },
  });

  const getContent = () => {
    switch (NotificationsQuery.status) {
      case 'error':
        return <ErrorMessage message={t(messages.unknownError())} />;
      case 'loading':
        return <NotificationsSectionSkeleton />;
      default:
    }
    return <NotificationList data={NotificationsQuery.data} />;
  };

  if (NotificationsQuery.data?.registryNotifications.length === 0) return null;
  return (
    <Stack gap="1.6rem">
      <Typography variant="h3">{t(messages.notifications())}</Typography>
      <Stack
        component="form"
        gap="3.8rem"
        direction="column"
        overflow="hidden"
        maxHeight="100%"
        height="100%"
      >
        <Stack
          spacing="2.4rem"
          overflow="auto"
          display={{
            xs: 'unset',
            sm: 'flex',
          }}
        >
          {getContent()}
        </Stack>
      </Stack>
    </Stack>
  );
});
