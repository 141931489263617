import React, { memo, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Auth } from '@aws-amplify/auth';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { messages } from 'pages/Login/messages';
import { ErrorMessage } from 'components/ErrorMessage/index';
import { PasswordValidator } from 'components/PasswordValidator';
import { PasswordInput } from 'components/PasswordInput';
import { useValidationSchema } from 'hooks/useValidationSchema';
import { errorToast } from 'helpers/toast';
import { MessageIllustration } from 'assets/illustrations_old';
import { getValidationSchema } from './validators';
import { StyledInput } from './styled';
import {
  BackIcon,
  IconButtonsContainer,
  FormModalStepContainer,
  FormStackWrapper,
} from '../../styled';
import { ResponsivenessHelperDown } from '../../../../helpers/responsiveness';

interface FormTypes {
  code: [string, string, string, string, string, string];
  password: string;
}

interface Props {
  onSuccess(): void;
  onBack(): void;
  onReturn(): void;
  username: string;
}

export const PasswordResetCodeStep = memo(
  ({ onSuccess, onBack, onReturn, username }: Props) => {
    const { t } = useTranslation();
    const validationSchema = useValidationSchema(getValidationSchema);
    const inputRefs = useRef<HTMLInputElement[]>(new Array(6).fill(null));
    const [isResendActionBlocked, setIsResendActionBlocked] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { mutate, isLoading, error, reset } = useMutation<
      unknown,
      Record<string, unknown>,
      FormTypes
    >({
      mutationFn: ({ code, password }) =>
        Auth.forgotPasswordSubmit(username, code.join(''), password),
    });

    useEffect(() => {
      try {
        Auth.forgotPassword(username);
      } catch (_e: unknown) {
        errorToast(t(messages.errorOccurredTryAgain()), {
          position: 'top-right',
        });
      }
    }, [username]);

    const mapError = (formError?: string | string[]) => {
      if (error?.code === 'CodeMismatchException')
        return t(messages.incorrectCode());
      if (typeof formError === 'string') return formError;
      if (typeof formError === 'object') return formError[0];
    };

    const resendCode = () => {
      if (!isResendActionBlocked) {
        setIsResendActionBlocked(true);
        Auth.forgotPassword(username);

        // Resent actions is blocked for 1 minute
        setTimeout(() => setIsResendActionBlocked(false), 60 * 1000);
      }
    };

    const changePassword = (form: FormTypes) => mutate(form, { onSuccess });

    const formContent = (
      <Formik
        initialValues={
          {
            code: Array(6).fill(''),
            password: '',
          } as FormTypes
        }
        onSubmit={changePassword}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          errors,
          touched,
          setFieldValue,
          validateForm,
          values,
          validateField,
          setFieldTouched,
        }) => {
          const onInputChange = (
            index: number,
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => {
            reset();
            const { value } = e.target;
            setFieldValue(`code.${index}`, value.charAt(0), false);

            if (index <= 4 && Number(value) >= 0) {
              inputRefs.current[index + 1]?.focus();
            }
            if (index === 5)
              setTimeout(() => {
                validateForm();
              }, 100);
          };

          const onKeyDown = (
            index: number,
            e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => {
            const { key } = e;
            const {
              target: { value },
            } = e as unknown as { target: { value: string } };

            switch (key) {
              case 'Backspace':
                if (value.length > 0) setFieldValue(`code.${index}`, '', false);
                else if (index > 0) {
                  setFieldValue(`code.${index - 1}`, '', false);
                  inputRefs.current[index - 1]?.focus();
                }
                reset();
                e.preventDefault();
                break;
              case 'ArrowLeft':
                if (index > 0) inputRefs.current[index - 1]?.focus();
                e.preventDefault();
                break;
              case 'ArrowRight':
                if (index < 5) inputRefs.current[index + 1]?.focus();
                e.preventDefault();
                break;
              case 'ArrowUp':
                reset();
                setFieldValue(`code.${index}`, Number(value) + 1, false);
                e.preventDefault();
                break;
              case 'ArrowDown':
                reset();
                setFieldValue(`code.${index}`, (Number(value) + 9) % 10, false);
                e.preventDefault();
                break;
              default:
                break;
            }
          };

          const onKeyPress = (e: React.KeyboardEvent) => {
            const { key } = e;
            if (key === 'Enter') return;
            if (!key.match(/^[0-9]+$/)) e.preventDefault();
          };

          const onPaste = (index: number, e: React.ClipboardEvent) => {
            reset();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const pasted = (e.clipboardData || window.clipboardData).getData(
              'text',
            );

            let pastedCounter = 0;
            pasted.split('').forEach(number => {
              if (index + pastedCounter > 5) return;
              if (number.match(/^[0-9]+$/)) {
                setFieldValue(`code.${index + pastedCounter}`, number, false);
                pastedCounter += 1;
              }
            });

            const toFocus =
              index + pastedCounter > 5 ? 5 : index + pastedCounter;
            inputRefs.current[toFocus]?.focus();
            e.preventDefault();

            setTimeout(() => {
              validateForm();
            }, 100);
          };

          return (
            <Form data-cy="password-reset-code-step">
              <FormStackWrapper spacing="2.4rem">
                {isMobile && <MessageIllustration style={{ width: '100%' }} />}
                <Typography variant="h1">
                  {t(messages.checkYouEmail())}
                </Typography>
                <Typography variant="body1" style={{ marginTop: '1.6rem' }}>
                  <Trans
                    i18nKey={t(messages.toResetEnterCode())}
                    values={{ username }}
                    components={[<strong />]}
                  />
                </Typography>
                <Stack
                  direction="row"
                  gap="0.8rem"
                  data-cy="code-inputs-container"
                >
                  {values.code.map((code, index) => (
                    <StyledInput
                      key={index}
                      value={code}
                      name={`code.${index}`}
                      onChange={e => onInputChange(index, e)}
                      onPaste={e => onPaste(index, e)}
                      InputProps={{
                        style: { padding: 0 },
                      }}
                      inputProps={{
                        onKeyDown: e => onKeyDown(index, e),
                        onKeyPress,
                        ref: (element: HTMLInputElement) => {
                          inputRefs.current[index] = element;
                        },
                        'data-cy': `code-${index}-input`,
                        style: { textAlign: 'center' },
                      }}
                    />
                  ))}
                </Stack>
                <ErrorMessage
                  message={mapError(errors.code)}
                  visible={
                    !!error ||
                    (touched.code as unknown as boolean[])?.every(c => c)
                  }
                  style={{ marginTop: '0.8rem' }}
                />
                <Typography variant="h1">
                  {t(messages.enterNewPassword())}
                </Typography>
                <Typography variant="body1" style={{ marginTop: '1.6rem' }}>
                  <Trans
                    i18nKey={t(messages.chooseNewPassword())}
                    values={{ username }}
                    components={[<strong />]}
                  />
                </Typography>
                <PasswordInput
                  name="password"
                  placeholder={t(messages.newPassword())}
                  disableError={true}
                  validationSchema={validationSchema}
                  oneWayValidationOnChange={true}
                  onBlur={() => {
                    if (!touched.password) setFieldTouched('password', true);
                    validateField('password');
                  }}
                  data-cy="password-input"
                  label={t(messages.newPassword())}
                />
                <PasswordValidator password={values.password} />
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isLoading}
                  data-cy="password-reset-code-step-submit-button"
                >
                  {t(messages.resetPassword())}
                </LoadingButton>

                <ResponsivenessHelperDown
                  breakpoint="sm"
                  beforeBreakpoint={
                    <Button
                      variant="text"
                      onClick={resendCode}
                      data-cy="resend-code-button"
                    >
                      {t(messages.resendCode())}
                    </Button>
                  }
                  afterBreakpoint={
                    <>
                      <Stack
                        spacing="0.4rem"
                        direction="row"
                        justifyContent="center"
                      >
                        <Typography variant="h4">
                          {t(messages.didntGetTheCode())}
                        </Typography>
                        <Button
                          variant="text"
                          onClick={resendCode}
                          data-cy="resend-code-button"
                        >
                          {t(messages.resendCode())}
                        </Button>
                      </Stack>
                      <Button
                        variant="text"
                        onClick={onReturn}
                        data-cy="back-button"
                        style={{ marginTop: '4.5rem' }}
                      >
                        {t(messages.back())}
                      </Button>
                    </>
                  }
                />
              </FormStackWrapper>
            </Form>
          );
        }}
      </Formik>
    );

    return (
      <ResponsivenessHelperDown
        breakpoint={'sm'}
        beforeBreakpoint={
          <FormModalStepContainer>
            <IconButtonsContainer>
              <BackIcon onClick={onBack} />
            </IconButtonsContainer>
            {formContent}
          </FormModalStepContainer>
        }
        afterBreakpoint={formContent}
      />
    );
  },
);
