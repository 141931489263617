import { FeatureToggles } from 'types/index';
import configDev from './values/dev.config.json';
import configExp from './values/exp.config.json';
import configUat from './values/uat.config.json';
import configPrd from './values/prd.config.json';

export default class Config {
  public amplify!: unknown;

  public apiUrl!: string;

  public apiTimeout!: number;

  public ehrRedirectUri!: string;

  public s3PublicBucketUrl!: string;

  public featureToggles!: Record<FeatureToggles, boolean>;

  public autoLogout!: typeof configDev['autoLogout'];

  public mixpanelToken!: string;

  public sentryUrl!: string;

  public static get(env?: string): Config {
    switch (env || Config.getEnvKey()) {
      case 'exp':
        return configExp;

      case 'dev':
        return configDev;

      case 'uat':
        return configUat;

      case 'prd':
        return configPrd;

      default:
        return configDev;
    }
  }

  public static getEnvKey(): string {
    if (!process.env.REACT_APP_ENV) {
      throw new Error(
        'Please set ENV environment variable when invoking tests',
      );
    }
    return process.env.REACT_APP_ENV;
  }

  public static isTestEnv(): boolean {
    return process.env.REACT_APP_CYPRESS_ENV === 'true';
  }

  private static getValueFromEnvironmentVariables(key: string): string {
    const value = process.env[key];
    if (!value) {
      return 'not-found';
    }
    return value;
  }
}
