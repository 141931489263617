/* eslint-disable import/no-import-module-exports */
import * as React from 'react';
import mixpanel from 'mixpanel-browser';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import 'sanitize.css/sanitize.css';

// Initialize languages
import './locales/i18n';
import './assets/fonts/fonts.css';
import 'material-react-toastify/dist/ReactToastify.css';

import { initSentry } from 'helpers/sentry';
import Config from 'config';
import App from './app';
import Providers from './providers';
import reportWebVitals from './reportWebVitals';

const { mixpanelToken } = Config.get();
if (mixpanelToken) {
  mixpanel.init(mixpanelToken, {
    debug: import.meta.env.DEV,
    track_pageview: false, // doesn't work in SPA
    persistence: 'localStorage',
  });
}

initSentry();

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.Fragment>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <Providers>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Providers>
  </React.Fragment>,
);

reportWebVitals();
