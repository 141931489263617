/**
 *
 * Tooltip
 *
 */
import React, { memo, useEffect, useRef, useState } from 'react';
import { Tooltip as UnstyledTooltip, TooltipProps } from '@mui/material';
import { Title } from './styled';

interface Props extends TooltipProps {
  title: string | NonNullable<React.ReactNode>;
  type?: 'error' | 'info';
  'data-cy'?: string;
  showTooltipOnlyOnOverflow?: boolean;
}

export const Tooltip = memo(
  ({
    type = 'info',
    title,
    children,
    showTooltipOnlyOnOverflow = false,
    ...props
  }: Props) => {
    const [isOverflow, setIsOverflow] = useState(false);
    const elementRef = useRef<HTMLSpanElement>(null);

    const updateIsOverflowing = () => {
      if (elementRef.current)
        setIsOverflow(
          elementRef.current.scrollWidth > elementRef.current.clientWidth ||
            elementRef.current.scrollHeight > elementRef.current.clientHeight,
        );
    };

    useEffect(() => {
      updateIsOverflowing();
    }, [elementRef.current, children]);

    useEffect(() => {
      window.addEventListener('resize', updateIsOverflowing);

      return () => {
        window.removeEventListener('resize', updateIsOverflowing);
      };
    }, []);

    const renderTitle = () => {
      // empty strings are not rendered by MUI Tooltip
      if (!title) return '';

      return <Title>{title}</Title>;
    };

    return (
      <UnstyledTooltip
        title={renderTitle()}
        PopperProps={{
          style: {
            margin: '1rem',
            ...props?.PopperProps?.style,
          },
        }}
        disableHoverListener={!isOverflow && showTooltipOnlyOnOverflow}
        ref={showTooltipOnlyOnOverflow ? elementRef : undefined}
        {...props}
      >
        {children}
      </UnstyledTooltip>
    );
  },
);
