import styled from 'styled-components';

export const ListItemIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 4rem;
  min-width: 4rem;
  height: 4rem;

  background: ${({ theme }) => theme.palette.background.elevated.primary};

  box-shadow: ${({ theme }) => theme.palette.additionalColors.tileShadow};
  border-radius: 1.6rem;

  > svg {
    width: 2.4rem;
    height: 2.4rem;

    * {
      stroke: ${({ theme }) => theme.palette.button.primary};
    }
  }
`;
