import { memo, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ScientistIllustration } from 'assets/illustrations';
import { queryKeys } from 'api/queryKeys';
import Api from 'api/api';
import { EhrState } from 'api/models/HealthcareProviders';
import { useEhrDialogState } from 'api/storage/ehrDialogState/hook';
import { ShareHealthRecordsCard } from 'components/ShareHealthRecordsCard';
import { ReconnectHealthProviderCard } from 'components/ReconnectHealthProviderCard';
import { hasFeature } from 'helpers/featureToggles';
import { FeatureToggles } from 'types';
import { IllustrationWrapper } from './styled';

const LARGE_DIALOG_BREAKPOINT = 328;
const SMALL_DIALOG_BREAKPOINT = 258;

export const ResponsiveIllustration = memo(() => {
  const wrapper = useRef<HTMLDivElement>(null);
  const shareHealthRecords = useRef<HTMLDivElement>(null);
  const reconnectHealthProvider = useRef<HTMLDivElement>(null);
  const illustration = useRef<SVGSVGElement>(null);
  const [showEhrDialog, closeEhrDialog] = useEhrDialogState();
  const [showIllustration, setShowIllustration] = useState(true);
  const [dialogsSize, setDialogsSize] = useState<'large' | 'small' | 'hidden'>(
    'large',
  );

  const { data } = useQuery({
    queryKey: queryKeys.healthcareProviders(),
    queryFn: Api.getHealthcareProviders,
    enabled: hasFeature(FeatureToggles.HealthcareProviders),
  });

  const hasNoConnectedProviders =
    data?.healthcareProviders?.some(({ status }) => status !== 'none') ===
    false;

  const disconnectedProvider = hasNoConnectedProviders
    ? undefined
    : data?.healthcareProviders?.find(
        ({ status }) => status === EhrState.unpaired,
      );

  const hasAnyDialog =
    (hasNoConnectedProviders || disconnectedProvider) &&
    showEhrDialog &&
    dialogsSize !== 'hidden';

  useEffect(() => {
    if (!wrapper.current) return;

    // eslint-disable-next-line compat/compat
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        if (!illustration.current) return;

        if (entry.contentRect.height > LARGE_DIALOG_BREAKPOINT)
          setDialogsSize('large');
        else if (entry.contentRect.height > SMALL_DIALOG_BREAKPOINT)
          setDialogsSize('small');
        else setDialogsSize('hidden');

        if (entry.contentRect.height / illustration.current.scrollHeight < 0.84)
          setShowIllustration(false);
        else setShowIllustration(true);
      });
    });

    resizeObserver.observe(wrapper.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [
    wrapper.current,
    illustration.current,
    shareHealthRecords.current,
    reconnectHealthProvider.current,
  ]);

  return (
    <IllustrationWrapper ref={wrapper}>
      <ScientistIllustration
        ref={illustration}
        style={{ opacity: showIllustration && !hasAnyDialog ? 1 : 0 }}
      />
      <ShareHealthRecordsCard
        ref={shareHealthRecords}
        show={
          hasNoConnectedProviders && showEhrDialog && dialogsSize !== 'hidden'
        }
        onClose={closeEhrDialog}
        displaySmallerCard={dialogsSize !== 'large'}
      />
      <ReconnectHealthProviderCard
        ref={reconnectHealthProvider}
        show={
          disconnectedProvider !== undefined &&
          showEhrDialog &&
          dialogsSize !== 'hidden'
        }
        healthProvider={disconnectedProvider}
        onClose={closeEhrDialog}
        displaySmallerCard={dialogsSize !== 'large'}
      />
    </IllustrationWrapper>
  );
});
