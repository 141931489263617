import { useMediaQuery } from '@mui/material';
import { useTheme } from 'styled-components';
import { ActivityCard } from 'components/ActivityCard';
import { SurveysContainer } from 'pages/Registry/styled';
import { GetRegistryResponse } from 'api/interfaces/response';
import { useNumberOfTopSurveys } from 'api/storage/numberOfTopPrioritySurveys/hook';
import { useUserRole } from 'api/storage/userRoles/hook';
import { useConfig } from 'hooks/useConfig';
import { RegistryIllustration, RegistryIllustrationWrapper } from './styled';
import { NoSurveysAssigned } from '../common/NoSurveysAssigned';
import { NoSurveysLeft } from '../common/NoSurveysLeft';
import { NoTopSurveysComponent } from './components/NoTopSurveysComponent';
import scientistIllustrationLight from '../../../IntroPage/components/IntroSection/assets/scientist.svg';
import scientistIllustrationDark from '../../../IntroPage/components/IntroSection/assets/scientist_dark.svg';

interface Props {
  data: GetRegistryResponse;
}

export const TopPrioritySurveys = ({ data }: Props) => {
  const { roleId } = useUserRole(data.id);

  const config = useConfig();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mdl'));

  const filteredSurveys = data?.surveys?.filter(
    survey => survey.roleId === roleId || !survey.roleId,
  );

  const { remainingTopSurveys, resetTopSurveys } = useNumberOfTopSurveys(
    data.id,
    filteredSurveys.length,
  );

  const topSurveys = filteredSurveys.slice(0, remainingTopSurveys);

  const getIllustration = () => {
    if (!data?.additionalParams?.introPage) return scientistIllustrationLight;

    const { registryIllustration, registryIllustrationDark } =
      data.additionalParams.introPage;

    if (theme.palette.type === 'dark')
      return registryIllustrationDark
        ? `${config.s3PublicBucketUrl}${registryIllustrationDark}`
        : scientistIllustrationDark;
    return registryIllustration
      ? `${config.s3PublicBucketUrl}${registryIllustration}`
      : scientistIllustrationLight;
  };

  if (data.cohorts.length === 0) return <NoSurveysAssigned />;
  if (filteredSurveys.length === 0) return <NoSurveysLeft />;
  if (remainingTopSurveys === 0)
    return <NoTopSurveysComponent loadNextBatch={resetTopSurveys} />;
  return (
    <>
      <SurveysContainer>
        {topSurveys?.map(survey => (
          <ActivityCard
            registryId={data.id}
            roles={data.roles}
            survey={survey}
            key={survey.id}
          />
        ))}
      </SurveysContainer>
      {isMobile && (
        <RegistryIllustrationWrapper>
          <RegistryIllustration src={getIllustration()} alt="" />
        </RegistryIllustrationWrapper>
      )}
    </>
  );
};
