import { memo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Profile, Profile2User, SecurityUser } from 'iconsax-react';
import { GetRegistryResponse } from 'api/interfaces/response';
import { messages } from './messages';
import {
  StyledRoleButton,
  RoleButtonContentWrapper,
  RoleType,
  RoleMessage,
} from './styled';

type Roles = GetRegistryResponse['roles'];

interface Props {
  onSelect(roleId: string): void;
  roles: Roles;
  selectedId?: string;
  showTitle?: boolean;
  isEnrollSelection?: boolean;
}

export const RoleSelection = memo(
  ({
    onSelect,
    roles,
    selectedId,
    showTitle,
    isEnrollSelection = false,
  }: Props) => {
    const { t } = useTranslation();

    const getLabelContent = (code: string, perspective: 'first' | 'third') => {
      if (['doctor', 'nurse'].includes(code))
        return {
          icon: <SecurityUser size="2.8rem" />,
          message: t(messages.enrollingParticipant()),
        };

      if (perspective === 'first')
        return {
          icon: <Profile size="2.8rem" />,
          message: t(messages.joiningByMyself()),
        };

      return {
        icon: <Profile2User size="2.8rem" />,
        message: t(messages.assistingParticipant()),
      };
    };

    const getLabel = (
      name: string,
      code: string,
      perspective: 'first' | 'third',
    ) => {
      const { icon, message } = getLabelContent(code, perspective);

      return (
        <RoleButtonContentWrapper>
          {icon}
          <div style={{ textAlign: 'start' }}>
            <RoleType>{name}</RoleType>
            {isEnrollSelection && <RoleMessage>{message}</RoleMessage>}
          </div>
        </RoleButtonContentWrapper>
      );
    };

    return (
      <Stack
        maxWidth="63.6rem"
        width="100%"
        alignItems="center"
        textAlign="center"
        gap="4rem"
      >
        {showTitle && (
          <Typography variant="h1" textAlign="center">
            {t(messages.whoWillBeFillingOut())}
          </Typography>
        )}
        <Stack width="100%" gap="1.6rem">
          {roles.map(({ id, code, name, perspective }) => (
            <StyledRoleButton
              key={id}
              onClick={() => onSelect(id)}
              $selected={id === selectedId}
              data-cy={`role-${code}${id === selectedId ? '-selected' : ''}`}
              aria-checked={id === selectedId ? 'true' : 'false'}
            >
              {getLabel(name, code, perspective)}
            </StyledRoleButton>
          ))}
        </Stack>
      </Stack>
    );
  },
);
