import { Stack } from '@mui/material';
import { WVULogo, WVULogoDark } from 'assets/logo';
import { useProperlyThemedComponent } from 'hooks/useProperlyThemedComponent';
import { TermsAndConditions } from 'components/TermsAndConditions';
import { PrivacyPolicy } from 'components/PrivacyPolicy';

export const Footer = () => {
  const ThemedWVULogo = useProperlyThemedComponent({
    light: <WVULogo aria-hidden="true" />,
    dark: <WVULogoDark aria-hidden="true" />,
  });

  return (
    <Stack
      padding={{
        lg: '3.2rem 7.2rem',
        md: '3.2rem 4.8rem',
        xs: '3.2rem 2.4rem 2rem',
      }}
      direction={{ sm: 'row', xs: 'column' }}
      alignItems={{ sm: 'center', xs: 'flex-start' }}
      justifyContent="space-between"
      gap="2.4rem"
    >
      {ThemedWVULogo}
      <Stack
        direction="row"
        alignItems="center"
        gap="3.2rem"
        whiteSpace="nowrap"
      >
        <TermsAndConditions />
        <PrivacyPolicy />
      </Stack>
    </Stack>
  );
};
