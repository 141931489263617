import { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { capitalize } from 'lodash-es';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import Api from 'api/api';
import { queryKeys } from 'api/queryKeys';
import { FormikTextField } from 'components/FormikTextField';
import { ErrorMessage } from 'components/ErrorMessage';
import { useValidationSchema } from 'hooks/useValidationSchema';
import { errorToast, successToast } from 'helpers/toast';
import { AuthError } from 'types/amplify';
import { messages } from '../../messages';
import { getValidationSchema } from './validators';
import { JoinResearchButton } from './styled';

interface FormTypes {
  username: string;
}

interface Props {
  onUsernameSet(username: string): void;
  onNotConfirmed(username: string): void;
}

export const UsernameStep = memo(({ onUsernameSet, onNotConfirmed }: Props) => {
  const { t } = useTranslation();
  const validationSchema = useValidationSchema(getValidationSchema);
  const formikRef = useRef<FormikProps<FormTypes>>(null);

  const [searchParams] = useSearchParams();
  const registryId = searchParams.get('rid');
  const participationId = searchParams.get('pid');
  const emailHash = searchParams.get('eha');
  const accountActivated = searchParams.get('account-activated') !== null;
  const accountActivatedStatus = searchParams.get('status') !== 'false';

  const { data, mutate, isLoading, reset } = useMutation<
    boolean,
    AuthError,
    FormTypes
  >({ mutationFn: ({ username }) => Api.checkIfUserExists(username) });

  useEffect(() => {
    if (accountActivated) {
      if (accountActivatedStatus)
        successToast(t(messages.yourAccountHasBeenActivated()));
      else errorToast(t(messages.accountActivationError()));
    }
  }, [accountActivated]);

  const { data: getEmailData } = useQuery({
    queryKey: queryKeys.userEmail({
      registryId: registryId!,
      participationId: participationId!,
      emailHash: emailHash!,
    }),
    queryFn: () => {
      if (registryId && participationId && emailHash)
        return Api.getRegistrationEmail({
          registryId,
          participationId,
          emailHash,
        });
    },
    enabled: Boolean(registryId && participationId && emailHash),
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (
      getEmailData &&
      formikRef.current &&
      formikRef.current.values.username.length === 0
    )
      formikRef.current.setFieldValue('username', getEmailData.email);
  }, [getEmailData]);

  const submitForm = (form: FormTypes) => {
    mutate(form, {
      onSuccess: (userExists: boolean) => {
        if (userExists) onUsernameSet(form.username);
      },
      onError: authError => {
        switch (authError?.code) {
          case 'UserNotConfirmedException':
            onNotConfirmed(form.username);
            break;
          case 'LimitExceededException':
            errorToast(t(messages.tooManyAttempts()));
            break;
          default:
            errorToast(t(messages.errorOccurredTryAgain()));
        }
      },
    });
  };

  return (
    <>
      <Formik
        initialValues={{ username: '' } as FormTypes}
        onSubmit={submitForm}
        validationSchema={validationSchema}
        validateOnChange={false}
        innerRef={formikRef}
      >
        {({ errors, touched }) => (
          <Form data-cy="username-step">
            <Stack spacing="2.4rem" direction="column" justifyContent="center">
              <Typography variant="h1">{t(messages.login())}</Typography>
              <FormikTextField
                name="username"
                type="email"
                placeholder={capitalize(t(messages.email()))}
                onFocus={() => {
                  if (data === false) reset();
                }}
                validationSchema={validationSchema}
                oneWayValidationOnChange={true}
                data-cy="username-input"
                label={t(messages.email())}
              />
              <ErrorMessage
                message={t(messages.noAccount(), {
                  type: t(messages.email()),
                })}
                visible={
                  data === false && !(errors.username && touched.username)
                }
                style={{ marginTop: '0.8rem' }}
              />
              <LoadingButton
                type="submit"
                loading={isLoading}
                color="primary"
                variant="contained"
                data-cy="username-step-submit-button"
              >
                {t(messages.next())}
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
      <JoinResearchButton to="/registry">
        <div>{t(messages.notAMemberYet())}</div>
        <div>{t(messages.joinTheRegistry())}</div>
      </JoinResearchButton>
    </>
  );
});
