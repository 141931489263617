import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  disconnected: () =>
    _t(
      translations.components.ReconnectHealthProviderCard.disconnected,
      'Disconnected',
    ),
  description: () =>
    _t(
      translations.components.ReconnectHealthProviderCard.description,
      'Resync your healthcare provider account with your RNI account.',
    ),
  reconnectAccount: () =>
    _t(
      translations.components.ReconnectHealthProviderCard.reconnectAccount,
      'Reconnect account',
    ),
};
