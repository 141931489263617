/**
 *
 * CloseToastButton
 *
 */
import { CloseButton, Wrapper } from './styled';

interface Props {
  timeout?: number;
  onClick(): void;
  'data-cy'?: string;
}

export const CloseToastButton = ({ onClick, timeout, ...props }: Props) => (
  <Wrapper
    $timeout={timeout}
    onClick={onClick}
    data-cy={props['data-cy'] ? props['data-cy'] : 'close-toast-button'}
  >
    <svg className="progress-ring">
      <circle
        className="progress-ring__circle"
        stroke="black"
        strokeWidth="2"
        fill="transparent"
        r="13"
        cx="1.5rem"
        cy="1.5rem"
      />
    </svg>
    <CloseButton />
  </Wrapper>
);
