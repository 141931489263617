import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { LoadingOverlay } from 'components/LoadingOverlay';
import Api from 'api/api';
import { queryKeys } from 'api/queryKeys';
import { errorToast } from 'helpers/toast';
import { messages } from '../../messages';
import { Registry } from './Registry';
import { HealthyRegistry } from './HealthyRegistry';
import { AbstractBackground } from './styled';

export const RegistriesSection = () => {
  const { t } = useTranslation();

  const { data, status } = useQuery({
    queryKey: queryKeys.publicRegistries(),
    queryFn: Api.getPublicRegistries,
    staleTime: 30 * 60 * 1000,
    onError: (e: AxiosError<{ message?: string }>) => {
      const message = e?.response?.data?.message ?? e.message;
      errorToast(message);
    },
  });

  const { publicRegistry, withoutPublicRegistry } = useMemo(() => {
    if (!data) return {};

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const publicRegistry = data.registries.find(
      ({ isForHealthyPopulation }) => isForHealthyPopulation,
    );
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const withoutPublicRegistry = data.registries.filter(
      r => r !== publicRegistry,
    );

    return { publicRegistry, withoutPublicRegistry };
  }, [data]);

  return (
    <>
      <LoadingOverlay appear={false} in={status !== 'success'} zIndex={1000} />
      <section id="registries">
        <AbstractBackground
          as={Stack}
          padding={{
            xs: '1.6rem 2.4rem 3.2rem',
            md: '5.6rem 4.8rem',
            lg: '10.4rem 4.8rem 7.2rem',
          }}
          sx={{
            backgroundColor: 'background.primaryMobile',
          }}
          gap="9.6rem"
        >
          <Stack
            direction="column"
            gap="2.4rem"
            alignItems={{
              xs: 'start',
              lg: 'center',
            }}
          >
            <Typography variant="xxl" component="h1">
              {t(messages.togetherWeCanMakeDifference())}
            </Typography>
            <Typography color="additionalColors.bodyText" maxWidth="85.6rem">
              {t(messages.registriesAreCrucial())}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            gap="3.2rem"
            alignItems={{
              xs: 'start',
              lg: 'center',
            }}
          >
            <Typography variant="h1" component="h2">
              {t(messages.listOfConditionBasedRegistries())}
            </Typography>
            <Stack
              width="100%"
              maxWidth={{
                xs: '90rem',
                xl: '100%',
              }}
              display={{
                md: 'grid',
                xl: 'flex',
              }}
              gridTemplateColumns="1fr 1fr"
              direction={{
                xs: 'column',
                xl: 'row',
              }}
              gap="2.4rem"
              justifyContent="center"
              justifyItems="center"
              alignItems={{
                xs: 'center',
                xl: 'stretch',
              }}
            >
              {withoutPublicRegistry?.map(registry => (
                <Registry registry={registry} key={registry.code} />
              ))}
            </Stack>
          </Stack>
        </AbstractBackground>
        {publicRegistry && (
          <Stack
            direction={{
              xs: 'column',
              lg: 'row',
            }}
            padding={{
              xs: '5.6rem 2.4rem 3.2rem',
              md: '7.2rem 5.6rem 4.8rem',
              lg: '7.2rem',
            }}
            sx={{
              backgroundColor: 'additionalColors.background',
            }}
            justifyContent="center"
          >
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              gap={{
                lg: '13.4rem',
                xs: '4rem',
              }}
              alignItems="center"
              maxWidth="129.6rem"
            >
              <Stack gap="1.6rem">
                <Typography variant="h1" color="additionalColors.primaryText">
                  {t(messages.joinOutControlGroup())}
                </Typography>
                <Stack gap="0.8rem">
                  <Typography
                    variant="body1"
                    color="additionalColors.primaryText"
                  >
                    {t(messages.healthyVolunteersAreJustAsImportant())}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="additionalColors.primaryText"
                  >
                    {t(messages.yourParticipationInControlGroupIsEssential())}
                  </Typography>
                </Stack>
              </Stack>
              <HealthyRegistry registry={publicRegistry} />
            </Stack>
          </Stack>
        )}
      </section>
    </>
  );
};
