import Session from 'api/storage/session';
import mixpanel from 'mixpanel-browser';
import { Auth } from '@aws-amplify/auth';
import { sendLogoutEvent } from 'hooks/useLogoutTimer';
import { GlobalKeys, setGlobalVariable } from 'helpers/globalVariables';
import LastUrlStorage from 'api/storage/lastUrl';
import { STABLE_BETWEEN_LOGIN_SESSION_KEYS } from 'config/constants';

export const logOut = async (options?: { saveLocation?: boolean }) => {
  const { saveLocation = true } = options || {};

  try {
    await Auth.signOut();
    // eslint-disable-next-line no-empty
  } catch (e) {}
  sendLogoutEvent();
  mixpanel.reset();

  Object.keys(window.sessionStorage).forEach(x => {
    if (STABLE_BETWEEN_LOGIN_SESSION_KEYS.includes(x)) return;
    window.sessionStorage.removeItem(x);
  });

  if (
    saveLocation &&
    !['/login', '/loading'].includes(window.location.pathname)
  )
    LastUrlStorage.save();

  Session.clearSession();
  window.localStorage.clear();

  setGlobalVariable(GlobalKeys.FORCE_REDIRECT, true);

  window.location.assign('/login');
};
