import { TinySliderSettings } from 'tiny-slider/src/tiny-slider';
import { breakpoints } from 'styles/theme/themes/breakpoints';

export const baseConfig: TinySliderSettings = {
  controls: true,
  mouseDrag: true,
  nav: false,
  slideBy: 1,
  items: 1,
  autoplay: false,
  autoWidth: true,
  loop: false,

  gutter: 24,

  responsive: {
    [breakpoints.values!.lg]: {
      gutter: 32,
    },
  },
};
