import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as GoodJobIllustrationLight } from './goodJob.svg';
import { ReactComponent as GoodJobIllustrationDark } from './goodJob_dark.svg';

export const GoodJobIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return <GoodJobIllustrationDark aria-hidden="true" {...props} />;
    return <GoodJobIllustrationLight aria-hidden="true" {...props} />;
  },
);
