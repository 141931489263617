import { Components } from '@mui/material/styles/components';
import { Theme } from 'styles/theme/themes';
import { typography } from 'styles/theme/themes/fonts';

export const MuiTab: Components<Theme>['MuiTab'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ...typography(theme.palette, theme.breakpoints).h5,
      textTransform: 'none',
      color: theme.palette.text.primary,
      minWidth: 'unset',
      paddingRight: '1rem',
      paddingLeft: '1rem',
      letterSpacing: 'normal',

      '&.Mui-selected': {
        color: theme.palette.button.primary,
      },
      ':hover': {
        color: theme.palette.button.primary,
      },
    }),
  },
};
