import styled from 'styled-components';
import { useTypography } from 'helpers/mixins';

export const Wrapper = styled.ul`
  ${({ theme }) => useTypography(theme.typography.body2)}

  && {
    margin-top: 1.6rem;
  }
`;

export const ConstraintWrapper = styled.li<{ isValid: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ theme, isValid }) =>
    isValid ? theme.palette.text.primary : theme.palette.text.secondary};

  svg {
    *[stroke='#D9DEEB'] {
      stroke: ${({ theme, isValid }) =>
        isValid ? theme.palette.button.primary : theme.palette.text.secondary};
    }

    *[fill='#002E5F'] {
      fill: ${({ theme, isValid }) =>
        isValid ? theme.palette.button.primary : theme.palette.text.secondary};
    }
  }

  &:not(:last-child) {
    margin-bottom: 0.4rem;
  }
`;

export const ConstraintIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.3rem;
  height: 1.3rem;
`;

export const ConstraintText = styled.div`
  ${({ theme }) => useTypography(theme.typography.body2)}
  margin-left: 1rem;
`;
