export const questionsAndAnswers = [
  {
    question: 'Why should I join?',
    answer:
      'By joining, you have the opportunity to contribute to the advancement of neurological research. Your participation can help identify patterns and trends in disease progression and inform clinical decision-making, ultimately leading to improved outcomes for patients.',
  },
  {
    question: 'How do I use the platform?',
    answer:
      'To get started, set up your account and create a profile for the person for whom you are entering information. The platform includes activities with questions about the patient, their medical history and their current health. You can complete these activities over multiple sessions and update your information at any time.',
  },
  {
    question: 'Do I have to participate?',
    answer:
      'Your participation is completely voluntary. You do not have to participate.',
  },
  {
    question: 'Can I stop being in the registry?',
    answer:
      'Yes, you have the right to stop being in the registry at any time. If you wish to withdraw from the registry, please contact us: registries@amazingrni.atlassian.net. Your decision to withdraw will not affect your current or future medical care. Please note that your data collected up to the point of your withdrawal may still be used for research purposes.',
  },
  {
    question: 'Are there any payments or costs?',
    answer:
      'There are no payments or costs associated with joining the registry. Your participation is voluntary and free of charge. However, please note that some costs associated with your medical care and treatment may be incurred outside of the registry, such as routine medical visits or prescription medications. These costs are separate from the registry and will be billed according to your insurance or other payment arrangements.',
  },
  {
    question: 'Will I receive payment for joining?',
    answer: 'You will not be paid for participating in this study.',
  },
  {
    question: 'How is the registry funded?',
    answer:
      'This registry is being funded internally, which means that it is typically not supported by external grants. The funding for the registry covers a variety of costs, including personnel, infrastructure, technology, and administrative costs. This allows the registry to remain independent and maintain its autonomy in pursuing its goals.',
  },
  {
    question: 'Will my data be used for commercial purposes?',
    answer:
      'Your data, research results, or any other information related to this research or used in this research study may contribute to a discovery. In some instances, your data, your research results, the discoveries, or any other information related to this research study (even if identifiers are removed) may be of commercial value. The information may be sold, patented, or licensed by the Principal Investigator and West Virginia University for use in other research or the development of new products. You will not retain any property rights, and you will not be eligible to share in any monetary or commercial profit that the Principal Investigators, West Virginia University, or their agents may realize.',
  },
  {
    question: 'Does my doctor need to be involved or give permission?',
    answer:
      'No, your doctor does not need to be involved or give permission for you to participate in the registry. However, we encourage you to discuss your participation with your doctor as they can provide valuable guidance and support throughout your treatment.',
  },
  {
    question: 'Can insurance companies access my information?',
    answer:
      'No, insurance companies do not have access to registry data. The registry will never share your private information with anyone except those who have been approved by the Institutional Review Board (IRB). These researchers must adhere to strict ethical and legal guidelines regarding the use and dissemination of registry data. The confidentiality and privacy of participants are of utmost importance and the registry takes every precaution to ensure that personal information is protected at all times.',
  },
  {
    question:
      'Will my participation in the registry be shared with my healthcare providers?',
    answer:
      'The information collected is intended to be shared with healthcare providers in order to optimize care. This means that your participation in this registry may be shared with your healthcare providers, with the goal of improving your health outcomes. Sharing data with healthcare providers may help them make better-informed decisions about your care by providing them with a more complete picture of your medical history, symptoms, and treatments. However, it is important to note that your privacy and confidentiality will be protected at all times.',
  },
  {
    question:
      'Will my participation in the registry affect my ability to participate in other studies or clinical trials?',
    answer:
      'Participation should not affect your ability to participate in other studies or clinical trials. Patient registries are designed to collect observational data and do not involve the administration of any treatments or interventions. Therefore, your participation in this registry should not interfere with your eligibility to participate in other studies or clinical trials. However, we will ask you if you want to be contacted about future studies conducted at WVU RNI, but you can take part in this registry study even if you don’t want to be contacted about future studies. It is important to note that eligibility for specific studies or trials may depend on a variety of factors, such as your medical history or the specific requirements of the study or trial.',
  },
  {
    question: 'Where will my data be kept and will it be confidential?',
    answer:
      'Your information will be put into a computer database, along with information about other people who join this study. \nYour privacy is important, and we take it very seriously. We will keep your information as confidential as possible. However, if the law requires that we disclose your confidential information, every effort will be made to limit the use and disclosure of the information. There are instances where the researcher is legally required to provide information to the appropriate authorities. This could include the mandatory reporting of information about behavior that is imminently dangerous to you or others, such as suicide, child abuse, etc. Your name will not be used in the publication of information about the research and we will make every effort to safeguard your data, but as with anything online, we cannot guarantee the security of data transmitted via the Internet.',
  },
  {
    question: 'Who has access to registry data?',
    answer:
      '• The research site(s) carrying out this study. UHA or UHA Affiliates, WVU, WVU Hospitals, West Virginia University Health System (WVUHS), and other affiliate sites, including the research and medical staff at the site(s). \n• Health care providers who provide services to you as part of this research study.\n• Rockefellet Neuroscience Institute and the people and companies that they use to oversee, manage, conduct the research or analyze data.\n• The West Virginia University Office of Human Research Protections and the West Virginia University Office of Sponsored Programs.',
  },
  {
    question: 'Was this registry reviewed for ethical approval?',
    answer:
      'Yes, this registry has undergone an extensive review process to ensure that it meets ethical standards and protects the rights and privacy of its participants. The registry has received approval from an independent ethics committee, and all research conducted using data from the registry will follow established ethical guidelines. If you have any further questions about the ethical review process or the safeguards in place to protect your privacy, please do not hesitate to contact us: registries@amazingrni.atlassian.net',
  },
  {
    question: 'Where can I find more information if I still have questions?',
    answer:
      'A member of our research team can answer any additional questions you may have or if you need assistance with setup or any other aspect of your account. Research team members are available Monday – Friday, 8 a.m. – 5 p.m EST. They can be reached at (304) 293-5886 or through email: registries@amazingrni.atlassian.net',
  },
  {
    question:
      "I'm having technical issues with the registry. Who can I contact?",
    answer:
      'If you are experiencing technical issues with the registry, you can contact the registry support team for assistance.They can be reached at (304) 293-5886 or through registries@amazingrni.atlassian.net. Be sure to include as much detail as possible about the issue you are experiencing, including any error messages you may have received. The support team will work with you to resolve the issue as quickly as possible. ',
  },
  {
    question: 'How will my information be used?',
    answer:
      'The information collected will be used to better understand and improve the care of patients with a particular condition. This includes studying the natural history of the disease, identifying risk factors and potential treatments, and developing new therapies. Your information may be used to create anonymous statistical reports or to conduct research studies. Your participation in the registry will help researchers and healthcare professionals gain insights into your condition, and ultimately, improve the quality of care for future patients. It is important to note that your personal information will be kept confidential and your identity will be protected at all times.',
  },
  {
    question: 'How long will my information be kept in the registry?',
    answer: 'Any data collected will be kept indefinitely',
  },
  {
    question: 'What happens if there is a breach of security?',
    answer:
      'We take the security and privacy of your information very seriously and have measures in place to prevent any breaches of security. However, in the unlikely event of a breach, the registry will follow strict protocols to ensure that your information is protected and any potential harm is minimized. The registry will notify you as soon as possible if a breach occurs and will provide you with information on the steps you should take to protect yourself. The registry team will work to rectify the situation and prevent any future breaches from occurring. It is important to note that the registry has policies and procedures in place to prevent unauthorized access to your information, and regularly undergoes audits and evaluations to ensure its security measures are up-to-date and effective.',
  },
  {
    question: 'Can I update or correct my information in the registry?',
    answer:
      'It is important to ensure that your information is accurate and up-to-date. The registry will ask for periodic updates on your health status. If you feel that your health has changed since the last time you completed assessments in the registry, you can always click on “Change assignment” after you log into your account and fill out the qualification survey again. We will find the most suitable assignment for you.',
  },
  {
    question: 'How long does the study last?',
    answer:
      'Your participation in the registry will continue as long you do not withdraw your consent.',
  },
];
