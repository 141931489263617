import { memo, useEffect, useState, useRef, useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams, useNavigate, useLocation } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import { errorToast } from 'helpers/toast';
import { useBlockExit } from 'hooks/useBlockExit';
import {
  GetRegistrySurveyResponse,
  GetRegistriesResponse,
  GetRegistryResponse,
} from 'api/interfaces/response';
import { AddRepositorySurveyResponseRequest } from 'api/interfaces/request';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { SurveyModule } from 'components/SurveyModule';
import { SurveyResult } from 'components/SurveyModule/types';
import { EventNames, SurveyModuleEventActions } from 'types/analytics';
import { RegistryLocationState } from 'pages/Registry/types';
import { useSetQueryData } from 'helpers/queries';
import Api from 'api/api';
import { queryKeys } from 'api/queryKeys';
import Session from 'api/storage/session';
import { useNumberOfTopSurveys } from 'api/storage/numberOfTopPrioritySurveys/hook';
import { INITIAL_TOP_SURVEYS_LENGTH } from 'config/constants';
import UserRoles from 'api/storage/userRoles';
import { RoleManagement } from './RoleManagement';
import { SurveyInstruction } from './SurveyInstruction';
import { UpperBar } from './UpperBar';
import { Wrapper } from './styled';
import { SurveyLocationState } from './types';
import { messages } from './messages';

interface Props {
  qualificationSurvey?: boolean;
}

export const SurveyPage = memo(({ qualificationSurvey = false }: Props) => {
  useBlockExit();
  const { t } = useTranslation();
  const location = useLocation();
  const { firstCompletion, returnUrl }: SurveyLocationState =
    location.state ?? {};
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setQueryData = useSetQueryData();
  const { registryId, surveyId } = useParams<{
    registryId: string;
    surveyId: string;
  }>();
  const [isSurveyModuleLoaded, setIsSurveyModuleLoaded] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [firstLoader, setFirstLoader] = useState(true); // show loader until first calculations are done
  const { current: userEmail } = useRef(Session.decodeToken()?.email);

  const { data, isLoading } = useQuery<
    GetRegistrySurveyResponse,
    { message: string }
  >({
    queryKey: queryKeys.survey({
      registryId,
      surveyId,
      qualificationSurvey,
    }),
    queryFn: () => {
      if (qualificationSurvey)
        return Api.getRegistryQualificationSurvey({
          registryId: registryId!,
          surveyId: surveyId!,
        });

      return Api.getRegistrySurvey({
        registryId: registryId!,
        surveyId: surveyId!,
      });
    },
    onError: error => {
      errorToast(error.message);
      navigate(returnUrl ?? `/registries/${registryId}`);
    },
    staleTime: 10 * 60 * 1000,
  });

  const registryQuery = useQuery({
    queryKey: queryKeys.registry({
      registryId: registryId!,
      userEmail: userEmail!,
    }),
    queryFn: () => Api.getRegistry(registryId!),
    staleTime: Infinity,
  });

  const { remainingTopSurveys, incrementTopSurveys } = useNumberOfTopSurveys(
    registryId!,
    registryQuery.data?.surveys.length ?? INITIAL_TOP_SURVEYS_LENGTH,
  );

  const invalidateRegistryQueries = () => {
    queryClient.invalidateQueries({
      queryKey: queryKeys.allRegistries(),
    });
  };

  const updateCacheAfterQualificationSurvey = () => {
    if (!qualificationSurvey) return;

    setQueryData(
      ['registry', { registryId: registryId!, userEmail: userEmail! }],
      registry => {
        return {
          ...registry,
          qualification: {
            ...registry?.qualification,
            surveySubmitted: true,
          },
        } as GetRegistryResponse;
      },
    );

    setQueryData(['registries', { userEmail: userEmail! }], registries => {
      if (!registries?.registries) return registries;

      const copy = [...registries.registries];
      const registryIndex = copy.findIndex(({ id }) => id === registryId);
      if (registryIndex === -1) return registries;

      const registry = copy[registryIndex];

      copy[registryIndex] = {
        ...registry,
        showWarning: false,
      };

      return { registries: copy };
    });
  };

  const updateCacheAfterSurvey = () => {
    setQueryData(
      ['registry', { registryId: registryId!, userEmail: userEmail! }],
      registry => {
        const filteredSurveys = registry?.surveys.filter(
          ({ id }) => id !== surveyId,
        );
        return {
          ...registry,
          surveys: filteredSurveys,
        } as GetRegistryResponse;
      },
    );

    setQueryData(
      ['registries', { userEmail: userEmail! }],
      (registries: GetRegistriesResponse | undefined) => {
        if (!registries?.registries) return registries;

        const copy = [...registries.registries];
        const registryIndex = copy.findIndex(({ id }) => id === registryId);
        if (registryIndex === -1) return registries;

        const registry = copy[registryIndex];

        copy[registryIndex] = {
          ...registry,
          surveys: registry.surveys.filter(({ id }) => id !== surveyId),
        };

        return { registries: copy };
      },
    );
  };

  const qualificationSurveyMutation = useMutation({
    mutationFn: Api.submitQualificationSurvey,
    onSuccess: ({ cohorts }) => {
      updateCacheAfterQualificationSurvey();
      invalidateRegistryQueries();
      navigate(returnUrl ?? `/registries/${registryId}`, {
        state: {
          cohorts: {
            newCohorts: cohorts,
            previousCohorts: registryQuery.data?.cohorts,
            firstCompletion: firstCompletion ?? false,
          },
        } as RegistryLocationState,
      });
    },
    onError: () => {
      errorToast('Unknown error occurred, try again later');
    },
  });

  const changeTopPriorityLength = () => {
    if (!registryQuery.data) return;

    const userRole = UserRoles.getRoleForRegistry(registryQuery.data.id);

    const filteredSurveys = registryQuery.data?.surveys?.filter(
      survey => survey.roleId === userRole || !survey.roleId,
    );

    const actualSurveyIndex: number =
      filteredSurveys.findIndex(survey => survey.id === surveyId) ?? -1;
    if (
      actualSurveyIndex >= remainingTopSurveys ||
      filteredSurveys.length <= INITIAL_TOP_SURVEYS_LENGTH
    )
      return;
    incrementTopSurveys();
  };

  const surveyMutation = useMutation({
    mutationFn: Api.submitSurvey,
    onSuccess: () => {
      updateCacheAfterSurvey();
      changeTopPriorityLength();
      invalidateRegistryQueries();
      navigate(returnUrl ?? `/registries/${registryId}`, {
        state: {
          surveyCompleted: true,
        } as RegistryLocationState,
      });
    },
    onError: () => {
      errorToast('Unknown error occurred, try again later');
    },
  });

  const mutate = useCallback<
    (value: AddRepositorySurveyResponseRequest) => void
  >(
    qualificationSurvey
      ? qualificationSurveyMutation.mutate
      : surveyMutation.mutate,
    [
      qualificationSurvey,
      qualificationSurveyMutation.mutate,
      surveyMutation.mutate,
    ],
  );

  const isRoleRequired = (registryQuery.data?.roles.length ?? 2) > 1;
  const selectRoleStep = location.pathname.indexOf('select-role') !== -1;

  useEffect(() => {
    if (isRoleRequired && !selectedRole && !selectRoleStep)
      navigate(
        `/registries/${registryId}/${
          qualificationSurvey ? 'qualification-survey' : 'survey'
        }/${surveyId}/select-role`,
        { state: location.state },
      );
    else if (!isRoleRequired || (selectedRole && selectRoleStep))
      navigate(
        `/registries/${registryId}/${
          qualificationSurvey ? 'qualification-survey' : 'survey'
        }/${surveyId}`,
        { state: location.state },
      );

    setFirstLoader(false);
  }, [location.pathname, isRoleRequired, selectedRole]);

  useEffect(() => {
    if (!registryId || !surveyId) navigate(-1);
  }, [registryId, surveyId]);

  const postResults = useCallback(
    ({ studyId, assignmentId, ...results }: SurveyResult) => {
      mutate({
        ...results,
        surveyId: surveyId!,
        registryId: registryId!,
        roleId: selectedRole!,
      });
    },
    [mutate, selectedRole],
  );

  const onClose = useCallback(() => {
    mixpanel.track(EventNames.SURVEY_MODULE, {
      action: SurveyModuleEventActions.SURVEY_CLOSED,
      survey_id: surveyId,
      registry_id: registryId,
      qualification_survey: qualificationSurvey ?? false,
      selected_role_id: selectedRole ?? 'none',
      selected_role_name: registryQuery.data?.roles.find(
        ({ id }) => id === selectedRole,
      )?.name,
      flutter_module_loaded: isSurveyModuleLoaded,
      survey_loaded: isLoading,
      registry_loaded: registryQuery.isLoading,
      number_of_questions: data?.questions.length,
      is_submitting:
        qualificationSurveyMutation.isLoading || surveyMutation.isLoading,
    });
    navigate(returnUrl ?? `/registries/${registryId}`);
  }, [navigate]);

  const roleOverlayVisible = Boolean(
    registryQuery.data && selectRoleStep && !selectedRole,
  );

  return (
    <Wrapper>
      <Helmet>
        <title>
          {[
            data?.name,
            t(messages.survey()),
            roleOverlayVisible ? t(messages.roleSelection()) : undefined,
          ]
            .filter(Boolean)
            .join(' - ')}
        </title>
      </Helmet>
      <SurveyInstruction
        survey={data}
        registryLoaded={
          !selectRoleStep && !firstLoader && registryQuery.data !== undefined
        }
      />
      <RoleManagement
        onConfirm={setSelectedRole}
        roles={registryQuery.data?.roles ?? []}
        in={roleOverlayVisible}
        qualificationSurvey={qualificationSurvey}
        onClose={onClose}
      />
      <LoadingOverlay
        in={
          isLoading ||
          qualificationSurveyMutation.isLoading ||
          surveyMutation.isLoading ||
          !isSurveyModuleLoaded ||
          registryQuery.isLoading ||
          firstLoader
        }
        loaderProps={{
          sx: {
            backgroundColor: 'background.elevated.primary',
          },
        }}
      />
      <UpperBar
        role={registryQuery.data?.roles.find(({ id }) => id === selectedRole)}
        qualificationSurvey={qualificationSurvey}
        onClose={onClose}
      />
      <SurveyModule
        onSuccess={postResults}
        survey={data}
        onInitialized={() => setIsSurveyModuleLoaded(true)}
        registryId={registryId}
        aria-hidden={
          selectRoleStep ||
          isLoading ||
          qualificationSurveyMutation.isLoading ||
          surveyMutation.isLoading ||
          !isSurveyModuleLoaded ||
          registryQuery.isLoading
        }
      />
    </Wrapper>
  );
});
