import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as SuccessIllustrationLight } from './success.svg';
import { ReactComponent as SuccessIllustrationDark } from './success_dark.svg';

export const SuccessIllustration = memo(
  (props: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();

    if (theme.palette.type === 'dark')
      return <SuccessIllustrationDark aria-hidden="true" {...props} />;
    return <SuccessIllustrationLight aria-hidden="true" {...props} />;
  },
);
