import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckmarkIcon, CloseIcon } from 'assets/icons';
import { messages } from './messages';
import {
  Wrapper,
  ConstraintWrapper,
  ConstraintText,
  ConstraintIcon,
} from './styles';
import { lowercaseRegex, uppercaseRegex, numberRegex } from './validators';

interface Props extends React.HTMLAttributes<HTMLUListElement> {
  password: string;
}

export const PasswordValidator = memo(({ password, ...props }: Props) => {
  const { t } = useTranslation();

  const lengthConstraint = password.length >= 8;
  const lowercaseConstraint = lowercaseRegex.test(password);
  const uppercaseConstraint = uppercaseRegex.test(password);
  const numberConstraint = numberRegex.test(password);

  const renderIcon = (isValid: boolean) =>
    isValid ? (
      <CheckmarkIcon aria-hidden="true" />
    ) : (
      <CloseIcon aria-hidden="true" />
    );

  return (
    <>
      <Wrapper {...props} aria-label={t(messages.ariaLabel())}>
        <ConstraintWrapper
          isValid={lengthConstraint}
          data-cy={`length-constraint-${
            lengthConstraint ? 'valid' : 'invalid'
          }`}
        >
          <ConstraintIcon>{renderIcon(lengthConstraint)}</ConstraintIcon>
          <ConstraintText>{t(messages.charactersConstraint())}</ConstraintText>
        </ConstraintWrapper>
        <ConstraintWrapper
          isValid={lowercaseConstraint}
          data-cy={`lowercase-constraint-${
            lowercaseConstraint ? 'valid' : 'invalid'
          }`}
        >
          <ConstraintIcon>{renderIcon(lowercaseConstraint)}</ConstraintIcon>
          <ConstraintText>{t(messages.lowercaseConstraint())}</ConstraintText>
        </ConstraintWrapper>
        <ConstraintWrapper
          isValid={uppercaseConstraint}
          data-cy={`uppercase-constraint-${
            uppercaseConstraint ? 'valid' : 'invalid'
          }`}
        >
          <ConstraintIcon>{renderIcon(uppercaseConstraint)}</ConstraintIcon>
          <ConstraintText>{t(messages.uppercaseConstraint())}</ConstraintText>
        </ConstraintWrapper>
        <ConstraintWrapper
          isValid={numberConstraint}
          data-cy={`number-constraint-${
            numberConstraint ? 'valid' : 'invalid'
          }`}
        >
          <ConstraintIcon>{renderIcon(numberConstraint)}</ConstraintIcon>
          <ConstraintText>{t(messages.numberConstraint())}</ConstraintText>
        </ConstraintWrapper>
      </Wrapper>
    </>
  );
});

export { getPasswordValidationSchema } from './validators';
