import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Formik } from 'formik';
import { FormikTextField } from 'components/FormikTextField';
import { useValidationSchema } from 'hooks/useValidationSchema';
import { getValidationSchema } from '../../../AccountDetailsStep/validators';
import { AccountDetailsForm } from '../../../../types';
import { messages } from '../../../../messages';
import { DataRowWrapper } from '../../styled';
import { SettingSection } from '../../SettingsSection';

interface Props {
  active: boolean;
  disabled: boolean;
  onCancel: () => void;
  onSubmit: (newValues: AccountDetailsForm) => void;
  onEdit: () => void;
  accountDetails: AccountDetailsForm;
}

export const AccountSection = ({
  active,
  disabled,
  onCancel,
  onSubmit,
  onEdit,
  accountDetails,
}: Props) => {
  const { t } = useTranslation();
  const validationSchema = useValidationSchema(getValidationSchema);

  const details = (
    <Stack direction="column" spacing="0.6rem">
      <DataRowWrapper>
        <div>{t(messages.email())}</div>
        <div>{accountDetails.email}</div>
      </DataRowWrapper>
    </Stack>
  );

  const form = (
    <Stack direction="column" spacing="1.6rem">
      <FormikTextField
        name="email"
        type="email"
        placeholder={t(messages.email())}
        validationSchema={validationSchema}
        oneWayValidationOnChange={true}
        data-cy="email-input"
        label={t(messages.email())}
      />
    </Stack>
  );

  return (
    <Formik
      initialValues={accountDetails}
      onSubmit={values => onSubmit(values)}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      {({ resetForm }) => (
        <SettingSection
          disabled={disabled}
          active={active}
          onCancel={() => {
            onCancel();
            resetForm();
          }}
          onEdit={onEdit}
          sectionName={t(messages.accountEmailAddress())}
        >
          {active ? form : details}
        </SettingSection>
      )}
    </Formik>
  );
};
