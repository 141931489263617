import { memo, useState, useRef } from 'react';
import { times } from 'lodash-es';
import { pdfjs, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import animateScrollTo from 'animated-scroll-to';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { LoadingPage } from 'pages/Loading';
import { isIOS } from 'helpers/isIOS';
import { StyledDocument, TableOfContents, PageWrapper } from './styled';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  isIOS() ? 'pdf.worker.legacy.js' : 'pdf.worker.js',
  import.meta.url,
).toString();
const CMapsUrl = 'assets/cmaps/';
const StandardFontsUrl = 'assets/standard_fonts/';

interface RendererProps {
  path: string;
}

export const PDFRenderer = memo(({ path }: RendererProps) => {
  const { width: windowWidth } = useWindowDimensions(); // rerender on resize
  const [numPages, setNumPages] = useState<number | null>(null);
  const pagesContainer = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const scrollToPage = (pageNum: number) => {
    if (!pagesContainer.current) return;
    const toElement = pagesContainer.current.children[pageNum];
    if (!toElement) return;

    animateScrollTo(toElement, {
      elementToScroll: pagesContainer.current,
      speed: 750,
      maxDuration: 1500,
    });
  };

  return (
    <StyledDocument
      file={path}
      onLoadSuccess={onDocumentLoadSuccess}
      externalLinkTarget="_blank"
      options={{
        cMapUrl: CMapsUrl,
        cMapPacked: true,
        standardFontDataUrl: StandardFontsUrl,
      }}
      loading={
        <LoadingPage
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
          }}
        />
      }
    >
      <TableOfContents aria-hidden="true">
        {times(numPages ?? 0, i => (
          <Page
            pageNumber={i + 1}
            width={windowWidth / 5}
            key={i}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onClick={() => {
              scrollToPage(i);
            }}
            loading={null}
          />
        ))}
      </TableOfContents>
      <PageWrapper ref={pagesContainer}>
        {times(numPages ?? 0, i => (
          <Page
            pageNumber={i + 1}
            width={pagesContainer.current?.clientWidth ?? windowWidth}
            key={i}
            renderAnnotationLayer={true}
            renderTextLayer={true}
            loading={null}
          />
        ))}
      </PageWrapper>
    </StyledDocument>
  );
});
