import { useState, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import Session from 'api/storage/session';
import { RegistryNotifications } from 'api/interfaces/response/getRegistryNotificationsResponse';
import { useSetQueryData, useGetQueryData } from 'helpers/queries';
import { errorToast } from 'helpers/toast';
import { updateNotificationSettings } from './requests';
import { messages } from '../../messages';

export const useNotificationForm = (initialData: RegistryNotifications[]) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(initialData);
  const setQueryData = useSetQueryData();
  const getQueryData = useGetQueryData();
  const { current: userEmail } = useRef(Session.decodeToken()?.email ?? '');

  if (!values && initialData) setValues(initialData);

  const mutation = useMutation({
    mutationFn: updateNotificationSettings,
    onSuccess: (_, variables) => {
      setQueryData(['userRegistryNotifications', { userEmail }], () => ({
        registryNotifications: variables,
      }));
    },
    onError: () => {
      errorToast(t(messages.unknownError()));
      const previousData = getQueryData([
        'userRegistryNotifications',
        { userEmail },
      ]);
      if (previousData) {
        setValues(previousData.registryNotifications);
      }
    },
  });

  const debouncedSubmitForm = useDebouncedCallback(
    () => mutation.mutate(values),
    1000,
  );

  useEffect(
    () => () => {
      debouncedSubmitForm.flush();
    },
    [debouncedSubmitForm],
  );

  const handleChange = (registryId: string, email: string, value: boolean) => {
    const newValues = values.map(registry => {
      if (registry.registryId === registryId) {
        return {
          ...registry,
          surveysNotifications: {
            ...registry.surveysNotifications,
            participation:
              registry.surveysNotifications.participation.email === email
                ? {
                    ...registry.surveysNotifications.participation,
                    isActive: value,
                  }
                : registry.surveysNotifications.participation,
            persons: registry.surveysNotifications.persons.map(person =>
              person.email === email ? { ...person, isActive: value } : person,
            ),
          },
        };
      }
      return registry;
    });

    setValues(newValues);
    debouncedSubmitForm();
  };

  return {
    values,
    handleChange,
  };
};
