import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  healthRecords: () =>
    _t(
      translations.pages.settings.healthcareProviders.healthRecords,
      'Health records',
    ),
  seamlesslyLinkYourPatientPortalAccount: () =>
    _t(
      translations.pages.settings.healthcareProviders
        .seamlesslyLinkYourPatientPortalAccount,
      'Seamlessly link your patient portal account, allowing our research team to securely retrieve your medical records from the MyChart platform.',
    ),
  noAccountConnectedYet: () =>
    _t(
      translations.pages.settings.healthcareProviders.noAccountConnectedYet,
      'No account connected yet',
    ),
  connectNewAccount: () =>
    _t(
      translations.pages.settings.healthcareProviders.connectNewAccount,
      'Connect a new account',
    ),
  connectYouPatientPortalAccount: () =>
    _t(
      translations.pages.settings.healthcareProviders
        .connectYouPatientPortalAccount,
      'Connect your patient portal account',
    ),
  healthcareProvider: () =>
    _t(
      translations.pages.settings.healthcareProviders.healthcareProvider,
      'Healthcare provider',
    ),
  cancel: () =>
    _t(translations.pages.settings.healthcareProviders.cancel, 'Cancel'),
  connectAccount: () =>
    _t(
      translations.pages.settings.healthcareProviders.connectAccount,
      'Connect account',
    ),
  thisFieldCannotBeEmpty: () =>
    _t(
      translations.pages.settings.healthcareProviders.thisFieldCannotBeEmpty,
      'This field cannot be empty.',
    ),
  moreHealthcareProvidersComingSoon: () =>
    _t(
      translations.pages.settings.healthcareProviders
        .moreHealthcareProvidersComingSoon,
      'More healthcare providers coming soon!',
    ),
  unknownError: () =>
    _t(
      translations.pages.settings.healthcareProviders.unknownError,
      'Unknown error, try again later',
    ),
  reconnect: () =>
    _t(translations.pages.settings.healthcareProviders.reconnect, 'Reconnect'),
  yourHealthcareProviderAccountConnectedSuccessfully: () =>
    _t(
      translations.pages.settings.healthcareProviders
        .yourHealthcareProviderAccountConnectedSuccessfully,
      'Your healthcare provider account connected successfully.',
    ),
  connected: () =>
    _t(translations.pages.settings.healthcareProviders.connected, 'Connected'),
  disconnected: () =>
    _t(
      translations.pages.settings.healthcareProviders.disconnected,
      'Disconnected',
    ),
};
