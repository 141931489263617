import { useTranslation, Trans } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { IconList, ListItem } from 'components/IconList';
import { useProperlyThemedComponent } from 'hooks/useProperlyThemedComponent';
import participationIllustrationLight from './assets/participation.svg';
import participationIllustrationDark from './assets/participation_dark.svg';
import previewIllustrationLight from './assets/preview.svg';
import previewIllustrationDark from './assets/preview_dark.svg';
import { messages } from '../../messages';
import { IllustrationWrapper, PreviewIllustrationWrapper } from './styled';

interface Props {
  participantDescription?: string;
  participationRequirements?: string[];
}

export const ParticipationSection = ({
  participantDescription,
  participationRequirements,
}: Props) => {
  const { t } = useTranslation();

  const participationIllustration = useProperlyThemedComponent({
    light: participationIllustrationLight,
    dark: participationIllustrationDark,
  });

  const previewIllustration = useProperlyThemedComponent({
    light: previewIllustrationLight,
    dark: previewIllustrationDark,
  });

  return (
    <Stack
      padding={{
        xxl: '12rem 7.2rem',
        lg: '9.6rem 7.2rem',
        md: '7.2rem 4.8rem',
        xs: '5.6rem 2.4rem',
      }}
      gap={{
        lg: '12rem',
        sm: '9.5rem',
        xs: '7.6rem',
      }}
      maxWidth="144rem"
      margin="auto"
      boxSizing="content-box"
    >
      <Stack
        direction={{ lg: 'row', xs: 'column' }}
        gap={{ lg: '2.4', md: '7.2rem', xs: '5.6rem' }}
        width="100%"
        alignItems="center"
      >
        <Stack
          gap="1.6rem"
          width={{
            lg: '50%',
            xs: '100%',
          }}
        >
          <Typography variant="h1">
            {t(messages.whatIsActiveParticipation())}
          </Typography>
          <Stack gap="0.8rem">
            <Typography variant="body1">
              <Trans
                t={t}
                i18nKey={t(messages.yourInputIsImportant())}
                components={[<Typography variant="h5" component="span" />]}
              />
            </Typography>
            <Typography variant="body1">
              <Trans
                t={t}
                i18nKey={t(messages.weUsuallyAssign())}
                components={[<Typography variant="h5" component="span" />]}
              />
            </Typography>
            <Typography variant="body1">
              <Trans
                t={t}
                i18nKey={t(messages.participationIsColuntary())}
                components={[<Typography variant="h5" component="span" />]}
              />
            </Typography>
          </Stack>
        </Stack>
        <PreviewIllustrationWrapper>
          <img src={previewIllustration} alt="" />
        </PreviewIllustrationWrapper>
      </Stack>
      {participantDescription !== undefined && (
        <Stack
          direction={{ lg: 'row-reverse', xs: 'column' }}
          gap={{ lg: '2.4', md: '7.2rem', xs: '5.6rem' }}
          width="100%"
          alignItems="center"
        >
          <Stack
            gap="1.6rem"
            width={{
              lg: '50%',
              xs: '100%',
            }}
          >
            <Typography variant="h1">
              {t(messages.whoCanParticipate())}
            </Typography>
            <Typography variant="body1">{participantDescription}</Typography>
            {!!participationRequirements?.length && (
              <>
                <IconList title={t(messages.toParticipateMeetConditions())}>
                  {participationRequirements.map(requirement => (
                    <ListItem key={requirement}>{requirement}</ListItem>
                  ))}
                </IconList>
              </>
            )}
          </Stack>
          <IllustrationWrapper>
            <img src={participationIllustration} alt="" />
          </IllustrationWrapper>
        </Stack>
      )}
    </Stack>
  );
};
