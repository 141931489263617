import styled from 'styled-components';
import { Component as ComponentIcon } from 'iconsax-react';

export const StyledGoalList = styled.ul`
  padding-left: 1.6rem;
  list-style: none;
  margin: 0;

  li:not(:last-child) {
    margin-bottom: 0.8rem;
  }
  li {
    svg {
      color: ${({ theme }) => theme.palette.button.primary};
    }
  }
`;

export const GoalListIcon = styled(ComponentIcon)`
  height: 0.8rem;
  width: 0.8rem;
  min-width: 0.8rem;
  margin-top: 0.6rem;
  margin-bottom: 0.8rem;
`;
