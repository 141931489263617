import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
  Button,
} from '@mui/material';
import { useProperlyThemedComponent } from 'hooks/useProperlyThemedComponent';
import { WVULogo, WVULogoDark } from 'assets/logo';
import { messages } from '../../messages';

export const TopBar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const ThemedWVULogo = useProperlyThemedComponent({
    light: <WVULogo aria-hidden="true" />,
    dark: <WVULogoDark aria-hidden="true" />,
  });

  return (
    <Stack
      width="100%"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap="3.2rem"
      padding={{
        lg: '2.4rem 7.2rem 0',
        md: '1.6rem 4.8rem 0',
        xs: '1.6rem 2.4rem 0',
      }}
    >
      {ThemedWVULogo}
      <Stack direction="row" gap="0.6rem">
        {!isMobile && (
          <Typography whiteSpace="nowrap">
            {t(messages.alreadyMember())}
          </Typography>
        )}
        <Button
          variant="text"
          component={Link}
          to="/login"
          style={{ whiteSpace: 'nowrap' }}
        >
          {t(messages.login())}
        </Button>
      </Stack>
    </Stack>
  );
};
