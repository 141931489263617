import { XIcon } from 'assets/icons';
import styled from 'styled-components';

export const Wrapper = styled.div<{
  $timeout?: number;
}>`
  margin-top: 0.25rem;

  .progress-ring {
    position: absolute;
    width: 3rem;
    height: 3rem;
    transform: rotate(-90deg);

    .progress-ring__circle {
      @keyframes progress-ring-fillup {
        from {
          stroke-dashoffset: 0;
          stroke: ${({ theme }) => theme.palette.text.primary};
        }
        to {
          stroke-dashoffset: ${() => 13 * 2 * Math.PI};
          stroke: ${({ theme }) => theme.palette.text.primary};
        }
      }

      stroke-dasharray: ${() => 13 * 2 * Math.PI};
      fill: transparent !important;
      stroke: transparent;
      ${({ $timeout }) =>
        $timeout
          ? `animation: progress-ring-fillup linear ${$timeout}ms;`
          : 'stroke: transparent !important;'}
    }
  }
`;

export const CloseButton = styled(XIcon)`
  cursor: pointer;
  margin-left: 0.6rem;
  margin-top: 0.6rem;

  * {
    stroke: ${({ theme }) => theme.palette.text.primary};
  }
`;
