import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { messages } from 'pages/Registry/messages';
import { RegistryPerson } from 'api/interfaces/response/getRegistryPersonsResponse';
import { IconButton } from 'components/IconButton';
import { DeleteIcon, EditIcon } from 'assets/icons';
import { CaregiverSectionWrapper } from './styled';
import { DeleteCaregiverRoleModal } from '../DeleteCaregiverRoleModal';
import { CaregiverInfoRow } from './CaregiverInfoRow';
import { AddCaregiverRow } from './AddCaregiverRow';
import { CaregiverDetailsModal } from '../../../../components/CaregiverDetailsModal';

interface Props {
  registryId: string;
  registryName: string;
  persons?: RegistryPerson[];
  caregiverRoleId: string;
}

export const CaregiverDetailsSection = ({
  registryId,
  registryName,
  persons,
  caregiverRoleId,
}: Props) => {
  const { t } = useTranslation();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const caregiverPerson = persons?.find(person => person.role === 'caregiver');

  if (!persons) return null;
  return (
    <>
      <DeleteCaregiverRoleModal
        showModal={showDeleteConfirmation}
        closeModal={() => setShowDeleteConfirmation(false)}
        registryId={registryId}
        registryName={registryName}
        personId={caregiverPerson?.id ?? ''}
      />
      {showEditModal && (
        <CaregiverDetailsModal
          open={showEditModal}
          onCancel={() => setShowEditModal(false)}
          onSuccess={() => setShowEditModal(false)}
          registryId={registryId}
          initialValues={caregiverPerson}
          roleId={caregiverPerson?.roleId ?? caregiverRoleId} // todo improve
          personId={caregiverPerson?.id}
          requireConfirmation={false}
        />
      )}
      <div>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4" paddingY="0.7rem">
            {t(messages.caregiverDetails())}
          </Typography>
          {caregiverPerson && (
            <Stack direction="row" gap="1.6rem">
              <IconButton
                data-cy="edit-caregiver-info-button"
                hoverMessage={t(messages.editCaregiver())}
                onClick={() => setShowEditModal(true)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="warning"
                data-cy="remove-caregiver-info-button"
                hoverMessage={t(messages.deleteCaregiver())}
                onClick={() => setShowDeleteConfirmation(true)}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          )}
        </Stack>
        <CaregiverSectionWrapper>
          {caregiverPerson ? (
            <CaregiverInfoRow caregiver={caregiverPerson} />
          ) : (
            <AddCaregiverRow onClick={() => setShowEditModal(true)} />
          )}
        </CaregiverSectionWrapper>
      </div>
    </>
  );
};
