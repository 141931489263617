import { memo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as SideIllustrationLight } from './side.svg';
import { ReactComponent as SideIllustrationDark } from './side_dark.svg';

export const SideIllustration = memo((props: React.SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  if (theme.palette.type === 'dark')
    return <SideIllustrationDark aria-hidden="true" {...props} />;
  return <SideIllustrationLight aria-hidden="true" {...props} />;
});
