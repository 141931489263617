import { RegistryNotifications } from 'api/interfaces/response/getRegistryNotificationsResponse';
import { UpdateRegistryNotificationsRequest } from 'api/interfaces/request/updateRegistryNotificationsRequest';

export const mapNotifications = (
  notifications: RegistryNotifications[],
): UpdateRegistryNotificationsRequest => {
  return {
    registryNotifications: notifications.map(notification => ({
      registryId: notification.registryId,
      surveysNotifications: {
        participation: {
          participationId:
            notification.surveysNotifications.participation.participationId,
          isActive: notification.surveysNotifications.participation.isActive,
        },
        persons: notification.surveysNotifications.persons.map(person => ({
          personId: person.personId,
          isActive: person.isActive,
        })),
      },
    })),
  };
};
