import { translations } from 'locales/translations';
import { _t } from 'helpers/messages';

export const messages = {
  stillWithUs: () =>
    _t(translations.components.AppLogout.stillWithUs, 'Still with us?'),
  beenInactiveForWhile: () =>
    _t(
      translations.components.AppLogout.beenInactiveForWhile,
      'You’ve been inactive for a while. For your security, we will automatically sign you out in approximately <0>{{timeout}}s</0>. Do you want to extend your current session?',
    ),
  signOutNow: () =>
    _t(translations.components.AppLogout.signOutNow, 'Sign out now'),
  extendSession: () =>
    _t(translations.components.AppLogout.extendSession, 'Extend session'),
};
