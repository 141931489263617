import styled from 'styled-components';
import { ErrorIcon as UnstyledErrorIcon } from 'assets/icons';
import { useTypography } from 'helpers/mixins';

export const ErrorIcon = styled(UnstyledErrorIcon)`
  margin: 0 0.2rem;
  * {
    fill: ${({ theme }) => theme.palette.message.text.error};
  }
`;

export const Title = styled.div`
  ${({ theme }) => useTypography(theme.typography.body2)}
  color: ${({ theme }) => theme.palette.button.text};
  line-height: 125%;
`;
