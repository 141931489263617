import { Stack, Typography } from '@mui/material';
import { Icon as IconType } from 'iconsax-react';
import { Statistics } from './styled';

interface Props {
  label: string;
  number: number | string;
  Icon: IconType;
}

export const SingleStatistic = ({ label, number, Icon }: Props) => (
  <Stack gap="3rem" direction="row" alignItems="center">
    <Icon variant="Bold" />
    <Stack direction="column">
      <Statistics>{number}</Statistics>
      <Typography variant="body1">{label}</Typography>
    </Stack>
  </Stack>
);
