import { memo } from 'react';
import { WVULogoDark } from 'assets/logo';
import { SideIllustration } from 'assets/illustrations';
import { Container, LogoContainer } from './styled';

export const SidePanel = memo(() => {
  return (
    <Container>
      <LogoContainer>
        <WVULogoDark />
      </LogoContainer>
      <SideIllustration />
    </Container>
  );
});
