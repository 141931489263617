import { useState } from 'react';
import { EhrDialogState } from './index';

export const useEhrDialogState = () => {
  const [showEhrDialog, setShowEhrDialog] = useState(EhrDialogState.isOpened());

  const closeEhrDialog = () => {
    setShowEhrDialog(false);
    EhrDialogState.saveState(false);
  };

  return [showEhrDialog, closeEhrDialog] as const;
};
