import * as yup from 'yup';
import i18next from 'i18next';
import { messages } from '../../messages';
import { regexPatterns } from '../../../../helpers/regexPatterns';

export const getValidationSchema = () =>
  yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required(i18next.t(messages.emptyFirstName())),
    lastName: yup.string().trim().required(i18next.t(messages.emptyLastName())),
    dateOfBirth: yup
      .date()
      .typeError(i18next.t(messages.invalidDateOfBirth()))
      .required(i18next.t(messages.emptyDateOfBirth()))
      .min(new Date('1900-01-01'), i18next.t(messages.invalidDateOfBirth()))
      .max(new Date(), i18next.t(messages.dateOfBirthMustBeBeforeToday())),
    phone: yup
      .string()
      .optional()
      .matches(
        regexPatterns.phoneNumber,
        i18next.t(messages.incorrectPhoneNumberFormat()),
      )
      .min(6, i18next.t(messages.incorrectPhoneNumberFormat())),
  });
