import { useCallback, useEffect, useRef, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { prepareSurveyForFlutterModule } from 'helpers/prepareSurveyForFlutterModule';
import { GetRegistrySurveyResponse } from 'api/interfaces/response';
import { useTheme } from 'styles/theme/ThemeProvider';
import { EventNames } from 'types/analytics';
import { errorToast } from 'helpers/toast';
import {
  FlutterMessageEvent,
  SurveyResult,
  FlutterMessageEventTypes,
} from './types';

interface Props extends React.HTMLAttributes<HTMLIFrameElement> {
  onSuccess(result: SurveyResult): void;
  onInitialized(): void;
  survey?: GetRegistrySurveyResponse;
  hideProgressBar?: boolean;
  hideFullScreenPrefixes?: boolean;
  registryId?: string;
}

export const SurveyModule = ({
  onSuccess,
  onInitialized,
  survey,
  hideProgressBar = false,
  hideFullScreenPrefixes = false,
  registryId,
  ...props
}: Props) => {
  const theme = useTheme();
  const [isFlutterModuleLoaded, setIsFlutterModuleLoaded] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const questionStartTime = useRef<Date>(new Date());

  const sendSurvey = () => {
    if (!iframeRef.current) return;
    if (!survey) return;

    const { origin } = window;
    const parsedSurvey = prepareSurveyForFlutterModule(survey);

    iframeRef.current?.contentWindow?.postMessage(
      JSON.stringify({
        source: 'rni_web_app',
        survey: parsedSurvey,
      }),
      origin,
    );
  };

  useEffect(() => {
    if (isFlutterModuleLoaded && survey) sendSurvey();
  }, [survey, isFlutterModuleLoaded]);

  const handleInitializedMessage = () => {
    setIsFlutterModuleLoaded(true);
    onInitialized();
  };

  const onMessage = useCallback(
    (event: FlutterMessageEvent) => {
      if (event.data?.source !== 'rni_web_surveys') return;
      if (!event.data) return;

      switch (event.data.type) {
        case FlutterMessageEventTypes.MODULE_READY:
          handleInitializedMessage();
          questionStartTime.current = new Date();
          break;
        case FlutterMessageEventTypes.SURVEY_COMPLETED:
          mixpanel.track(EventNames.SURVEY_COMPLETED, {
            survey_id: survey?.id,
            registry_id: registryId,
          });

          if (event.data.data) onSuccess(event.data.data as SurveyResult);
          else errorToast('Error parsing survey results. Contact IT support');
          break;
        case FlutterMessageEventTypes.QUESTION_CHANGED:
          if (event.data.data) {
            try {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const { results } = event.data.data as any;

              if (!results) return;

              const questionIndex = results.length - 1;
              const question = results[questionIndex];

              if (!question) return;
              const { questionVersionId, questionAssignmentId, answers } =
                question;

              const questionEndTime = new Date();
              const questionDuration =
                questionEndTime.getTime() - questionStartTime.current.getTime();

              mixpanel.track(EventNames.SURVEY_QUESTION_ANSWERED, {
                survey_id: survey?.id,
                question_duration: questionDuration,
                question_version_id: questionVersionId,
                question_assignment_id: questionAssignmentId,
                question_index: questionIndex,
                skipped: answers === null,
                registry_id: registryId,
              });
              // eslint-disable-next-line no-empty
            } catch (e) {}
          }
          questionStartTime.current = new Date();
          break;
      }
    },
    [onSuccess],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage, false);

    return () => window.removeEventListener('message', onMessage, false);
  }, [onMessage]);

  const getUrlWithParameters = () => {
    const params: string[] = [];
    if (theme.palette.type === 'dark') params.push('dark="true"');
    if (hideProgressBar) params.push('hideProgressBar="true"');
    if (hideFullScreenPrefixes) params.push('hideFullScreenPrefixes="true"');

    return `/flutter/surveys/index.html?${params.join('&')}`;
  };

  return (
    <iframe
      title="survey"
      ref={iframeRef}
      src={getUrlWithParameters()}
      width="100%"
      height="100%"
      {...props}
    />
  );
};
