/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styles/theme/ThemeProvider';
import { UserProvider } from 'providers/UserProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: false,
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    },
  },
});

const Providers = ({ children }: { children?: React.ReactNode }) => (
  <ThemeProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <UserProvider>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>{children}</HelmetProvider>
        </QueryClientProvider>
      </UserProvider>
    </LocalizationProvider>
  </ThemeProvider>
);

export default Providers;
